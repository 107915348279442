import { fromJS } from "immutable";
import store from "store";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import userStatusActions from "modules/userStatus/redux/actions";
import { database } from "./firebaseConfig";
import { getCurrentSpaceId } from "../../helpers/reduxHelper";
import { SPACE_PATH, USER_STATUS_PATH } from "./storagePaths";

const syncUserStatusWithRedux = async (
  userStatusResult: firebase.database.DataSnapshot,
  key: string | null,
) => {
  if (userStatusResult.val()) {
    const data = key
      ? { [key]: userStatusResult.val() }
      : userStatusResult.val();

    store.dispatch(userStatusActions.syncUserStatus(fromJS(data)));
  }
};

// handle sync user status logics

export const unsubscribeUserStatus = () => {
  const spaceId = getCurrentSpaceId();

  database.ref(`${SPACE_PATH}/${spaceId}/${USER_STATUS_PATH}`).off();
};

export const handleSyncUserStatus = () => {
  const spaceId = getCurrentSpaceId();
  const dbRef = database.ref(`${SPACE_PATH}/${spaceId}/${USER_STATUS_PATH}`);

  dbRef.off();

  const handler = async (userStatusResult: firebase.database.DataSnapshot) => {
    if (userStatusResult) {
      syncUserStatusWithRedux(userStatusResult, userStatusResult.key);
    }
  };

  // Listen for changes only after getting all values
  dbRef.on("child_changed", handler);
  dbRef.on("child_added", handler);
  dbRef.on("child_removed", async (userStatusResult) => {
    const { key } = userStatusResult;

    if (key) {
      store.dispatch(userStatusActions.removeUserStatus(key));
    }
  });
};
