import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITranscriptionReducerState {
  isLoading: boolean;
  showCaptions: boolean;
  showLiveCaptions: boolean;
  shouldProcessCaptions: boolean;
}

export const transcriptionInitialState: ITranscriptionReducerState = {
  isLoading: false,
  showCaptions: false,
  showLiveCaptions: false,
  shouldProcessCaptions: false,
};

export const transcriptionSlice = createSlice({
  name: "transcription",
  initialState: transcriptionInitialState,
  reducers: {
    toggleShowCaptions: (state) => {
      state.showCaptions = !state.showCaptions;
    },
    toggleShowLiveCaptions: (state) => {
      state.showLiveCaptions = !state.showLiveCaptions;
    },
    hideCaptions: (state) => {
      state.showCaptions = false;
    },
    hideLiveCaptions: (state) => {
      state.showLiveCaptions = false;
    },
    turnOnProcessing: (state) => {
      state.shouldProcessCaptions = true;
    },
    turnOffProcessing: (state) => {
      state.shouldProcessCaptions = false;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    reset: () => transcriptionInitialState,
  },
});

export const {
  toggleShowCaptions,
  toggleShowLiveCaptions,
  hideCaptions,
  hideLiveCaptions,
  turnOnProcessing,
  turnOffProcessing,
  setIsLoading,
  reset,
} = transcriptionSlice.actions;

export default transcriptionSlice.reducer;
