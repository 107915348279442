import { useI18n } from "i18n";
import { ActionButton } from "modules/actionButton";
import { Settings } from "@remo-co/ui-core/src/icons/Settings";
import useCameraSettingsActions from "modules/cameraSettings/hooks/useCameraSettingsActions";

export const SettingsButton = () => {
  const { t } = useI18n("micCamCheck");
  const { showCameraSettingsDialog } = useCameraSettingsActions();

  const handleSettings = () => showCameraSettingsDialog();

  return (
    <ActionButton
      data-testid="settings-button"
      onClick={handleSettings}
      label={t("settings")}
      icon={<Settings />}
    />
  );
};
