import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import { ILoaderProps } from "./Loader";

export const useStyles = makeStyles<Theme, ILoaderProps>(() => ({
  root: ({ backgroundColor, loaderColor }) => ({
    backgroundColor,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: loaderColor,
  }),
}));
