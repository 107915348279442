import * as Immutable from "immutable";
import { IConfigProvider, ISsoInfo } from "modules/auth/sso/types";
import { ICompanySettings } from "modules/company/types";
import { IEvent, ISponsor } from "modules/event/types";
import { IEventMember } from "modules/eventMemberList/types";
import { ISubscriptionDetail } from "types/subscription";
import { LiveStreamData } from "modules/liveStream/request";
import {
  sendDeleteRequest,
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from ".";

type EventResponse = APIResponse<{
  event?: IEvent;
  eventLiveStreams?: LiveStreamData[];
}>;

export type EventArrayResponse = APIResponse<{
  events: IEvent[];
  totalCount: number;
}>;

type ProviderResponse = APIResponse<{
  provider: IConfigProvider;
}>;

type EventSponsorResponse = APIResponse<{
  sponsors?: ISponsor[];
}>;

type EventUpdateResponse = APIResponse<{
  event?: IEvent;
  companySettings?: ICompanySettings;
  data?: {
    isValidationError: boolean;
    validationError: string;
    concurrentEvents: IEvent[];
  };
}>;

type GetQAResponse =
  | {
      valid: true;
      data: string | ArrayBuffer | ArrayBufferView | Blob;
    }
  | {
      valid: false;
      message: string;
    };

type CompanySettingsResponse = APIResponse<{
  companySettings?: ICompanySettings;
}>;

interface IChargebeePortalSession {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  portal_session: any;
}

export const getOngoingEvents = (
  companyId: string,
): Promise<EventArrayResponse> =>
  sendGetRequest(`/company-event/${companyId}/ongoing-events`);

export const getUpcomingEvents = (
  companyId: string,
): Promise<EventArrayResponse> =>
  sendGetRequest(`/company-event/${companyId}/upcoming-events`);

export interface PageParam {
  id: string;
  endTime: string;
  countEndTime: string;
}

export const getPastEvents = (
  companyId: string | null,
  pageParam: PageParam = {
    id: "",
    endTime: new Date().toISOString(),
    countEndTime: new Date().toISOString(),
  },
): Promise<EventArrayResponse> =>
  sendGetRequest(
    `/company-event/${companyId}/past-events?endTime=${
      pageParam.endTime
    }&pageCursor=${pageParam.id}&countEndTime=${pageParam.countEndTime || ""}`,
  );

export const getJoinedEvents = (
  time: number,
): Promise<
  APIResponse<{
    pastEvents: IEvent[];
    upcomingEvents: IEvent[];
    ongoingEvents: IEvent[];
  }>
> => sendGetRequest(`/event/joined-events?time=${time}`);

export const getEvent = (eventIdOrCode: string): Promise<EventResponse> =>
  sendGetRequest(`/event/${eventIdOrCode}`);

export const getEventByCode = (eventCode: string): Promise<EventResponse> =>
  sendGetRequest(`/event/code/${eventCode}`);

export const getCompanySettingsByEventCode = (eventCode: string) =>
  sendGetRequest<ICompanySettings>(`/event/code/${eventCode}/company-settings`);
export const getCompanyPublicSettingsByEventCode = (eventCode: string) =>
  sendGetRequest<ICompanySettings>(
    `/event/code/${eventCode}/company-settings/public`,
  );
export const getCompanyPublicSettingsByCustomDomain = (customDomain: string) =>
  sendGetRequest<ICompanySettings>(
    `/event/custom-domain/${customDomain}/company-settings/public`,
  );

export const createEvent = (
  companyId: string,
  data: Optional<IEvent, "id">,
): Promise<EventUpdateResponse> =>
  sendPostRequest(`/company-event/${companyId}/event`, data);

export const updateEvent = (
  data: Optional<IEvent, "id">,
): Promise<EventUpdateResponse> =>
  sendPostRequest(`/event/${data.id ? `${data.id}` : ""}`, data);

export const getEventData = (eventId: string): Promise<EventResponse> =>
  sendGetRequest(`/event/${eventId}`);

export const getSponsorsInEvent = (
  eventId: string,
): Promise<EventSponsorResponse> => sendGetRequest(`/event-sponsor/${eventId}`);

export const getEventFromToken = (data: {
  inviteToken: string;
}): Promise<
  APIResponse<{
    event: IEvent;
    company: {
      name: string;
      logoURL: string;
    };
    eventLiveStreams: LiveStreamData[];
  }>
> => sendGetRequest(`/event/token?inviteToken=${data.inviteToken}`);

export const registerUserToEvent = (data: {
  eventId: string;
  timezone: string;
}): Promise<
  APIResponse<{
    eventMember: IEventMember;
  }>
> => sendPostRequest("/event-member/register", data);

export const getEventMemberStatus = (
  eventId: string,
): Promise<APIResponse<{ data?: IEventMember; message?: string }>> =>
  sendGetRequest(`/event-member/${eventId}`);

export const attendEvent = (
  eventId: string,
): Promise<
  APIResponse<{
    member: IEventMember;
  }>
> => sendPostRequest(`/event-member/${eventId}/attend`, {});

export const admitAllLobbyGuests = (eventId: string): Promise<APIResponse> =>
  sendPostRequest(`/lobby/${eventId}/admit-all`, {});

export const extendEventEndTime = (data: {
  event?: string;
  extendBy: number;
}): Promise<{ isSuccess: boolean; message?: string }> =>
  sendPostRequest(`/event/${data.event}/extend`, data);

export const deleteEventApi = (eventId: string): Promise<APIResponse> =>
  sendPostRequest(`/event/${eventId}/delete`, {});

export const stopEventApi = (eventId: string): Promise<EventUpdateResponse> =>
  sendPostRequest(`/event/${eventId}/stop`, {});

export const reopenEventApi = (eventId: string): Promise<EventUpdateResponse> =>
  sendPostRequest(`/event/${eventId}/reopen`, {});

export const addSponsorInEvent = (
  eventId: string,
  sponsor: ISponsor,
): Promise<EventSponsorResponse> =>
  sendPostRequest(`/event-sponsor/${eventId}`, { sponsor });

export const updateSponsorInEvent = (
  eventId: string,
  sponsor: ISponsor,
): Promise<EventSponsorResponse> =>
  sendPutRequest(`/event-sponsor/${eventId}`, { sponsor });

export const deleteSponsorInEvent = (
  eventId: string,
  sponsorId: string,
): Promise<EventSponsorResponse> =>
  sendDeleteRequest(`/event-sponsor/${eventId}/delete/${sponsorId}`);

export const addMemberToSponsor = (
  eventId: string,
  sponsor: string,
): Promise<APIResponse> =>
  sendPostRequest(`/event-sponsor-member/${eventId}/${sponsor}`, {});

export const exportMembersBySponsor = (
  eventId: string,
  sponsor: string,
  timezone: string,
): Promise<
  APIResponse<{
    data?: string;
  }>
> =>
  sendGetRequest(
    `/event-sponsor-member/export/${eventId}/${sponsor}?timezone=${timezone}`,
  );

export const exportQuestions = (
  theaterId: string,
  eventId: string,
): Promise<GetQAResponse> =>
  sendGetRequest(`/theater/questions/export/${theaterId}/${eventId}`);

export const updateRoomName = (
  roomId: string,
  name: string,
  isForAllRooms: boolean,
  roomCode: string,
  eventId: string,
  // eslint-disable-next-line max-params
): Promise<APIResponse> =>
  sendPostRequest(`/room/${roomId}/change-name/${eventId}`, {
    name,
    isForAllRooms,
    code: roomCode,
  });

export const getChargebeePortalSession = (): Promise<
  APIResponse<IChargebeePortalSession>
> => sendGetRequest("/chargebee/generate-portal-session");

export const getChargebeeSubscriptionDetail = (): Promise<
  APIResponse<ISubscriptionDetail>
> => sendGetRequest("/chargebee/subscription-detail");

export const deleteRecording = ({
  eventId,
  recordingId,
}: {
  eventId: string;
  recordingId: string;
}): Promise<IGenericAPIResponse> =>
  sendDeleteRequest(`/daily/${eventId}/recordings/${recordingId}`);

export const getRecordingAccessLink = ({
  eventId,
  recordingId,
}: {
  eventId: string;
  recordingId: string;
}): Promise<APIResponse<{ download_link: string; expires: number }>> =>
  sendGetRequest(`/daily/${eventId}/recordings/${recordingId}/access-link`);

export const getUnreadCount = (
  spaceId: string,
  time: number,
): Promise<Immutable.Map<string, number>> =>
  sendGetRequest(`/chat/unread/${spaceId}?time=${time}`);

export const exportChat = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
): Promise<
  APIResponse<{
    data?: string;
    valid?: boolean;
  }>
> => sendPostRequest("/chat/export/", data);

// company settings
export const updateExitURL = (
  companyId: string,
  exitURL: string,
): Promise<CompanySettingsResponse> =>
  sendPostRequest(`/company/${companyId}/settings/exit-door`, { exitURL });

export const createSamlConfig = (
  companyId: string,
  config: Pick<ISsoInfo, "displayName" | "providerId" | "enabled">,
): Promise<CompanySettingsResponse> =>
  sendPostRequest(`/company/${companyId}/settings/saml-config`, config);

export const updateSamlConfig = (
  companyId: string,
  config: Pick<ISsoInfo, "displayName" | "providerId" | "enabled">,
): Promise<CompanySettingsResponse> =>
  sendPutRequest(`/company/${companyId}/settings/saml-config`, config);

export const updateCompanySettings = (
  companyId: string,
  companySettings: Partial<ICompanySettings> | null,
): Promise<CompanySettingsResponse> =>
  sendPostRequest(`/company/${companyId}/settings/update-company-settings`, {
    companySettings,
  });

export const deleteWhiteLabelLogoURL = (
  companyId: string,
): Promise<CompanySettingsResponse> =>
  sendDeleteRequest(
    `/company/${companyId}/settings/delete-whitelabel-logo-url`,
  );

export const deleteWhiteLabelFaviconURL = (
  companyId: string,
): Promise<CompanySettingsResponse> =>
  sendDeleteRequest(
    `/company/${companyId}/settings/delete-whitelabel-favicon-url`,
  );

export const deleteWhiteLabelSignInBackgroundURL = (
  companyId: string,
): Promise<CompanySettingsResponse> =>
  sendDeleteRequest(
    `/company/${companyId}/settings/delete-whitelabel-sign-in-page-background-url`,
  );

export const deleteCustomDomain = (
  companyId: string,
): Promise<CompanySettingsResponse> =>
  sendDeleteRequest(`/company/${companyId}/settings/delete-custom-domain`);

export const getProvider = (providerId: string): Promise<ProviderResponse> =>
  sendGetRequest(`/sso/provider?providerId=${providerId}`);

export const createProvider = (
  data: IConfigProvider,
): Promise<ProviderResponse> => sendPostRequest("/sso/provider", data);

export const updateProvider = (
  data: IConfigProvider,
): Promise<ProviderResponse> =>
  sendPutRequest(`/sso/provider?providerId=${data.providerId}`, data);

interface RecordingManagement {
  eventId: string;
  action: "start" | "stop";
  sessionId: string;
  layout?: "bestFit" | "verticalPresentation";
}

export const manageRecording = async ({
  eventId,
  action,
  sessionId,
  layout,
}: RecordingManagement): Promise<APIResponse> => {
  try {
    const resp = await sendPostRequest<
      Pick<RecordingManagement, "action" | "sessionId" | "layout">,
      IGenericAPIResponse
    >(`/event/${eventId}/recordings`, {
      action,
      sessionId,
      layout,
    });

    return resp;
  } catch (anyError) {
    const error = anyError as Error;

    return { isSuccess: false, message: error.message };
  }
};
