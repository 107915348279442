import { ReactNode, ComponentPropsWithRef, ClipboardEvent } from "react";
import TextField from "@material-ui/core/TextField";
import { Theme } from "@material-ui/core/styles/createTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import { styles } from "./styles";

export type FormTextInputProps = ComponentPropsWithRef<typeof TextField> & {
  tooltip?: string | ReactNode;
  autoFocus?: boolean;
  getRemainingCharsMessage?: (remainingChars: string) => string;
};

const useStyles = makeStyles<Theme, FormTextInputProps>(styles);

const FormTextInput = (props: FormTextInputProps) => {
  const {
    inputProps,
    InputLabelProps,
    autoComplete,
    tooltip,
    fullWidth,
    label,
    value,
    helperText,
    autoFocus = false,
    getRemainingCharsMessage,
    ...rest
  } = props;

  const handleOnPaste = (e: ClipboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const classes = useStyles(props);
  const currentLength = value ? `${value}`.length : 0;

  const input = (
    <div
      style={{
        marginBottom: "10px",
        width: fullWidth ? "100%" : "fit-content",
        marginTop:
          InputLabelProps && InputLabelProps.shrink && label ? "30px" : "10px",
      }}
    >
      <TextField
        autoFocus={autoFocus}
        label={label}
        fullWidth={fullWidth}
        onPaste={handleOnPaste}
        autoComplete={autoComplete || "off"}
        value={value}
        inputProps={{
          ...inputProps,
          className:
            inputProps && inputProps.className
              ? inputProps.className
              : classes.formTextInput,
        }}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: false,
          className: `${
            InputLabelProps && InputLabelProps.shrink
              ? classes.formTextInputShrinkLabel
              : ""
          }
          ${InputLabelProps ? InputLabelProps.className : ""}`,
        }}
        helperText={
          getRemainingCharsMessage && inputProps?.maxLength ? (
            <>
              {getRemainingCharsMessage?.(
                `${inputProps.maxLength - currentLength}`,
              )}
              <br />
              {helperText}
            </>
          ) : (
            helperText ?? ""
          )
        }
        {...rest}
      />
    </div>
  );

  if (!tooltip) {
    return input;
  }

  return (
    <Tooltip title={tooltip} placement="top">
      {input}
    </Tooltip>
  );
};

export default FormTextInput;
