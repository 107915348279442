import { getVar } from "config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import logger from "logging/logger";

// TODO: this does not use any react hooks. Doesn't need to be a hook.
const fireStorage = () => {
  const deleteFile = (filePath: string) =>
    firebase.storage().ref(filePath).delete();

  const listFiles = async (path: string): Promise<string[]> => {
    const files = await firebase.storage().ref(path).listAll();
    const paths = files.items.map((item) => item.fullPath);

    return paths;
  };

  const getFileDownloadURL = async (filePath: string): Promise<string> =>
    firebase.storage().ref(filePath).getDownloadURL();

  const getAssetsUrl = async (filePath: string): Promise<string> => {
    const url = await getFileDownloadURL(filePath);

    try {
      if (getVar("NODE_ENV") === "development") {
        return url;
      }

      const assetsUrl = `${getVar("REACT_APP_ASSETS_URL")}${decodeURIComponent(
        url.split("/o/")[1],
      )}`;

      return assetsUrl;
    } catch (err) {
      if (err instanceof Error) {
        logger.error(`[getAssetsUrl] err: ${err.message} path: ${filePath}`);
      }
    }

    return url;
  };

  return {
    deleteFile,
    listFiles,
    getFileDownloadURL,
    getAssetsUrl,
  };
};

export default fireStorage;
