import { EventContentMediaType, EventContentType } from "graphql/generated";
import { boolean, InferType, object, string, StringSchema } from "yup";
import { validateVideoURL } from "helpers/videoHelper";
import { ContentFormState } from "modules/eventContent/types";
import { isValidURL } from "../isValidURL";

export const validationSchema = object().shape(
  {
    name: string().required(),
    type: string()
      .required()
      .oneOf([
        EventContentType.EventFloor,
        EventContentType.FullScreen,
        EventContentType.Popup,
      ]) as StringSchema<EventContentType>,
    mediaType: string()
      .required()
      .oneOf([
        EventContentMediaType.Iframe,
        EventContentMediaType.Image,
        EventContentMediaType.Text,
        EventContentMediaType.Video,
      ]) as StringSchema<EventContentMediaType>,
    media: string()
      .required()
      .when("mediaType", (mediaType, schema: StringSchema) => {
        switch (mediaType) {
          case EventContentMediaType.Iframe:
            return schema.test(
              "check-iframe-url",
              ({ path }) => `${path} is not a valid iframe URL`,
              (media) => Boolean(media && isValidURL(media)),
            );

          case EventContentMediaType.Video:
            return schema.test(
              "check-iframe-url",
              ({ path }) => `${path} is not a valid video URL`,
              (media) => Boolean(media && validateVideoURL(media)),
            );

          case EventContentMediaType.Image: // guaranteed to be valid unless uploader errors out
          case EventContentMediaType.Text:
          default:
            return schema;
        }
      }),
    floorImage: string().when("type", (type, schema: StringSchema) =>
      type !== EventContentType.EventFloor
        ? schema.required()
        : schema.nullable(),
    ),
    ctaLink: string().when("ctaText", (ctaText, schema: StringSchema) =>
      ctaText
        ? schema.required().test(
            "check-cta-link-url",
            ({ path }) => `${path} is not a valid URL`,
            (link) => Boolean(link && isValidURL(link)),
          )
        : schema.nullable(),
    ),
    ctaText: string().when("ctaLink", (ctaLink, schema: StringSchema) =>
      ctaLink ? schema.required() : schema.nullable(),
    ),
    hideOpenInNewTabButton: boolean().optional().nullable(),
  },
  [["ctaLink", "ctaText"]],
);

export type ValidatedContentData = InferType<typeof validationSchema>;

export const validateContent = (
  content: Partial<ContentFormState>,
): ValidatedContentData => validationSchema.validateSync(content);
