import { MouseEvent } from "react";
import { useSelector } from "react-redux";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { selectIsLobbyBannerOpen } from "modules/lobby/redux/selectors";
import { selectIsMobile } from "modules/deviceInfo";
import { useEnforceTableAssignment } from "../../hooks";
import { EnforceCountdownTimer } from "../EnforceCountdownTimer";
import useStyles from "./styles";

const EnforceCountdownMapBanner = (): JSX.Element => {
  const { t } = useI18n(["event"]);
  const { showInProgressDialog, showCountdownDialog, hideCountdownBanner } =
    useEnforceTableAssignment();
  const isLobbyBannerOpen = useSelector(selectIsLobbyBannerOpen);
  const isMobileView = useSelector(selectIsMobile);
  const styles = useStyles();

  const showDialog = (e: MouseEvent) => {
    e.stopPropagation();
    hideCountdownBanner();
    showCountdownDialog();
  };

  const handleCountdownEnd = () => {
    hideCountdownBanner();
    showInProgressDialog();
  };

  if (isMobileView) {
    return (
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="row"
        className={styles.container}
      >
        <EnforceCountdownTimer size={30} onCountdownEnd={handleCountdownEnd} />
        <Typography variant="body1" className={styles.mobileText}>
          {t("seconds.left.transfers")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="row"
      onClick={showDialog}
      className={styles.container}
    >
      <EnforceCountdownTimer size={40} onCountdownEnd={handleCountdownEnd} />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
        ml={3}
      >
        <Typography variant="body1">
          {t("enforce.table.assignment.banner.title")}
        </Typography>
        {!isLobbyBannerOpen && (
          <Typography variant="caption">{t("wrap.conversation")}</Typography>
        )}
      </Box>
      <Button
        variant="secondary"
        color="dark"
        size="sm"
        onClick={showDialog}
        className={styles.button}
      >
        {isLobbyBannerOpen ? t("view") : t("view.more")}
      </Button>
    </Box>
  );
};

export default EnforceCountdownMapBanner;
