import { useContext } from "react";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { ActionButton } from "modules/actionButton";
import { PresentContentIcon } from "modules/eventEmbedContent/icons";
import { DialogType } from "modules/dialogNotification/constants";

import {
  selectCurrentEvent,
  selectCurrentEventCompanyPlan,
} from "modules/event/selectors";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectPresentationModeContent } from "modules/eventEmbedContent/redux";
import { usePresentContentActions } from "modules/eventEmbedContent/hooks";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { SharePresentContentDialogContent } from "../SharePresentContentDialogContent";
import useStyles from "./styles";

const PresentContentButton = (): JSX.Element | null => {
  const { track } = useContext(TRACKING_CONTEXT);
  const content = useSelector(selectPresentationModeContent);
  const { openDialog, closeDialogByType, updateDialogParams } =
    useDialogNotificationActions();
  const currentEvent = useSelector(selectCurrentEvent);
  const { shareContent, unShareContent, isLoading } =
    usePresentContentActions(currentEvent);

  const { t } = useI18n(["event", "common"]);
  const styles = useStyles();
  const { isFreePlan } = useCompanyPlanSettings();

  const eventCompanyPlan = useSelector(selectCurrentEventCompanyPlan);

  if (isFreePlan(eventCompanyPlan)) {
    return null;
  }

  const handleClose = () => {
    closeDialogByType(DialogType.PRESENT_CONTENT_SHARE);
  };

  const handleConfirm = async (url: string) => {
    await shareContent(url);
    handleClose();
  };

  const handleUrlChange = (url: string, confirmDisabled: boolean) => {
    updateDialogParams(DialogType.PRESENT_CONTENT_SHARE, {
      content: <SharePresentContentDialogContent onChange={handleUrlChange} />,
      confirmDisabled,
      onConfirm: () => handleConfirm(url),
    });
  };

  const handleClick = async () => {
    if (!currentEvent) {
      return;
    }

    if (content && content.url) {
      await unShareContent();
    } else {
      openDialog({
        confirmTestId: "toggle-confirm",
        dismissTestId: "toggle-dismiss",
        dismissDisabled: true,
        className: styles.dialog,
        content: (
          <SharePresentContentDialogContent onChange={handleUrlChange} />
        ),
        confirmDisabled: true,
        onDismiss: handleClose,
        confirmText: t("event:present.content.share"),
        dismissText: t("common:button.cancel"),
        hideCloseButton: true,
        type: DialogType.PRESENT_CONTENT_SHARE,
      });
      track(Events.PRESENT_CONTENT_BUTTON_CLICK, {
        eventId: currentEvent.id,
        eventType: currentEvent.eventType,
      });
    }
  };

  return (
    <ActionButton
      title={t("event:present.content")}
      onClick={handleClick}
      label={t("event:present.content")}
      isActive={!!content?.url}
      loading={isLoading}
      darkMode
      icon={<PresentContentIcon />}
    />
  );
};

export default PresentContentButton;
