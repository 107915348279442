import store from "store";
import { database } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";
import {
  setIsLiveStreamLoading,
  setIsLiveStreaming,
} from "modules/liveStream/redux/slice";

const getLiveStreamInfoDocRef = (theaterId: string) =>
  database.ref(`${THEATER_PATH}/${theaterId}/Broadcast/LiveStream`);

export interface LiveStreams {
  serverUrl: string;
  streamKey: string;
}

interface LiveStreamData {
  isLiveStreaming?: boolean;
}

const startLiveStream = async (theaterId: string) => {
  await getLiveStreamInfoDocRef(theaterId).set({
    isLiveStreaming: true,
  });
};

const stopLiveStream = async (theaterId: string) => {
  await getLiveStreamInfoDocRef(theaterId).remove();
};

export const subscribeEventLiveStream = (theaterId: string) => {
  const dbRef = getLiveStreamInfoDocRef(theaterId);
  dbRef.off();

  // eslint-disable-next-line consistent-return
  dbRef.on("value", async (snapshot) => {
    const livestream: LiveStreamData = snapshot.val();

    if (!livestream) {
      store.dispatch(setIsLiveStreamLoading(false));
      store.dispatch(setIsLiveStreaming(false));
      return undefined;
    }

    const { isLiveStreaming } = livestream;

    store.dispatch(setIsLiveStreaming(Boolean(isLiveStreaming)));
  });
};

export const unsubscribeEventLiveStream = (theaterId: string) => {
  const dbRef = getLiveStreamInfoDocRef(theaterId);
  dbRef.off();
};
export default {
  getLiveStreamInfoDocRef,
  startLiveStream,
  stopLiveStream,
  subscribeEventLiveStream,
  unsubscribeEventLiveStream,
};
