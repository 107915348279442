import { Errors, trackError } from "modules/monitoring";
import { useQuery } from "@tanstack/react-query";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useSelector } from "react-redux";
import { selectUserId } from "modules/auth/redux/selectors";
import { CUSTOM_QUESTIONS_QUERY_KEY } from "../../constants";
import { getCustomQuestions } from "./request";

export const useCustomRegistrationQuestions = (eventId?: string) => {
  const userId = useSelector(selectUserId);
  const { t } = useI18n(["eventForm"]);
  const { addErrorNotification } = useNotificationActions();

  const {
    data: customQuestions,
    status,
    fetchStatus,
    isLoading,
  } = useQuery(
    [CUSTOM_QUESTIONS_QUERY_KEY, eventId, userId],
    async () => {
      if (!eventId) return [];

      if (!userId) return [];

      const questions = await getCustomQuestions(eventId);

      return questions.sort((a, b) => a.order - b.order);
    },
    {
      onError: (error) => {
        addErrorNotification({
          message: t("eventForm:custom.registration.question.query.error", {
            error: error instanceof Error ? error.message : "unknown",
          }),
          position: "tc",
        });

        trackError(error, {
          label: Errors.CUSTOM_REGISTRATION_QUESTIONS_QUERY,
          eventId,
        });
      },
    },
  );

  return {
    status,
    fetchStatus,
    isLoading,
    questions: customQuestions ?? [],
  };
};
