import { useAppMessage, useLocalSessionId } from "@daily-co/daily-react";
import {
  CustomAppEvent,
  CustomAppEvents,
} from "modules/audioVideo/DailyEvents";
import { useDailyApi } from "modules/audioVideo/hooks/useDailyApi";
import { useParticipantSubscription } from "modules/audioVideo/hooks/useParticipantSubscription";
import { useI18n } from "i18n";
import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useRecordingSubscription } from "modules/recording/hooks/useRecordingAsync/useRecordingSubscription";
import { useActivePresenterSubscription } from "modules/audioVideo/hooks/usePresenters";
import {
  useLayoutSubscription,
  usePresentationLayout,
} from "modules/audioVideo/hooks/useLayout";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { selectCurrentUserPreferences } from "modules/userPreferences/redux/selectors";
import {
  selectCurrentEventId,
  selectIsEventManager,
} from "modules/event/selectors";
import { selectIsWhiteboardOpen } from "modules/broadcastWhiteboard/redux/selectors";
import { usePresentationLayoutEffects } from "modules/audioVideo/hooks/useLayout/usePresentationLayoutEffects";
import { selectIsRoomWhiteboardOpen } from "modules/roomWhiteboard/redux/selectors";
import { selectVideoURL } from "modules/broadcast/redux/selectors";
import { useCameraAndScreenTiles } from "modules/audioVideo/hooks";
import {
  useEmbedContentSubscriptionPresentation,
  selectPresentationModeContent,
} from "modules/eventEmbedContent";
import {
  selectActiveTile,
  selectLayout,
  selectPresentationSpeakersSessionIds,
} from "modules/audioVideo/redux/selectors";
import {
  setCameraActive,
  setMicrophoneActive,
} from "modules/session/redux/slice";
import { useAppDispatch } from "store/hooks";
import { DNS_KEYS } from "modules/globalUserPreference";
import { setLayout } from "modules/audioVideo/redux/slice";
import {
  DisplayItem,
  DisplayItemConfig,
  DisplayLayout,
  TileDraft,
} from "../../Layouts/types";
import { LayoutContainer } from "../../Layouts/Container";
import { ManageJoinedMeeting } from "../../ManageJoinedMeeting";

const { disableSwitchScreenFocusModeNotification } = DNS_KEYS;

export const Presentation = (): JSX.Element => {
  const { updateDailyParticipant } = useDailyApi();
  const localSessionId = useLocalSessionId();
  const videoURL = useSelector(selectVideoURL);
  const eventId = useSelector(selectCurrentEventId);
  const dispatch = useAppDispatch();
  const speakers = useSelector(selectPresentationSpeakersSessionIds);
  const { loading, error } = useEmbedContentSubscriptionPresentation(
    eventId || "",
  );
  const hasEventManagerAccess = useSelector(selectIsEventManager);
  const currentTheaterId = useSelector(selectCurrentTheaterId);
  const content = useSelector(selectPresentationModeContent);
  const isPresentContentEnabled = !loading && content && !error;
  const { openDialog } = useDialogNotificationActions();
  const { t } = useI18n();
  const currentUserPreferences = useSelector(selectCurrentUserPreferences);
  const isDNSDisabled = useMemo(
    () =>
      !(
        currentUserPreferences &&
        currentUserPreferences[disableSwitchScreenFocusModeNotification]
      ),
    [currentUserPreferences],
  );
  const {
    addPrimaryTile,
    addLocalActiveTile,
    clearActiveLayout,
    clearLocalLayout,
    focusedTiles,
  } = usePresentationLayout();
  const theaterId = useSelector(selectCurrentTheaterId);
  const activeTile = useSelector(selectActiveTile);
  const layout = useSelector(selectLayout);

  const { screens, cameras } = useCameraAndScreenTiles({
    participants: speakers,
    isInBroadcast: true,
  });

  useActivePresenterSubscription();
  useLayoutSubscription();
  usePresentationLayoutEffects({ focusedTiles, screens, cameras, speakers });
  useParticipantSubscription({ participants: speakers });
  useRecordingSubscription();

  const screenTilesDraft: TileDraft[] = screens.map((screenId) => ({
    type: DisplayItem.screenShare as const,
    streamId: screenId,
    local: screenId.includes(localSessionId),
  }));

  useAppMessage<CustomAppEvent>({
    onAppMessage: (event) => {
      switch (event.data.event) {
        case CustomAppEvents.JOIN_STAGE_REQUEST:
          updateDailyParticipant({
            participantId: event.fromId,
            canSend: true,
            withMic: event.data.withMic,
            withCam: event.data.withCam,
          });
          break;
        case CustomAppEvents.REMOVE_FROM_STAGE:
          dispatch(setMicrophoneActive(false));
          dispatch(setCameraActive(false));
          break;
        default:
          break;
      }
    },
  });

  const isWhiteboardOpen = useSelector(selectIsWhiteboardOpen);
  const isRoomWhiteBoardOpen = useSelector(selectIsRoomWhiteboardOpen);

  const speakerTiles = useMemo(
    () =>
      cameras.map((speaker) => ({
        type: DisplayItem.camera as const,
        streamId: speaker as string,
        local: speaker === localSessionId,
      })),
    [cameras, localSessionId],
  );

  const tilesDraft: TileDraft[] = useMemo(
    () => [...speakerTiles, ...screenTilesDraft],
    [speakerTiles, screenTilesDraft],
  );

  if (isWhiteboardOpen || isRoomWhiteBoardOpen) {
    tilesDraft.push({
      type: DisplayItem.whiteboard as const,
      streamId: DisplayItemConfig.whiteboardDisplayId,
    });
  }

  if (isPresentContentEnabled) {
    tilesDraft.push({
      type: DisplayItem.presentContent as const,
      streamId: DisplayItemConfig.presentContentDisplayId,
    });
  }

  if (videoURL) {
    tilesDraft.push({
      type: DisplayItem.externalVideo as const,
      streamId: DisplayItemConfig.externalVideoDisplayId,
    });
  }

  const displayFocusModePrompt = useCallback(
    (streamId: string) => {
      openDialog({
        message: t("dialog.display.mode.message"),
        hideCloseButton: true,
        confirmText: t("yes"),
        dismissText: t("no"),
        className: "dark",
        onConfirm: () => {
          if (theaterId) {
            addPrimaryTile({ theaterId, streamId });
          }
        },
        hasDoNotShowAgain: true,
        dnsKey: disableSwitchScreenFocusModeNotification,
        confirmTestId: "confirm-switch-mode",
      });
    },
    [openDialog, t, theaterId, addPrimaryTile],
  );

  const handleTileClick = useCallback(
    (streamId: string, displayDialog = true) => {
      if (activeTile?.streamId === streamId && layout === DisplayLayout.Focus) {
        return;
      }
      if (!hasEventManagerAccess) {
        addLocalActiveTile(streamId);
        return;
      }

      if (!isDNSDisabled && theaterId) {
        addPrimaryTile({ theaterId, streamId });
        return;
      }
      if (displayDialog) {
        displayFocusModePrompt(streamId);
        return;
      }
      if (theaterId) {
        addPrimaryTile({ theaterId, streamId });
      }
    },
    [
      activeTile?.streamId,
      layout,
      hasEventManagerAccess,
      isDNSDisabled,
      theaterId,
      displayFocusModePrompt,
      addPrimaryTile,
      addLocalActiveTile,
    ],
  );

  const switchLayout = useCallback(
    (layout: DisplayLayout, displayDialog = true) => {
      switch (layout) {
        case DisplayLayout.Focus: {
          dispatch(setLayout(DisplayLayout.Focus));
          handleTileClick("speaker", displayDialog);
          break;
        }
        case DisplayLayout.Tile: {
          if (currentTheaterId && hasEventManagerAccess) {
            clearActiveLayout({
              theaterId: currentTheaterId,
            });
          } else {
            clearLocalLayout();
          }

          break;
        }
        default:
          break;
      }
    },
    [
      dispatch,
      handleTileClick,
      currentTheaterId,
      hasEventManagerAccess,
      clearActiveLayout,
      clearLocalLayout,
    ],
  );

  return (
    <>
      <ManageJoinedMeeting />
      <LayoutContainer
        key="presentation"
        tiles={tilesDraft}
        onTileClick={handleTileClick}
        switchLayout={switchLayout}
      />
    </>
  );
};
