import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CompanyPublicSettings } from "modules/company/types";

type AgencyWhiteLabelSettings = Pick<
  CompanyPublicSettings,
  | "enableAgencyWhiteLabelling"
  | "whiteLabelFaviconURL"
  | "whiteLabelLogoURL"
  | "whiteLabelSignInBackgroundURL"
  | "disableFreshchatWidget"
> & {
  // only used to store company id using this custom domain IF we're on a custom domain and that's how we fetched the whitelabel settings
  // will be undefined in most other cases
  customDomainCompany?: string;
};

export const initialState: AgencyWhiteLabelSettings & { isLoaded: boolean } = {
  isLoaded: false,
  enableAgencyWhiteLabelling: false,
};

const agencyWhiteLabelSettingsSlice = createSlice({
  name: "agencyWhiteLabelSettings",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<AgencyWhiteLabelSettings>) => ({
      ...state,
      ...action.payload,
      isLoaded: true,
    }),
  },
});

export const { setSettings } = agencyWhiteLabelSettingsSlice.actions;

export default agencyWhiteLabelSettingsSlice.reducer;
