// eslint-disable-next-line you-dont-need-lodash-underscore/flatten
import { chunk, flatten } from "lodash";
import logger from "logging/logger";
import { IMapTemplate } from "types/theater";
import { firestore } from "../../firebaseService/firebaseConfig";

type IAcc = Partial<Record<string, IMapTemplate>>;

const getTemplatesDB = () => firestore.collection("MapTemplates");

export const getPublicTemplates = async (): Promise<IMapTemplate[]> => {
  try {
    const snapshots = await getTemplatesDB()
      .where("isPrivate", "==", false)
      .get();

    // Sort the template by created date so it can show in correct order
    return snapshots.docs
      .map((doc) => doc.data())
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        }

        return 1;
      }) as IMapTemplate[];
  } catch (error) {
    if (error instanceof Object) {
      logger.error("[getPublicTemplates] Error reading from firestore", error);
    }
  }

  return [];
};

export const getTemplateByCode = async (code: string) => {
  logger.info(`[getTemplate] fetching ${code} template from firstore`);
  const doc = await getTemplatesDB().doc(code).get();

  if (doc.exists) {
    return doc.data() as IMapTemplate;
  }
  logger.error(`[getTemplate] template ${code} not exist`);

  return null;
};

/** firebase IN query support only 10 values */
export const getTemplatesByCodes = async (allCodes: string[] | null) => {
  if (!allCodes || allCodes.length <= 0) {
    return [];
  }
  try {
    logger.info(`[getTemplate] fetching ${allCodes} templates from firstore`);
    const allPromises = chunk(allCodes, 10).map(async (codes) => {
      const snapshots = await getTemplatesDB().where("code", "in", codes).get();
      const templates = snapshots.docs.map((doc) =>
        doc.data(),
      ) as IMapTemplate[];

      return templates;
    });
    const allTemplates = await Promise.all(allPromises);

    // Sort the templates by allCodes to use same order
    const templateByCode = flatten(allTemplates).reduce(
      (acc: IAcc, template) => {
        acc[template.code] = template;

        return acc;
      },
      {},
    );

    return allCodes
      .map((code: string) => templateByCode[code])
      .filter((item): item is IMapTemplate => !!item);
  } catch (error) {
    if (error instanceof Object) {
      logger.error("[getTemplatesByCodes] Error reading from firestore", error);
    }
  }

  return [];
};
