import { useDrag } from "react-dnd";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useI18n } from "i18n";
import { EventRole } from "modules/event/constants";
import { useMemo } from "react";
import { ANONYMOUS_USER_EMAIL_DOMAIN } from "modules/auth/constants";
import { DRAGGABLE_ATTENDEE_TYPE } from "../../constants";
import { SettingUser } from "../../types";
import useStyles from "./styles";

export enum DisplayedEventRole {
  admin = "event:host",
  helper = "ea",
  speaker = "common:speaker",
}

interface Props {
  user: SettingUser;
  floorId?: string;
  tableId?: string;
  setHoveredUserId?: (id: string | null) => void;
}

const UserRow = ({
  user,
  floorId,
  tableId,
  setHoveredUserId,
}: Props): JSX.Element | null => {
  const styles = useStyles();
  const { t } = useI18n(["common", "event"]);
  const [_, drag] = useDrag(() => ({
    type: DRAGGABLE_ATTENDEE_TYPE,
    item: { user, floorId, tableId },
  }));

  const isAnonymous = useMemo(
    () =>
      user.email.split("@")[1] === ANONYMOUS_USER_EMAIL_DOMAIN.split("@")[1],
    [user.email],
  );

  return (
    <Container
      ref={drag}
      flex
      fullWidth
      justifyContent="flex-start"
      alignItems="center"
      className={styles.row}
      key={user.email}
      data-testid="draggable-email"
      onFocus={() => setHoveredUserId && setHoveredUserId(user.id)}
      onDragOver={() => setHoveredUserId && setHoveredUserId(user.id)}
      onMouseOver={() => setHoveredUserId && setHoveredUserId(user.id)}
      onBlur={() => setHoveredUserId && setHoveredUserId(null)}
      onDragLeave={() => setHoveredUserId && setHoveredUserId(null)}
      onMouseLeave={() => setHoveredUserId && setHoveredUserId(null)}
    >
      <Container className={styles.details}>
        <Typography className={styles.name}>
          {user.name !== "Unknown" ? user.name : user.email}
        </Typography>
        {user.name !== "Unknown" && (
          <Typography className={styles.email} variant="caption">
            {isAnonymous ? t("common:anonymous.user") : user.email}
          </Typography>
        )}
      </Container>
      {user.role !== EventRole.attendee && (
        <Typography className={styles.role}>
          {t(DisplayedEventRole[user.role])}
        </Typography>
      )}
    </Container>
  );
};

export default UserRow;
