import { useI18n } from "i18n";
import { useQuery } from "@tanstack/react-query";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { trackError, Errors } from "modules/monitoring";
import { EVENT_TICKETS_QUERY_KEY } from "../../constants";
import { getEventTickets } from "../../apis";

export const useEventTickets = (eventId?: string | null) => {
  const { t } = useI18n(["eventForm"]);
  const { addErrorNotification } = useNotificationActions();

  const { data, status } = useQuery(
    [EVENT_TICKETS_QUERY_KEY, eventId],
    async () => {
      if (!eventId) {
        return undefined;
      }

      const result = await getEventTickets(eventId);

      if (result.message) {
        throw new Error(result.message);
      }

      return result.tickets;
    },
    {
      enabled: Boolean(eventId),
      onError: (error) => {
        if (error instanceof Error) {
          addErrorNotification({
            message: t("eventForm:ticket.query.error"),
            position: "tc",
          });

          trackError(error, {
            label: Errors.EVENT_TICKET_QUERY,
          });
        }
      },
    },
  );

  return {
    tickets: data ?? [],
    status,
  };
};
