import { useRef } from "react";
import { firestore } from "services/firebaseService/firebaseConfig";
import { useAppDispatch } from "store/hooks";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { trackError, Errors } from "modules/monitoring";
import { selectCurrentEventId } from "modules/event/selectors";
import { useSelector } from "react-redux";
import { useConfirmAttendeeFloor } from "../useConfirmAttendeeFloor";
import {
  setIsCountdownDialogVisible,
  setIsCountdownBannerVisible,
  setIsInProgressDialogVisible,
  setCountdownStartTime,
  reset,
} from "../../redux";
import {
  ENFORCE_TABLE_ASSIGNMENT_COLLECTION,
  EnforceAssignmentStates,
} from "../../constants";

const useEnforceTableAssignmentFirestore = (eventId: string | null) => {
  const dispatch = useAppDispatch();
  const currentEventId = useSelector(selectCurrentEventId);
  const { addErrorNotification } = useNotificationActions();
  const { confirmAttendeeFloor } = useConfirmAttendeeFloor(eventId);
  const { t } = useI18n(["event"]);
  const listener = useRef<null | (() => void)>(null);
  const previousStatusRef = useRef<string | null>(null);

  const subscribeToEvent = () => {
    if (!eventId) {
      return;
    }

    listener.current = firestore
      .collection(ENFORCE_TABLE_ASSIGNMENT_COLLECTION)
      .doc(eventId)
      .onSnapshot((change) => {
        const data = change.data() || {};

        if (data.status === EnforceAssignmentStates.ERROR) {
          dispatch(reset());

          addErrorNotification({
            message: t("event:enforce.table.assignment.error"),
          });
          trackError(new Error("Enforce Table Assignment Error"), {
            label: Errors.ASSIGN_TABLES_ALL_ATTENDEE_MOVE,
            eventId,
          });

          previousStatusRef.current = data.status;
          return;
        }

        if (data.status === EnforceAssignmentStates.SCHEDULED) {
          dispatch(setCountdownStartTime(data?.timestamp?.seconds));

          dispatch(setIsCountdownDialogVisible(true));
          dispatch(setIsCountdownBannerVisible(false));
          dispatch(setIsInProgressDialogVisible(false));

          previousStatusRef.current = data.status;
          return;
        }

        if (data.status === EnforceAssignmentStates.SUCCESS) {
          if (
            previousStatusRef.current ===
              EnforceAssignmentStates.QUEUE_FLOOR_TRANSFERS &&
            currentEventId
          ) {
            confirmAttendeeFloor(data.presetId);
          }
          dispatch(setCountdownStartTime(null));
        }

        if (
          data.status === EnforceAssignmentStates.IN_PROGRESS ||
          data.status === EnforceAssignmentStates.SUCCESS
        ) {
          dispatch(setIsCountdownDialogVisible(false));
          dispatch(setIsCountdownBannerVisible(false));

          if (data.status === EnforceAssignmentStates.IN_PROGRESS) {
            dispatch(setIsInProgressDialogVisible(true));
          } else {
            dispatch(setIsInProgressDialogVisible(false));
          }
        }

        previousStatusRef.current = data.status;
      });
  };

  const unsubscribeFromEvent = () => {
    if (listener.current) {
      listener.current();
    }
  };

  return {
    subscribeToEvent,
    unsubscribeFromEvent,
  };
};

export default useEnforceTableAssignmentFirestore;
