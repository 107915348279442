import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import {
  selectIsCountdownDialogVisible,
  selectIsCountdownBannerVisible,
  selectIsInProgressDialogVisible,
  selectCountdownStartTime,
  selectIsEnforceTableAssignmentScheduled,
} from "../../redux/selectors";
import {
  setIsCountdownBannerVisible,
  setIsCountdownDialogVisible,
  setIsInProgressDialogVisible,
} from "../../redux/slice";

const useEnforceTableAssignment = () => {
  const dispatch = useAppDispatch();
  const isCountdownDialogVisible = useSelector(selectIsCountdownDialogVisible);
  const isCountdownBannerVisible = useSelector(selectIsCountdownBannerVisible);
  const isInProgressDialogVisible = useSelector(
    selectIsInProgressDialogVisible,
  );
  const countdownStartTime = useSelector(selectCountdownStartTime);
  const isEnforceTableAssignmentScheduled = useSelector(
    selectIsEnforceTableAssignmentScheduled,
  );

  const showCountdownDialog = () => {
    dispatch(setIsCountdownDialogVisible(true));
  };
  const showCountdownBanner = () => {
    dispatch(setIsCountdownBannerVisible(true));
  };
  const showInProgressDialog = () => {
    dispatch(setIsInProgressDialogVisible(true));
  };
  const hideCountdownDialog = () => {
    dispatch(setIsCountdownDialogVisible(false));
  };
  const hideCountdownBanner = () => {
    dispatch(setIsCountdownBannerVisible(false));
  };
  const hideInProgressDialog = () => {
    dispatch(setIsInProgressDialogVisible(false));
  };

  return {
    countdownStartTime,
    isCountdownDialogVisible,
    isCountdownBannerVisible,
    isInProgressDialogVisible,
    isEnforceTableAssignmentScheduled,
    showCountdownDialog,
    showCountdownBanner,
    showInProgressDialog,
    hideCountdownDialog,
    hideCountdownBanner,
    hideInProgressDialog,
  };
};

export default useEnforceTableAssignment;
