import { IEventcubeTicket } from "modules/ticketing/types";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { Edit } from "@remo-co/ui-core/src/icons/Edit";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { IEvent } from "modules/event/types";
import { getTicketStatus } from "../../utils/getTicketStatus";
import useStyles from "./styles";

interface Props {
  eventData: IEvent;
  ticket: IEventcubeTicket;
  currency: string;
  onUpdateClick: (ticket: IEventcubeTicket) => void;
  onDeleteClick: (ticket: IEventcubeTicket) => void;
  disabled: boolean;
}

export const TicketItem = ({
  eventData,
  ticket,
  currency,
  onUpdateClick,
  onDeleteClick,
  disabled,
}: Props) => {
  const { openDialog } = useDialogNotificationActions();
  const { t } = useI18n(["eventForm"]);
  const styles = useStyles({
    status: getTicketStatus(ticket, eventData),
  });

  const onDeleteButtonClick = () => {
    openDialog({
      content: (
        <div className={styles.confirmation}>
          <Typography>{t("eventForm:ticket.delete.confirmation")}</Typography>
        </div>
      ),
      actionsClassName: styles.confirmationActions,
      confirmBtnClassName: styles.confirmationActionButtons,
      dismissBtnClassName: styles.confirmationActionButtons,
      hideCloseButton: true,
      confirmText: t("common:yes"),
      dismissText: t("common:no"),
      onConfirm: () => {
        onDeleteClick(ticket);
      },
      confirmTestId: "confirm-ticket-deletion",
    });
  };

  return (
    <div className={styles.item}>
      <div className={styles.mainDetails}>
        <Typography className={styles.title}>{ticket.title}</Typography>
        <div className={styles.status}>
          <div className={styles.statusBullet} />
          <Typography className={styles.statusText}>
            {t(`eventForm:ticket.state.${getTicketStatus(ticket, eventData)}`)}
          </Typography>
        </div>
      </div>
      <div className={styles.otherDetailsContainer}>
        <div className={styles.otherDetails}>
          <Typography>
            {currency} {ticket.price}
          </Typography>
        </div>
        <div className={styles.otherDetails}>
          <Typography>
            {ticket.sales.sold}/{ticket.quantity}
          </Typography>
        </div>
        <div className={styles.actions}>
          <IconButtonWithTooltip
            title={t("eventForm:update.ticket")}
            id="update-ticket-button"
            size="small"
            onClick={() => onUpdateClick(ticket)}
            data-testid="update-ticket-button"
            disabled={disabled}
          >
            <Edit fontSize="small" />
          </IconButtonWithTooltip>
          <IconButtonWithTooltip
            title={t("eventForm:delete.ticket")}
            id="delete-ticket-button"
            size="small"
            onClick={onDeleteButtonClick}
            data-testid="delete-ticket-button"
            disabled={disabled || ticket.sales.sold > 0}
          >
            <Delete fontSize="small" />
          </IconButtonWithTooltip>
        </div>
      </div>
    </div>
  );
};
