import { gql } from "@apollo/client";

export const POLL_SUBSCRIPTION = gql`
  subscription OnPollChanged($eventId: ObjectID!) {
    pollChanged(eventId: $eventId) {
      ... on EventPoll {
        __typename
        id
        correctAnswers
        options {
          id
          option
          votes
        }
        userAnswers {
          pollId
          userId
          optionId
        }
        eventId
        createdBy
        createdAt
        updatedAt
        status
        timer
        title
        type
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;

export const ON_POLL_DELETED = gql`
  subscription OnPollDeleted($eventId: ObjectID!) {
    pollDeleted(eventId: $eventId) {
      ... on EventPollDelete {
        id
      }
      ... on GQLError {
        __typename
        message
      }
    }
  }
`;
