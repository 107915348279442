import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import useEventInviteActions from "modules/eventInvite/hooks/useEventInviteActions";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { MTableToolbar } from "material-table";
import { Autorenew as AutorenewIcon } from "@remo-co/ui-core/src/icons/Autorenew";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { EmailOutlined as EmailIcon } from "@remo-co/ui-core/src/icons/EmailOutlined";
import { useI18n } from "i18n";
import { IExportAttendeesColumnsMeta } from "modules/eventForm/types";
import { IEvent } from "modules/event/types";
import { SpeakerInviteView } from "modules/eventForm";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { useCustomRegistrationQuestions } from "modules/registration";
import exportAttendeeHelper from "../../exportAttendeeHelper";
import "../../AttendeeListContainer.scss";
import useSpeakerActions from "../../hooks/useSpeakerActions";
import { IEventMemberDataResults } from "../../types";
import { DataTable, EmailStatus } from "..";

interface Props {
  eventData: IEvent;
}

const headerStyle = { whiteSpace: "nowrap" };

const SpeakersListContainer = ({ eventData }: Props): JSX.Element => {
  const theme = useTheme();
  const { getEventSpeakersList, revokeSpeakerRights } = useSpeakerActions();
  const { t } = useI18n(["common", "event", "server", "manageEvent"]);
  const { sendSpeakerInvite } = useEventInviteActions();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { openDialog } = useDialogNotificationActions();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<IEventMemberDataResults[]>([]);
  const [, setEmails] = useState<string[]>([]);
  const { questions } = useCustomRegistrationQuestions(eventData.id);

  const getData = useCallback(async () => {
    setIsFetching(true);
    const { isSuccess, speakers = [] } = await getEventSpeakersList(
      eventData.id,
    );

    if (isSuccess) {
      const result = exportAttendeeHelper.convertEventMemberData(
        eventData,
        speakers,
      );

      setData(result);
    }
    setIsFetching(false);
  }, [eventData, getEventSpeakersList]);

  const handleResendEmails = useCallback(
    async (item: IEventMemberDataResults) => {
      if (!eventData || eventData.theaters?.length === 0) {
        addErrorNotification({ message: t("event:event.not.found") });

        return;
      }

      if (!item.email) {
        addErrorNotification({ message: t("event:email.not.found") });

        return;
      }

      // force resend
      const resp = await sendSpeakerInvite(
        eventData.id,
        eventData.theaters && eventData.theaters[0].id,
        [item.email],
      );

      if (resp.isSuccess) {
        addSuccessNotification({
          message: t("email.message.sent"),
          position: "tc",
          autoDismiss: 5,
        });
        // clean up input
        setEmails([]);
        await getData();
      } else {
        addErrorNotification({
          message: resp.message ? t(resp.message) : t("something.wrong"),
          position: "tc",
          autoDismiss: 5,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventData],
  );

  const handleToggleUserAccess = useCallback(
    (item: IEventMemberDataResults) => {
      openDialog({
        hideCloseButton: true,
        message: t("event:revoke.speaker.right", {
          key: `${item.email}`,
        }),
        onConfirm: async () => {
          const resp = await revokeSpeakerRights(eventData.id, item.email);

          if (resp.isSuccess) {
            await getData();
          }
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventData],
  );

  useEffect(() => {
    if (eventData && eventData.id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  const renderStatus = (rowData: IEventMemberDataResults) => (
    <EmailStatus emailStatus={rowData.emailStatus} />
  );

  const questionColumns = useMemo(
    () =>
      questions.map((question) => ({
        title: question.question,
        field: `question-${question._id}`,
        cellStyle: {
          maxWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        export: true,
        headerStyle,
      })),
    [questions],
  );

  return (
    <div className="attendee-list-ctn">
      <SpeakerInviteView eventData={eventData} onInvite={getData} />
      {!!data.length && (
        <Typography className="mar-top-40 mar-bottom-10" variant="h4">
          {t("event:speakers.list")}
        </Typography>
      )}

      <Box marginBottom="5px">
        {isFetching && (
          <div className="loading-div">
            <PageLoader fullScreen message={t("wait")} />
          </div>
        )}
        {!isFetching && !!data.length && (
          <DataTable
            title={t("registration")}
            columns={[
              {
                title: t("email"),
                field: "email",
                export: true,
                cellStyle: { width: "calc(30% - 0px)" },
                headerStyle,
              },
              {
                title: t("event:invited"),
                field: "invitedText",
                export: true,
                cellStyle: { width: "calc(20% - 0px)" },
                headerStyle,
                render: ({ invitedText }: IEventMemberDataResults) =>
                  t(invitedText),
              },
              {
                title: t("event:email.status"),
                field: "emailStatus",
                export: true,
                cellStyle: { width: "calc(10% - 0px)" },
                headerStyle,
                render: renderStatus,
              },
              {
                title: t("event:registered"),
                field: "registeredText",
                export: true,
                cellStyle: { width: "calc(10% - 0px)" },
                headerStyle,
                render: ({ registeredText }: IEventMemberDataResults) =>
                  t(registeredText),
              },
              {
                title: t("event:attended"),
                field: "attendedText",
                export: true,
                cellStyle: { width: "calc(10% - 0px)" },
                headerStyle,
                render: ({ attendedText }: IEventMemberDataResults) =>
                  t(attendedText),
              },
              ...questionColumns,
            ]}
            data={data}
            actions={[
              (rowData: IEventMemberDataResults) => ({
                // eslint-disable-next-line react/no-unstable-nested-components
                icon: () => <EmailIcon />,
                tooltip: !rowData.userId ? t("event:resend.email") : false,
                onClick: (
                  evt: MouseEvent<HTMLButtonElement>,
                  item: IEventMemberDataResults,
                ) => handleResendEmails(item),
                disabled: !!rowData.userId,
              }),
              (rowData: IEventMemberDataResults) => ({
                // eslint-disable-next-line react/no-unstable-nested-components
                icon: () => <Delete style={{ color: "unset" }} />,
                tooltip: rowData.userId
                  ? t("event:revoke.speaker.rights")
                  : false,
                onClick: (
                  evt: MouseEvent<HTMLButtonElement>,
                  item: IEventMemberDataResults,
                ) => handleToggleUserAccess(item),
                disabled: !rowData.email,
              }),
            ]}
            options={{
              exportButton: true,
              exportAllData: true,
              showTitle: false,
              pageSize: 5,
              pageSizeOptions: [],
              padding: "dense",
              actionsColumnIndex: 0,
              exportCsv: (
                columns: IExportAttendeesColumnsMeta[],
                records: IEventMemberDataResults[],
              ) => {
                exportAttendeeHelper.downloadCSV(
                  `${eventData.code}.csv`,
                  columns,
                  records,
                );
              },
            }}
            components={{
              // eslint-disable-next-line react/no-unstable-nested-components
              Toolbar: (props: unknown) => (
                <Box>
                  <MTableToolbar {...(props as object)} />
                  <Box
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "10px",
                    }}
                  >
                    <Tooltip title={<>{t("event:reload.data")}</>}>
                      <IconButton
                        disabled={isFetching}
                        size="small"
                        onClick={getData}
                        color="primary"
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ),
            }}
            style={{ fontFamily: theme.typography.fontFamily }}
          />
        )}
      </Box>
    </div>
  );
};

export default SpeakersListContainer;
