import { ReactNode } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Card } from "@remo-co/ui-core/src/components/Card";
import { RemoLogo } from "@remo-co/ui-core/src/components/RemoLogo";
import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useStyles } from "./styles";

const ActionMessageCard = ({ children }: { children: ReactNode }) => {
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });

  return (
    <Box className={classes.actionContainer}>
      <Card className={classes.actionCard}>
        <RemoLogo className={classes.actionLogo} />
        {children}
      </Card>
    </Box>
  );
};

export default ActionMessageCard;
