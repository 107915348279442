import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  colorInherit: {
    color: "inherit",
  },
  errorIcon: {
    verticalAlign: "middle",
  },
  bcuAvatar: {
    width: "28px !important",
    height: "28px !important",
    "&.avatar-initial": { fontSize: "100%" },
    "&.map-av-remo": { left: "38px" },
  },
  locateButton: {
    padding: 5,
  },
  raiseHandButton: {
    backgroundColor: "var(--TePapaGreen)",
    width: "28px",
    height: "28px",
    padding: "0",
    borderRadius: "50%",
    border: "none",
    "&:hover": { cursor: "pointer", backgroundColor: "var(--OuterSpace)" },
    "& img": { width: "16px", height: "16px" },
  },
  moreBtn: {
    "&:disabled svg": { color: "rgba(2, 2, 2, 0.14)" },
    color: theme.palette.white,
    "&:hover": { backgroundColor: "rgba(2, 2, 2, 0.14)" },
  },
  userName: {
    maxWidth: "160px",
    maxHeight: "45px",
    display: "-webkit-box",
    webkitLineClamp: "2",
    webkitBoxOrient: "vertical",

    "& > span": {
      maxWidth: "160px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    margin: 0,
  },
  listItem: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
}));
