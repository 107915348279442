import { RootState } from "store";
import { createSelector } from "@reduxjs/toolkit";
import { OS_NAMES, SUPPORT_STATUS } from "modules/systemCheck";
import { checkInternetConnection } from "../utils";

export const selectSystemCheckState = (state: RootState) => state.systemCheck;

export const selectIsSystemCheckCompleted = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.isSystemCheckCompleted,
);

export const selectFirebaseResult = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.firebaseResult,
);

export const selectFirestoreResult = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.firestoreResult,
);

export const selectReachabilityResult = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.reachabilityResult,
);

export const selectInternetSpeedResult = createSelector(
  selectSystemCheckState,
  (systemCheckState) => ({
    ...systemCheckState.internetSpeedResult,
    isSuccessful: checkInternetConnection(
      systemCheckState.internetSpeedResult.downloadSpeedMbps,
    ),
  }),
);

export const selectIsOSSupported = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.systemInfo.osSupported,
);

export const selectSystemTroubleShootLinks = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.systemInfo.troubleShootLinks,
);

export const selectOsName = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.systemInfo.osName,
);

export const selectIsMacOS = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.systemInfo.osName === OS_NAMES.MAC,
);

export const selectBrowserName = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.systemInfo.browserName,
);

export const selectSystemInfo = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.systemInfo,
);

export const selectIsBrowserSupported = createSelector(
  selectSystemCheckState,
  (systemCheckState) =>
    systemCheckState.systemInfo.browserSupport.status ===
    SUPPORT_STATUS.SUPPORTED,
);

export const selectIsSuccessful = createSelector(
  selectSystemCheckState,
  (systemCheckState) =>
    systemCheckState.firebaseResult.isSuccessful &&
    systemCheckState.dailyResult.isSuccessful &&
    systemCheckState.firestoreResult.isSuccessful,
);

export const selectRequiredSystemStatus = createSelector(
  selectSystemCheckState,
  (systemCheckState) => {
    if (
      !systemCheckState.firebaseResult.isSuccessful ||
      !systemCheckState.dailyResult.isSuccessful ||
      !systemCheckState.firestoreResult.isSuccessful
    ) {
      return SUPPORT_STATUS.UNSUPPORTED;
    }
    return SUPPORT_STATUS.SUPPORTED;
  },
);

export const selectSystemStatus = createSelector(
  selectSystemCheckState,
  (systemCheckState) => {
    if (
      !systemCheckState.firebaseResult.isSuccessful ||
      !systemCheckState.dailyResult.isSuccessful ||
      !systemCheckState.firestoreResult.isSuccessful
    ) {
      return SUPPORT_STATUS.UNSUPPORTED;
    }
    if (
      !systemCheckState.firebaseStorageResult.isSuccessful ||
      !systemCheckState.systemInfo.osSupported ||
      Object.values(systemCheckState.reachabilityResult).some(
        (result) => !result.isSuccessful,
      ) ||
      !systemCheckState.adBlockResult.isSuccessful ||
      (!systemCheckState.disableVPNCheck &&
        !systemCheckState.vpnResult.isSuccessful) ||
      systemCheckState.systemInfo.browserSupport.status !==
        SUPPORT_STATUS.SUPPORTED
    ) {
      return SUPPORT_STATUS.PARTIALLY_SUPPORTED;
    }
    return SUPPORT_STATUS.SUPPORTED;
  },
);

export const selectHaveChecksBeenRun = createSelector(
  selectSystemCheckState,
  (systemCheckState) =>
    systemCheckState.firebaseResult.isSuccessful !== null &&
    systemCheckState.firestoreResult.isSuccessful !== null &&
    systemCheckState.dailyResult.isSuccessful !== null &&
    systemCheckState.firebaseStorageResult.isSuccessful !== null &&
    systemCheckState.internetSpeedResult.downloadSpeedMbps !== null &&
    systemCheckState.adBlockResult.isSuccessful !== null &&
    (systemCheckState.disableVPNCheck ||
      systemCheckState.vpnResult.isSuccessful !== null) &&
    Object.values(systemCheckState.reachabilityResult).every(
      (result) => result.isSuccessful !== null,
    ),
);

export const selectIsRequiredLoading = createSelector(
  selectSystemCheckState,
  (systemCheckState) =>
    systemCheckState.firebaseResult.isLoading ||
    systemCheckState.dailyResult.isLoading ||
    systemCheckState.firestoreResult.isLoading,
);

export const selectIsLoading = createSelector(
  selectSystemCheckState,
  (systemCheckState) =>
    systemCheckState.firestoreResult.isLoading ||
    systemCheckState.firebaseResult.isLoading ||
    systemCheckState.dailyResult.isLoading ||
    systemCheckState.firebaseStorageResult.isLoading ||
    systemCheckState.internetSpeedResult.isLoading ||
    systemCheckState.adBlockResult.isLoading ||
    Object.values(systemCheckState.reachabilityResult).some(
      (result) => result.isLoading,
    ),
);

export const selectFirebaseStorageResult = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.firebaseStorageResult,
);

export const selectAdBlockResult = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.adBlockResult,
);

export const selectDailyResult = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.dailyResult,
);

export const selectVPNDetectionResult = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.vpnResult,
);

export const selectDisableSystemCheck = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.disableSystemCheck,
);

export const selectDisableVPNCheck = createSelector(
  selectSystemCheckState,
  (systemCheckState) => systemCheckState.disableVPNCheck,
);
