import firebase from "firebase/compat/app";
import logger from "logging/logger";
import {
  fieldValue,
  firestore,
} from "../../services/firebaseService/firebaseConfig";

export const USER_APPLICATION_COLLECTION = "UserApplications";

export const updateAppInstanceUuid = async (
  userId: string,
  eventId: string,
  appInstanceUuid: string,
) => {
  const doc = await firestore
    .collection(USER_APPLICATION_COLLECTION)
    .doc(userId)
    .get();

  if (!doc.exists) {
    return firestore
      .collection(USER_APPLICATION_COLLECTION)
      .doc(userId)
      .set({ [eventId]: appInstanceUuid });
  }

  return firestore
    .collection(USER_APPLICATION_COLLECTION)
    .doc(userId)
    .update({ [eventId]: appInstanceUuid });
};

export const onAppInstanceUuidChange = async (
  userId: string,
  eventId: string,
  onChange: (appInstanceUuid: string | undefined) => void,
) =>
  firestore
    .collection(USER_APPLICATION_COLLECTION)
    .doc(userId)
    .onSnapshot((doc) => {
      const data = doc.data() || {};

      if (eventId in data) {
        onChange(data[eventId]);
      }
    });

export const cleanAppInstanceUuid = async (userId: string, eventId: string) => {
  try {
    if (userId && eventId && firebase.auth().currentUser) {
      const doc = await firestore
        .collection(USER_APPLICATION_COLLECTION)
        .doc(userId)
        .get();

      if (doc.exists) {
        await firestore
          .collection(USER_APPLICATION_COLLECTION)
          .doc(userId)
          .update({
            [eventId]: fieldValue.delete(),
          });
      }
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : "";
    logger.error(
      `[cleanAppInstanceUuid] userId: ${userId} event: ${eventId} error: ${errorMessage}`,
    );
  }
};
