import React, { forwardRef } from "react";
import MuiContainer, {
  ContainerProps as MuiContainerProps,
} from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import classnames from "classnames";
import { ResponsiveProperties } from "../../utils/createResponsiveRules";
import { SpacingType } from "../../types";
import {
  ALIGN_ITEMS_DEFAULT_VALUE,
  FULL_WIDTH_DEFAULT_VALUE,
  IContainerStylingProperties,
  JUSTIFY_CONTENT_DEFAULT_VALUE,
  styles,
} from "./styles";

export interface IContainerProps
  extends MuiContainerProps,
    ResponsiveProperties<IContainerStylingProperties> {
  padding?: SpacingType;
  backgroundColor?: string;
  borderColor?: string;
  component?: React.ElementType;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

const useStyles = makeStyles<Theme, IContainerProps>(styles);

const Container = forwardRef<HTMLDivElement, IContainerProps>((props, ref) => {
  const {
    component = "div",
    backgroundColor,
    size,
    flex,
    fullWidth = FULL_WIDTH_DEFAULT_VALUE,
    border,
    borderColor,
    rounded,
    column,
    inline,
    justifyContent = JUSTIFY_CONTENT_DEFAULT_VALUE,
    alignItems = ALIGN_ITEMS_DEFAULT_VALUE,
    top,
    bottom,
    left,
    right,
    padding,
    margin,
    children,
    maxWidth = false,
    startAdornment: _startAdornment,
    endAdornment: _endAdornment,
    ...rest
  } = props;

  const classes = useStyles({
    ...props,
    backgroundColor,
    padding,
    borderColor,
  });
  const rootClassName = classnames(classes.root, {
    [classes.fullWidth]: fullWidth,
    [classes.flex]: flex,
    [classes.column]: column,
    [classes.inline]: inline,
    [classes.rounded]: rounded,
    [classes.size]: size,
    [classes.border]: border,
    [classes.topMargin]: top || margin,
    [classes.rightMargin]: right || margin,
    [classes.bottomMargin]: bottom || margin,
    [classes.leftMargin]: left || margin,
    [classes.alignItems]: flex || alignItems,
    [classes.justifyContent]: flex || justifyContent,
  });

  return (
    <MuiContainer
      classes={{ root: rootClassName }}
      ref={ref}
      component={component}
      maxWidth={maxWidth}
      {...rest}
    >
      {children}
    </MuiContainer>
  );
});

export default Container;
