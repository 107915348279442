import { firestore } from "services/firebaseService/firebaseConfig";
import { IEventAgenda } from "./types";

const eventAgendaDB = firestore.collection("EventAgenda");

let listener: () => void;

export const subscribeToEventAgenda = (
  eventId: string,
  date: string,
  callback: (id?: string, data?: IEventAgenda, type?: string) => void,
) => {
  listener = eventAgendaDB
    .doc(eventId)
    .collection(date)
    .onSnapshot((querySnapshot) => {
      const items = querySnapshot.docChanges();

      if (items && items.length) {
        items.forEach((change) => {
          const data = change.doc.data() as IEventAgenda;

          callback(change.doc.id, data, change.type);
        });
      } else {
        callback();
      }
    });
};

export const unsubscribeToEventAgenda = () => {
  if (listener) {
    listener();
  }
};
