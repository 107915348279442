import { getCurrentEventId } from "helpers/reduxHelper";
import store from "store";
import { setIsAdmin } from "modules/auth/redux/authSlice";
import { database } from "../../services/firebaseService/firebaseConfig";
import { getReducerState } from "../../helpers/reduxHelper";
import { addEventDetails, updateEventSpeakers } from "./eventSlice";

const EVENT_PATH = "Event";

export const getEventPrefix = () => EVENT_PATH;

const getEventAdminsDocRef = (eventId: string) =>
  database.ref(`${getEventPrefix()}/${eventId}/Admins`);

export const getCurrentAdmin = (eventId: string) =>
  getEventAdminsDocRef(eventId).once("value", (snap) => {
    const admins = snap.val();

    if (!Array.isArray(admins) || !admins.length) {
      return;
    }
    const { user, isManager } = getReducerState("auth");

    const isAdmin = admins.includes(user?.id) || isManager;

    store.dispatch(setIsAdmin(isAdmin));
  });

export const changeEventStatus = async (eventId: string, status: string) => {
  if (!eventId) {
    return;
  }

  const eventRef = database.ref(`${getEventPrefix()}/${eventId}/`);

  eventRef.update({ status });
};

export const listenForEventDetails = async () => {
  const eventId = getCurrentEventId();

  if (!eventId) {
    return;
  }

  const eventRef = database.ref(`${getEventPrefix()}/${eventId}/Details/`);

  eventRef.off();

  eventRef.on("value", (status) => {
    const eventData = status.val();

    store.dispatch(addEventDetails({ ...eventData, id: eventId }));

    if (eventData) {
      store.dispatch(
        updateEventSpeakers({
          eventId,
          speakers: eventData.speakers,
        }),
      );
    }
  });
};

export const stopListenEventDetails = () => {
  const eventId = getCurrentEventId();

  if (!eventId) {
    return;
  }

  const eventRef = database.ref(`${getEventPrefix()}/${eventId}/Details/`);

  eventRef.off();
};
