import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const ProfileInfoStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: "228px",
    margin: "10px",
    marginRight: "20px",
    display: "flex",
    alignItems: "center",
  },
  profileImage: {
    width: "28px",
    height: "28px",
    opacity: 1,
    display: "block",
    marginRight: "10px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export default ProfileInfoStyles;
