import { LoginOptionsConfiguration } from "@remo-co/types";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectCompanySettings = (state: RootState) => state.companySettings;

const selectIsCompanySettingsLoaded = createSelector(
  selectCompanySettings,
  ({ isLoaded }) => isLoaded,
);

const selectExitDoorUrl = createSelector(
  selectCompanySettings,
  (substate) => substate.exitURL,
);

const selectDisableVPNCheck = createSelector(
  selectCompanySettings,
  (substate) => substate.disableVPNCheck,
);

const selectDiscoveryDisabledByDefault = createSelector(
  selectCompanySettings,
  (substate) => substate.discoveryDisabledByDefault,
);

const selectCompanyBadgeUrl = createSelector(
  selectCompanySettings,
  (substate) => substate.companyBadgeURL,
);

const selectEnableCompanyBadge = createSelector(
  selectCompanySettings,
  (substate) => substate.enableCompanyBadge,
);

const selectOnboardDefaultText = createSelector(
  selectCompanySettings,
  ({ defaultOnboardText }) => defaultOnboardText,
);

const selectOnboardDefaultMedia = createSelector(
  selectCompanySettings,
  ({ defaultOnboardMedia }) => defaultOnboardMedia,
);

const selectBoardProvider = createSelector(
  selectCompanySettings,
  ({ boardProvider }) => boardProvider,
);

const selectCompanySettingsFeatureToggles = createSelector(
  selectCompanySettings,
  ({ disabledFeatures }) => disabledFeatures ?? [],
);

const selectIsDirectoryVisibleToAssistants = createSelector(
  selectCompanySettings,
  ({ isDirectoryVisibleToAssistants }) => isDirectoryVisibleToAssistants,
);

const selectLoginOptionsConfiguration = createSelector(
  selectCompanySettings,
  (state): LoginOptionsConfiguration => state.loginOptions,
);

const selectIsAnonymousEnabled = createSelector(
  selectLoginOptionsConfiguration,
  (state) => state.anonymous,
);

const selectIsEmailPasswordEnabled = createSelector(
  selectLoginOptionsConfiguration,
  (state) => state.emailWithPassword,
);

const selectEnableAgencyWhiteLabelling = createSelector(
  selectCompanySettings,
  ({ enableAgencyWhiteLabelling }) => enableAgencyWhiteLabelling,
);

const selectWhiteLabelLogoURL = createSelector(
  selectCompanySettings,
  ({ whiteLabelLogoURL }) => whiteLabelLogoURL,
);

const selectWhiteLabelFaviconURL = createSelector(
  selectCompanySettings,
  ({ whiteLabelFaviconURL }) => whiteLabelFaviconURL,
);

const selectWhiteLabelSignInBackgroundURL = createSelector(
  selectCompanySettings,
  ({ whiteLabelSignInBackgroundURL }) => whiteLabelSignInBackgroundURL,
);

const selectCustomDomain = createSelector(
  selectCompanySettings,
  ({ customDomain }) => customDomain,
);

const selectIsCustomDomainVerified = createSelector(
  selectCompanySettings,
  ({ isCustomDomainVerified }) => isCustomDomainVerified,
);

const selectDisableFreshchatWidget = createSelector(
  selectCompanySettings,
  ({ disableFreshchatWidget }) => disableFreshchatWidget,
);

const selectCustomEmailSenderSettings = createSelector(
  selectCompanySettings,
  ({ emailSenderSettings }) => emailSenderSettings,
);

export {
  selectCompanySettings,
  selectIsCompanySettingsLoaded,
  selectExitDoorUrl,
  selectDisableVPNCheck,
  selectDiscoveryDisabledByDefault,
  selectCompanyBadgeUrl,
  selectEnableCompanyBadge,
  selectOnboardDefaultText,
  selectOnboardDefaultMedia,
  selectBoardProvider,
  selectCompanySettingsFeatureToggles,
  selectIsDirectoryVisibleToAssistants,
  selectLoginOptionsConfiguration,
  selectIsAnonymousEnabled,
  selectIsEmailPasswordEnabled,
  selectEnableAgencyWhiteLabelling,
  selectWhiteLabelLogoURL,
  selectWhiteLabelFaviconURL,
  selectWhiteLabelSignInBackgroundURL,
  selectCustomDomain,
  selectIsCustomDomainVerified,
  selectDisableFreshchatWidget,
  selectCustomEmailSenderSettings,
};
