import { database, serverValue } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";

const getBroadcastInfoDocRef = (theaterId: string) =>
  database.ref(`${THEATER_PATH}/${theaterId}/Broadcast/BroadcastInfo`);

interface IBroadcastDataProps {
  isBroadcasting?: boolean;
  timeout: number;
  streamerName?: string;
  startedAt?: number;
  startedBy: string;
}

export const isInPresentationMode = async (
  theaterId: string,
): Promise<boolean> => {
  const broadcastInfoRef = getBroadcastInfoDocRef(theaterId);
  const broadcastInfoData = await broadcastInfoRef.get();
  const broadcastInfo =
    (await broadcastInfoData.val()) as IBroadcastDataProps | null;
  return broadcastInfo?.isBroadcasting ?? false;
};

const startBroadcast = async (theaterId: string, data: IBroadcastDataProps) => {
  await getBroadcastInfoDocRef(theaterId).set({
    ...data,
    networkIssue: false,
    startedAt: serverValue.TIMESTAMP,
  });
};

const stopBroadcast = async (theaterId: string) => {
  await getBroadcastInfoDocRef(theaterId).remove();
};

export default {
  getBroadcastInfoDocRef,
  startBroadcast,
  stopBroadcast,
};
