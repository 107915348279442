import { MuiPickersUtilsProvider } from "@material-ui/pickers/MuiPickersUtilsProvider";
import { ReactNode } from "react";
import MomentUtils from "@date-io/moment";

const PickerProvider = ({ children }: { children: ReactNode }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    {children}
  </MuiPickersUtilsProvider>
);

export default PickerProvider;
