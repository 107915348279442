import { useMutation } from "@tanstack/react-query";
import { useAppDispatch } from "store/hooks";
import {
  addErrorNotification,
  addSuccessNotification,
} from "modules/notification/redux/notificationSlice";
import {
  addLiveStreamRequest,
  LiveStreamPayload,
} from "modules/liveStream/request";
import { useI18n } from "i18n";
import { updateLiveStreamData } from "modules/liveStream/redux/slice";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useContext } from "react";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { useIsInEvent } from "modules/event";

export const useAddLiveStream = () => {
  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const isInEvent = useIsInEvent();
  const eventData = state?.eventData ?? undefined;
  const { track } = useContext(TRACKING_CONTEXT);
  const dispatch = useAppDispatch();
  const { t } = useI18n(["manageEvent"]);

  const {
    mutate: addLiveStream,
    isLoading: isLiveStreamAdding,
    status,
  } = useMutation(
    ["addLiveStream"],
    (payload: LiveStreamPayload) => addLiveStreamRequest(payload),
    {
      onSuccess(data) {
        if (data.data) {
          if (!isInEvent) dispatch(updateLiveStreamData(data.data));
          track(Events.RTMP_OUT_DESTINATION_ADDED, {
            eventId: eventData?.id,
            eventType: eventData?.eventType,
            eventOutcome: eventData?.eventOutcome,
            destinationName: data.data.destinationName,
            playbackUrl: data.data.playbackUrl,
          });
        }
        dispatch(
          addSuccessNotification({
            message: t("manageEvent:stream.added.success"),
          }),
        );
      },
      onError(error: Error) {
        dispatch(
          addErrorNotification({
            message: error.message,
          }),
        );
      },
    },
  );
  return { addLiveStream, isLiveStreamAdding, status };
};
