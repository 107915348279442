import React, { useMemo, forwardRef } from "react";
import { Avatar as UICoreAvatar } from "@remo-co/ui-core/src/components/Avatar";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import classNames from "classnames";
import BadgePreview from "modules/settings/companyBadge/BadgePreview";
import { useI18n } from "i18n";
import { IUser } from "modules/app/types";
import { useSelector } from "react-redux";
import { XS, SM, MD, LG } from "@remo-co/ui-core/src/types";
import { DisplayLayout } from "modules/audioVideo";
import { selectLayout } from "modules/audioVideo/redux/selectors";
import { ConversationEmoji as ConversationEmojiType } from "modules/emoji/redux";
import { TileEmoji } from "modules/emoji/ConversationEmoji/TileEmoji";
import { useShowReaction } from "modules/emoji/ConversationEmoji/hooks/useShowReaction";
import starIcon from "./star.svg";
import { ReactComponent as WarningIcon } from "./warning.inline.svg";
import { ReactComponent as RemoBadge } from "./remo-badge.inline.svg";
import { useStyles } from "./styles";
import { parseInitials } from "./utils";

export interface IAvatarProps {
  alt?: string;
  pictureUrl?: string;
  name?: string;
  isAdmin?: boolean;
  isRemoEmployee?: boolean;
  remoEmployeeBadge?: React.ReactNode;
  hasMicCamIssues?: boolean;
  micAndCamIssueLabel?: string;
  remoEmployeeLabel?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: XS | SM | MD | LG;
  onClick?(): void;
  color?: string;
  fallbackBackgroundColor?: string;
  user?: IUser;
  isCompanyBadgeEdit?: boolean;
  id?: string;
  "data-testid"?: string;
  "data-automationid"?: string;
  emoji?: ConversationEmojiType;
  showEmoji?: boolean;
}

const Avatar = forwardRef<HTMLElement, IAvatarProps>((props, ref) => {
  const {
    isAdmin,
    style,
    isRemoEmployee,
    remoEmployeeBadge,
    hasMicCamIssues,
    micAndCamIssueLabel,
    remoEmployeeLabel,
    user,
    isCompanyBadgeEdit,
    pictureUrl,
    alt,
    name = "",
    className = "",
    size = "",
    onClick,
    id,
    "data-testid": dataTestId,
    "data-automationid": dataAutomationId,
    emoji,
    showEmoji = false,
  } = props;
  const classes = useStyles(props);
  const badgeInitials = useMemo(() => parseInitials(name), [name]);
  const { t } = useI18n(["event"]);
  const { showReaction } = useShowReaction({ emoji });
  const layout = useSelector(selectLayout);
  const showTileEmoji =
    showEmoji && showReaction && layout !== DisplayLayout.Focus;

  return (
    <span
      ref={ref}
      className={classNames(classes.root, {
        [className]: className,
        [classes[size]]: size,
      })}
      style={style}
      aria-label={name}
      id={id}
      data-testid={dataTestId}
      data-automationid={dataAutomationId}
    >
      <Tooltip
        open={showTileEmoji}
        title={<TileEmoji emoji={emoji} avatarEmoji />}
        arrow
        placement="top-start"
        classes={{
          tooltip: classes.toolTip,
          arrow: classes.toolTipArrow,
        }}
        PopperProps={{
          disablePortal: true,
        }}
      >
        <UICoreAvatar
          onClick={onClick}
          alt={alt ?? name}
          src={pictureUrl}
          data-dd-action-name="User Avatar"
          data-testid="avatar-pic"
          classes={{
            root: classes.avatar,
          }}
        >
          {badgeInitials}
        </UICoreAvatar>
      </Tooltip>
      {isAdmin && (
        <img
          alt={t("event:host")}
          src={starIcon}
          className={classes.admin}
          width={28}
          height={28}
        />
      )}
      {isRemoEmployee && remoEmployeeLabel && (
        <Tooltip title={remoEmployeeLabel}>
          <span className={classes.remoBadge}>
            {remoEmployeeBadge ?? <RemoBadge width="36" height="100%" />}
          </span>
        </Tooltip>
      )}
      {!isRemoEmployee && (
        <span className={classes.badge}>
          <BadgePreview
            user={user}
            size="sm"
            isCompanyBadgeEdit={isCompanyBadgeEdit}
          />
        </span>
      )}
      {hasMicCamIssues && micAndCamIssueLabel && (
        <Tooltip title={micAndCamIssueLabel}>
          <span>
            <WarningIcon className={classes.micCamIssues} />
          </span>
        </Tooltip>
      )}
    </span>
  );
});

export default Avatar;
