import { ChangeEvent } from "react";
import { useI18n } from "i18n";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { ActionButton } from "modules/actionButton";
import {
  useRemoveLocksFromEvent,
  useRemoveLocksFromFloor,
} from "modules/lockTables/hooks/useRemoveLocks";
import { LockOpen } from "@remo-co/ui-core/src/icons/LockOpen";
import { DialogType } from "modules/dialogNotification/constants";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { LockAllTablesDialog, UNASSIGN_OPTIONS } from "../LockAllTablesDialog";
import { useStyles } from "./styles";

interface Props {
  setClickAwayListenerDisabled: (isDisabled: boolean) => void;
}

const LockAllTablesButton = ({
  setClickAwayListenerDisabled,
}: Props): JSX.Element | null => {
  const { openDialog, updateDialogParams } = useDialogNotificationActions();
  const { t } = useI18n();
  const { removeLocksOnFloor } = useRemoveLocksFromFloor();
  const { removeLocksOnEvent } = useRemoveLocksFromEvent();
  const styles = useStyles();

  const handleSubmit = (unassignAllFrom: string) => {
    switch (unassignAllFrom) {
      case UNASSIGN_OPTIONS.FLOOR:
        removeLocksOnFloor();
        break;

      default:
        removeLocksOnEvent();
        break;
    }
  };

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateDialogParams(DialogType.UNASSIGN_ALL_ATTENDEES_CONVERSATION_MODE, {
      content: (
        <LockAllTablesDialog
          selectedOption={e.target.value}
          onChange={handleOptionChange}
        />
      ),
      onConfirm: () => handleSubmit(e.target.value),
    });
  };

  const handleClick = () => {
    setClickAwayListenerDisabled(true);
    openDialog({
      confirmBtnId: "toggle-confirm",
      dismissBtnId: "toggle-dismiss",
      content: (
        <LockAllTablesDialog
          onChange={handleOptionChange}
          selectedOption={UNASSIGN_OPTIONS.FLOOR}
        />
      ),
      onConfirm: () => handleSubmit(UNASSIGN_OPTIONS.FLOOR),
      onDismiss: () => setClickAwayListenerDisabled(false),
      confirmText: t("common:remove"),
      dismissText: t("common:button.cancel"),
      hideCloseButton: true,
      type: DialogType.UNASSIGN_ALL_ATTENDEES_CONVERSATION_MODE,
    });
  };

  return (
    <ActionButton
      title={t("manageEvent:unlock.all.tables")}
      label={t("manageEvent:unlock.all.tables")}
      onClick={handleClick}
      isPopoverButton
      icon={
        <Container flex alignItems="center" className={styles.root}>
          <LockOpen width={34} height={34} />
        </Container>
      }
    />
  );
};

export default LockAllTablesButton;
