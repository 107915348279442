import MuiLink, { LinkBaseProps } from "@material-ui/core/Link";
import { buildURL } from "@remo-co/url";
import { useMemo } from "react";
import { useStyles } from "./styles";

export interface ILinkProps extends LinkBaseProps {
  openInNewTab?: boolean;
  isExternal?: boolean;
}

const Link = ({
  openInNewTab,
  href,
  isExternal,
  children,
  ...props
}: ILinkProps) => {
  const classes = useStyles();

  const url = useMemo(
    () => (isExternal && href ? buildURL(href) : href),
    [isExternal, href],
  );

  if (openInNewTab) {
    return (
      <MuiLink
        className={classes.root}
        {...props}
        target="_blank"
        rel="noopener noreferrer"
        href={url}
      >
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink className={classes.root} href={url} {...props}>
      {children}
    </MuiLink>
  );
};

export default Link;
