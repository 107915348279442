import { forwardRef, Ref } from "react";
import MuiMenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useStyles } from "./styles";

export interface IMenuItemProps extends MenuItemProps {
  icon?: React.ReactNode;
  text?: string;
}

const MenuItem = forwardRef(
  (props: IMenuItemProps, ref?: Ref<HTMLLIElement>) => {
    const { icon, text, children, ...rest } = props;
    const classes = useStyles();

    return (
      <MuiMenuItem
        className={classes.menuItem}
        disableGutters
        ref={ref}
        {...rest}
        button
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {text && <ListItemText color="textPrimary" primary={text} />}
        {children}
      </MuiMenuItem>
    );
  },
);

export default MenuItem;
