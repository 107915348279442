import { useMemo, memo } from "react";
import { Gif } from "@giphy/react-components";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { useQuery } from "@tanstack/react-query";
import { GIPHY_SDK_KEY } from "modules/chat/components/Giphy/getGiphySDKKey";
import { IUser } from "modules/app/types";
import { IChat } from "modules/chat/types";
import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { getUserName } from "modules/userProfile";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import classNames from "classnames";
import { useStyles } from "../styles";
import MessageTime from "../MessageTime";
import { GIF_MESSAGE_QUERY_KEY } from "../../constants";

interface Props {
  message: IChat;
  isMe?: boolean;
  author: IUser;
  onGifLoaded: () => void;
}

export const GifMessage = memo(
  ({ message, isMe, author, onGifLoaded }: Props): JSX.Element | null => {
    const isInBroadcast = useSelector(selectIsInBroadcast);
    const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });
    const giphyFetch = useMemo(() => new GiphyFetch(GIPHY_SDK_KEY), []);

    const { data: gifData } = useQuery(
      [GIF_MESSAGE_QUERY_KEY, message.gifKey],
      async () => {
        if (!message.gifKey) {
          return undefined;
        }

        const { data } = await giphyFetch.gif(message.gifKey);

        return data;
      },
      {
        onSuccess: onGifLoaded,
        enabled: Boolean(message.gifKey),
      },
    );

    if (!gifData) {
      return (
        <div className={classes.gifWrapper}>
          <div className={classes.gifLoading}>
            <MessageTime time={message.time} />
          </div>
        </div>
      );
    }

    if (isMe) {
      return (
        <div className={classes.gifWrapper}>
          <div className={classes.gifSent}>
            <Gif gif={gifData} width={200} hideAttribution noLink />
            <MessageTime time={message.time} />
          </div>
        </div>
      );
    }

    return (
      <div className={classes.gifWrapper}>
        <div className={classNames(classes.text, classes.received)}>
          <Typography
            data-testid="gif-author-name"
            variant="body2"
            className={classes.authorName}
          >
            {getUserName(author)}
          </Typography>
          <div className={classes.emoji}>
            <span>
              {gifData && (
                <Gif gif={gifData} width={200} hideAttribution noLink />
              )}
            </span>
            <MessageTime time={message.time} />
          </div>
        </div>
      </div>
    );
  },
);
