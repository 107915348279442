import { useCallback } from "react";
import firebase from "firebase/compat/app";
import { useAppDispatch } from "store/hooks";
import { ICompanySettings, BoardProvider } from "modules/company/types";
import actions from "../redux/actions";
import { getCompanySettingsDocRef } from "../companySettings.firebase";

const useCompanySettingsActions = () => {
  const dispatch = useAppDispatch();

  const setCompanySettings = useCallback((data?: ICompanySettings) => {
    if (data) {
      dispatch(actions.setCompanySettings(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetCompanySettings = useCallback(() => {
    dispatch(actions.resetCompanySettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subscribeToCompanySettings = useCallback((companyId: string) => {
    const ref = getCompanySettingsDocRef(companyId);

    resetCompanySettings();
    ref.off();
    ref.on("value", (snap: firebase.database.DataSnapshot) => {
      const val = snap.val();

      if (val) {
        setCompanySettings(val);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unsubscribeFromCompanySettings = useCallback((companyId: string) => {
    const ref = getCompanySettingsDocRef(companyId);

    ref.off();
  }, []);

  const updateBoardProvider = (
    companyId: string,
    boardProvider: BoardProvider,
  ) => getCompanySettingsDocRef(companyId).update({ boardProvider });

  return {
    setCompanySettings,
    resetCompanySettings,
    subscribeToCompanySettings,
    unsubscribeFromCompanySettings,
    updateBoardProvider,
  };
};

export default useCompanySettingsActions;
