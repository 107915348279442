import { useState, useEffect } from "react";
import { Checkbox } from "@remo-co/ui-core/src/components/Checkbox";
import { DialogContentText } from "@remo-co/ui-core/src/components/DialogContentText";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { DialogType } from "modules/dialogNotification/constants";

interface Props {
  onConfirm: (data: { isAnonymous: boolean }) => void;
  onCancel: () => void;
  setIsClickAwayListenerDisabled?: (isDisabled: boolean) => void;
}

const ConfirmQADialog = ({
  onConfirm,
  onCancel,
  setIsClickAwayListenerDisabled,
}: Props): null => {
  const { t } = useI18n();
  const [isAnonymous, setIsAnonymous] = useState(false);
  const { openDialog, closeDialogByType } = useDialogNotificationActions();

  const handleConfirm = () => {
    onConfirm({ isAnonymous });
    setIsClickAwayListenerDisabled?.(false);
  };

  const handleCancel = () => {
    onCancel();
    setIsClickAwayListenerDisabled?.(false);
  };

  const toggleIsAnonymous = () => {
    setIsAnonymous(!isAnonymous);
  };

  const renderDialog = () => (
    <DialogContentText
      data-testid="qa-confirm-dialog"
      style={{ textAlign: "center" }}
    >
      <Typography style={{ marginTop: "10px", marginBottom: "10px" }}>
        {t("dialog.qna.text")}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            data-testid="qa-anonymous-checkbox"
            color="primary"
            checked={isAnonymous}
            onChange={toggleIsAnonymous}
          />
        }
        label={t("dialog.qna.label")}
      />
    </DialogContentText>
  );

  useEffect(() => {
    setIsClickAwayListenerDisabled?.(true);

    openDialog({
      content: renderDialog(),
      onConfirm: handleConfirm,
      onDismiss: handleCancel,
      confirmText: t("dialog.qna.confirm"),
      dismissText: t("button.cancel"),
      hideCloseButton: true,
      type: DialogType.QNA,
      className: "dark",
    });

    return () => closeDialogByType(DialogType.QNA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnonymous]);

  return null;
};

export default ConfirmQADialog;
