// types of notification

export const DialogType = {
  JOIN_WHITEBOARD: "whiteboard/join",
  JOIN_ROOM: "room/join",
  ROOM_NAME: "room/name",
  JOIN_STAGE: "stage/join",
  BROADCASTER_NOTIFICATION: "broadcaster/notification",
  RECORDING_REMINDER: "broadcast/recording-reminder",
  SEND_EMAIL_CONFIRMATION: "users/send-emails-confirmation",
  UPGRADE_PLAN: "billing/upgrade-plan",
  UNASSIGN_ALL_ATTENDEES_TABLE_MANAGER:
    "event-assign-tables/table-manager/unassign-all-attendees",
  UNASSIGN_ALL_ATTENDEES_CONVERSATION_MODE:
    "event-assign-tables/conversation-mode/unassign-all-attendees",
  EMBED_CONTENT_LAUNCH: "event-embed-content/launch",
  PRESENT_CONTENT_SHARE: "present-content/share",
  QNA: "broadcast/qna",
  ERROR_MESSAGE: "error",
  MODIFY_ALL_LOCK_TABLES: "MODIFY_ALL_LOCK_TABLES",
  SET_ACTIVITY_STATUS: "activity-status/set",
};
