import { useState, useCallback, useEffect, KeyboardEvent } from "react";
import { Checkbox } from "@remo-co/ui-core/src/components/Checkbox";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogContentText } from "@remo-co/ui-core/src/components/DialogContentText";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { useI18n } from "i18n";
import { DialogType } from "modules/dialogNotification/constants";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import useRoomSettings from "../useRoomSettings";

interface DialogParams {
  currentRoomName: string;
  roomId: string;
  roomCode: string;
  currentEventId: string | null;
  hasMultipleFloors: boolean;
  onClose?: () => void;
}

const useRenameRoom = () => {
  const [roomName, setRoomName] = useState("");
  const [roomId, setRoomId] = useState<string | null>(null);
  const [isForAllRooms, setIsForAllRooms] = useState(false);
  const [dialogParams, setDialogParams] = useState<DialogParams | null>(null);
  const { changeRoomName } = useRoomSettings();
  const { openDialog, closeDialogByType, updateDialogParams } =
    useDialogNotificationActions();
  const { t } = useI18n(["common", "manageEvent"]);

  const closeDialog = useCallback(() => {
    if (roomId) {
      closeDialogByType(`${DialogType.ROOM_NAME}/${roomId}`);
    }

    if (dialogParams?.onClose) {
      dialogParams.onClose();
    }
  }, [dialogParams, roomId, closeDialogByType]);

  const handleSubmit = useCallback(async () => {
    if (dialogParams?.currentEventId && roomId) {
      updateDialogParams(`${DialogType.ROOM_NAME}/${roomId}`, {
        confirmLoading: true,
      });
      await changeRoomName(
        roomId,
        roomName,
        isForAllRooms,
        dialogParams.roomCode,
        dialogParams.currentEventId,
      );
      closeDialog();
    }
  }, [
    dialogParams,
    isForAllRooms,
    roomId,
    roomName,
    updateDialogParams,
    closeDialog,
    changeRoomName,
  ]);

  const handleEnter = useCallback(
    async (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "enter") {
        await handleSubmit();
      }
    },
    [handleSubmit],
  );

  const renderForm = useCallback(
    (defaultRoomName: string) => (
      <DialogContent onKeyUp={(e) => handleEnter(e)}>
        <DialogContentText style={{ textAlign: "center" }}>
          <Typography style={{ marginTop: "10px", marginBottom: "10px" }}>
            {t("manageEvent:change.table.name")}
          </Typography>
          <Input
            fullWidth
            placeholder={t("manageEvent:please.enter.table.name")}
            onChange={(e) => {
              setRoomName(e.target.value);
            }}
            inputProps={{
              "data-testid": "room-name",
            }}
            autoFocus
            defaultValue={defaultRoomName}
          />
          {dialogParams?.hasMultipleFloors && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  data-testid="all-rooms-checkbox"
                  checked={isForAllRooms}
                  onChange={(e) => {
                    setIsForAllRooms(e.target.checked);
                  }}
                />
              }
              label={t("manageEvent:change.table.name.every.floor")}
            />
          )}
        </DialogContentText>
      </DialogContent>
    ),
    [t, dialogParams?.hasMultipleFloors, isForAllRooms, handleEnter],
  );

  const showDialog = (params: DialogParams) => {
    const { currentRoomName } = params;

    setRoomName(currentRoomName);
    setRoomId(params.roomId);
    setDialogParams(params);

    openDialog({
      content: renderForm(currentRoomName),
      hideCloseButton: true,
      type: `${DialogType.ROOM_NAME}/${params.roomId}`,
      onConfirm: handleSubmit,
      onDismiss: closeDialog,
      dismissText: t("common:button.cancel"),
      confirmText: t("common:button.save"),
      confirmTestId: "confirm-button",
      dismissTestId: "dismiss-button",
    });
  };

  useEffect(() => {
    if (dialogParams) {
      const { currentRoomName } = dialogParams;

      updateDialogParams(`${DialogType.ROOM_NAME}/${roomId}`, {
        content: renderForm(currentRoomName),
        onConfirm: handleSubmit,
        onDismiss: closeDialog,
      });
    }
  }, [
    dialogParams,
    roomName,
    roomId,
    isForAllRooms,
    updateDialogParams,
    handleSubmit,
    closeDialog,
    renderForm,
  ]);

  return { showDialog };
};

export default useRenameRoom;
