import { Map } from "immutable";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IUserState extends Map<string, any> {
  readonly voiceLevel: number;
  readonly idle: boolean;
  readonly lastClick?: number;
  readonly userId: string;
  readonly spaceId: string;
  readonly volume?: number;
  networkQuality: number;
  typing: { status: boolean; channel: string | undefined };
}

export const SYNC_USER_STATE = "app/userState/SYNC_USER_STATE";

export const REMOVE_USER_STATE = "app/userState/REMOVE_USER_STATE";
