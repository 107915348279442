import { getVar } from "config";
import logger from "logging/logger";
import { useQuery } from "@tanstack/react-query";
import { SYMBL_TOKEN_REQUEST_OPTIONS } from "./constants";

const basePath = getVar("REACT_APP_SYMBL_BASE_PATH");
const endpoint = `${basePath}/oauth2/token:generate`;

const CACHE_TIME_IN_MILLISECONDS = 12 * 60 * 60 * 1000; // 12 hours

export const getSymblToken = async (): Promise<string> => {
  const res = await fetch(endpoint, SYMBL_TOKEN_REQUEST_OPTIONS);

  if (!res.ok) {
    throw new Error("Error fetching Symbl token");
  }

  const data = await res.json();

  if (!data.accessToken) {
    throw new Error("Symbl token not found on response");
  }

  return data.accessToken as string;
};

export const useSymblToken = () => {
  const { data, status } = useQuery(
    ["transcription.symblToken"],
    getSymblToken,
    {
      cacheTime: CACHE_TIME_IN_MILLISECONDS,
      onError: (err: Error) => {
        logger.error("Symbl token error", { err });
      },
    },
  );

  return { data, status };
};
