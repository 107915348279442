import { StyleRulesCallback } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import { FormTextInputProps } from "./FormTextInput";

export const styles: StyleRulesCallback<Theme, FormTextInputProps> = (
  theme: Theme,
) => ({
  formTextInput: {
    fontSize: 14,
    color: theme.palette.blue.dark,
    borderColor: "var(--LightGrey)",
  },
  formTextInputShrinkLabel: {
    fontSize: 16,
    color: "var(--TextDark)",
    transform: "translate(4px, -25px) scale(1) !important",
  },
});
