import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import {
  FOCUS_MODE_TILE_HEIGHT,
  FOCUS_MODE_TILE_WIDTH,
  TOTAL_ARROW_BUTTON_HEIGHT,
} from "../hooks/constants";

interface Props {
  isMobile: boolean;
  hasPagination: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(0.25),
    },
  },
  leftColumnWrapper: () => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
    maxHeight: "100%",
    width: FOCUS_MODE_TILE_WIDTH,
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      width: "fit-content",
    },
  }),
  leftColumn: () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    pointerEvents: "all",
    width: "100%",
    minWidth: "fit-content",
    height: "auto",
    margin: "auto",
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    gap: theme.spacing(1),
    "& > div": {
      aspectRatio: "4/3",
      width: FOCUS_MODE_TILE_WIDTH,
      minHeight: "112px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "112px",
      maxWidth: "100%",
      minWidth: "auto",
      flexDirection: "row",
      width: "100%",
      overflowY: "hidden",
      overflowX: "auto",
    },
  }),
  focusedTile: () => ({
    height: "100%",
    width: `calc(100% - ${FOCUS_MODE_TILE_WIDTH}px)`,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: `calc(100% - ${
        FOCUS_MODE_TILE_HEIGHT + TOTAL_ARROW_BUTTON_HEIGHT
      }px)`,
    },
    "& > div": {
      display: "flex",
      height: "100%",
      width: "100%",
      "&  video": {
        objectFit: "contain",
      },
    },
  }),
  banner: {
    display: "flex",
    justifyContent: "center",
  },
  viewButtonRoot: ({ hasPagination }) => ({
    display: "flex",
    backgroundColor: theme.palette.white,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.secondary.main}`,
    height: "40px",
    width: hasPagination ? "180px" : "86px",
  }),
  button: ({ isMobile, hasPagination }) => ({
    paddingBottom: isMobile ? theme.spacing(1) : 0,
    paddingTop: theme.spacing(1),
    maxWidth: hasPagination ? "180px" : "100px",
    height: TOTAL_ARROW_BUTTON_HEIGHT,
  }),
}));
