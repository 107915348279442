import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import logger from "logging/logger";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { selectCurrentSpaceId } from "modules/space/redux";
import useAttendeeActions from "modules/eventMemberList/hooks/useAttendeeActions";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { useSendMessage } from "modules/chat";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { selectUser } from "modules/auth/redux/selectors";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { IRoom } from "types";
import { isLinkedInConnected } from "helpers/linkedInHelper";
import { ReactComponent as LinkedInLogo } from "assets/linkedin.inline.svg";
import { isRoomFull } from "helpers/roomHelper";
import { selectCurrentRoom, selectStage } from "store/rooms";
import {
  selectCurrentEvent,
  selectCurrentEventId,
  selectIsChatEnabled,
} from "modules/event/selectors";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { MenuItem } from "@remo-co/ui-core/src/components/MenuItem";
import { Chat as ChatIcon } from "@remo-co/ui-core/src/icons/Chat";
import { HowToReg } from "@remo-co/ui-core/src/icons/HowToReg";
import { PersonAdd } from "@remo-co/ui-core/src/icons/PersonAdd";
import { PersonPinCircle as PersonPinCircleIcon } from "@remo-co/ui-core/src/icons/PersonPinCircle";
import { RemoveCircle } from "@remo-co/ui-core/src/icons/RemoveCircle";
import { makeSelectUserRoomStatusByRoomId } from "modules/room/redux/selectors";
import { useInviteToTable } from "modules/inviteToTable";
import { getUserName } from "modules/userProfile";
import { useRemoveUserFromFloor } from "modules/joinFloor";
import { isEventManager } from "modules/event/utils/eventUtils";
import { useAppDispatch } from "store/hooks";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { setShowUserForceMoveDialog } from "modules/userForceMove/redux/slice";
import { selectIsUserMoving } from "modules/userForceMove/redux/selectors";
import { ContextMenuItem, ContextMenuTitle } from "..";
import { useStyles } from "./styles";

interface Props {
  userId?: string;
  roomId: string;
  onClose: () => void;
}

const OtherUserContextMenu = ({
  userId,
  roomId,
  onClose,
}: Props): JSX.Element => {
  const { track } = useContext(TRACKING_CONTEXT);
  const dispatch = useAppDispatch();
  const stage = useSelector(selectStage);
  const { t } = useI18n(["common", "event"]);
  const { startDirectMessage } = useSendMessage();
  const { updateEventMemberAccess } = useAttendeeActions();
  const fromUser = useSelector(selectUser);
  const { openDialog } = useDialogNotificationActions();
  const spaceId = useSelector(selectCurrentSpaceId);
  const userIdOrFallback = userId || "";
  const userInSpace = useSelector(makeSelectUserById(userIdOrFallback));
  const toRoom = useSelector(selectCurrentRoom);
  const currentEvent = useSelector(selectCurrentEvent);
  const eventId = useSelector(selectCurrentEventId);
  const theaterId = useSelector(selectCurrentTheaterId);
  const toRoomId = toRoom && toRoom.id;
  const usersInMyRoom = useSelector(
    makeSelectUserRoomStatusByRoomId(toRoomId ?? ""),
  );
  const { eventCompanyPlan } = useCompanyPlanSettings();
  const { addWarningNotification } = useNotificationActions();
  const isChatEnabled = useSelector(selectIsChatEnabled);
  const isUserMoving = useSelector(selectIsUserMoving);
  const classes = useStyles();
  const { inviteToTable } = useInviteToTable();
  const { removeUserFromFloor } = useRemoveUserFromFloor();

  const handleChatStart = () => {
    onClose();

    if (userInSpace && fromUser) {
      startDirectMessage(userInSpace.id, fromUser.id);
    }
  };

  const onInviteUserToRoom = (toId: string, room: IRoom) => {
    if (!room) {
      logger.warn("[onInviteUserToRoom] No Room ");
      return;
    }

    const { isFull } = isRoomFull(room, eventCompanyPlan);

    if (isFull) {
      // If room is full, show error message
      addWarningNotification({
        message: t("table.full.message"),
      });
    } else {
      inviteToTable({ userId: toId, tableId: room.id });
    }
  };

  const handleInviteUser = () => {
    onClose();

    if (userInSpace && toRoom) {
      onInviteUserToRoom(userInSpace.id, toRoom);
    }
  };

  const handleMoveToStage = () => {
    onClose();

    if (userInSpace && stage) {
      onInviteUserToRoom(userInSpace.id, stage);
    }
  };

  const handleRemoveUserFromEvent = useCallback(async () => {
    onClose();

    if (eventId && theaterId && userId && userInSpace && roomId && spaceId) {
      openDialog({
        message: t("confirm.remove"),
        confirmText: t("remove.user"),
        dismissText: t("block.guest"),
        confirmTestId: "confirm-button",
        dismissTestId: "dismiss-button",
        onConfirm: () => {
          removeUserFromFloor({ eventId, floorId: spaceId, userId });
        },
        onDismiss: async () => {
          const { isSuccess } = await updateEventMemberAccess(
            eventId,
            theaterId,
            userIdOrFallback,
            true,
          );

          if (isSuccess) {
            removeUserFromFloor({ eventId, floorId: spaceId, userId });
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, theaterId, userInSpace, roomId, spaceId]);

  const handleOpenLinkedin = () => {
    onClose();

    if (userInSpace) {
      window.open(userInSpace?.linkedIn?.publicProfileUrl);
    }
  };

  const handleForceMoveUser = () => {
    onClose();

    if (userInSpace) {
      track(Events.MOVE_USER_INTENT, { eventId, userId: userInSpace.id });
      dispatch(setShowUserForceMoveDialog(userInSpace));
    }
  };

  const weAreInSameRoom =
    usersInMyRoom && userInSpace && usersInMyRoom[userInSpace.id];
  const isUserOnStage = (stage && stage.id) === roomId;
  const isUserAdmin =
    userInSpace && isEventManager(currentEvent ?? undefined, userInSpace.id);

  return (
    <div data-testid="user-context-menu">
      <ContextMenuTitle
        id="ucm-other-user-title"
        text={getUserName(userInSpace)}
      />
      <MenuItem className={classes.menuItem} disabled>
        {userInSpace && isLinkedInConnected(userInSpace) && (
          <div className={classes.menuItemHeadline}>
            {userInSpace?.linkedIn?.headline}
          </div>
        )}
      </MenuItem>
      {isChatEnabled && (
        <ContextMenuItem
          id="ucm-message"
          data-testid="ucm-message"
          onClick={handleChatStart}
          text={t("message.send")}
          icon={
            <ChatIcon className={classes.chatIcon} width="26" height="26" />
          }
        />
      )}
      {!weAreInSameRoom && userInSpace && (
        <ContextMenuItem
          id="ucm-get-user"
          data-testid="ucm-get-user"
          onClick={handleInviteUser}
          text={t("get.user", {
            key: getUserName(userInSpace),
          })}
          icon={<PersonAdd color="secondary" />}
        />
      )}
      {!isUserOnStage && (
        <ContextMenuItem
          id="ucm-put-to-stage"
          data-testid="ucm-put-to-stage"
          onClick={handleMoveToStage}
          text={t("text.stage")}
          icon={<HowToReg color="secondary" />}
        />
      )}
      {userInSpace && !isUserAdmin && (
        <ContextMenuItem
          id="ucm-remo-attendee"
          data-testid="remove-guest-menu-item"
          onClick={handleRemoveUserFromEvent}
          text={t("remove.guest", {
            key: getUserName(userInSpace),
          })}
          icon={<RemoveCircle color="secondary" />}
        />
      )}
      {userInSpace && !isUserMoving && !isUserAdmin && (
        <ContextMenuItem
          id="ucm-force-move"
          data-testid="force-move-menu-item"
          onClick={handleForceMoveUser}
          text={t("event:force.move.user.menu.item", {
            key: getUserName(userInSpace),
          })}
          icon={<PersonPinCircleIcon color="secondary" />}
        />
      )}
      {userInSpace &&
        isLinkedInConnected(userInSpace) &&
        userInSpace?.linkedIn?.publicProfileUrl && (
          <ContextMenuItem
            id="ucm-linkedin"
            onClick={handleOpenLinkedin}
            icon={<LinkedInLogo className={classes.icon} />}
            text={t("connect.linkedin")}
          />
        )}
    </div>
  );
};

export default OtherUserContextMenu;
