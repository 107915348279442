import { useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { IUser } from "modules/app/types";
import { initUser } from "../../helpers/initUser";
import { config } from "../../config";
import useTrackRefreshes from "../useTrackRefreshes";
import useTrackCompanyPlan from "../useTrackCompanyPlan";
import useTrackEventCode from "../useTrackEventCode";
import useTrackIframe from "../useTrackIframe";
import useTrackEventRole from "../useTrackEventRole";
import useTrackCompanyRole from "../useTrackCompanyRole";

export const useMonitoring = (user?: IUser): void => {
  const [isInit, setInit] = useState(
    Boolean(datadogRum.getInternalContext()?.session_id),
  );

  useEffect(() => {
    if (isInit) {
      return;
    }

    datadogRum.init(config);

    setInit(true);
  }, [isInit]);

  useEffect(() => {
    if (!isInit || !user) {
      return;
    }

    initUser(user);
  }, [user, isInit]);

  useTrackCompanyPlan(isInit);
  useTrackIframe(isInit);
  useTrackEventCode(isInit);
  useTrackRefreshes(isInit);
  useTrackEventRole(isInit);
  useTrackCompanyRole(isInit);
};
