import { database } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";
import { InviteToStageStatus } from "./constants";

const getBroadcasterDocRef = (theaterId: string) =>
  database.ref(`${THEATER_PATH}/${theaterId}/Broadcaster`);

const giveBroadcastRight = (theaterId: string, userId: string) => {
  const dbRef = getBroadcasterDocRef(theaterId);

  return dbRef.child(userId).set({
    status: InviteToStageStatus.SUCCESS,
  });
};

const takeBroadcastRight = (theaterId: string, userId: string) => {
  const dbRef = getBroadcasterDocRef(theaterId);

  return dbRef.child(userId).remove();
};

const removeBroadcaster = (theaterId: string) => {
  if (!theaterId) {
    return false;
  }

  return getBroadcasterDocRef(theaterId).remove();
};

export default {
  getBroadcasterDocRef,
  giveBroadcastRight,
  takeBroadcastRight,
  removeBroadcaster,
};
