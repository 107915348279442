import {
  MouseEventHandler,
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Warning } from "@remo-co/ui-core/src/icons/Warning";
import { IRoom, ISeatPosition } from "types";
import { useI18n } from "i18n";
import classNames from "classnames";
import { IMapTemplate } from "types/theater";
import { Popper, PopperProps } from "@remo-co/ui-core/src/components/Popper";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";

export interface ICFPOverlayHandle {
  refs: Array<HTMLDivElement | null>;
}

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 3,
  },
}));

interface ICustomFloorPlanOverlay {
  config?: null | Partial<IMapTemplate>;
  overlapCheckResult: string[] | null;
}

const CustomFloorPlanOverlay = (
  { config, overlapCheckResult }: ICustomFloorPlanOverlay,
  ref: Ref<ICFPOverlayHandle> | undefined,
) => {
  const { t } = useI18n(["common", "customFloorPlan"]);
  const styles = useStyles();
  const roomRefs = useRef<Array<HTMLDivElement | null>>([]);

  useImperativeHandle(ref, () => ({
    get refs() {
      return roomRefs.current;
    },
  }));

  const cfArea = config?.conferenceArea;

  /**
   * A note on the following refs and Popper component:
   *
   * Our version of MUI does not currently support the followCursor prop, so to provide
   * this functionality we had to implement our own cursor tracking.
   * Remove all of the below once we've updated MUI
   */

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error popperjs (correct type) is not a dependency we currently have within the project
  const popperRef = useRef<Popper | null>(null);
  const tooltipPositionRef = useRef({ x: 0, y: 0 });

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
    tooltipPositionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current) {
      popperRef.current.update();
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const CursorFollowingPopper = (props: PopperProps) => (
    <Popper
      {...props}
      popperRef={popperRef}
      anchorEl={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error will be removed in the future
        getBoundingClientRect: () => ({
          top: tooltipPositionRef.current.y,
          left: tooltipPositionRef.current.x,
          right: tooltipPositionRef.current.x,
          bottom: tooltipPositionRef.current.y,
          width: 0,
          height: 0,
        }),
      }}
      placement="bottom-start"
    />
  );

  if (!config) {
    return null;
  }

  return (
    <div className={styles.root} onMouseMove={handleMouseMove}>
      {config?.rooms?.map((rm: IRoom, index: number) => (
        <Tooltip
          key={rm.code}
          title={rm.name}
          PopperComponent={CursorFollowingPopper}
          // followCursor={true} we could use this after updating material-ui/core
        >
          <div
            ref={(el) => {
              roomRefs.current[index] = el;
            }}
            className={classNames("room", {
              isometric: rm.boundaryPolygon,
              blocked:
                overlapCheckResult && overlapCheckResult.includes(rm.code),
            })}
            id={rm.code}
            style={{
              width: rm.width,
              height: rm.height,
              top: rm.y - (cfArea?.y || 0),
              left: rm.x - (cfArea?.x || 0),
            }}
            data-testid="cfp-room-overlay"
          >
            {!rm.nameTextPath && rm.nameParams && (
              <div
                className="room-name"
                style={{
                  bottom: rm.height - rm.nameParams.y,
                  left: rm.nameParams.x,
                  fontSize: rm.nameParams?.fontSize,
                  color: rm.nameParams.color,
                }}
              >
                {rm.name}
              </div>
            )}
            {rm.nameTextPath && rm.nameParams && (
              <div
                className="text-path-name-container"
                data-testid="cfp-test-rm-text-path"
              >
                <svg width="100%" height="100%">
                  <path
                    id={`text-path-${rm.code}`}
                    fill="none"
                    stroke="red"
                    d={rm.nameTextPath}
                  />
                  <text
                    style={{
                      fill: rm.nameParams?.color,
                      fontSize: rm.nameParams?.fontSize,
                    }}
                    className="MuiTypography-body1"
                  >
                    <textPath
                      textAnchor="middle"
                      href={`#text-path-${rm.code}`}
                      startOffset="50%"
                    >
                      {rm.name}
                    </textPath>
                  </text>
                </svg>
              </div>
            )}
            {rm.boundaryPolygon && (
              <svg width="100%" height="100%">
                <polygon
                  id={`rm-bp-${rm.code}`}
                  stroke="lime"
                  fill="#00000077"
                  strokeWidth="2px"
                  points={rm.boundaryPolygon}
                  data-testid="cfp-test-rm-boundary-polygon"
                  className="room-boundary"
                />
              </svg>
            )}
            {rm.positions &&
              config &&
              config.seatRadius &&
              rm.positions.map((seat, i) => (
                <div
                  key={`${seat.x}-${seat.y}`}
                  className="seat"
                  style={{
                    left: seat.x,
                    top: seat.y,
                    width: (config.seatRadius || 1) * 2,
                    height: (config.seatRadius || 1) * 2,
                  }}
                  data-testid="cfp-test-rm-positions"
                >
                  {i + 1}
                </div>
              ))}
            {rm.adminSeats &&
              config &&
              config.seatRadius &&
              rm.adminSeats.map((seat: ISeatPosition) => (
                <div
                  key={`${seat.x}-${seat.y}`}
                  className="seat admin"
                  style={{
                    left: seat.x,
                    top: seat.y,
                    width: (config.seatRadius || 1) * 2,
                    height: (config.seatRadius || 1) * 2,
                    pointerEvents: "none",
                  }}
                  data-testid="cfp-test-rm-admin-seats"
                >
                  <b />
                </div>
              ))}
            {rm.order && (
              <div
                className={classNames("room-position", {
                  isometric: rm.boundaryPolygon,
                  blocked:
                    overlapCheckResult && overlapCheckResult.includes(rm.code),
                })}
                data-testid="cfp-test-rm-order"
              >
                {rm.order}{" "}
                {overlapCheckResult && overlapCheckResult.includes(rm.code) ? (
                  <Warning className="icon" />
                ) : null}
              </div>
            )}
            {(rm.name === "Stage" ||
              rm.name === "Left Board" ||
              rm.name === "Right Board") && (
              <div
                className={`room-position special${
                  rm.boundaryPolygon && rm.name === "Stage" ? " isometric" : ""
                }`}
              >
                {rm.name === "Stage" ? t("customFloorPlan:stage") : rm.name}
              </div>
            )}
          </div>
        </Tooltip>
      ))}

      {config?.sponsors?.map((sponsor, index) => (
        <Tooltip
          key={`${sponsor.y}-${sponsor.x}`}
          title={`${t("customFloorPlan:sponsor")} ${index + 1}`}
          PopperComponent={CursorFollowingPopper}
          // followCursor={true} we could use this after updating material-ui/core
        >
          <div
            className="sponsor"
            style={{
              width: `${sponsor.width}px`,
              height: `${sponsor.height}px`,
              top: `${Number(sponsor.y) - (cfArea?.y || 0)}px`,
              left: `${Number(sponsor.x) - (cfArea?.x || 0)}px`,
              // temporarily disable pointer events if overlap check is ongoing
              pointerEvents: !overlapCheckResult ? "none" : "auto",
            }}
            data-testid="cfp-sponsor-overlay"
          >
            {t("customFloorPlan:sponsor")} {index + 1}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default forwardRef<ICFPOverlayHandle, ICustomFloorPlanOverlay>(
  CustomFloorPlanOverlay,
);
