import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 300,
    height: 300,
    background: "white",
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "5px",
    boxShadow: `2px 4px 20px rgba(0, 0, 0, 0.1)`,
  },
  header: {
    padding: "10px 15px",
    borderBottom: `1px solid ${theme.palette.gray.light}`,
  },
  title: {
    color: theme.palette.blue.dark,
    fontWeight: 800,
  },
  content: {
    height: "calc(100% - 66px)",
    position: "relative",
    overflowY: "auto",
  },
  rows: {
    height: "100%",
    padding: 10,
  },
}));

export default useStyles;
