import { selectUserId } from "modules/auth/redux/selectors";
import { Errors, trackError } from "modules/monitoring";
import useSystemClock from "modules/system/hooks/useSystemClock";
import fireStorageService from "modules/videoBackground/hooks/useFireStorage";
import { IBackgroundImage } from "modules/videoBackground/types";
import videoBackgroundFirebase from "modules/videoBackground/videoBackground.firebase";
import { useCallback, useMemo } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  BACKGROUND_IMAGE_QUERY_LIMIT,
  VIRTUAL_BACKGROUND_CACHE_KEY,
  presetVirtualBackgroundImages,
} from "./constants";

interface BackgroundImageQueryParams {
  pageParam?: number;
  userId: string;
}

const backgroundImageQuery = async ({
  pageParam = 0,
  userId,
}: BackgroundImageQueryParams) => {
  // TODO: this is not a hook.
  const fireStorage = fireStorageService();

  const allImagesQuery = videoBackgroundFirebase
    .getUserVideoBackgroundCollection(userId)
    .orderBy("createdAt", "desc");

  const allSnapshot = await allImagesQuery.get();

  let allImages = allSnapshot.docs.map((doc, index) => {
    const data = doc.data();
    return {
      id: doc.id,
      filePath: data.filePath,
      createdAt: data.createdAt,
      author: data.author,
      index,
    };
  });

  const nonPresetImages = allImages.filter((image) => image.author !== "Remo");
  const presetImagesInFirestore = allImages.filter(
    (image) => image.author === "Remo",
  );

  const newPresetImagesToAdd = presetVirtualBackgroundImages
    .filter(
      (presetImagePath) =>
        !presetImagesInFirestore.some(
          (imgInFirestore) => imgInFirestore.filePath === presetImagePath,
        ),
    )
    .map((filePath, index) => ({
      filePath,
      author: "Remo",
      index: allImages.length + index,
      id: `preset-${index}`,
      createdAt: Date.now(),
    }));

  allImages = [
    ...nonPresetImages,
    ...presetImagesInFirestore,
    ...newPresetImagesToAdd,
  ];

  const startIndex = pageParam === 0 ? pageParam : pageParam + 1;
  const paginatedImages = allImages.slice(
    startIndex,
    startIndex + BACKGROUND_IMAGE_QUERY_LIMIT,
  );

  const imagesWithUrls = await Promise.all(
    paginatedImages.map(async (image) => {
      const url = await fireStorage.getAssetsUrl(image.filePath);
      return {
        ...image,
        url,
      };
    }),
  );

  return imagesWithUrls;
};

export const useBackgroundImageQuery = () => {
  const { serverTime } = useSystemClock();
  const userId = useSelector(selectUserId);
  const queryClient = useQueryClient();

  const {
    status: backgroundImageStatus,
    isLoading: isLoadingBackgroundImages,
    data,
    fetchNextPage: fetchMoreBackgroundImages,
  } = useInfiniteQuery(
    [VIRTUAL_BACKGROUND_CACHE_KEY, userId],
    async ({ pageParam }: Omit<BackgroundImageQueryParams, "userId">) => {
      if (!userId) {
        return [];
      }
      return backgroundImageQuery({ pageParam, userId });
    },
    {
      enabled: userId !== null,
      getNextPageParam: (lastPage, pages) => {
        // start of pagination
        if (pages.length === 0) {
          return undefined;
        }

        return lastPage[lastPage.length - 1]?.index;
      },
    },
  );

  const { mutateAsync: addBackgroundMutation } = useMutation(
    async (payload: Omit<IBackgroundImage, "id">) => {
      if (userId === null) {
        throw new Error("no background image");
      }
      await videoBackgroundFirebase
        .getUserVideoBackgroundCollection(userId)
        .add(payload);
    },
    {
      onError: (error) => {
        trackError(error, { label: Errors.VIRTUAL_BACKGROUND });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [VIRTUAL_BACKGROUND_CACHE_KEY, userId],
        });
      },
    },
  );

  const addBackground = useCallback(
    async (filePath: string) => {
      if (!userId) {
        return;
      }

      await addBackgroundMutation({
        author: userId,
        filePath,
        deleted: false,
        createdAt: serverTime,
      });
    },
    [addBackgroundMutation, userId, serverTime],
  );

  const backgroundImages = useMemo(() => data?.pages ?? [], [data]);
  const endOfPagination = useMemo(
    () => backgroundImages[backgroundImages.length - 1]?.length === 0,
    [backgroundImages],
  );

  return {
    backgroundImageStatus,
    isLoadingBackgroundImages,
    backgroundImages,
    endOfPagination,
    fetchMoreBackgroundImages,
    addBackground,
  };
};
