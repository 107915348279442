import { ComponentProps } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import Typography from "./Typography";

export const useStyles = makeStyles<Theme, ComponentProps<typeof Typography>>(
  () => ({
    underline: ({ underline }) => ({
      textDecoration: underline ? "underline" : "initial",
    }),
  }),
);
