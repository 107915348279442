import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "500px",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(4),
  },
  header: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px`,
  },
  actions: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px`,
    justifyContent: "space-between",
  },
  contentTypeLabel: {
    marginTop: theme.spacing(2.5),
  },
}));
