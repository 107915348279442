import { StyleRulesCallback, Theme } from "@remo-co/ui-core/src/types";
import { createStyles } from "@remo-co/ui-core/src/utils/createStyles";
import MiroWhiteboard from "modules/whiteboard/MiroWhiteboard";
import { ComponentProps } from "react";

export const styles: StyleRulesCallback<
  Theme,
  ComponentProps<typeof MiroWhiteboard>
> = (theme: Theme) =>
  createStyles({
    whiteboardContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      "& iframe": {
        border: "none",
        marginRight: "4px",
      },
    },
    tryAgainButton: {
      marginTop: 15,
    },
    progressIndicator: {
      textAlign: "center",
    },
    whiteboardFabButton: {
      zIndex: 9,
      backgroundColor: "white",
      boxShadow: "1px 1px 4px -1px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        color: theme.palette.blue.main,
      },
    },
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      marginTop: 35,
    },

    closeButton: {
      marginBottom: 10,
    },
  });
