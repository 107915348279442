import { Errors, trackError } from "modules/monitoring";
import {
  THIRD_PARTY_REACHABILITY_URLS,
  ThirdPartyService,
} from "modules/micCamCheck/systemCheck/hooks/useFirewallTest/constants";

const isReachable = async (url: string) => {
  await fetch(url, { method: "HEAD", mode: "no-cors" });
};

const isThirdPartyReachable = async (
  thirdParty: ThirdPartyService,
): Promise<boolean> => {
  try {
    await isReachable(THIRD_PARTY_REACHABILITY_URLS[thirdParty]);

    return true;
  } catch (error) {
    trackError(error, {
      label: Errors.UNREACHABLE_THIRD_PARTY_SERVICE,
      info: thirdParty,
    });

    return false;
  }
};

export default isThirdPartyReachable;
