import { THEATER_PATH } from "modules/theater/firebase";
import { generateDocRef } from "modules/firebase";
import { LayoutCollection, Tile } from "./types";

export const getLayoutTilesDbRef = generateDocRef(
  (theaterId: string) => `${THEATER_PATH}/${theaterId}/Broadcast/Layout/Tiles`,
);

export const clearLayout = async ({ theaterId }: { theaterId: string }) => {
  await getLayoutTilesDbRef(theaterId).remove();
};

export interface AddTileParams {
  theaterId: string;
  streamId: string;
  primary?: boolean;
}

export const addTile = ({ theaterId, streamId }: AddTileParams) => {
  const tileRef = getLayoutTilesDbRef(theaterId);

  const updatedAt = Date.now();

  return tileRef.transaction(
    (state: LayoutCollection | null): LayoutCollection => {
      if (state === null) {
        return {
          [streamId]: {
            streamId,
            updatedAt,
            primary: false,
          },
        };
      }

      return {
        ...state,
        [streamId]: {
          streamId,
          updatedAt,
          primary: false,
        },
      };
    },
  );
};

export const removeTile = ({ theaterId, streamId }: AddTileParams) => {
  const tileRef = getLayoutTilesDbRef(theaterId);

  return tileRef.transaction(
    (state: LayoutCollection | null): LayoutCollection | null => {
      if (state === null) {
        return null;
      }
      const { [streamId]: _tileToRemove, ...tiles } = state;

      return tiles;
    },
  );
};

export interface AddPrimaryTileParams {
  theaterId: string;
  streamId: string;
}

export const addPrimaryTileTransaction = (
  primaryStreamId: string,
  state: LayoutCollection | null,
): LayoutCollection => {
  const tileState: Tile = {
    streamId: primaryStreamId,
    primary: true,
    updatedAt: Date.now(),
  };
  if (state === null) {
    return { [primaryStreamId]: tileState };
  }

  const { [primaryStreamId]: _primaryTile, ...rest } = state;

  const otherTiles = Object.fromEntries(
    Object.entries(rest).map(([streamId, tile]) => [
      streamId,
      { ...tile, primary: false },
    ]),
  );

  return {
    ...otherTiles,
    [primaryStreamId]: tileState,
  };
};

export const addPrimaryTile = ({
  theaterId,
  streamId,
}: AddPrimaryTileParams) => {
  const tileRef = getLayoutTilesDbRef(theaterId);

  return tileRef.transaction(
    (state: LayoutCollection | null): LayoutCollection | null =>
      addPrimaryTileTransaction(streamId, state),
  );
};

export const parseActiveTiles = (
  layoutCollection: LayoutCollection,
): Tile[] => {
  // primary first, then last updated
  const tiles = Object.values(layoutCollection);
  tiles.sort((a, b) => (a.primary ? -1 : 0 || b.updatedAt - a.updatedAt));

  return tiles;
};

export const getActiveTiles = async ({
  theaterId,
}: {
  theaterId: string;
}): Promise<Tile[]> => {
  const layoutModeRef = getLayoutTilesDbRef(theaterId);
  const layoutSnapshot = await layoutModeRef.get();
  const activeTiles = layoutSnapshot.val() as LayoutCollection | null;
  if (activeTiles === null) {
    return [];
  }

  return parseActiveTiles(activeTiles);
};

type PresetCustomType = "custom";

interface FocusLayoutConfigProps {
  streamIds: string[];
  preferredParticipant: string;
  preferScreenShare: boolean;
}

export const gridLayoutConfig = (streamIds: string[]) => ({
  layout: {
    participants: {
      video: streamIds,
      audio: streamIds,
    },
    preset: "custom" as PresetCustomType,
    composition_params: {
      mode: "grid",
      "videoSettings.showParticipantLabels": true,
      "videoSettings.omitPausedVideo": true,
      "videoSettings.omitExtraScreenshares": false,
      "videoSettings.grid.useDominantForSharing": false,
    },
  },
});

export const focusLayoutConfig = ({
  streamIds,
  preferredParticipant,
  preferScreenShare,
}: FocusLayoutConfigProps) => ({
  layout: {
    participants: {
      video: streamIds,
      audio: streamIds,
    },
    preset: "custom" as PresetCustomType,
    composition_params: {
      mode: "dominant",
      "videoSettings.dominant.position": "right",
      "videoSettings.omitPausedVideo": true,
      "videoSettings.omitExtraScreenshares": false,
      "videoSettings.showParticipantLabels": true,
      "videoSettings.dominant.followDomFlag": false,
      "videoSettings.preferredParticipantIds": preferredParticipant.replace(
        "-screen",
        "",
      ),
      "videoSettings.preferScreenshare": preferScreenShare,
    },
  },
});
