import ActionTypes from "./constants";

type ShowCameraSettings = {
  type: ActionTypes.SHOW_CAMERA_SETTINGS_DIALOG;
};

type HideCameraSettings = {
  type: ActionTypes.HIDE_CAMERA_SETTINGS_DIALOG;
};

const showCameraSettingsDialog = (): ShowCameraSettings => ({
  type: ActionTypes.SHOW_CAMERA_SETTINGS_DIALOG,
});

const hideCameraSettingsDialog = (): HideCameraSettings => ({
  type: ActionTypes.HIDE_CAMERA_SETTINGS_DIALOG,
});

export type CameraSettingsAction = ShowCameraSettings | HideCameraSettings;

export default {
  showCameraSettingsDialog,
  hideCameraSettingsDialog,
};
