import { useMemo, useState, useEffect, useContext, useCallback } from "react";
import MicrophoneSelector from "modules/conversation/components/MicrophoneSelector";
import { useSelector } from "react-redux";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import onboardingActions from "modules/onboarding/redux/actions";
import { Actions, trackAction, trackActionStart } from "modules/monitoring";
import { ActionButton } from "modules/actionButton";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { getButtonTitle, hotKeys } from "modules/eventButtons/utils";
import { useDevices } from "modules/audioVideo/hooks";
import { usePrevious } from "helpers/reactHooksUtils";
import {
  PermissionState,
  selectAudioPermissionsState,
  selectHasAudioPermissions,
} from "modules/deviceInfo";
import { showDevicePromptPopup } from "modules/audioVideo/redux/slice";
import { MicCamPopupState } from "modules/audioVideo/types";
import { MicrophoneIcon } from "@remo-co/ui-core/src/icons/Microphone";
import { Container } from "@remo-co/ui-core/src/components/Container";
import {
  MicrophoneNotFoundIcon,
  MicrophoneDisabledIcon,
  MicrophoneNeedsPermissionIcon,
} from "./icons";
import { useKeyPressedAndHeld } from "../../hooks";
import { useStyles } from "./styles";

export const MICROPHONE_BUTTON_THROTTLE_TIME = 500;

interface Props {
  darkMode?: boolean;
  hideOptions?: boolean;
  askForConfirmation?: boolean;
}

const MicrophoneButton = ({
  darkMode,
  hideOptions = false,
  askForConfirmation = false,
}: Props): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const hasAudioPermissions = useSelector(selectHasAudioPermissions);
  const audioPermissionState = useSelector(selectAudioPermissionsState);
  const { openDialog } = useDialogNotificationActions();
  const { track } = useContext(TRACKING_CONTEXT);
  const [isClicked, setIsClicked] = useState(false);
  const { t } = useI18n();
  const { microphoneActive, setMicrophoneActive } = useDevices();
  const prevMicrophoneActive = usePrevious(microphoneActive);

  const { isKeyPressedAndHeld: isSpacePressed, didPressOnce } =
    useKeyPressedAndHeld("Space");

  const micNeedsPermission = audioPermissionState === PermissionState.BLOCKED;
  const micNotFound =
    audioPermissionState === PermissionState.NOT_FOUND ||
    audioPermissionState === PermissionState.PENDING;
  const micUnavailable = !hasAudioPermissions;

  useEffect(() => {
    if (isClicked) {
      dispatch(onboardingActions.showCamAndMicPopover(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked]);

  const handleTurnOnMicrophone = useCallback(() => {
    if (microphoneActive) return;
    setIsClicked(true);

    track(Events.MICROPHONE_START_CLICK);
    trackAction(Actions.MICROPHONE_START_CLICK);
    trackActionStart(Actions.DAILY_MICROPHONE_START_DURATION);

    if (askForConfirmation) {
      openDialog({
        confirmText: t("turn.on.mic"),
        dismissText: t("button.close"),
        onConfirm: () => {
          setMicrophoneActive(true);
        },
        message: t("audio.will.be.shared"),
        hideCloseButton: true,
      });
      return;
    }
    setMicrophoneActive(true);
  }, [
    microphoneActive,
    track,
    setMicrophoneActive,
    askForConfirmation,
    openDialog,
    t,
  ]);

  const handleTurnOffMicrophone = useCallback(() => {
    setIsClicked(true);

    track(Events.MICROPHONE_STOP_SUCCESS);
    trackAction(Actions.MICROPHONE_STOP_CLICK);

    setMicrophoneActive(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microphoneActive]);

  const handleButtonClick = useCallback(() => {
    if (micNeedsPermission) {
      dispatch(
        showDevicePromptPopup({
          state: PermissionState.BLOCKED,
          audio: true,
        } as MicCamPopupState),
      );
      return;
    }

    if (micNotFound) {
      dispatch(
        showDevicePromptPopup({
          state: PermissionState.NOT_FOUND,
          audio: true,
        } as MicCamPopupState),
      );
      return;
    }

    if (microphoneActive) {
      handleTurnOffMicrophone();
    } else {
      handleTurnOnMicrophone();
    }
  }, [
    micNeedsPermission,
    micNotFound,
    microphoneActive,
    dispatch,
    handleTurnOffMicrophone,
    handleTurnOnMicrophone,
  ]);

  useEffect(() => {
    if (isInBroadcast) return;

    if (isSpacePressed) {
      handleTurnOnMicrophone();
    } else if (didPressOnce) {
      handleTurnOffMicrophone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpacePressed]);

  useEffect(() => {
    if (!prevMicrophoneActive && microphoneActive && isClicked) {
      track(Events.MICROPHONE_START_SUCCESS);
    }
  }, [prevMicrophoneActive, microphoneActive, isClicked, track]);

  let buttonState: "default" | "active" | "warning" = microphoneActive
    ? "active"
    : "warning";

  if (micUnavailable) {
    buttonState = "default";
  }

  const icon = useMemo(() => {
    if (micNeedsPermission) {
      return <MicrophoneNeedsPermissionIcon />;
    }
    if (micNotFound) {
      return <MicrophoneNotFoundIcon />;
    }
    if (!microphoneActive) {
      return <MicrophoneDisabledIcon />;
    }
    return (
      <Container className={styles.microphoneButton}>
        <MicrophoneIcon />
      </Container>
    );
  }, [
    micNeedsPermission,
    micNotFound,
    microphoneActive,
    styles.microphoneButton,
  ]);

  const title = getButtonTitle(
    "mic",
    audioPermissionState,
    microphoneActive ?? false,
    Boolean(isInBroadcast),
  );

  const rightComponent = useMemo(
    () => !hideOptions && <MicrophoneSelector dark={darkMode} />,
    [darkMode, hideOptions],
  );

  return (
    <ActionButton
      data-testid="microphone-button"
      title={t(title)}
      onClick={handleButtonClick}
      label={microphoneActive ? t("mic.on") : t("mic.off")}
      data-dd-action-name={
        microphoneActive ? "Turn off microphone" : "Turn on microphone"
      }
      state={buttonState}
      hotKey={
        hasAudioPermissions ? hotKeys.toggleMicrophone.keyName : undefined
      }
      icon={icon}
      rightComponent={rightComponent}
      darkMode={darkMode}
    />
  );
};

export default MicrophoneButton;
