import React from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { DialogTitle as MuiDialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import CustomFloorPlanContainer from "../../customFloorPlan/CustomFloorPlanContainer";
import "./UploaderDialog.scss";

interface IUploaderDialogProps {
  open: boolean;
  handleClose: () => void;
}

const DialogTitle = ({ onClose }: { onClose: () => void }) => (
  <MuiDialogTitle disableTypography className="uploader-dialog-title">
    {onClose && (
      <Button
        aria-label="close"
        className="close-btn uploader-close-btn"
        onClick={onClose}
        isIconButton
        color="gray"
        variant="ghost"
      >
        <Close />
      </Button>
    )}
  </MuiDialogTitle>
);

const UploaderDialog = ({ open, handleClose }: IUploaderDialogProps) => {
  const { state, getTemplates } = React.useContext(MANAGE_EVENT_CONTEXT);
  const templates = state?.templates ?? null;

  const handleSave = async () => {
    await getTemplates();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen disableEscapeKeyDown>
      <DialogTitle onClose={handleClose} />
      <DialogContent className="uploader-dialog-content">
        <div className="container uploader-dialog-cfp-container">
          <CustomFloorPlanContainer
            onSave={handleSave}
            templates={templates?.privateTemplates}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploaderDialog;
