import { ListItemIcon } from "@remo-co/ui-core/src/components/ListItemIcon";
import { ListItemText } from "@remo-co/ui-core/src/components/ListItemText";
import { MenuItem } from "@remo-co/ui-core/src/components/MenuItem";

interface Props {
  onClick?: () => void;
  icon?: JSX.Element;
  text: string;
  id: string;
  disabled?: boolean;
}

const ContextMenuItem = (props: Props): JSX.Element => {
  const { onClick, icon, text, id, disabled, ...rest } = props;

  return (
    <MenuItem
      id={id}
      className="context-menu-item"
      disableGutters
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText color="textPrimary" primary={text} />
    </MenuItem>
  );
};

export default ContextMenuItem;
