import { useSelector } from "react-redux";
import { selectBrowserName } from "modules/micCamCheck/systemCheck/redux/selectors";
import { selectIsSafariAutoplayResumed } from "modules/session/redux/selectors";
import {
  selectHasAudioPermissions,
  selectHasVideoPermissions,
} from "modules/deviceInfo/slice/selectors";
import { BROWSER_NAMES } from "modules/systemCheck/constants";
import { useDevices } from "modules/audioVideo/hooks/useDevices/useDevices";
import { AudioTile } from "./AudioTile";

export const AudioGroup = ({ sessionIds }: { sessionIds: string[] }) => {
  const isSafariAutoplayResumed = useSelector(selectIsSafariAutoplayResumed);
  const browser = useSelector(selectBrowserName);
  const hasAudioPermissions = useSelector(selectHasAudioPermissions);
  const hasVideoPermissions = useSelector(selectHasVideoPermissions);
  const { microphones, cameras } = useDevices();

  const isSafari = [BROWSER_NAMES.SAFARI, BROWSER_NAMES.MOBILE_SAFARI].includes(
    browser,
  );

  const hasDevices = microphones.length > 0 || cameras.length > 0;
  const isMicCamBlockedOrNoDevice =
    !hasDevices || (hasDevices && !hasAudioPermissions && !hasVideoPermissions);

  if (isSafari && isMicCamBlockedOrNoDevice && !isSafariAutoplayResumed)
    return null;

  return (
    <>
      {sessionIds.map((sessionId) => (
        <AudioTile key={sessionId} sessionId={sessionId} />
      ))}
    </>
  );
};
