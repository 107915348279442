import React from "react";
import { Trans } from "react-i18next";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { getUserName } from "modules/userProfile";
import { useAppDispatch } from "store/hooks";
import { openUserProfileWindow } from "modules/userProfileWindow/redux/userProfileWindowSlice";
import { useStyles } from "../styles";
import MessageContent from "../../MessageContent";
import { CHAT_MESSAGE_TYPES } from "../../../../redux/constants";
import ActionMessageCard from "../ActionMessageCard";
import MessageTime from "../MessageTime";
import { IMessageTypeProps } from "../../Message";

export const TextMessage = ({ message, author, isMe }: IMessageTypeProps) => {
  const dispatch = useAppDispatch();
  const isInBroadcast = useSelector(selectIsInBroadcast);
  const classes = useStyles({ isInBroadcast: Boolean(isInBroadcast) });

  const handleOpenProfile = React.useCallback(() => {
    dispatch(openUserProfileWindow());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMe) {
    return (
      <div className={classes.sentWrapper}>
        <div className={classNames(classes.text, classes.sent)}>
          <MessageContent key={message.time} text={message.text} />
          <MessageTime time={message.time} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.receivedWrapper}>
      <div className={classNames(classes.text, classes.received)}>
        <Typography variant="body2" className={classes.authorName}>
          {getUserName(author)}
        </Typography>
        <MessageContent key={message.time} text={message.text} />
        <MessageTime time={message.time} />
      </div>
      {message.type === CHAT_MESSAGE_TYPES.PROFILE_INFO_REQUEST && (
        <ActionMessageCard>
          <Trans i18nKey="conversation:update.profile.button">
            Click
            <Button
              className={classes.actionButton}
              variant="text"
              color="dark"
              onClick={handleOpenProfile}
            >
              here
            </Button>
            to update profile now!
          </Trans>
        </ActionMessageCard>
      )}
    </div>
  );
};
