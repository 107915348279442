import React from "react";
import { FormHelperText } from "@remo-co/ui-core/src/components/FormHelperText";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Select } from "@remo-co/ui-core/src/components/Select";
import { useI18n } from "i18n";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";

const EventTypeSelect = () => {
  const { t } = useI18n(["common", "eventForm"]);
  const { state, updateEventData } = React.useContext(MANAGE_EVENT_CONTEXT);
  const eventData = state?.eventData ?? undefined;
  const eventTypeOptions = state?.eventTypeOptions ?? [];

  const handleSetEventType = (eventType: string) => {
    updateEventData({ eventType });
  };

  return (
    <Container bottom="sm" data-testid="event-type-select">
      <Select
        size="sm"
        fullWidth
        id="event-type"
        label={t("eventForm:event.type.label")}
        name="eventType"
        onChange={handleSetEventType}
        options={eventTypeOptions.map(({ value }) => ({
          value,
          label: t(`eventForm:event.type.${value}`),
        }))}
        placeholder={t("eventForm:event.type.placeholder")}
        value={eventData?.eventType || ""}
      />
      <FormHelperText>{t("eventForm:event.type.description")}</FormHelperText>
    </Container>
  );
};

export default EventTypeSelect;
