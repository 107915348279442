import React from "react";
import { lightTheme, darkTheme, RemoTheme } from "@remo-co/ui-core/theme";
import { MuiThemeProvider } from "@material-ui/core/styles";

export interface IProviderProps {
  children?: React.ReactNode;
  theme?: RemoTheme;
}

const Provider = ({ children, theme = RemoTheme.light }: IProviderProps) => (
  <MuiThemeProvider theme={theme === RemoTheme.light ? lightTheme : darkTheme}>
    {children}
  </MuiThemeProvider>
);

export default Provider;
