import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme, { isInBroadcast?: boolean }>(
  (theme) => {
    const inputBackgroundColor =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-error
      theme.overrides.MuiInputBase.root.backgroundColor || "transparent";

    return {
      receivedWrapper: {
        paddingBottom: "10px",
      },
      sentWrapper: {
        width: "80%",
        paddingBottom: "10px",
      },
      gifWrapper: {
        paddingBottom: "10px",
      },
      text: {
        color: theme.palette.text.primary,
        padding: "6px 10px",
        borderRadius: "6px",
        fontWeight: 300,
        fontSize: "15px",
        lineHeight: "1.4",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
      },
      textMessage: {
        color: theme.palette.text.primary,
        fontWeight: 300,
        fontSize: "15px",
        lineHeight: "1.4",
        whiteSpace: "pre-wrap",
        maxWidth: 280,
      },
      link: {
        color: theme.palette.text.primary,
      },
      time: {
        color: theme.palette.text.primary,
        fontWeight: 400,
        display: "block",
        fontSize: "10px",
        paddingTop: "4px",
        whiteSpace: "initial",
      },
      sent: {
        backgroundColor: ({ isInBroadcast }) =>
          isInBroadcast
            ? theme.palette.darkModeBlue.main
            : theme.palette.blue.lightShade1,
        maxWidth: "calc(100% - 10px)",
        wordWrap: "break-word",
        borderTopRightRadius: "0",
        width: "100%",
      },
      gifLoading: {
        backgroundColor: ({ isInBroadcast }) =>
          isInBroadcast
            ? theme.palette.darkModeBlue.main
            : theme.palette.blue.lightShade1,
        borderTopRightRadius: "0",
        padding: "10px",
        marginBottom: "5px",
      },
      gifSent: {
        backgroundColor: ({ isInBroadcast }) =>
          isInBroadcast
            ? theme.palette.darkModeBlue.main
            : theme.palette.blue.lightShade1,
        maxWidth: "calc(100% - 10px)",
        wordWrap: "break-word",
        borderTopRightRadius: "0",
        padding: "10px",
      },
      received: {
        backgroundColor: ({ isInBroadcast }) =>
          isInBroadcast ? "rgba(255, 255, 255, 0.05)" : "#f4f7f9",
        marginRight: "0",
        wordBreak: "break-word",
        borderTopLeftRadius: "0",
      },
      authorName: {
        color: (props) =>
          props.isInBroadcast
            ? theme.palette.white
            : theme.palette.text.primary,
      },
      actionContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
      },
      actionCard: {
        background: "#fff6f6",
        borderRadius: "10px",
        padding: "10px",
        position: "relative",
        overflow: "visible",
        marginTop: "2rem",
        marginBottom: "1rem",
        paddingTop: "1.6rem",
        boxShadow: "none",
        fontSize: "0.8rem",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
      },
      actionLogo: {
        position: "absolute",
        width: "50px",
        top: "9px",
        left: "50%",
        transform: "translateX(-50%)",
      },
      emoji: {
        fontSize: "30px",
      },
      fileContainer: {
        marginBottom: "10px",
        maxWidth: "250px",
      },
      imageMessageContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",

        "&:hover button": {
          opacity: 0.8,
        },
      },
      imageMessage: {
        maxWidth: "100%",
        height: "8rem",
        minWidth: "6rem",
        border: "1px solid #999",
        borderRadius: "5px",
        cursor: "pointer",
        objectFit: "contain",
      },
      fileMessage: {
        display: "block",
        textDecoration: "none",
        color: (props) =>
          props.isInBroadcast
            ? theme.palette.white
            : theme.palette.text.primary,
        border: "1px solid #ddd",
        padding: "0.5rem",
        borderRadius: "5px",
      },
      fileName: {
        display: "block",
        fontWeight: 700,
        lineHeight: "1rem",
        maxWidth: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
      },
      fileSize: {
        fontSize: "0.7rem",
        color: "#666",
        fontStyle: "normal",
        textTransform: "capitalize",
      },
      downloadButton: {
        opacity: 0,
        position: "absolute",
        top: "1rem",
        left: "0px",
        textAlign: "right",
        paddingRight: "1rem",
        boxSizing: "border-box",
        display: "block",
      },
      downloadIcon: {
        fill: "#fff",
      },
      audioMessage: {
        maxWidth: "100%",
      },
      textInput: {
        paddingLeft: "10px",
        width: "290px",
        resize: "none",
        border: "none",
        outline: "none",
        boxSizing: "border-box",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: 1.33,
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        maxHeight: "200px",
        overflow: "scroll",
        bottom: 0,
        overflowX: "hidden",
        overflowY: "auto",
        "& > div": {
          fontSize: "15px",
          lineHeight: "20px",
        },
        "& > textarea": {
          minHeight: "20px",
          minWidth: "120px",
          lineHeight: "20px",
          "&:disabled": {
            color: "#000",
          },
          "&:empty:before": {
            content: "attr(placeholder)",
            display: "block",
            /* For Firefox */
            color: "rgba(86, 88, 103, 0.3)",
            outline: "none",
          },
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
      chatInputContainer: {
        position: "relative",
        bottom: 0,
        display: "flex",
        alignItems: "center",
        border: ({ isInBroadcast }) => {
          const inputBorderColor = isInBroadcast
            ? "none"
            : theme.palette.gray.medium;

          return `1px solid ${inputBorderColor}`;
        },
        backgroundColor: inputBackgroundColor,
        margin: theme.spacing(0.25),
        padding: theme.spacing(0.25),
      },
      backButton: {
        color: "#fff",
        position: "fixed",
        zIndex: 3,
      },
    };
  },
);
