import { createSelector } from "@reduxjs/toolkit";
import {
  compact,
  difference,
  includes,
  intersection,
  memoize,
  sortBy,
} from "lodash";
import { RootState } from "store";
import {
  selectLiveUsers,
  selectPresences,
} from "modules/eventUserPresence/redux/selectors";
import { selectBroadcasters } from "modules/broadcaster";
import * as Immutable from "immutable";
import {
  mapUsers,
  nameSortFunc,
} from "modules/participants/sectionContainerHelpers";
import { IUser } from "modules/app/types";
import { IUsersInEventState } from "./reducer";
import { selectCurrentEvent } from "../selectors";
import { selectUser, selectUserId } from "../../auth/redux/selectors";

const selectUsersInEvent = (state: RootState) => state.usersInEvent;

const makeSelectUsersInEvent = createSelector(
  selectUsersInEvent,
  (usersInEvent: IUsersInEventState) => usersInEvent.users,
);

const selectUserIdsInEvent = createSelector(makeSelectUsersInEvent, (users) =>
  users.isEmpty() ? [] : users.keySeq().toArray(),
);

const selectManagers = createSelector(
  selectCurrentEvent,
  (currentEvent) => compact(currentEvent?.managers) as string[],
);

const selectManagersInEvent = createSelector(
  selectManagers,
  selectUserIdsInEvent,
  selectPresences,
  (eventManagerIds, userIdsInEvent, { liveUsers }) =>
    intersection(eventManagerIds, userIdsInEvent, liveUsers),
);

const selectSpeakers = createSelector(
  selectCurrentEvent,
  (currentEvent) => compact(currentEvent?.speakers) as string[],
);

const selectSpeakersInEvent = createSelector(
  selectSpeakers,
  selectManagers,
  selectUserIdsInEvent,
  selectPresences,
  // eslint-disable-next-line max-params
  (eventSpeakerIds, eventManagerIds, userIdsInEvent, { liveUsers }) =>
    intersection(
      difference(eventSpeakerIds, eventManagerIds),
      userIdsInEvent,
      liveUsers,
    ),
);

const selectBroadcastersInEvent = createSelector(
  selectBroadcasters,
  selectManagers,
  selectSpeakers,
  selectUserIdsInEvent,
  selectPresences,
  (
    broadcasters,
    eventSpeakerIds,
    eventManagerIds,
    userIdsInEvent,
    { liveUsers },
  ) =>
    intersection(
      difference(compact(Object.keys(broadcasters)) as string[], [
        ...eventManagerIds,
        ...eventSpeakerIds,
      ]),
      userIdsInEvent,
      liveUsers,
    ),
);

const selectLoginUserInEvent = createSelector(
  selectUsersInEvent,
  selectUser,
  (usersInEvent, loginUser: IUser | undefined) => {
    if (!loginUser) {
      return null;
    }

    return usersInEvent?.users ? usersInEvent.users.get(loginUser.id) : null;
  },
);

const selectLiveUserInEvent = createSelector(
  selectUsersInEvent,
  selectLiveUsers,
  (usersInEvent, userIds: string[]) => {
    const result = new Map<string, IUser>();

    if (usersInEvent.users && userIds && userIds.length > 0) {
      userIds.forEach((userId: string) => {
        const user = usersInEvent.users.get(userId);

        if (user) {
          result.set(userId, user);
        }
      });
    }

    return Immutable.Map<string, IUser>(result);
  },
);

const selectIsManager = createSelector(
  selectManagers,
  selectUserId,
  (eventManagers, loginUserId) => includes(eventManagers, loginUserId),
);

const selectExcludedFromAttendees = createSelector(
  selectSpeakers,
  selectManagers,
  selectBroadcastersInEvent,
  (eventSpeakers, eventManagers, broadcasterIds) =>
    compact([...eventSpeakers, ...eventManagers, ...broadcasterIds]),
);

const makeSelectUsersInEventIsLoaded = () =>
  createSelector(
    selectUsersInEvent,
    (substate: IUsersInEventState) => substate.isLoaded,
  );

const makeSelectEventIdOfUsersInEvent = () =>
  createSelector(
    selectUsersInEvent,
    (substate: IUsersInEventState) => substate.eventId,
  );

const makeSelectLoginUserInEvent = () =>
  createSelector(
    selectUsersInEvent,
    selectUser,
    (substate, loginUser: IUser | undefined) => {
      if (!loginUser) {
        return undefined;
      }

      return substate.users
        ? substate.users.get(loginUser.id, undefined)
        : undefined;
    },
  );
const makeSelectUserById = (userId?: string) =>
  createSelector(selectUsersInEvent, (substate) =>
    substate.users && userId
      ? substate.users.get(userId, undefined)
      : undefined,
  );

const makeSelectUsersById = (userIds: string[]) =>
  createSelector(
    selectUsersInEvent,
    memoize((substate: IUsersInEventState) => {
      const result = new Map<string, IUser>();

      if (substate.users && userIds && userIds.length > 0) {
        userIds.forEach((userId) => {
          const user = substate.users.get(userId);

          if (user) {
            result.set(userId, user);
          }
        });
      }

      return Immutable.Map<string, IUser>(result);
    }),
  );

const selectAttendeeIds = createSelector(
  selectUserIdsInEvent,
  selectPresences,
  selectExcludedFromAttendees,
  (userIdsInEvent, { liveUsers }, excludedFromAttendees) =>
    difference<string>(
      intersection(userIdsInEvent, liveUsers),
      excludedFromAttendees,
    ),
);

/**
 * Selectors for BraodcastUserSectionContainers
 */
const selectMappedBroadcastAttendees = createSelector(
  [selectIsManager, selectUserId, selectAttendeeIds, selectLiveUserInEvent],
  // eslint-disable-next-line max-params
  (isManager, loginUserId, attendeeIds, onlineUsersMap) =>
    mapUsers(isManager, loginUserId, attendeeIds, onlineUsersMap),
);

const selectMappedBroadcastableAttendees = createSelector(
  [
    selectIsManager,
    selectUserId,
    selectBroadcastersInEvent,
    selectLiveUserInEvent,
  ],
  // eslint-disable-next-line max-params
  (isManager, loginUserId, broadcasterIds, onlineUsersMap) => {
    const broadcastableAttendees = mapUsers(
      isManager,
      loginUserId,
      broadcasterIds,
      onlineUsersMap,
      { isAllowBroadcast: true },
    );

    return sortBy(broadcastableAttendees, nameSortFunc);
  },
);

const selectMappedBroadcastManagers = createSelector(
  [selectIsManager, selectUserId, selectManagersInEvent, selectLiveUserInEvent],
  // eslint-disable-next-line max-params
  (isManager, loginUserId, managerIds, onlineUsersMap) => {
    const managers = mapUsers(
      isManager,
      loginUserId,
      managerIds,
      onlineUsersMap,
    );

    return sortBy(managers, nameSortFunc);
  },
);

const selectMappedBroadcastSpeakers = createSelector(
  [selectIsManager, selectUserId, selectSpeakersInEvent, selectLiveUserInEvent],
  (
    isManager: boolean,
    loginUserId: string | null,
    speakerIds: string[],
    onlineUsersMap,
    // eslint-disable-next-line max-params
  ) => {
    const speakers = mapUsers(
      isManager,
      loginUserId,
      speakerIds,
      onlineUsersMap,
      { isAllowBroadcast: true, speaker: true },
    );

    return sortBy(speakers, nameSortFunc);
  },
);

export {
  selectUsersInEvent,
  selectManagers,
  selectSpeakers,
  makeSelectUsersInEvent,
  selectManagersInEvent,
  selectLiveUserInEvent,
  selectSpeakersInEvent,
  selectBroadcastersInEvent,
  selectUserIdsInEvent,
  selectIsManager,
  selectLoginUserInEvent,
  selectExcludedFromAttendees,
  selectAttendeeIds,
  makeSelectLoginUserInEvent,
  makeSelectUserById,
  makeSelectUsersById,
  makeSelectUsersInEventIsLoaded,
  makeSelectEventIdOfUsersInEvent,
  selectMappedBroadcastAttendees,
  selectMappedBroadcastableAttendees,
  selectMappedBroadcastManagers,
  selectMappedBroadcastSpeakers,
};
