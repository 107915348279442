import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 900,
    color: theme.palette.blue.dark,
    background: "#EAEEF2",
    borderRadius: "100px",
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
    alignSelf: "flex-start",
  },
}));
