import { createTheme } from "@material-ui/core/styles";
import { lightPalette } from "./palettes";
import { overrides } from "./overrides";
import { baseTheme } from "./baseTheme";

export const lightTheme = createTheme({
  ...baseTheme,
  palette: lightPalette,
  overrides,
});
