import { database, serverValue } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";

interface IShareVideo {
  videoURL?: string;
  startTime: number | typeof serverValue.TIMESTAMP;
  userId: string;
  isVideoControlsEnabled: boolean;
}

const getBroadcastVideoURLDocRef = (theaterId: string) =>
  database.ref(`${THEATER_PATH}/${theaterId}/Broadcast/ShareVideo`);

const updateBroadcastVideoURL = (theaterId: string, shareVideo: IShareVideo) =>
  getBroadcastVideoURLDocRef(theaterId).set(shareVideo);

const updateIsVideoControlsEnabled = (
  theaterId: string,
  shareVideo: IShareVideo,
  // eslint-disable-next-line consistent-return
) => {
  if (shareVideo?.videoURL) {
    return getBroadcastVideoURLDocRef(theaterId).set(shareVideo);
  }
};

export default {
  updateBroadcastVideoURL,
  updateIsVideoControlsEnabled,
  getBroadcastVideoURLDocRef,
};
