import logger from "logging/logger";
import { FirebaseDBDataSnapshot, FirebaseDBReference } from "types/firebase";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const asyncResult = (ref: FirebaseDBReference): Promise<any> =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new Promise<any>((resolve, reject) =>
    // eslint-disable-next-line no-promise-executor-return
    ref.once(
      "value",
      (result: FirebaseDBDataSnapshot) => resolve(result.val()),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error: any) => {
        logger.error(error);
        reject(error);
      },
    ),
  );
