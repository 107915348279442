import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "../../../../zIndexes.module.scss";

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: zIndexes.broadcastDialogZIndex,
    background: theme.palette.white,
    borderColor: theme.palette.gray.muted2,
    boxShadow: "-2px 2px 10px rgba(0, 0, 0, 0.5)",
    maxWidth: 375,
    borderRadius: 10,
    [theme.breakpoints.up("md")]: {
      transform: "translateY(-50%)",
      left: "auto !important",
      right: 10,
      position: "absolute !important",
    },
  },

  closeButton: {
    float: "right",
  },
  content: {
    marginTop: 30,
  },
  title: {
    margin: "0 10px",
  },
  subtitle: {
    margin: "17px 20px 20px",
  },
  animationContainer: {
    marginTop: 14,
    [theme.breakpoints.up("xs")]: {
      margin: "0 16px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "60px 37px 0",
    },
  },
  buttonContainer: {
    margin: "0 20px 30px 20px",
  },
}));

export default useStyles;
