import { createAsyncThunk } from "@reduxjs/toolkit";
import { Errors, trackError } from "modules/monitoring";
import { fetchDeployedVersion } from "./services";

const fetchDeployedVersionAction = createAsyncThunk(
  "appVersion/fetchDeployedVersion",
  async () => {
    try {
      const deployedVersion = await fetchDeployedVersion();
      return deployedVersion;
    } catch (error) {
      trackError(error, {
        label: Errors.GET_DEPLOYED_VERSION,
      });

      throw error;
    }
  },
);

export { fetchDeployedVersionAction as fetchDeployedVersion };
