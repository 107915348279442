import { useMutation } from "@tanstack/react-query";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Errors, trackError } from "modules/monitoring";
import { useContext } from "react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { AssignTableErrors } from "../../constants";
import request from "./request";

const useInviteAllAssignedAttendees = (eventId?: string, presetId?: string) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { t } = useI18n(["manageEvent"]);

  const { mutate, status } = useMutation(
    async () => {
      if (!eventId) {
        throw new Error(AssignTableErrors.NO_EVENT_ID);
      }

      return request(eventId, presetId);
    },
    {
      onSuccess: () => {
        addSuccessNotification({
          message: t("manageEvent:invite.attendees.success"),
          position: "tc",
        });

        track(Events.ASSIGN_TABLE_INVITE_ALL);
      },
      onError: (error: Error, _) => {
        trackError(error, {
          label: Errors.ASSIGN_TABLES_ALL_ATTENDEE_INVITE,
          eventId,
        });
        addErrorNotification({
          message: t("manageEvent:invite.attendees.error"),
          position: "tc",
        });
      },
    },
  );

  return { inviteAllAttendees: mutate, status };
};

export default useInviteAllAssignedAttendees;
