import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Chip } from "@remo-co/ui-core/src/components/Chip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useI18n } from "i18n";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { useStyles } from "./styles";

type Props = {
  open: boolean;
  onUpdate: () => void;
  onCancel: () => void;
  children: React.ReactElement;
  placement?: "top-start" | "bottom";
};

const VersionPrompt = ({
  open,
  onUpdate,
  onCancel,
  children,
  placement,
}: Props) => {
  const styles = useStyles();
  const { t } = useI18n("common");

  return (
    <Tooltip
      arrow
      interactive
      open={open}
      placement={placement}
      classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
      title={
        <>
          <Box
            data-testid="new-version-prompt"
            mb={1.25}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Chip size="small" label={t("new").toUpperCase()} color="salmon" />
            <Button
              isIconButton
              variant="ghost"
              color="dark"
              data-testid="close-button"
              size="sm"
              onClick={onCancel}
            >
              <CloseIcon />
            </Button>
          </Box>

          <Box mb={1.25}>
            <Typography align="center" color="textPrimary">
              {t("version.description")}
            </Typography>
          </Box>

          <Button
            variant="primary"
            color="blue"
            size="sm"
            fullWidth
            onClick={onUpdate}
            data-testid="update-button"
          >
            {t("version.cta")}
          </Button>
        </>
      }
    >
      {children}
    </Tooltip>
  );
};

export default VersionPrompt;
