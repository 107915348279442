import { ChangeEvent } from "react";
import { AppBar } from "@remo-co/ui-core/src/components/AppBar";
import { Tabs } from "@remo-co/ui-core/src/components/Tabs";
import { Tab } from "@remo-co/ui-core/src/components/Tab";
import { TabPanel } from "@remo-co/ui-core/src/components/TabPanel";
import { useI18n } from "i18n";
import {
  EventPollStatus,
  EventPoll,
  EventPollType,
  EventPollOption,
  AnswerPollMutation,
} from "graphql/generated";
import { selectIsEventManager } from "modules/event/selectors";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { setPollsTabId } from "modules/rightPanel/redux/slice";
import { selectCurrentPollTab } from "modules/rightPanel/redux/selectors";
import { PollsAndQuizzes } from "../PollsAndQuizzesWrapper/PollsAndQuizzes";
import { useStyles } from "./styles";
import { CreatePoll } from "../CreatePoll/CreatePoll";
import { NoActivePolls } from "../NoActivePolls";

enum EditState {
  Edit = "EDIT",
}

export const PollTabContainer = ({
  pollsAndQuizzes,
  onSubmit,
  eventId,
}: {
  pollsAndQuizzes: Record<EventPollStatus, Record<EventPollType, EventPoll[]>>;
  eventId: string;
  onSubmit: (
    option: EventPollOption,
    pollId: number,
  ) => Promise<AnswerPollMutation | null | undefined>;
}) => {
  const styles = useStyles();
  const { t } = useI18n(["polls"]);
  const isHost = useSelector(selectIsEventManager);
  const dispatch = useAppDispatch();

  const value = useSelector(selectCurrentPollTab) || EventPollStatus.Ongoing;

  const handleChange = (
    _event: ChangeEvent<unknown>,
    newValue: EventPollStatus,
  ) => {
    dispatch(setPollsTabId(newValue));
  };
  const numberOfOngoingPolls =
    pollsAndQuizzes.ONGOING.POLL.length + pollsAndQuizzes.ONGOING.QUIZ.length;

  return (
    <>
      <AppBar position="static" color="transparent">
        <Tabs
          value={value}
          variant="fullWidth"
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab
            className={styles.tab}
            value={EventPollStatus.Ongoing}
            label={t("polls:ongoing")}
          />
          <Tab
            className={styles.tab}
            value={EventPollStatus.Closed}
            label={t("polls:closed")}
          />
          {isHost && (
            <Tab
              data-testid="draft-polls"
              className={styles.tab}
              value={EventPollStatus.Draft}
              label={t("polls:draft")}
            />
          )}
          {isHost && (
            <Tab
              data-testid="add-poll"
              className={styles.tab}
              value={EditState.Edit}
              label={<Add />}
            />
          )}
        </Tabs>
      </AppBar>
      <TabPanel
        className={styles.tabContainer}
        value={value}
        index={EventPollStatus.Ongoing}
      >
        {numberOfOngoingPolls > 0 ? (
          <PollsAndQuizzes
            isHost={isHost}
            onSubmit={onSubmit}
            quizzes={pollsAndQuizzes.ONGOING.QUIZ}
            polls={pollsAndQuizzes.ONGOING.POLL}
          />
        ) : (
          <NoActivePolls
            isHost={isHost}
            onClick={() => {
              dispatch(setPollsTabId(EditState.Edit));
            }}
          />
        )}
      </TabPanel>
      <TabPanel
        value={value}
        index={EventPollStatus.Closed}
        className={styles.tabContainer}
      >
        <PollsAndQuizzes
          isHost={isHost}
          onSubmit={onSubmit}
          quizzes={pollsAndQuizzes.CLOSED.QUIZ}
          polls={pollsAndQuizzes.CLOSED.POLL}
        />
      </TabPanel>
      <TabPanel
        value={value}
        index={EventPollStatus.Draft}
        className={styles.tabContainer}
      >
        <PollsAndQuizzes
          isHost={isHost}
          onSubmit={onSubmit}
          quizzes={pollsAndQuizzes.DRAFT.QUIZ}
          polls={pollsAndQuizzes.DRAFT.POLL}
        />
      </TabPanel>
      <TabPanel
        value={value}
        index={EditState.Edit}
        className={styles.tabContainer}
      >
        <CreatePoll
          onCreatePoll={(poll) => {
            dispatch(setPollsTabId(poll.status));
          }}
          eventId={eventId}
        />
      </TabPanel>
    </>
  );
};
