export enum OS_NAMES {
  ANDROID = "Android",
  IOS = "iOS",
  MAC = "Mac OS",
  UNKNOWN = "Unknown",
  WINDOWS = "Windows",
}

export enum BROWSER_NAMES {
  CHROME = "Google Chrome",
  EDGE = "Microsoft Edge",
  FIREFOX = "Firefox",
  MOBILE_SAFARI = "Mobile Safari",
  SAFARI = "Safari",
  UNKNOWN = "Unknown",
}

export enum SUPPORT_STATUS {
  SUPPORTED,
  PARTIALLY_SUPPORTED,
  UNSUPPORTED,
}

export enum UNSUPPORTED_FEATURES {
  SCREEN_SHARING = 1,
}

export const MIN_DOWNLOAD_SPEED_MBPS = 2;
