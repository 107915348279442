import { StyleRulesCallback, Theme } from "@material-ui/core/styles";
import {
  CSSProperties,
  CreateCSSProperties,
} from "@material-ui/core/styles/withStyles";

export type XL = "xl";

export type LG = "lg";

export type MD = "md";

export type SM = "sm";

export type XS = "xs";

export type SpacingType = XS | SM | MD | LG | XL;

export type SizeType = XS | SM | MD | LG | XL;

export enum AnimationDirection {
  NORMAL = 1,
  REVERSE = -1,
}

export type LogoVariants =
  | "beta"
  | "powered-by-conversation"
  | "powered-by-presentation"
  | "powered-by-sidebar"
  | "presentation"
  | "large-logo-white";

export type { Theme, StyleRulesCallback, CSSProperties, CreateCSSProperties };
