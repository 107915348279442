import { useContext } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { FormHelperText } from "@remo-co/ui-core/src/components/FormHelperText";
import { Select } from "@remo-co/ui-core/src/components/Select";
import { useI18n } from "i18n";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { EVENT_OUTCOMES } from "modules/eventForm/constants";

const EventOutcomeSelect = (): JSX.Element | null => {
  const { t } = useI18n(["common", "eventForm"]);
  const { state, updateEventData } = useContext(MANAGE_EVENT_CONTEXT);
  const eventData = state?.eventData ?? undefined;

  const handleSetEventOutcome = (eventOutcome: string) => {
    updateEventData({ eventOutcome });
  };

  return (
    <Container bottom="sm" data-testid="event-outcome-select">
      <Select
        size="sm"
        fullWidth
        id="event-outcome"
        label={t("eventForm:event.outcome.label")}
        name="eventOutcome"
        onChange={handleSetEventOutcome}
        options={EVENT_OUTCOMES.map(({ label, value }) => ({
          value,
          label: t(label),
        }))}
        placeholder={t("eventForm:event.outcome.placeholder")}
        value={eventData?.eventOutcome || ""}
      />
      <FormHelperText>
        {t("eventForm:event.outcome.description")}
      </FormHelperText>
    </Container>
  );
};

export default EventOutcomeSelect;
