import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    padding: "10px 15px",
  },
  closeButton: {
    minWidth: 34,
    height: 34,
    backgroundColor: theme.palette.gray.light,
    margin: 5,
  },
  saveChangesButton: {
    marginRight: 10,
  },
  eventName: {
    fontWeight: "bolder",
    marginLeft: 3,
  },
  buttonsContainer: {
    width: "30%",
  },
  presetTitle: {
    marginRight: theme.spacing(1),
  },
  presetSelect: {
    width: "120px",
  },
}));

export default useStyles;
