import { ThemeOptions } from "@material-ui/core/styles/createTheme";
import { typography } from "./typography";
import AsideBGMask from "../components/Provider/assets/aside-background-maskV2.png";
import PartyPopper from "../components/Provider/assets/party-popper.png";

export const baseTheme: Pick<
  ThemeOptions,
  "props" | "breakpoints" | "typography" | "assets" | "constants"
> = {
  props: {
    MuiModal: {
      disableEnforceFocus: true,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 720,
      lg: 960,
      xl: 1140,
    },
  },
  typography,
  constants: {
    headerHeight: 64,
    maxContentWidth: 1140,
  },
  assets: {
    founder: PartyPopper,
    asideBGMask: AsideBGMask,
  },
};
