import { useEffect } from "react";
import useChatFirestore from "modules/chat/firestore/useChatFirestore";
import { selectUser } from "modules/auth/redux/selectors";
import { useSelector } from "react-redux";
import {
  MessageList,
  ChatMessagesHeaderContainer,
  ChatInputContainer,
} from "../../components";
import { makeSelectActiveChannel } from "../../redux/selectors";

const ChatMessagesView = () => {
  const { updateLastAccessTimeForChannel } = useChatFirestore();
  const user = useSelector(selectUser);
  const activeChannel = useSelector(makeSelectActiveChannel());

  useEffect(() => {
    if (!user) {
      return;
    }

    updateLastAccessTimeForChannel(user.id, activeChannel);

    // returned function will be called on component unmount
    // eslint-disable-next-line consistent-return
    return () => {
      updateLastAccessTimeForChannel(user.id, activeChannel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ChatMessagesHeaderContainer />
      <MessageList />
      <ChatInputContainer />
    </>
  );
};

export default ChatMessagesView;
