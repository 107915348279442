import React from "react";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import Typography from "@material-ui/core/Typography/Typography";
import { Container, IContainerProps } from "../Container";
import { styles } from "./styles";

export interface ICardProps extends Omit<IContainerProps, "title" | "rounded"> {
  htmlTitle?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  elevation?: number;
  innerClassName?: string;
  variant?: "primary";
  action?: React.ReactNode;
  border?: boolean;
}

const useStyles = makeStyles<Theme, ICardProps>(styles);

const Card = (props: ICardProps) => {
  const {
    startAdornment,
    action,
    title,
    subtitle,
    variant = "primary",
    size = "md",
    children,
    flex,
    column,
    justifyContent,
    alignItems,
    elevation = 1,
    innerClassName = "",
    className,
    htmlTitle: _htmlTitle,
    ...rest
  } = props;

  const classes = useStyles(props);

  const rootClassName = classNames(
    classes.root,
    classes[variant],
    elevation > 0 && classes[`elevation${elevation}`],
    className,
  );

  return (
    <Container
      {...rest}
      rounded={variant === "primary" ? size : undefined}
      size={size}
      className={rootClassName}
    >
      {action && <div className={classes.action}> {action} </div>}
      {startAdornment && <Container bottom="sm">{startAdornment}</Container>}
      {typeof title === "string" ? (
        <Typography variant="h3">{title}</Typography>
      ) : (
        title
      )}
      {subtitle && (
        <Container top={title ? "sm" : undefined}>
          {typeof subtitle === "string" ? (
            <Typography variant="body2">{subtitle}</Typography>
          ) : (
            subtitle
          )}
        </Container>
      )}
      <Container
        top={title || subtitle ? "md" : undefined}
        flex={flex}
        column={column}
        justifyContent={justifyContent}
        alignItems={alignItems}
        className={innerClassName}
      >
        {children}
      </Container>
    </Container>
  );
};

export default Card;
