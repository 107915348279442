import { useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import {
  selectManagers,
  selectSpeakers,
} from "modules/event/usersInEvent/selectors";
import { selectUserId } from "modules/auth/redux/selectors";
import { selectBroadcasters } from "modules/broadcaster";
import logger from "logging/logger";
import { PRESENTERS_CACHE_KEY } from "./constants";
import { addPresenter, clearPresenters } from "./dal";

export const useActivePresenterActions = () => {
  const theaterId = useSelector(selectCurrentTheaterId);
  const speakerIds = useSelector(selectSpeakers);
  const managerIds = useSelector(selectManagers);
  const stagedAttendeeIds = useSelector(selectBroadcasters);
  const userId = useSelector(selectUserId);
  const queryClient = useQueryClient();

  const allPresenterIds = useMemo(
    () =>
      new Set([
        ...speakerIds,
        ...managerIds,
        ...Object.keys(stagedAttendeeIds),
      ]),
    [speakerIds, managerIds, stagedAttendeeIds],
  );

  const { mutateAsync: addActivePresenter } = useMutation(
    [PRESENTERS_CACHE_KEY, theaterId],
    async () => {
      if (!theaterId || !userId) {
        throw new Error("missing data");
      }

      if (!allPresenterIds.has(userId)) {
        throw new Error("insufficient permissions");
      }

      await addPresenter(theaterId, userId);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([PRESENTERS_CACHE_KEY, theaterId]);
      },
      onError: (error) => {
        const errorMessage = error instanceof Error ? error.message : "unknown";
        logger.error(`[addActivePresenter] ${errorMessage}`);
      },
    },
  );

  const { mutateAsync: clearActivePresenters } = useMutation(
    [PRESENTERS_CACHE_KEY, theaterId],
    async () => {
      if (!theaterId || !userId) {
        throw new Error("missing data");
      }

      if (!allPresenterIds.has(userId)) {
        throw new Error("insufficient permissions");
      }

      await clearPresenters(theaterId);
    },
  );

  return {
    addActivePresenter,
    clearActivePresenters,
  };
};
