import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector } from "react-redux";
import { selectAuth } from "modules/auth/redux/selectors";

const useTrackCompanyRole = (isDatadogInit: boolean): void => {
  const { role: companyRole } = useSelector(selectAuth);

  useEffect(() => {
    if (!isDatadogInit) {
      return;
    }

    if (companyRole) {
      datadogRum.setGlobalContextProperty("companyRole", companyRole);
    }
  }, [companyRole, isDatadogInit]);
};

export default useTrackCompanyRole;
