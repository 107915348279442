import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: `0 ${theme.spacing(0.5)}px`,
    display: "flex",
    width: "100%",
    height: "inherit",
    justifyContent: "center",
    alignItems: "flex-start",
  },
}));
