import store, { RootState } from "store";
import { database } from "./firebaseConfig";
import {
  IUserPreferences,
  LAST_LOGIN,
} from "../../modules/userPreferences/redux/types";
import { addUserPreferencesInSpace } from "../../modules/userPreferences/redux/actions";
import logger from "../../logging/logger";
import { getCurrentEventId, getCurrentUserId } from "../../helpers/reduxHelper";

export const ROOM_ID = "roomId";

interface IError {
  code: string;
  message: string;
}
const USER_PREFERENCES_PATH = "UserPreferences";

let RETRIES = 0;

/**
 * Get current user preferences
 */
export const getUserPreferences = (userId: string, currentEventId?: string) => {
  const eventId = currentEventId || getCurrentEventId();

  if (!eventId) {
    return;
  }

  const dbRef = database.ref(
    `Event/${eventId}/${USER_PREFERENCES_PATH}/${userId}`,
  );

  dbRef.off();
  dbRef.on(
    "value",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (userPreferences: any) => {
      RETRIES = 0;
      const userPrefs: IUserPreferences = userPreferences.val();

      if (!userPrefs) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      checkUserLoginTime(userPrefs[LAST_LOGIN]);
      store.dispatch(addUserPreferencesInSpace(userId, userPrefs));
    },
    (error: IError) => {
      // When current user logs-in in another browser/tab, for some time, this tab user will lose permission
      // So we will try to get data for 2 tries and if it is success, we can redirect user to login page
      logger.error(
        `[UserPreferences][getUserPreferences]Error while getting user preferences: Code: ${error.code} message: ${error.message}`,
      );

      if (RETRIES <= 2 && error.code === "PERMISSION_DENIED") {
        RETRIES += 1;
        setTimeout(() => {
          getUserPreferences(userId);
        }, 1000);
      }
    },
  );
};

/**
 * Update preference for current user in firebase
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateEventUserPreference = async (data: any) => {
  // Get currently logged in user
  const loggedinUserId = getCurrentUserId();

  if (loggedinUserId) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    await updateEventPreferencesByUserId(loggedinUserId, data);
  }
};

export const updateEventPreferencesByUserId = async (
  userId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
) => {
  const eventId = getCurrentEventId();

  if (eventId) {
    const dbRef = database.ref(
      `Event/${eventId}/${USER_PREFERENCES_PATH}/${userId}`,
    );

    await dbRef.update(data);
  }
};

/**
 * Checks whether the currently logged in user time is greater than firebase login time
 * If user has opened another session, the logout older sessions
 */

function checkUserLoginTime(loginTimePref: number) {
  const state: RootState = store.getState();
  const loginUserState = state.auth;

  if (
    loginUserState &&
    loginUserState.signInTime &&
    loginUserState.signInTime
  ) {
    const lastLogin = loginUserState.signInTime;

    if (loginTimePref && +lastLogin < loginTimePref) {
      // doSoftLogout();
    }
  }
}
