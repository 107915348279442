import { useEffect, useState } from "react";
import throttle from "modules/throttle";
import firebase from "firebase/compat/app";
import { RaisedHandUser } from "../redux/broadcastRaiseHandSlice";
import { getRaisedHandUserDocRef } from "../raiseHand.firebase";

export const useUserRaisedHand = (
  theaterId: string | null,
  userId: string | null,
): boolean => {
  const [isUsersHandRaised, setIsUsersHandRaised] = useState(false);

  useEffect(() => {
    let docRef: firebase.database.Reference;

    if (theaterId && userId) {
      docRef = getRaisedHandUserDocRef(theaterId, userId);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      subscribe(docRef);
    }

    return () => {
      if (docRef) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        unsubscribe(docRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId, userId]);

  const throttledUpdate = throttle((isHandRaised?: RaisedHandUser) => {
    setIsUsersHandRaised(isHandRaised ? isHandRaised.raised : false);
  }, 500);

  const subscribe = (docRef: firebase.database.Reference) => {
    if (docRef) {
      docRef.off();
      docRef.on("value", (snap: firebase.database.DataSnapshot) => {
        const raisedHandUsers = snap.val();

        throttledUpdate(raisedHandUsers);
      });
    }
  };

  const unsubscribe = (docRef: firebase.database.Reference) => {
    docRef.off();
  };

  return isUsersHandRaised;
};
