import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { TOTAL_ARROW_BUTTON_HEIGHT } from "../hooks/constants";

interface Props {
  hasPagination: boolean;
  isMobile: boolean;
  showViewButton?: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    pointerEvents: "all",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 90px)",
    },
  },
  grid: ({ hasPagination, showViewButton }) => ({
    width: "calc(100% - 2px)",
    height:
      hasPagination || showViewButton
        ? `calc(100% - ${TOTAL_ARROW_BUTTON_HEIGHT}px)`
        : "100%",
    overflow: "hidden",
  }),
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "all",
    padding: 2,
    width: "calc(100% - 2px)",
    height: "calc(100% - 2px)",
  },
  banner: {
    display: "flex",
    justifyContent: "center",
  },
  viewButtonRoot: ({ hasPagination }) => ({
    display: "flex",
    backgroundColor: theme.palette.white,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.secondary.main}`,
    height: "40px",
    // eslint-disable-next-line no-nested-ternary
    width: hasPagination ? "180px" : "86px",
  }),
  button: ({ isMobile, hasPagination }) => ({
    paddingBottom: isMobile ? theme.spacing(1) : 0,
    paddingTop: theme.spacing(1),
    maxWidth: hasPagination ? "180px" : "100px",
    height: TOTAL_ARROW_BUTTON_HEIGHT,
  }),
}));
