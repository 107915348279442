import { ReactElement } from "react";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Toolbar } from "@remo-co/ui-core/src/components/Toolbar";
import { Container } from "@remo-co/ui-core/src/components/Container";
import {
  CameraButton,
  MicrophoneButton,
  ChatButton,
  PollsButton,
  LeaveEventButton,
  PresentButton,
  WhiteboardButton,
  ParticipantsButton,
  LobbyButton,
  ScreenSharingButton,
} from "modules/eventButtons";
import { useSelector } from "react-redux";
import {
  selectIsChatEnabled,
  selectIsPollsEnabled,
  selectIsScreenShareEnabled,
} from "modules/event/selectors";
import { ReactionButton } from "modules/eventButtons/components/ReactionButton";
import { useSmallDesktop } from "modules/common/hooks/useSmallDesktop";
import { DevicePromptPopup } from "modules/audioVideo/components";
import { useScreenShare } from "@daily-co/daily-react";
import { ManagerMenu } from "../ManagerMenu";
import { TryCameraAndMicPopover } from "./TryCameraAndMicPopover";
import { useStyles } from "./styles";

interface Props {
  isEventManager: boolean;
}

export const RemoDesktopToolbar = ({ isEventManager }: Props): ReactElement => {
  const styles = useStyles();
  const isChatEnabled = useSelector(selectIsChatEnabled);
  const isPollsEnabled = useSelector(selectIsPollsEnabled);
  const isScreenShareEnabled = useSelector(selectIsScreenShareEnabled);
  const { isSharingScreen } = useScreenShare();
  const isSmallDesktop = useSmallDesktop();
  const showScreenShareButton =
    isSharingScreen || isScreenShareEnabled || isEventManager;

  return (
    <div className={styles.container} data-testid="remo-desktop-toolbar">
      <Toolbar disableGutters>
        {isEventManager && (
          <>
            <span id="present-mode">
              <PresentButton />
            </span>
            <Divider className={styles.divider} orientation="vertical" />
          </>
        )}

        <Container id="mic-cam-buttons" flex fullWidth={false}>
          <MicrophoneButton />
          <CameraButton animate />
        </Container>
        <DevicePromptPopup />
        <TryCameraAndMicPopover />
        {isChatEnabled && <ChatButton />}
        {isPollsEnabled && <PollsButton />}
        <ReactionButton />
        <Divider className={styles.divider} orientation="vertical" />
        {showScreenShareButton && <ScreenSharingButton />}
        <ParticipantsButton />
        {isEventManager && !isSmallDesktop && <LobbyButton />}
        {!isEventManager && (
          <>
            <WhiteboardButton />
            <LeaveEventButton />
          </>
        )}
        {isEventManager && <ManagerMenu showLobbyButton={isSmallDesktop} />}
      </Toolbar>
    </div>
  );
};
