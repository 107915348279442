import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import { statusVariant } from "./StatusIcon";

export const useStyles = makeStyles<Theme, { variant: statusVariant }>(
  (theme) => ({
    status: ({ variant }) => ({
      color: theme.palette.status[variant],
      marginRight: "10px",
    }),
  }),
);
