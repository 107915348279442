import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Draft } from "immer";
import { ICompany, ICompanyServicePlan } from "modules/company/types";
import { ITheater } from "types/theater";
import actionTypes from "./constants";
import { IEvent } from "./types";

export type EventState = {
  events: Record<string, IEvent>;
  currentEventId: string | null;
  pastMyEventIds: string[];
  ongoingMyEventIds: string[];
  upcomingMyEventIds: string[];
  ongoingJoinedEventIds: string[];
  upcomingJoinedEventIds: string[];
  newEventId: string | null;
  currentEventCompany: string | ICompany | null;
  currentEventCompanyPlan: ICompanyServicePlan | null;
};

export const initialState: EventState = {
  events: {},
  currentEventId: null,
  pastMyEventIds: [],
  ongoingMyEventIds: [],
  upcomingMyEventIds: [],
  ongoingJoinedEventIds: [],
  upcomingJoinedEventIds: [],
  newEventId: null, // newly created event to show success popup
  currentEventCompany: null,
  currentEventCompanyPlan: null, // current event's company plan, for company feature toggles
};

const addAndUpdateEventToState = (
  state: Draft<EventState>,
  newEvent: IEvent,
) => {
  let updatedNewEvent = newEvent;
  const oldEvent = state.events[newEvent.id];

  if (oldEvent) {
    let newTheaters: ITheater[];
    const { theaters: oldTheaters } = oldEvent;

    if (newEvent.theaters) {
      newTheaters = newEvent.theaters.map(
        (theater: ITheater, index: number) => {
          if (typeof theater !== "string") {
            // If new data has details, then merge old and new
            const oldTheaterToSeed =
              typeof oldTheaters?.[index] === "object"
                ? oldTheaters?.[index]
                : {};
            return { ...oldTheaterToSeed, ...theater };
          }

          // New data has only ID as string
          // And old data has all details - return only old details
          if (
            typeof oldTheaters?.[index] !== "string" &&
            oldTheaters?.[index].id === theater
          ) {
            return { ...oldTheaters[index] };
          }

          return theater;
        },
      );
    } else {
      newTheaters = oldTheaters as ITheater[];
    }
    updatedNewEvent = {
      ...oldEvent,
      ...newEvent,
      theaters: newTheaters,
    };
  }

  state.events[newEvent.id] = updatedNewEvent;
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setNewEventId: (state, action: PayloadAction<string | null>) => {
      state.newEventId = action.payload;
    },
    setPastMyEventIds: (state, action: PayloadAction<string[]>) => {
      state.pastMyEventIds = action.payload;
    },
    setOngoingMyEventIds: (state, action: PayloadAction<string[]>) => {
      state.ongoingMyEventIds = action.payload;
    },
    setUpcomingMyEventIds: (state, action: PayloadAction<string[]>) => {
      state.upcomingMyEventIds = action.payload;
    },
    setOngoingJoinedEventIds: (state, action: PayloadAction<string[]>) => {
      state.ongoingJoinedEventIds = action.payload;
    },
    setUpcomingJoinedEventIds: (state, action: PayloadAction<string[]>) => {
      state.upcomingJoinedEventIds = action.payload;
    },
    addEventDetails: (state, action: PayloadAction<IEvent>) => {
      const { payload: newEvent } = action;

      addAndUpdateEventToState(state, newEvent);
    },
    openUnlimitedEvent: (state, action: PayloadAction<IEvent>) => {
      const { payload: newEvent } = action;

      addAndUpdateEventToState(state, newEvent);
      state.ongoingMyEventIds = [...state.ongoingMyEventIds, newEvent.id];
      state.pastMyEventIds = state.pastMyEventIds.filter(
        (eventId) => eventId !== newEvent.id,
      );
    },
    removeUnlimitedEvent: (state, action: PayloadAction<IEvent>) => {
      const { payload: newEvent } = action;

      addAndUpdateEventToState(state, newEvent);
      state.pastMyEventIds = [newEvent.id, ...state.pastMyEventIds];
      state.ongoingMyEventIds = state.ongoingMyEventIds.filter(
        (eventId) => eventId !== newEvent.id,
      );
    },
    addAllEventDetails: (state, action: PayloadAction<IEvent[]>) => {
      action.payload.forEach((newEvent) =>
        addAndUpdateEventToState(state, newEvent),
      );
    },
    removeEvent: (state, action: PayloadAction<string>) => {
      const { payload: eventId } = action;

      delete state.events[eventId];

      state.pastMyEventIds = state.pastMyEventIds.filter(
        (id) => id !== eventId,
      );

      state.ongoingMyEventIds = state.ongoingMyEventIds.filter(
        (id) => id !== eventId,
      );

      state.upcomingMyEventIds = state.upcomingMyEventIds.filter(
        (id) => id !== eventId,
      );
    },
    resetEvents: (state) => ({
      ...initialState,
      currentEventId: state.currentEventId,
      newEventId: state.newEventId,
    }),
    setCurrentEventId: (state, action: PayloadAction<string | null>) => {
      state.currentEventId = action.payload;
    },
    updateEventSpeakers: (
      state,
      action: PayloadAction<{ eventId: string; speakers: string[] }>,
    ) => {
      const {
        payload: { eventId, speakers },
      } = action;

      state.events[eventId].speakers = speakers;
    },
    setCurrentEventCompany: (
      state,
      action: PayloadAction<string | ICompany | null>,
    ) => {
      state.currentEventCompany = action.payload;
    },
    setCurrentEventCompanyPlan: (
      state,
      action: PayloadAction<ICompanyServicePlan | null>,
    ) => {
      state.currentEventCompanyPlan = action.payload;
    },
  },
});

export const {
  setNewEventId,
  setPastMyEventIds,
  setOngoingMyEventIds,
  setUpcomingMyEventIds,
  setOngoingJoinedEventIds,
  setUpcomingJoinedEventIds,
  removeEvent,
  addEventDetails,
  addAllEventDetails,
  resetEvents,
  setCurrentEventId,
  updateEventSpeakers,
  setCurrentEventCompany,
  setCurrentEventCompanyPlan,
  openUnlimitedEvent,
  removeUnlimitedEvent,
} = eventSlice.actions;

export const viewEvent = (eventId: string) => ({
  type: actionTypes.VIEW_EVENT,
  payload: { eventId },
});

export default eventSlice.reducer;
