import { useState } from "react";
import { useI18n } from "i18n";
import { Errors, trackError } from "modules/monitoring";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { useInfiniteQuery } from "@tanstack/react-query";
import { PAST_JOINED_EVENTS_QUERY_KEY } from "modules/event/constants";
import { getPastJoinedEvents } from "./request";

export const usePastJoinedEventsQuery = () => {
  const { t } = useI18n(["event"]);
  const [totalCount, setTotalCount] = useState(0);

  const eventsQuery = useInfiniteQuery(
    [PAST_JOINED_EVENTS_QUERY_KEY],
    async ({
      pageParam = {
        id: "",
        endTime: new Date().toISOString(),
        countEndTime: new Date().toISOString(),
      },
    }) => {
      const response = await getPastJoinedEvents(pageParam);

      if (!response.isSuccess) {
        throw new Error(response.message);
      }

      setTotalCount(response.totalCount);

      return response.events;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length === 0) {
          return undefined;
        }

        const { id } = lastPage[lastPage.length - 1];

        const { endTime } = lastPage[lastPage.length - 1];

        const countEndTime =
          allPages.length > 0 ? allPages[0][0].endTime : undefined;

        return { id, endTime, countEndTime };
      },
      onError: (error) => {
        if (error instanceof Error) {
          addErrorNotification({
            message: t("event:error.load.past.events", {
              error: error.message,
            }),
            position: "tr",
          });

          trackError(error, {
            label: Errors.GET_EVENTS,
          });
        }
      },
    },
  );

  return { totalCount, ...eventsQuery };
};
