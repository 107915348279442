import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const selectPresences = (state: RootState) => state.presence;

export const selectUsersPerSpace = createSelector(
  selectPresences,
  (substate) => substate.usersPerSpace,
);

export const selectLiveUsers = createSelector(
  selectPresences,
  (substate) => substate.liveUsers,
);

export const selectLiveUserCount = createSelector(
  selectPresences,
  (substate) => substate.liveUsers.length,
);
