import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
  },
  floors: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #e6e6e6",
    width: "2.2rem",
    height: "2.2rem",
    borderRadius: "50%",
    fontWeight: 700,
    fontSize: "1.4rem",
    color: "#626262",
  },
  error: {
    borderColor: "#f44336",
  },
}));
