import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import {
  USER_PATH,
  VIRTUAL_BACKGROUND,
} from "services/firebaseService/storagePaths";

const collectionName = "UserVirtualCameraSettings";
const backgroundCollectionName = "VirtualBackgrounds";

const getUserVideoBackgroundSettingsCollection = (userId: string) =>
  firebase.firestore().collection(collectionName).doc(userId);

const getUserVideoBackgroundCollection = (userId: string) =>
  getUserVideoBackgroundSettingsCollection(userId).collection(
    backgroundCollectionName,
  );

const getNewDocId = (userId: string) =>
  getUserVideoBackgroundCollection(userId).doc().id;

const getFile = (filePath: string) =>
  firebase.storage().ref(filePath).getDownloadURL();

const createUserFilePath = (userId: string, filename: string) =>
  `${USER_PATH}/${VIRTUAL_BACKGROUND}/${userId}/${filename}`;

export default {
  getNewDocId,
  getFile,
  createUserFilePath,
  getUserVideoBackgroundCollection,
};
