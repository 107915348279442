import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#FAFAFA",
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  progress: {
    color: theme.palette.blue.dark,
    marginRight: 15,
    alignSelf: "center",
  },
}));

export default useStyles;
