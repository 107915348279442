import { useAppDispatch } from "store/hooks";
import { useCallback } from "react";
import {
  addDialogNotification,
  closeDialogNotification,
  closeDialogNotificationsByType,
  updateDialogNotificationParams,
} from "../redux/dialogNotificationSlice";
import { IDialogParams } from "../DialogNotificationContainer";

const useDialogNotificationActions = () => {
  const dispatch = useAppDispatch();

  const openDialog = useCallback(
    (item: IDialogParams) => {
      dispatch(addDialogNotification(item));
    },
    [dispatch],
  );

  const closeDialog = useCallback(() => {
    dispatch(closeDialogNotification());
  }, [dispatch]);

  const closeDialogByType = useCallback(
    (type: string) => {
      dispatch(closeDialogNotificationsByType(type));
    },
    [dispatch],
  );

  const updateDialogParams = useCallback(
    (type: string, data: Partial<IDialogParams>) => {
      dispatch(updateDialogNotificationParams({ type, data }));
    },
    [dispatch],
  );

  return {
    openDialog,
    closeDialog,
    closeDialogByType,
    updateDialogParams,
  };
};

export default useDialogNotificationActions;
