import { useContext, useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector } from "react-redux";
import { selectUser } from "modules/auth/redux/selectors";
import {
  selectCurrentEvent,
  selectIsEventManager,
} from "modules/event/selectors";
import { getEventRole, isEventSpeaker } from "modules/event/utils/eventUtils";
import { Events, TRACKING_CONTEXT } from "modules/tracking";

const useTrackEventRole = (isDatadogInit: boolean): void => {
  const { track } = useContext(TRACKING_CONTEXT);
  const user = useSelector(selectUser);
  const currentEvent = useSelector(selectCurrentEvent);
  const isEventManager = useSelector(selectIsEventManager);

  useEffect(() => {
    if (!isDatadogInit || !currentEvent || !user) {
      return;
    }

    const isSpeaker = isEventSpeaker(currentEvent, user.id, user.email);
    const eventRole = getEventRole(isEventManager, isSpeaker);

    if (eventRole) {
      datadogRum.setGlobalContextProperty("eventRole", eventRole);
      track(Events.USER_ROLE, { role: eventRole });
    }
  }, [isDatadogInit, user, currentEvent, isEventManager, track]);
};
export default useTrackEventRole;
