import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import logger from "logging/logger";
import { PRESENTERS_CACHE_KEY } from "./constants";
import { getPresenters } from "./dal";

export const useActivePresenters = () => {
  const theaterId = useSelector(selectCurrentTheaterId);

  const { data: presenters = [] } = useQuery(
    [PRESENTERS_CACHE_KEY, theaterId],
    async () => {
      if (!theaterId) {
        throw new Error("missing theater Id");
      }
      return getPresenters(theaterId);
    },
    {
      onError: (error) => {
        const errorMessage = error instanceof Error ? error.message : "unknown";
        logger.error(`[useActivePresenters] query error ${errorMessage}`);
      },
      enabled: theaterId !== null,
    },
  );

  return {
    presenters,
  };
};
