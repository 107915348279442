import { Box } from "@remo-co/ui-core/src/components/Box";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Edit } from "@remo-co/ui-core/src/icons/Edit";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import { ContentWithMapping, EventContentType } from "graphql/generated";
import { useI18n } from "i18n";
import { ISlot } from "modules/theater/types";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { ContentWithConsolidatedMapping } from "../../types";
import { ContentListItemLocation } from "../ContentListItemLocation";
import { FloorContent } from "../FloorContent";
import { FullScreenContent } from "../FullScreenContent";
import { PopupContent } from "../PopupContent";
import { useStyles } from "./styles";

interface Props {
  content: ContentWithConsolidatedMapping;
  unsavedCapacityChanges: boolean;
  hasSponsors: boolean;
  onUpdateButtonClick: (content: ContentWithConsolidatedMapping) => void;
  onDeleteButtonClick: (content: ContentWithConsolidatedMapping) => void;
}

export const ContentListItem = ({
  content,
  unsavedCapacityChanges,
  hasSponsors,
  onUpdateButtonClick,
  onDeleteButtonClick,
}: Props) => {
  const { t } = useI18n(["eventForm"]);
  const styles = useStyles();

  const { openDialog } = useDialogNotificationActions();

  const handleDelete = (content: ContentWithConsolidatedMapping) =>
    openDialog({
      message: t("event:warning.delete.content.banner", {
        key: content.name,
      }),
      onConfirm: () => onDeleteButtonClick(content),
      confirmText: t("confirm.text.delete"),
      dismissText: t("dismiss.text"),
      hideCloseButton: true,
      confirmTestId: "confirm-delete",
    });

  const slot = {
    width: 120,
    height: 80,
  };

  const { mappings: _, ...contentData } = content;

  const renderedContent: ContentWithMapping = {
    ...contentData,
    floor: "",
    slot: "",
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      position="relative"
      className={styles.root}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className={styles.preview}
      >
        {content.type === EventContentType.EventFloor && (
          <FloorContent
            content={renderedContent}
            slot={slot as ISlot}
            preview
          />
        )}
        {content.type === EventContentType.FullScreen && (
          <FullScreenContent
            content={renderedContent}
            slot={slot as ISlot}
            preview
          />
        )}
        {content.type === EventContentType.Popup && (
          <PopupContent
            content={renderedContent}
            slot={slot as ISlot}
            preview
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className={styles.contentName}
      >
        <Typography variant="h5">{content.name}</Typography>
        {content.ctaLink && (
          <Typography variant="subtitle1">{content.ctaLink}</Typography>
        )}
      </Box>
      <div className={styles.info}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={styles.infoHeaders}
        >
          <Typography className={styles.infoHeader}>
            {t("eventForm:content.label.type")}
          </Typography>
          <Typography className={styles.infoHeader}>
            {t("eventForm:content.label.display")}
          </Typography>
          <Typography className={styles.infoHeader}>
            {t("eventForm:content.label.location")}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={styles.infoValues}
        >
          <Typography className={styles.infoValue}>
            {t(`eventForm:display.label.${content.mediaType}`)}
          </Typography>
          <Typography className={styles.infoValue}>
            {t(`eventForm:display.label.${content.type}`)}
          </Typography>
          <ContentListItemLocation content={content} />
        </Box>
      </div>
      <Box display="flex" flexDirection="column" justifyContent="space-around">
        <IconButtonWithTooltip
          id="1"
          title={t("eventForm:edit.content")}
          size="small"
          onClick={() => onUpdateButtonClick(content)}
          disabled={unsavedCapacityChanges || hasSponsors}
          data-testid="update-content-button"
        >
          <Edit />
        </IconButtonWithTooltip>
        <IconButtonWithTooltip
          id="1"
          title={t("eventForm:delete.content")}
          size="small"
          onClick={() => handleDelete(content)}
          disabled={unsavedCapacityChanges || hasSponsors}
          data-testid="delete-content-button"
        >
          <Delete />
        </IconButtonWithTooltip>
      </Box>
    </Box>
  );
};
