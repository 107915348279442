import { useState } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Select } from "@remo-co/ui-core/src/components/Select";
import { SwitchInput } from "@remo-co/ui-core/src/components/SwitchInput";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { ICustomRegistrationQuestion } from "modules/registration/types";
import { QuestionType } from "modules/registration/constants";
import { useStyles } from "./styles";
import { getQuestionIcon } from "../../utils";
import { QuestionFormOptionList } from "../QuestionFormOptionList";

interface Props {
  eventId: string;
  order: number;
  preloadedQuestion: ICustomRegistrationQuestion | null;
  isLoading: boolean;
  createQuestion: (
    question: Partial<ICustomRegistrationQuestion>,
  ) => Promise<ICustomRegistrationQuestion[]>;
  updateQuestion: (
    question: ICustomRegistrationQuestion,
  ) => Promise<ICustomRegistrationQuestion[]>;
  onClose: () => void;
}

export const QuestionFormDialog = ({
  eventId,
  order,
  preloadedQuestion,
  isLoading,
  createQuestion,
  updateQuestion,
  onClose,
}: Props) => {
  const { t } = useI18n(["eventForm", "common"]);
  const styles = useStyles();

  const [questionState, setQuestionState] = useState<
    Partial<ICustomRegistrationQuestion>
  >(
    preloadedQuestion ?? {
      question: "",
      type: QuestionType.SINGLE_LINE_TEXT,
      event: eventId,
      order,
      isRequired: false,
      options: [],
    },
  );

  const handleSubmit = async () => {
    if (preloadedQuestion) {
      await updateQuestion({
        ...preloadedQuestion,
        ...questionState,
      });
      return;
    }

    await createQuestion({
      ...questionState,
      event: eventId,
      order,
    });
  };

  const hasFormErrors =
    !questionState.question ||
    ((questionState.type === QuestionType.SINGLE_SELECT ||
      questionState.type === QuestionType.MULTI_SELECT) &&
      questionState.options &&
      questionState.options.filter((item) => item.trim() !== "").length === 0);

  return (
    <Dialog
      data-testid="popup-dialog"
      onClose={onClose}
      aria-labelledby={t(
        preloadedQuestion
          ? "eventForm:edit.question"
          : "eventForm:add.question",
      )}
      fullWidth
      open
      classes={{
        paper: styles.root,
      }}
    >
      <DialogTitle disableTypography className={styles.header}>
        <Typography variant="h3">
          {t(
            preloadedQuestion
              ? "eventForm:edit.question"
              : "eventForm:add.question",
          )}
        </Typography>
        <IconButtonWithTooltip
          id="close-popup-dialog"
          title={t("common:button.close")}
          data-testid="close-button"
          onClick={onClose}
          size="small"
          className={styles.closeButton}
        >
          <Close />
        </IconButtonWithTooltip>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Select
          fullWidth
          value={questionState.type as QuestionType}
          onChange={(type: QuestionType) =>
            setQuestionState({
              ...questionState,
              type,
            })
          }
          name="type"
          label={t("eventForm:question.type")}
          options={Object.values(QuestionType).map((type) => ({
            label: t(`eventForm:option.${type}`),
            description: t(`eventForm:option.${type}.description`),
            leftAdornment: <img src={getQuestionIcon(type)} alt={type} />,
            value: type,
          }))}
          // eslint-disable-next-line i18next/no-literal-string
          inputTestId="type-select-field"
        />

        <Input
          size="sm"
          fullWidth
          getRemainingCharsMessage={(key) =>
            t("common:character.remaining", { key })
          }
          label={t("eventForm:question.label")}
          name="question"
          placeholder={t("eventForm:question.placeholder")}
          value={questionState.question}
          onChange={(event) =>
            setQuestionState({
              ...questionState,
              question: event.target.value,
            })
          }
          inputProps={{ maxLength: 100, "data-testid": "question-field" }}
        />
        {(questionState.type === QuestionType.SINGLE_SELECT ||
          questionState.type === QuestionType.MULTI_SELECT) && (
          <QuestionFormOptionList
            options={questionState.options ?? []}
            onOptionsChange={(options) =>
              setQuestionState({
                ...questionState,
                options,
              })
            }
            multi={questionState.type === QuestionType.MULTI_SELECT}
          />
        )}
        <SwitchInput
          id="isRequired-toggle"
          name="isRequired"
          label={t("eventForm:mark.as.required")}
          checked={Boolean(questionState.isRequired)}
          onChange={(_, isRequired) =>
            setQuestionState({
              ...questionState,
              isRequired,
            })
          }
        />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          variant="secondary"
          color="blue"
          disabled={isLoading}
          onClick={onClose}
        >
          {t("common:button.close")}
        </Button>
        <Button
          variant="primary"
          color="blue"
          disabled={hasFormErrors || isLoading}
          loading={isLoading}
          onClick={handleSubmit}
          data-testid="save-button"
        >
          {t("eventForm:save.question")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
