import { useState } from "react";
import classNames from "classnames";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Loader } from "@remo-co/ui-core/src/components/Loader";
import { OpenInNew } from "@remo-co/ui-core/src/icons/OpenInNew";
import { EventContentType } from "graphql/generated";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

interface Props {
  name: string;
  media: string;
  className?: string;
  contentType: EventContentType;
  hideOpenInNewTabButton?: boolean;
}

export const IframeMedia = ({
  name,
  media,
  className,
  contentType,
  hideOpenInNewTabButton,
}: Props) => {
  const { t } = useI18n(["common"]);
  const [isLoading, setIsLoading] = useState(true);
  const styles = useStyles();

  const handleContentLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loading}>
          <Loader data-testid="loading-indicator" />
        </div>
      )}
      {contentType === EventContentType.Popup && !hideOpenInNewTabButton && (
        <div className={styles.openInNewTab}>
          <Button
            variant="text"
            color="dark"
            size="sm"
            contentClassName={styles.openInNewTabButtonContent}
            href={media}
            component={Link}
            openInNewTab
            data-testid="open-new-tab-button"
          >
            {t("event:open.in.new.tab")}
            <OpenInNew className={styles.openInNewTabButtonIcon} />
          </Button>
        </div>
      )}
      <iframe
        src={media}
        frameBorder={0}
        onLoad={handleContentLoad}
        className={classNames(styles.iframe, className)}
        title={name}
      />
      {contentType === EventContentType.EventFloor &&
        !hideOpenInNewTabButton && (
          <Tooltip title={t("open.in.new.tab")} placement="top">
            <Button
              href={media}
              component={Link}
              openInNewTab
              variant="text"
              size="sm"
              className={styles.button}
              data-testid="open-new-tab-icon-button"
            >
              <OpenInNew />
            </Button>
          </Tooltip>
        )}
    </>
  );
};
