import { useI18n } from "i18n";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogContentText } from "@remo-co/ui-core/src/components/DialogContentText";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Box } from "@remo-co/ui-core/src/components/Box";

export interface ISendEmailConfirmationPopup {
  onClose: (sendInvite: boolean) => void;
}

const SendEmailConfirmationPopup = ({
  onClose,
}: ISendEmailConfirmationPopup): JSX.Element | null => {
  const { t } = useI18n(["common"]);
  return (
    <Dialog
      open
      maxWidth="md"
      id="email-confirm-dialog"
      data-testid="broadcast-confirm-dialog"
    >
      <DialogContent>
        <DialogContentText className="content">
          {t("dialog.message.invite.guest")}
        </DialogContentText>
      </DialogContent>
      <Box paddingY={1} paddingX={3}>
        <DialogActions>
          <Button
            onClick={() => onClose(false)}
            variant="secondary"
            color="dark"
            size="md"
            data-testid="cancel-invite-guest"
          >
            {t("dialog.cancel.invite.guest")}
          </Button>
          <Button
            variant="primary"
            color="blue"
            onClick={() => onClose(true)}
            size="md"
            data-cy="start-now"
            data-testid="confirm-invite-guest"
          >
            {t("dialog.confirm.invite.guest")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default SendEmailConfirmationPopup;
