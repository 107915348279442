import { IEvent, NativeMediaType } from "modules/event/types";
import { useManageEvent } from "modules/manageEvent";
import { useState } from "react";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { WelcomePreviewDialog } from "../WelcomePreviewDialog";
import { LobbySettings } from "../LobbySettings";
import { WelcomeSettings, WelcomeSettingsInputField } from "../WelcomeSettings";

interface Props {
  eventData: IEvent;
  onEventDataChange: (data: Partial<IEvent>) => void;
}

const LobbyAndWelcome = ({
  eventData,
  onEventDataChange,
}: Props): JSX.Element | null => {
  const theme = useTheme();

  const defaultLobbyMessage = eventData?.lobbyWelcomeMessage ?? "";
  const [lobbyMessage, setLobbyMessage] = useState(defaultLobbyMessage);

  const { getDefaultWelcomeMessage } = useManageEvent();
  const defaultWelcomeMessage =
    (eventData && eventData.welcomeMessage) ||
    getDefaultWelcomeMessage(eventData.name);
  const [welcomeMessage, setWelcomeMessage] =
    useState<WelcomeSettingsInputField>({
      error: false,
      value: defaultWelcomeMessage.message,
      message: "",
    });
  const [welcomeMediaType, setWelcomeMediaType] = useState<
    NativeMediaType | undefined
  >(defaultWelcomeMessage.mediaType);
  const [welcomeMediaURL, setWelcomeMediaURL] =
    useState<WelcomeSettingsInputField>({
      error: false,
      value:
        !defaultWelcomeMessage.mediaURL &&
        defaultWelcomeMessage.mediaType === "image"
          ? theme.assets.founder
          : defaultWelcomeMessage.mediaURL ?? "",
      message: "",
    });
  const [showWelcomeMessagePreview, setShowWelcomeMessagePreview] =
    useState(false);

  if (!eventData) return null;

  return (
    <Box>
      <LobbySettings
        isActive={!!eventData.isLobbyActive}
        message={lobbyMessage}
        setMessage={setLobbyMessage}
        onEventDataChange={onEventDataChange}
      />
      <Box marginTop={2}>
        <Divider />
        <Box marginTop={2}>
          <WelcomeSettings
            defaultWelcomeMessage={defaultWelcomeMessage}
            message={welcomeMessage}
            mediaURL={welcomeMediaURL}
            mediaType={welcomeMediaType}
            setMessage={setWelcomeMessage}
            setMediaURL={setWelcomeMediaURL}
            setMediaType={setWelcomeMediaType}
            onEventDataChange={onEventDataChange}
            openPreview={() => setShowWelcomeMessagePreview(true)}
          />
        </Box>
      </Box>
      {showWelcomeMessagePreview && (
        <WelcomePreviewDialog
          mediaURL={welcomeMediaURL.value}
          defaultWelcomeMessage={defaultWelcomeMessage}
          message={welcomeMessage.value}
          mediaType={welcomeMediaType}
          closePreview={() => setShowWelcomeMessagePreview(false)}
        />
      )}
    </Box>
  );
};

export default LobbyAndWelcome;
