import i18n from "i18next";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { selectCurrentEventId } from "modules/event/selectors";
import { DialogType } from "modules/dialogNotification/constants";
import { acceptInviteToTableRequest } from "modules/inviteToTable/request";
import { playInviteSound } from "helpers/audioHelper";
import { IRoom } from "types";
import { Errors, trackError } from "modules/monitoring";
import { selectCurrentRoomId } from "modules/room/redux/selectors";
import { selectCurrentSpaceId } from "modules/space/redux";
import { selectRooms } from "store/rooms";
import logger from "logging/logger";
import { IUser } from "modules/app/types";
import { getUserName } from "modules/userProfile";
import { useUserData } from "modules/audioVideo/hooks";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { getErrorMessage } from "modules/joinTable/errorHandler";
import {
  addDialogNotification,
  closeDialogNotificationsByType,
} from "../dialogNotification/redux/dialogNotificationSlice";
import { sendBrowserNotification } from "../../services/pushNotificationService/BrowserNotificationsService";

interface Dialog {
  dialogType: string;
  message: string;
}
const getDialogCopy = (room: IRoom, fromUser: IUser): Dialog =>
  room.code === "stage"
    ? {
        dialogType: DialogType.JOIN_STAGE,
        message: i18n.t("invited.greenroom", { who: getUserName(fromUser) }),
      }
    : {
        dialogType: DialogType.JOIN_ROOM,
        message: i18n.t("invited.room", {
          who: getUserName(fromUser),
          where: room.name,
        }),
      };

export const useAcceptInviteToTable = () => {
  const currentRoomId = useSelector(selectCurrentRoomId);
  const eventId = useSelector(selectCurrentEventId);
  const spaceId = useSelector(selectCurrentSpaceId);
  const rooms = useSelector(selectRooms);
  const dispatch = useAppDispatch();
  const { t } = useI18n(["template"]);

  const { setConversationId } = useUserData();

  const acceptInvitationRequest = useCallback(
    async (fromUser: IUser, room: IRoom, inviteId: string) => {
      if (currentRoomId === room.id) {
        logger.info(
          `[useAcceptInviteToTable] User is already in room ${currentRoomId}.`,
        );
        return;
      }
      logger.info(
        `[useAcceptInviteToTable] User accepted invitation to room ${room.id}.`,
      );

      if (!eventId) {
        trackError(new Error("No Event"), {
          label: Errors.ACCEPT_TABLE_INVITE,
        });
        return;
      }
      if (!spaceId) {
        trackError(new Error("No Space"), {
          label: Errors.ACCEPT_TABLE_INVITE,
        });
        return;
      }

      try {
        const { tableId } = await acceptInviteToTableRequest({
          eventId,
          spaceId,
          inviteId,
        });
        setConversationId({ conversationId: tableId });
      } catch (error) {
        const errorMessage = getErrorMessage((error as Error).message);

        if (errorMessage) {
          dispatch(
            addErrorNotification({
              message: t(errorMessage),
            }),
          );
        }

        trackError(error, {
          label: Errors.ACCEPT_TABLE_INVITE,
        });
      }
    },
    [currentRoomId, eventId, spaceId, setConversationId, dispatch, t],
  );

  const notifyUser = useCallback(
    (fromUser: IUser, roomCode: string, inviteId: string) => {
      const room = Object.values(rooms).find(({ code }) => code === roomCode);

      if (!room) {
        trackError(new Error(`Room ${roomCode} not found.`), {
          label: Errors.ACCEPT_TABLE_INVITE,
        });
        return;
      }

      if (currentRoomId === room.id) {
        logger.info(
          `[onReceivingInviteToRoom] user already in room ${currentRoomId}`,
        );
        return;
      }

      const { dialogType, message } = getDialogCopy(room, fromUser);

      playInviteSound();

      dispatch(closeDialogNotificationsByType(dialogType));
      dispatch(
        addDialogNotification({
          type: dialogType,
          message,
          image: fromUser.profile.picture,
          user: fromUser,
          onConfirm: () => acceptInvitationRequest(fromUser, room, inviteId),
          confirmText: t("join"),
          hideCloseButton: true,
        }),
      );

      sendBrowserNotification(message, {
        icon: fromUser.profile.picture?.replace("sz=50", "sz=100"),
      });
    },
    [acceptInvitationRequest, currentRoomId, dispatch, rooms, t],
  );

  return { notifyUser, acceptInvitationRequest };
};
