import { useMemo, useState, useEffect, useContext, useCallback } from "react";
import { useAppDispatch } from "store/hooks";
import { Lottie } from "@remo-co/ui-core/src/components/Lottie";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useI18n } from "i18n";
import CameraSelector from "modules/conversation/components/CameraSelector";
import { Actions, trackAction } from "modules/monitoring";
import onboardingActions from "modules/onboarding/redux/actions";
import { ActionButton } from "modules/actionButton";
import { getButtonTitle, hotKeys } from "modules/eventButtons/utils";
import { useDevices } from "modules/audioVideo/hooks";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { usePrevious } from "helpers/reactHooksUtils";
import {
  PermissionState,
  selectHasVideoPermissions,
  selectVideoPermissionsState,
} from "modules/deviceInfo";
import { useSelector } from "react-redux";
import { showDevicePromptPopup } from "modules/audioVideo/redux/slice";
import { MicCamPopupState } from "modules/audioVideo/types";
import { CameraIcon } from "@remo-co/ui-core/src/icons/Camera";
import {
  CameraDisabledIcon,
  CameraNotFoundIcon,
  CameraNeedsPermissionIcon,
} from "./assets";
import CamAnimation from "./assets/cam_animation.json";
import useStyles from "./styles";

export const CAMERA_BUTTON_THROTTLE_TIME = 1000;

interface Props {
  animate?: boolean;
  darkMode?: boolean;
  hideOptions?: boolean;
  askForConfirmation?: boolean;
}

const CameraButton = ({
  animate,
  darkMode,
  hideOptions,
  askForConfirmation = false,
}: Props): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const [isClicked, setIsClicked] = useState(false);
  const { openDialog } = useDialogNotificationActions();
  const hasVideoPermissions = useSelector(selectHasVideoPermissions);
  const videoPermissionState = useSelector(selectVideoPermissionsState);
  const { track } = useContext(TRACKING_CONTEXT);
  const [isAnimating, setIsAnimating] = useState(Boolean(animate));
  const { setCameraActive, cameraActive } = useDevices();
  const prevCameraActive = usePrevious(cameraActive);

  const handleAnimationComplete = useCallback(() => {
    setIsAnimating(false);
  }, []);

  const [isToggleInProgress, setIsToggleInProgress] = useState(false);

  const camNeedsPermission = videoPermissionState === PermissionState.BLOCKED;
  const camNotFound =
    videoPermissionState === PermissionState.NOT_FOUND ||
    videoPermissionState === PermissionState.PENDING;
  const camUnavailable = !hasVideoPermissions;

  const handleTurnCameraOn = useCallback(() => {
    setCameraActive(true);
  }, [setCameraActive]);

  // manually throttling toggle by 1s
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isToggleInProgress) {
      const timeoutId = window.setTimeout(
        () => setIsToggleInProgress(false),
        CAMERA_BUTTON_THROTTLE_TIME,
      );

      return () => window.clearTimeout(timeoutId);
    }
  }, [isToggleInProgress]);

  useEffect(() => {
    if (isClicked) {
      dispatch(onboardingActions.showCamAndMicPopover(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked]);

  useEffect(() => {
    if (!prevCameraActive && cameraActive && isClicked) {
      track(Events.CAMERA_START_SUCCESS);
    }
  }, [prevCameraActive, cameraActive, isClicked, track]);

  const handleButtonClick = useCallback(() => {
    if (camNeedsPermission) {
      dispatch(
        showDevicePromptPopup({
          state: PermissionState.BLOCKED,
          video: true,
        } as MicCamPopupState),
      );
      return;
    }

    if (camNotFound) {
      dispatch(
        showDevicePromptPopup({
          state: PermissionState.NOT_FOUND,
          video: true,
        } as MicCamPopupState),
      );
      return;
    }

    setIsToggleInProgress(true);
    setIsClicked(true);

    track(
      cameraActive ? Events.CAMERA_STOP_SUCCESS : Events.CAMERA_START_CLICK,
    );
    trackAction(
      cameraActive ? Actions.CAMERA_STOP_CLICK : Actions.CAMERA_START_CLICK,
    );

    if (cameraActive) {
      setCameraActive(false);
    } else {
      if (askForConfirmation) {
        openDialog({
          confirmText: t("turn.on.cam"),
          dismissText: t("button.close"),
          onConfirm: handleTurnCameraOn,
          message: t("video.will.be.shared"),
          hideCloseButton: true,
        });
        return;
      }
      handleTurnCameraOn();
    }
  }, [
    camNeedsPermission,
    camNotFound,
    track,
    cameraActive,
    dispatch,
    setCameraActive,
    askForConfirmation,
    handleTurnCameraOn,
    openDialog,
    t,
  ]);

  let buttonState: "default" | "active" | "warning" = cameraActive
    ? "active"
    : "warning";

  if (camUnavailable) {
    buttonState = "default";
  }

  const icon = useMemo(() => {
    if (camNeedsPermission) {
      return <CameraNeedsPermissionIcon />;
    }
    if (camNotFound) {
      return <CameraNotFoundIcon />;
    }
    if (!cameraActive) {
      return isAnimating && !isClicked ? (
        <Container className={styles.cameraButton}>
          <Lottie
            loop={4}
            animationData={CamAnimation}
            onComplete={handleAnimationComplete}
          />
        </Container>
      ) : (
        <CameraDisabledIcon />
      );
    }
    return (
      <Container className={styles.cameraIcon}>
        <CameraIcon />
      </Container>
    );
  }, [
    camNeedsPermission,
    camNotFound,
    cameraActive,
    handleAnimationComplete,
    isAnimating,
    isClicked,
    styles.cameraButton,
    styles.cameraIcon,
  ]);

  const title = getButtonTitle("cam", videoPermissionState, !!cameraActive);

  const rightComponent = useMemo(
    () => !hideOptions && <CameraSelector dark={darkMode} />,
    [darkMode, hideOptions],
  );

  return (
    <ActionButton
      data-testid="camera-button"
      data-cy="cam-button"
      title={t(title)}
      data-dd-action-name={cameraActive ? "Turn off camera" : "Turn on camera"}
      onClick={handleButtonClick}
      label={cameraActive ? t("cam.on") : t("cam.off")}
      state={buttonState}
      hotKey={hasVideoPermissions ? hotKeys.toggleCamera.keyName : undefined}
      icon={icon}
      rightComponent={rightComponent}
      darkMode={darkMode}
    />
  );
};

export default CameraButton;
