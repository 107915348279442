import { useState, useContext } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import EventAgendaEnd from "modules/eventAgendaPublic/EventAgendaEnd";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { useI18n } from "i18n";
import InfoBlock from "./InfoBlock";
import NewAgendaItem from "./NewAgendaItem";
import EventAgendaContextProvider, {
  EVENT_AGENDA_CONTEXT,
} from "./context/EventAgendaContext";
import EventAgendaItems from "./EventAgendaItems";
import EventAgendaHeader from "./EventAgendaHeader";
import "./EventAgenda.scss";

const EventAgenda = (): JSX.Element => {
  const [showAddNewItem, setShowAddNewItem] = useState(false);
  const { t } = useI18n(["common", "eventAgenda"]);
  const { state } = useContext(MANAGE_EVENT_CONTEXT);

  return (
    <EventAgendaContextProvider isInEventSettings>
      <Box className="event-agenda">
        <Box className="ea-title" display="flex" flexDirection="column">
          <Typography variant="h3">
            {t("eventAgenda:box.event.agenda")}
          </Typography>
          <Typography variant="body1">
            {t("eventAgenda:agenda.message.info")}
            <br />
            {t("eventAgenda:agenda.message.guest.info")}
          </Typography>
        </Box>
        <EventAgendaHeader />
        <Box className="ea-cnt">
          <EVENT_AGENDA_CONTEXT.Consumer>
            {({ isLoading }) => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {state?.eventData?.id ? (
                  <>
                    <EventAgendaItems />
                    {!isLoading && (
                      <Button
                        variant="secondary"
                        color="blue"
                        id="event-agenda-add"
                        onClick={() => setShowAddNewItem(true)}
                      >
                        <Container flex alignItems="center">
                          <Add fontSize="small" />
                          {t("eventAgenda:add.new.item")}
                        </Container>
                      </Button>
                    )}
                  </>
                ) : (
                  <InfoBlock text={t("eventAgenda:agenda.clone.message")} />
                )}
              </>
            )}
          </EVENT_AGENDA_CONTEXT.Consumer>
          <EventAgendaEnd
            theme="light"
            tooltip={t("eventAgenda:tooltip.end.time")}
          />
        </Box>
        {showAddNewItem && (
          <NewAgendaItem onAfterSave={() => setShowAddNewItem(false)} />
        )}
      </Box>
    </EventAgendaContextProvider>
  );
};

export default EventAgenda;
