import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  uploaderContainer: {
    "& .filepond--file-action-button": {
      pointerEvents: "all",
      cursor: "pointer",
    },
  },
}));
