import { useAppDispatch } from "store/hooks";
import React from "react";
import {
  JoinRoomStatus,
  setJoiningRoomId as setJoiningRoomIdAction,
  updateRoomStatus,
} from "store/rooms";

export const useRoomActions = () => {
  const dispatch = useAppDispatch();

  const setJoiningRoomId = React.useCallback((roomId: string | null) => {
    dispatch(setJoiningRoomIdAction(roomId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRoomChangeInProgress = React.useCallback(
    (isInProgress: boolean) => {
      dispatch(
        updateRoomStatus(
          isInProgress ? JoinRoomStatus.IN_PROGRESS : JoinRoomStatus.DONE,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const setIsPreparingToChangeRoom = React.useCallback(
    (isPreparing: boolean) => {
      dispatch(
        updateRoomStatus(
          isPreparing ? JoinRoomStatus.PREPARING : JoinRoomStatus.DONE,
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    setJoiningRoomId,
    updateRoomChangeInProgress,
    setIsPreparingToChangeRoom,
  };
};
