import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector } from "react-redux";
import { selectCurrentEventCode } from "modules/event/selectors";

export const useTrackEventCode = (isDatadogInit: boolean): void => {
  const eventCode = useSelector(selectCurrentEventCode);

  useEffect(() => {
    if (!isDatadogInit) {
      return;
    }

    if (eventCode) {
      datadogRum.setGlobalContextProperty("eventCode", eventCode);
    }
  }, [eventCode, isDatadogInit]);
};

export default useTrackEventCode;
