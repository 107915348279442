import { datadogRum } from "@datadog/browser-rum";

export {
  trackAction,
  trackActionStart,
  trackActionEnd,
} from "./helpers/trackActions";

export { default as updateFeatureFlagInContext } from "./helpers/updateContext";

export { trackError } from "./helpers/trackErrors";

export { useMonitoring } from "./hooks";

export { Actions } from "./actions";
export { Errors } from "./errors";
export { Warnings } from "./warnings";
export { trackWarning } from "./helpers";

export const setGlobalContext = datadogRum.setGlobalContextProperty;
