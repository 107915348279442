import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { inIframe } from "../../helpers/inIframe";

const useTrackIframe = (isDatadogInit: boolean) => {
  useEffect(() => {
    if (!isDatadogInit) {
      return;
    }

    datadogRum.setGlobalContextProperty("isInIframe", inIframe());
  }, [isDatadogInit]);
};

export default useTrackIframe;
