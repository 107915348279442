import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

type UsersPerSpace = Partial<Record<string, string[]>>;
export interface EventUserPresenceState {
  isOnline: boolean;
  liveUsers: string[];
  usersPerSpace: UsersPerSpace;
}

export const initialState: EventUserPresenceState = {
  isOnline: false,
  liveUsers: [],
  usersPerSpace: {},
};

const eventUserPresenceSlice = createSlice({
  initialState,
  name: "eventUserPresence",
  reducers: {
    resetPresenceInfo: () => ({ ...initialState }),
    setLiveUsers: (state, action: PayloadAction<string[]>) => {
      // check if reference is the same as initial, or if not initial, is deeply equal
      // we want to skip deep comparison if it's still the initial by reference
      if (
        current(state.liveUsers) === initialState.liveUsers ||
        !isEqual(state.liveUsers, action.payload)
      ) {
        state.liveUsers = action.payload;
      }
    },
    setUsersPerSpace: (state, action: PayloadAction<UsersPerSpace>) => {
      // check if reference is the same as initial, or if not initial, is deeply equal
      // we want to skip deep comparison if it's still the initial by reference
      if (
        current(state.usersPerSpace) === initialState.usersPerSpace ||
        !isEqual(state.usersPerSpace, action.payload)
      ) {
        state.usersPerSpace = action.payload;
      }
    },
  },
});

export const { resetPresenceInfo, setLiveUsers, setUsersPerSpace } =
  eventUserPresenceSlice.actions;

export default eventUserPresenceSlice.reducer;
