import { Button } from "@remo-co/ui-core/src/components/Button";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { Add } from "@remo-co/ui-core/src/icons/Add";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import {
  autoUpdate,
  offset,
  useFloating,
} from "@floating-ui/react-dom-interactions";
import { useI18n } from "i18n";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectLiveStreams } from "modules/liveStream/redux/selectors";
import { useStyles } from "./styles";

interface Props {
  handleStreamDialog: () => void;
  showContactSales: boolean;
  closeStreamDialog: () => void;
}

export const AddLiveStream = ({
  handleStreamDialog,
  showContactSales,
  closeStreamDialog,
}: Props): JSX.Element => {
  const liveStreams = useSelector(selectLiveStreams);
  const { t } = useI18n(["manageEvent, settings"]);
  const styles = useStyles();
  const { x, y, floating, strategy, reference } =
    useFloating<HTMLButtonElement>({
      whileElementsMounted: autoUpdate,
      placement: "bottom-start",
      middleware: [offset({ mainAxis: 0, crossAxis: 0 })],
    });

  return (
    <>
      <div className="mar-top-40">
        <Button
          variant="secondary"
          color="blue"
          size="sm"
          className={classNames(styles.addContentButton, {
            [styles.buttonGrey]: liveStreams.length >= 1,
          })}
          onClick={handleStreamDialog}
          ref={reference}
        >
          <Add className={styles.addContentButtonIcon} />
          {t("manageEvent:add.rtmp.destination")}
        </Button>
      </div>

      {showContactSales && (
        <ClickAwayListener
          onClickAway={closeStreamDialog}
          mouseEvent="onMouseDown"
        >
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
              zIndex: 10,
            }}
          >
            <div className={styles.popoverContent}>
              <Container>
                <Container
                  flex
                  alignItems="center"
                  className={styles.promptToSave}
                >
                  <Info className={styles.promptIcon} />
                  <div className={styles.message}>
                    <Typography variant="body1" component="span">
                      {t("manageEvent:need.another.rtmp.description")}
                    </Typography>
                  </div>
                </Container>
                <div className={styles.dialogButtons}>
                  <Button
                    component={Link}
                    variant="primary"
                    className={styles.button}
                    onClick={closeStreamDialog}
                    href={t("url:contact.sales.mailto")}
                  >
                    {t("settings:contact.sales.team")}
                  </Button>
                </div>
              </Container>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};
