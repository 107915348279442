import { ReactElement, useState } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import {
  EventPollOption,
  AnswerPollMutation,
  EventPollStatus,
  EventPoll,
  EventPollType,
} from "graphql/generated";
import { useSelector } from "react-redux";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { useStyles } from "./styles";
import { HostControls } from "../HostControls";
import Metadata from "./Metadata";
import Options from "./Options";
import PollResults from "./PollResults";
import Stats from "./Stats";
import { EditPollForm } from "../EditPollForm";

interface Props {
  isHost?: boolean;
  status: EventPollStatus;
  poll: EventPoll;
  onSubmit: (
    option: EventPollOption,
  ) => Promise<AnswerPollMutation | null | undefined>;
}

const Poll = ({
  isHost = false,
  status,
  poll,
  onSubmit,
}: Props): ReactElement => {
  const user = useSelector(makeSelectUserById(poll.createdBy));
  const classes = useStyles();
  const ended = status === EventPollStatus.Closed;
  const [isEditing, setIsEditing] = useState(false);

  const showResults =
    ended ||
    (poll.selectedAnswer &&
      poll.options.some((option) => option.id === poll.selectedAnswer)) ||
    isHost;
  const userAnswers = poll.options;
  const totalVotes =
    userAnswers?.reduce((total, option) => total + (option?.votes ?? 0), 0) ??
    0;

  return (
    <Box className={classes.poll}>
      {isEditing && (
        <EditPollForm
          closeForm={() => setIsEditing(false)}
          initialForm={{
            id: poll.id,
            status: poll.status,
            type: poll.type,
            title: poll.title,
            eventId: poll.eventId,
            timer: poll.timer,
            options: poll.options,
          }}
          pollType={EventPollType.Poll}
        />
      )}
      {!isEditing && (
        <>
          {user && (
            <Metadata
              isHost={isHost}
              status={status}
              timestamp={new Date(poll.updatedAt).getTime()}
              type="poll"
              user={user}
            />
          )}
          <Container>
            <Typography variant="h4" className={classes.question}>
              {poll.title}
            </Typography>
            {showResults && poll.options ? (
              <PollResults
                options={poll.options}
                selectedAnswer={poll.selectedAnswer}
                totalVotes={totalVotes}
              />
            ) : (
              <Options
                options={poll.options}
                onSubmit={(option) => {
                  if (option) {
                    onSubmit(option);
                  }
                }}
              />
            )}
            {isHost && (
              <>
                <Stats options={poll.options} status={status} />
                <HostControls
                  setIsEditing={setIsEditing}
                  poll={poll}
                  status={status}
                  totalVotes={totalVotes}
                />
              </>
            )}
          </Container>
        </>
      )}
    </Box>
  );
};

export default Poll;
