import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import { ChangeEvent, MouseEventHandler, ReactNode } from "react";

export interface SwitchInputProps {
  id?: string;
  toolTip?: string;
  name?: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  label: ReactNode;
  className?: string;
}

const SwitchInput = ({
  id,
  toolTip,
  name,
  disabled,
  checked,
  onChange,
  onClick,
  label,
  className,
}: SwitchInputProps) => {
  const classes = classNames("form-theme-label", className);

  const switchContent = (
    <FormControlLabel
      className={classes}
      name={name}
      control={
        <Checkbox
          id={id}
          color="primary"
          checked={checked}
          onClick={onClick}
          onChange={onChange}
          data-testid={`test-${id}`}
          inputProps={{
            "aria-label": "primary checkbox",
          }}
          disabled={disabled}
        />
      }
      label={label}
    />
  );

  return toolTip ? (
    <Tooltip title={toolTip} placement="top">
      {switchContent}
    </Tooltip>
  ) : (
    switchContent
  );
};

export default SwitchInput;
