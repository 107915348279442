import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    paddingBottom: "56.30%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "80%",
    },
  },
  toolbar: {
    transform: "scale(0.8, 0.8)",
    display: "flex",
    position: "absolute",
    bottom: 0,
    padding: "10px 0px 5px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px",
      transform: "scale(0.7, 0.7)",
    },
    borderRadius: "15px",
    alignItems: "flex-start",
    backgroundColor: theme.palette.white,
    boxShadow: "2px 4px 20px rgba(0, 0, 0, 0.1)",
    pointerEvents: "all",
  },
  video: {
    position: "absolute",
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
}));
