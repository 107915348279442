import { IGlobalUserPreference } from "modules/globalUserPreference";

export interface IUserPreferences extends IGlobalUserPreference {
  lastLogin: number;
  roomId: string; // room id in which user was in before leaving the app
  leftEventAt: number; // Time at which user has left the app
  spaceId: string;
  enteredEventAt: number; // Timestamp when user entered event
  isLoaded?: boolean;
  welcomeMessageSeen?: boolean;
  disableWhiteboardInitialDialog?: boolean;
  hideTakePhoto?: boolean;
  disableSwitchScreenFocusModeNotification?: boolean;
}

export const USER_PREFERENCES = "app/userPreferences/USER_PREFERENCES";

export const LAST_LOGIN = "lastLogin";
