import { useSelector } from "react-redux";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { DailyTracker } from "modules/audioVideo/components/DailyTracker";
import { ActiveSpeakerSubscriber } from "modules/daily/ActiveSpeakerSubscriber";
import { TileReceiveSettings } from "modules/audioVideo/hooks/useDailyPerformance";
import { Conversation } from "./Views/Conversation/Conversation";
import { Presentation } from "./Views/Presentation/Presentation";
import { PresentationSpeakersCalculator } from "../PresentationSpeakersCalculator";

const DailyContainer = (): JSX.Element => {
  const isInBroadcast = useSelector(selectIsInBroadcast);

  return (
    <>
      {isInBroadcast ? <Presentation /> : <Conversation />}
      <DailyTracker />
      <PresentationSpeakersCalculator />
      <ActiveSpeakerSubscriber />
      <TileReceiveSettings />
    </>
  );
};

export default DailyContainer;
