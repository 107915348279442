import { database } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";
import store from "store";

export const getRaisedHandUsersDocRef = (theaterId: string) =>
  database.ref(`${THEATER_PATH}/${theaterId}/RaisedHandUsers/`);

export const getRaisedHandUserDocRef = (theaterId: string, userId: string) =>
  database.ref(`${THEATER_PATH}/${theaterId}/RaisedHandUsers/${userId}`);

export const toggleRaisedHand = async (theaterId: string, userId: string) => {
  const dbRef = getRaisedHandUserDocRef(theaterId, userId);
  const isUsersHandRaised = await dbRef.once("value");
  const time = store.getState().serverTime.deviation;
  const userHand = isUsersHandRaised.val();

  if (userHand && userHand.raised) {
    dbRef.set({
      raised: false,
      time: time ? time + Date.now() : null,
    });
  } else {
    dbRef.set({
      raised: true,
      time: time ? time + Date.now() : null,
    });
  }
};

export const resetRaisedHands = (theaterId: string) => {
  const dbRef = getRaisedHandUsersDocRef(theaterId);

  dbRef.set({});
};
