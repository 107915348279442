import { firebase, firestore } from "services/firebaseService/firebaseConfig";
import logger from "logging/logger";
import { IUser } from "modules/app/types";
import getRandomString from "../getRandomString";

export const PRIVATE_ACCESS_CHECK_PATHS = ["GeartestTest1", "GeartestTest2"];

export const PUBLIC_ACCESS_CHECK_PATH = "NewAccessCheck";

const documentsExistAndTimesMatch = (
  documents: firebase.firestore.DocumentSnapshot[],
  time: number,
) =>
  documents.every((document) => {
    if (!document.exists || document.data()?.time !== time) {
      logger.error("Failed to get data from firestore path.", {
        path: document.ref,
      });

      return false;
    }

    return true;
  });

const checkFirestoreAccess = async (user?: IUser): Promise<boolean> => {
  const random = getRandomString();
  const time = Date.now();

  const paths = user
    ? [PUBLIC_ACCESS_CHECK_PATH, ...PRIVATE_ACCESS_CHECK_PATHS]
    : [PUBLIC_ACCESS_CHECK_PATH];

  await Promise.all(
    paths.map((path) =>
      firestore.collection(`${path}`).doc(random).set({ time }),
    ),
  );

  const readResults = await Promise.all(
    paths.map((path) => firestore.collection(`${path}`).doc(random).get()),
  );

  await Promise.all(
    paths.map((path) => firestore.collection(`${path}`).doc(random).delete()),
  );

  return (
    documentsExistAndTimesMatch(readResults, time) &&
    readResults.length === paths.length
  );
};

export default checkFirestoreAccess;
