import { ChangeEvent, useCallback, useState } from "react";
import { Checkbox } from "@remo-co/ui-core/src/components/Checkbox";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogContentText } from "@remo-co/ui-core/src/components/DialogContentText";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { CameraIcon } from "@remo-co/ui-core/src/icons/Camera";
import { MicrophoneIcon } from "@remo-co/ui-core/src/icons/Microphone";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { selectLiveStreams } from "modules/liveStream/redux/selectors";
import { IStartBroadcastParams } from "modules/broadcast/redux/actions";
import { useStyles } from "./styles";

interface Props {
  onConfirm(
    data: Pick<
      IStartBroadcastParams,
      "delay" | "isTurnOnMicCamWhenStart" | "streams" | "recordOnEntering"
    >,
  ): void;
  onCancel(): void;
}

const BroadcastConfirmationDialog = ({ onConfirm, onCancel }: Props) => {
  const classes = useStyles();
  const { t } = useI18n(["common", "event"]);
  const [isTurnOnMicCamWhenStart, setIsTurnOnMicCamWhenStart] = useState(true);
  const [recordOnEntering, setRecordOnEntering] = useState(false);
  const [open, setOpen] = useState(true);
  const [liveStreamingOn, setLiveStreamingOn] = useState(true);
  const liveStreams = useSelector(selectLiveStreams);

  const handleMicCamToggleChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setIsTurnOnMicCamWhenStart(checked);
    },
    [],
  );

  const handleRecordingStartToggleChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setRecordOnEntering(checked);
    },
    [],
  );

  const handleStreaming = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setLiveStreamingOn(checked);
    },
    [],
  );

  const handleConfirm = (delay: number) => async () => {
    const isStreamAvailable = liveStreamingOn && liveStreams.length;

    onConfirm({
      isTurnOnMicCamWhenStart,
      delay,
      recordOnEntering,
      streams: isStreamAvailable
        ? [
            {
              serverUrl: liveStreams[0].serverUrl,
              streamKey: liveStreams[0].streamKey,
            },
          ]
        : [],
    });
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    onCancel();
  };

  return (
    <Dialog
      open={open}
      id="broadcast-confirm-dialog"
      data-testid="broadcast-confirm-dialog"
      onClose={handleCancel}
    >
      <DialogContent>
        <div className={classes.broadcastConfirmationDialog}>
          <DialogTitle className="header">
            <Typography className={classes.titleText}>
              {t("event:ask.start.presentation")}
            </Typography>
            <IconButton
              aria-label={t("modal.window.aria.label")}
              className={classes.closeBtn}
              data-testid="broadcast-dialog-close-button"
              onClick={handleCancel}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContentText className={classes.content}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isTurnOnMicCamWhenStart}
                  onChange={handleMicCamToggleChange}
                  data-cy="start-mic-cam-on"
                />
              }
              label={
                <>
                  {t("event:start.mic")}
                  <Container className={classes.labelIcon}>
                    <MicrophoneIcon className={classes.textIcon} />
                  </Container>
                  {t("event:and.cam")}
                  <Container className={classes.labelIcon}>
                    <CameraIcon className={classes.textIcon} />
                  </Container>
                  {t("event:turned.on")}
                </>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={recordOnEntering}
                  onChange={handleRecordingStartToggleChange}
                  data-cy="start-presentation-with-recording"
                />
              }
              label={t("event:start.broadcast.with.recording")}
            />
            {liveStreams.map(({ id, destinationName }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={liveStreamingOn}
                    onChange={handleStreaming}
                    value={id}
                  />
                }
                label={`${t("event:start.streaming.out.to", {
                  destinationName,
                })}`}
                key={id}
              />
            ))}
          </DialogContentText>
          <DialogActions className={classes.buttons}>
            <Button
              variant="primary"
              color="blue"
              fullWidth
              onClick={handleConfirm(0)}
              id="broadcast-start-now"
              size="md"
              data-cy="start-now"
              className={classes.button}
            >
              {t("start.now")}
            </Button>
            <Button
              fullWidth
              onClick={handleConfirm(30000)}
              variant="secondary"
              color="blue"
              size="md"
              className={classes.button}
            >
              {t("start.time")}
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BroadcastConfirmationDialog;
