import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { database } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";

export const useFloorNames = (
  theaterId: string | null,
): Record<string, string> => {
  const [floorNameRecord, setFloorNameRecord] = useState<
    Record<string, string>
  >({});

  const subscribe = (docRef: firebase.database.Reference) => {
    if (docRef) {
      docRef.on("value", (snap: firebase.database.DataSnapshot) => {
        const record = snap.val();

        setFloorNameRecord(record || {});
      });
    }
  };

  const unsubscribe = (docRef: firebase.database.Reference) => {
    docRef.off();
  };

  useEffect(() => {
    let docRef: firebase.database.Reference;

    if (theaterId) {
      docRef = database.ref(`${THEATER_PATH}/${theaterId}/FloorNames/`);
      subscribe(docRef);
    }

    return () => {
      if (docRef) {
        unsubscribe(docRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId]);

  return floorNameRecord;
};
