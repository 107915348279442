import { useContext } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { push } from "connected-react-router";
import { updateGlobalUserPreference } from "modules/globalUserPreference";
import useSystemClock from "modules/system/hooks/useSystemClock";
import useHelpChatActions from "modules/helpChat/hooks/useHelpChatActions";
import { Errors, trackError } from "modules/monitoring";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { setAppLogo } from "modules/appSetting";
import { isMyCompanyTrialPeriodActive } from "modules/companyPlanSettings/utils/trial";
import {
  setCompanyTags,
  setDefaultTags,
} from "modules/customFloorPlan/redux/slice";
import { selectCompany } from "../redux/selectors";
import companyApi from "../company.api";
import actions from "../redux/actions";
import { updateHelpChatEvents } from "../../event/saga";
import useCompanySettingsActions from "../../companySettings/hooks/useCompanySettingsActions";
import { setUserCompanyRole } from "../../auth/redux/authSlice";
import useCompanyActions from "./useCompanyActions";
import getWhitelabelledLogo from "../utils/getWhitelabelledLogo";
import { ICompany } from "../types";

type UseCompanyParams = {
  createdEvent?: string | Events;
};

const useCompany = ({
  createdEvent = "rc_company_created",
}: UseCompanyParams = {}) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const { serverTime } = useSystemClock();
  const dispatch = useAppDispatch();
  const { loadCompanies, getCurrentCompanyId } = useCompanyActions();
  const { company, isInternalCompany, isLoading } = useSelector(selectCompany);
  const { setCompanySettings, resetCompanySettings } =
    useCompanySettingsActions();
  const { pushEvents } = useHelpChatActions();

  // eslint-disable-next-line consistent-return
  const saveCompany = async (companyInfo: Partial<ICompany>) => {
    dispatch(actions.setIsLoading(true));

    if (companyInfo.id) {
      const updateResp = await companyApi.updateMyCompany(companyInfo);

      if (updateResp && updateResp.company) {
        dispatch(actions.setCompany(updateResp.company));
      }
      dispatch(actions.setIsLoading(false));

      return updateResp;
    }

    let logo: string | null = null;

    try {
      const createResp = await companyApi.createCompany(
        companyInfo as Required<ICompany>,
      );

      if (createResp?.isSuccess) {
        logo = getWhitelabelledLogo(createResp?.company);

        updateHelpChatEvents({
          rc_first_company_created_name: companyInfo.name,
          rc_first_company_created_at: Math.ceil(serverTime),
        });

        // Push event
        pushEvents(createdEvent, {
          company: companyInfo.name,
          time: Math.ceil(serverTime),
        });
        // Set user is in host onboarding flow
        updateGlobalUserPreference({ isInHostOnboarding: true });
      }

      if (logo) {
        dispatch(setAppLogo(logo));
      }

      dispatch(actions.setIsLoading(false));

      return createResp;
    } catch (e) {
      trackError(e, { label: Errors.COMPANY_SAVE, companyInfo });
    }
  };

  const loadCompany = async () => {
    dispatch(actions.setIsLoading(true));
    resetCompanySettings();

    const companyId = await getCurrentCompanyId();

    if (!companyId) {
      dispatch(actions.setIsLoading(false));
      return null;
    }

    const resp = await companyApi.getCompanyById(companyId);

    if (resp.isSuccess) {
      track(
        Events.SIGNED_IN_B2B,
        {},
        {
          groupId: companyId,
        },
      );

      const logo = getWhitelabelledLogo(resp.company);

      if (logo) {
        dispatch(setAppLogo(logo));
      }

      dispatch(actions.setCompany(resp.company));
      dispatch(setCompanyTags(resp.tags.companyTags));
      dispatch(setDefaultTags(resp.tags.defaultTags));
      dispatch(setUserCompanyRole(resp.role));

      if (resp.plan) {
        dispatch(actions.setCompanyPlanSettings(resp.plan));
        updateHelpChatEvents(
          {
            rc_company_name: resp.company.name,
            rc_cb_plan_id: resp.plan.planId,
            role: resp.role,
            is_in_trial:
              !resp.plan.planId && isMyCompanyTrialPeriodActive(resp.plan),
          },
          15000,
        );
      }

      if (resp.companySettings) {
        setCompanySettings(resp.companySettings);
      }

      dispatch(actions.setIsLoading(false));

      return resp;
    }

    return null;
  };

  const checkIsCompanyOwner = async () => {
    if (company) {
      return true;
    }

    return loadCompanies();
  };

  const onCancel = () => {
    dispatch(push("/home"));
  };

  const getCompanyDataById = async (companyId: string) => {
    const resp = await companyApi.getCompanyById(companyId);

    return resp.company;
  };

  const getCompanyPlanData = async (companyData: ICompany | undefined) => {
    if (companyData && companyData.id) {
      const resp = await companyApi.getCompanyPlan(companyData.id);

      if (resp.isSuccess) {
        return resp.plan;
      }
    }

    return null;
  };

  const validateCompanyName = async (name: string) => {
    const resp = await companyApi.isValidCompany(name);

    return resp;
  };

  return {
    isLoading,
    company, // your own company, not current event's
    checkIsCompanyOwner,
    saveCompany,
    loadCompany,
    getCompanyDataById,
    onCancel,
    getCompanyPlanData,
    validateCompanyName,
    isInternalCompany, // for current event's company, not your own company
  };
};

export default useCompany;
