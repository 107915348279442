import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "zIndexes.module.scss";

export const useStyles = makeStyles<Theme, { darkMode?: boolean }>(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    height: "46px",
  },
  arrowIcon: {
    width: "1.3em",
    height: "1.3em",
    color: ({ darkMode }) => (darkMode ? "white" : "inherit"),
  },
  popover: {
    zIndex: zIndexes.broadcastMenuZIndex + 1,

    "&:before": {
      content: "",
      position: "absolute",
      top: "auto",
      left: "50%",
      bottom: "-32px",
      borderBottom: "1rem solid transparent",
      borderRight: "1rem solid transparent",
      borderLeft: "1rem solid transparent",
      borderTop: ({ darkMode }) =>
        darkMode ? "1rem solid #1c1f2f" : "1rem solid white",
      zIndex: zIndexes.broadcastMenuZIndex + 2,
      transform: "translateX(-50%)",
    },
  },
  popoverContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: ({ darkMode }) => (darkMode ? "var(--DarkBlue)" : "white"),
    borderRadius: "10px",
    boxShadow: "-2px 2px 40px #0000000d",
  },
  header: {
    width: "100%",
    boxSizing: "border-box",
    color: ({ darkMode }) => (darkMode ? "var(--White-op-090)" : "#484a56"),
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 500,
    padding: "16px 21px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    appearance: "none",
    border: "none",
    outline: "none",
    background: "none",
    overflow: "hidden",
    width: "30px !important",
    minWidth: "unset !important",
    height: "30px",
    padding: "0",
  },
  rowItem: {
    position: "relative",
    padding: "15px 38px",
    color: ({ darkMode }) => (darkMode ? "var(--White-op-090)" : "#484a56"),
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    boxSizing: "border-box",
    borderTop: "1px solid #d5d5d5",
  },
  rowIcon: {
    position: "absolute",
    left: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    color: "#484a56",
    width: "16px",
    filter: ({ darkMode }) => (darkMode ? "invert(1)" : "none"),
    height: "unset",
    lineHeight: "normal",
  },
}));
