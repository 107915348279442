import { database } from "services/firebaseService/firebaseConfig";
import getRandomString from "../getRandomString";

export const ACCESS_CHECK_PATH = "NewAccessCheck";

const checkFirebaseAccess = async (): Promise<boolean> => {
  const random = getRandomString();
  const time = Date.now();

  await database.ref(`${ACCESS_CHECK_PATH}/${random}`).set({ time });
  const readResult = await database
    .ref(`${ACCESS_CHECK_PATH}/${random}`)
    .once("value");

  const timesMatch = readResult.val().time === time;

  await database.ref(`${ACCESS_CHECK_PATH}/${random}`).remove();

  return timesMatch;
};

export default checkFirebaseAccess;
