import { storage } from "services/firebaseService/firebaseConfig";
import {
  createFakeImage,
  deleteImage,
  downloadImage,
  uploadImage,
} from "./imageUtils";

export const STORAGE_CHECK_PATH = "storage-access-test";

const checkFirebaseStorageAccess = async (): Promise<boolean> => {
  const { name: fakeImageName, file: fakeImageFile } = await createFakeImage();
  const fakeImageRef = storage
    .ref()
    .child(`${STORAGE_CHECK_PATH}/${fakeImageName}`);
  await uploadImage(fakeImageFile, fakeImageRef);
  await downloadImage(fakeImageRef);
  await deleteImage(fakeImageRef);

  return true;
};

export default checkFirebaseStorageAccess;
