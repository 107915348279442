import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/index";

const selectAgencyWhiteLabel = (state: RootState) => state.agencyWhiteLabel;

export const selectIsAgencyWhitelabellingSettingsLoaded = createSelector(
  selectAgencyWhiteLabel,
  ({ isLoaded }) => isLoaded,
);

export const selectEnableAgencyWhiteLabelling = createSelector(
  selectAgencyWhiteLabel,
  ({ enableAgencyWhiteLabelling }) => enableAgencyWhiteLabelling,
);

export const selectWhiteLabelLogoURL = createSelector(
  selectAgencyWhiteLabel,
  ({ whiteLabelLogoURL }) => whiteLabelLogoURL,
);

export const selectWhiteLabelFaviconURL = createSelector(
  selectAgencyWhiteLabel,
  ({ whiteLabelFaviconURL }) => whiteLabelFaviconURL,
);

export const selectWhiteLabelSignInBackgroundURL = createSelector(
  selectAgencyWhiteLabel,
  ({ whiteLabelSignInBackgroundURL }) => whiteLabelSignInBackgroundURL,
);

export const selectCustomDomainCompanyID = createSelector(
  selectAgencyWhiteLabel,
  ({ customDomainCompany }) => customDomainCompany,
);

export const selectDisableFreshchatWidget = createSelector(
  selectAgencyWhiteLabel,
  ({ disableFreshchatWidget }) => disableFreshchatWidget,
);
