import { ThemeOptions } from "@material-ui/core/styles/createTheme";
import { lightPalette } from "./palettes";
import { Colors } from "./colors";
import { fontWeightBold } from "./fontWeights";

export const overrides: ThemeOptions["overrides"] = {
  MuiTypography: {
    root: {
      color: "inherit",
    },
  },
  MuiInputBase: {
    input: {
      cursor: "inherit",
      "&::placeholder": {
        color: lightPalette.blue.darkShade1,
        opacity: "100%",
      },
    },
    root: {
      backgroundColor: lightPalette.white,
      borderColor: lightPalette.blue.darkShade1,
      "&:hover": {
        borderColor: lightPalette.blue.dark,
      },
      "&$focused": {
        backgroundColor: lightPalette.white,
        borderColor: `${lightPalette.blue.main} !important`,
        "&:hover": {
          borderColor: lightPalette.blue.main,
        },
      },
      "&$disabled": {
        background: lightPalette.gray.disabledInput,
        borderColor: lightPalette.gray.light,
        color: `${lightPalette.blue.darkShade1}70 !important`,

        "&:hover": {
          cursor: "not-allowed",
          background: lightPalette.gray.disabledInput,
          borderColor: lightPalette.gray.light,
        },
      },
    },
  },
  MuiFormLabel: {
    root: {
      zIndex: 10,
    },
  },
  MuiAppBar: {
    colorDefault: {
      backgroundColor: Colors.BLUE_DARK,
    },
    root: {
      backgroundColor: Colors.BLUE_DARK,
      color: Colors.BLUE_PRIMARY,
    },
    colorPrimary: {
      backgroundColor: Colors.BLUE_DARK,
      color: Colors.BLUE_DARK,
    },
  },
  MuiCardActions: {
    root: {
      padding: 0,
    },
  },
  MuiAccordionSummary: {
    root: {
      minHeight: 0,
      padding: 0,
    },
    content: {
      // The expanded property wasn't being set correctly
      // which is why this required an `!important`.
      margin: "0 !important",
      padding: 0,
    },
  },
  MuiAccordionDetails: {
    root: {
      margin: 0,
      padding: 0,
    },
  },
  MuiButton: {
    root: {
      padding: "0 30px",
      borderRadius: 5,
      textTransform: "none",
      fontWeight: fontWeightBold,
      fontSize: 16,
    },
    contained: {
      // Primary
      color: Colors.WHITE,
      backgroundColor: Colors.BLUE_PRIMARY,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: Colors.BLUE_PRIMARY_HOVER,
        color: Colors.WHITE,
        boxShadow: "none",
      },
      "&$disabled": {
        backgroundColor: Colors.BLUE_LIGHTSHADE3_DISABLED,
        color: Colors.WHITE,
        boxShadow: "none",
      },
    },
    outlined: {
      // Secondary
      borderColor: Colors.BLUE_PRIMARY,
      color: Colors.BLUE_PRIMARY,
      "&:hover": {
        backgroundColor: Colors.BLUE_LIGHTSHADE1,
      },
      "&$disabled": {
        borderColor: Colors.BLUE_LIGHTSHADE3_DISABLED,
        color: Colors.BLUE_LIGHTSHADE3_DISABLED,
      },
    },
    text: {
      // Ghost
      color: Colors.BLUE_DARK,
      "&$disabled": {
        color: Colors.GRAY_DISABLED,
      },
    },
    label: {
      pointerEvents: "none",
    },
    startIcon: {
      pointerEvents: "none",
    },
  },
  MuiIconButton: {
    label: {
      pointerEvents: "none",
    },
  },
  MuiFab: {
    label: {
      pointerEvents: "none",
    },
  },
  MuiLink: {
    root: {
      color: Colors.BLUE_DARKSHADE1,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 25,
      marginRight: 10,
      color: Colors.BLUE_PRIMARY,
    },
  },
  MuiListItem: {
    container: {
      position: "relative",
    },
  },
  MuiPaper: {
    root: {
      color: Colors.BLUE_DARK,
    },
  },
  MuiDialog: {
    paper: {
      "@media (max-width: 539.95px)": {
        margin: 15,
        width: "100% !important",
      },
    },
    paperScrollPaper: {
      "@media (max-width: 539.95px)": {
        maxHeight: "calc(100% - 70px)",
      },
    },
  },
  MuiTabs: {
    indicator: {
      "&::after": {
        backgroundColor: "#EAEEF2",
      },
    },
  },
  MuiTab: {
    root: {
      textTransform: "none",
    },
  },
  MuiMenuItem: {
    root: {
      whiteSpace: "normal",
    },
  },
  MuiPopover: {
    paper: {
      zIndex: 100_000,
    },
  },
  MuiSwitch: {
    root: {
      "&checked": {
        color: Colors.BLUE_PRIMARY,
        "&:hover": {
          backgroundColor: Colors.BLUE_PRIMARY[600],
        },
      },
    },
    switchBase: {
      color: Colors.BLUE_DARKSHADE1,
      "&:hover": {
        backgroundColor: Colors.BLUE_DARKSHADE1[600],
      },
    },
    track: {
      backgroundColor: Colors.GRAY_DISABLED,
    },
    input: {
      pointerEvents: "auto",
    },
  },
};
