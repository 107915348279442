import React from "react";

export const PresentModeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className="MuiSvgIcon-root"
  >
    <path
      d="M12.0001 17.3334C14.9456 17.3334 17.3334 14.9455 17.3334 12C17.3334 9.0545 14.9456
      6.66669 12.0001 6.66669C9.05456 6.66669 6.66675 9.0545 6.66675 12C6.66675 14.9455
      9.05456 17.3334 12.0001 17.3334Z"
    />
    <path
      d="M11.9999 20C8.43992 20 1.33325 21.7867 1.33325 25.3334V28H22.6666V25.3334C22.6666 21.7867
      15.5599 20 11.9999 20ZM22.3466 7.14669L20.1066 9.40002C21.2266 10.9734 21.2266 13.0134
      20.1066 14.5867L22.3466 16.84C25.0399 14.1467 25.0399 10.08 22.3466 7.14669ZM26.7599
      2.66669L24.5866 4.84002C28.2799 8.86669 28.2799 14.92 24.5866 19.16L26.7599
      21.3334C31.9599 16.1467 31.9732 8.06669 26.7599 2.66669Z"
    />
  </svg>
);
