import { Theme } from "@material-ui/core/styles/createTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  description: {
    color: `${theme.palette.blue.darkShade1}`,
    margin: "0",
  },
  errorDescription: {
    color: `${theme.palette.red.main}`,
  },
}));
