import { firestore, firebase } from "services/firebaseService/firebaseConfig";
import { useEffect } from "react";
import { selectCurrentEventId } from "modules/event/selectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { setLiveStreams } from "modules/liveStream/redux/slice";
import { LiveStreamData } from "modules/liveStream/request";
import {
  EVENT_PATH,
  LIVE_STREAM_DESTINATIONS,
} from "../../../../services/firebaseService/storagePaths";

export const liveStreamDestinationsRef = (eventId: string) =>
  firestore
    .collection(EVENT_PATH)
    .doc(eventId)
    .collection(LIVE_STREAM_DESTINATIONS);

export const LiveStreamDestinationsSubscriber = () => {
  const eventId = useSelector(selectCurrentEventId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!eventId) return;

    const unsubscribe = liveStreamDestinationsRef(eventId).onSnapshot(
      (snapshot) => {
        const livestream = snapshot.docs.reduce(
          (accumulator: firebase.firestore.DocumentData[], doc) => {
            const data = doc.data();
            const { id } = doc;

            if (data) {
              accumulator.push({ ...data, id });
            }

            return accumulator;
          },
          [],
        );
        if (!livestream) {
          dispatch(setLiveStreams([]));
          return;
        }
        dispatch(setLiveStreams(livestream as LiveStreamData[]));
      },
    );

    // eslint-disable-next-line consistent-return
    return () => {
      unsubscribe();
    };
  }, [dispatch, eventId]);

  return null;
};
