import { useCallback } from "react";
import { useI18n } from "i18n";

import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Actions, trackAction } from "modules/monitoring";
import { DeviceSelector } from "modules/conversation/components";
import { useDevices } from "modules/audioVideo/hooks";
import { SerializedDevice } from "modules/deviceInfo";
import { useVirtualBackground } from "modules/audioVideo/hooks/useVirtualBackground";
import useCameraSettingsActions from "modules/cameraSettings/hooks/useCameraSettingsActions";
import { useStyles } from "../DeviceSelector/styles";
import pictureIcon from "../../assets/picture.svg";

interface Props {
  dark?: boolean;
}

const CameraSelector = ({ dark }: Props): JSX.Element => {
  const classes = useStyles({ darkMode: dark });
  const { t } = useI18n(["videoBackground"]);
  const { showCameraSettingsDialog } = useCameraSettingsActions();
  const { virtualBackgroundSupported } = useVirtualBackground();
  const { setCamera, cameras, activeDevices } = useDevices();

  const selectedCamera = activeDevices.videoInput;

  const handleCameraChange = useCallback(
    (device: SerializedDevice) => {
      setCamera(device);
      trackAction(Actions.CHANGE_CAMERA, {
        newCam: device,
        availableCams: cameras,
        currentCam: selectedCamera,
      });
    },
    [setCamera, cameras, selectedCamera],
  );

  const virtualBackgroundText = t("videoBackground:virtual.background");

  const menuFooter = (closePopover: () => void) => (
    <ListItem
      button
      className={classes.rowItem}
      onClick={() => {
        showCameraSettingsDialog();
        closePopover();
      }}
    >
      <img className={classes.rowIcon} src={pictureIcon} alt="" />
      <Typography component="span" color="inherit">
        {virtualBackgroundText}
      </Typography>
    </ListItem>
  );

  return (
    <DeviceSelector
      devices={cameras}
      title={t("conversation:select.device.camera")}
      onSelect={handleCameraChange}
      data-testid="device-selector"
      selectedDevice={selectedCamera}
      menuFooter={virtualBackgroundSupported ? menuFooter : undefined}
      dark={dark}
      showCameraSettingsOption
    />
  );
};

export default CameraSelector;
