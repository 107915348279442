import { useSelector } from "react-redux";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import {
  selectBrowserName,
  selectOsName,
} from "modules/micCamCheck/systemCheck/redux/selectors";
import { getMicCamTroubleshootLink } from "modules/micCamCheck/systemCheck/utils";
import { BlockedBy, PermissionState } from "modules/deviceInfo/types";
import { BROWSER_NAMES, OS_NAMES } from "modules/systemCheck";
import {
  getPromptTitle,
  getPromptContent,
  getPromptDirections,
  getPromptAssurance,
  getBlockedDevice,
} from "./utils";
import { hideDevicePromptPopup } from "../../redux/slice";
import { selectDevicePromptPopupState } from "../../redux/selectors";
import { useStyles } from "./styles";

const ScreenSharingDirections = ({
  state,
  blockedBy,
}: {
  state: PermissionState.BLOCKED | PermissionState.UNKNOWN;
  blockedBy?: BlockedBy;
}) => {
  const browserName = useSelector(selectBrowserName);
  const osName = useSelector(selectOsName);
  const { t } = useI18n(["settings", "url", "micCamCheck"]);

  if (
    state === PermissionState.BLOCKED &&
    blockedBy === BlockedBy.BROWSER &&
    browserName === BROWSER_NAMES.SAFARI
  ) {
    return (
      <>
        <br />
        {[1, 2, 3, 4].map((step) => (
          <Typography
            key={`step${step}`}
            variant="subtitle1"
            style={{ whiteSpace: "pre-line" }}
          >
            {t(`screenShare.permissions.needed.safari.step.${step}`)}
          </Typography>
        ))}
      </>
    );
  }

  if (
    state === PermissionState.BLOCKED &&
    blockedBy === BlockedBy.OS &&
    osName === OS_NAMES.MAC
  ) {
    return (
      <>
        <br />
        {[1, 2, 3, 4, 5].map((step) => (
          <Typography
            key={`step${step}`}
            variant="subtitle1"
            style={{ whiteSpace: "pre-line" }}
          >
            {t(`screenShare.permissions.needed.mac.step.${step}`)}
          </Typography>
        ))}
      </>
    );
  }

  return (
    <>
      <br />
      {[1, 2].map((step) => (
        <Typography
          key={`step${step}`}
          variant="subtitle1"
          style={{ whiteSpace: "pre-line" }}
        >
          {t(`screenShare.permissions.needed.generic.step.${step}`)}
        </Typography>
      ))}
    </>
  );
};

export const DevicePromptPopup = () => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["settings", "micCamCheck", "url"]);
  const promptState = useSelector(selectDevicePromptPopupState);
  const browser = useSelector(selectBrowserName);
  const os = useSelector(selectOsName);
  const styles = useStyles();

  if (!promptState.visible) {
    return null;
  }

  let state = PermissionState.UNKNOWN;
  let audio = false;
  let video = false;
  let screenShare;
  let blockedBy;

  if ("audio" in promptState || "video" in promptState) {
    state = promptState.state;
    audio = Boolean(promptState.audio);
    video = Boolean(promptState.video);
  } else if ("screenShare" in promptState) {
    state = promptState.state;
    screenShare = promptState.screenShare;
    blockedBy = promptState.blockedBy;
  }

  const handlePopupClose = () => {
    dispatch(hideDevicePromptPopup());
  };

  return (
    <Dialog
      data-testid="device-prompt-popup"
      maxWidth="xs"
      open
      onClose={handlePopupClose}
      PaperProps={{
        className: styles.paper,
      }}
    >
      <DialogContent>
        <div className={styles.heading}>
          <Typography variant="h4" className={styles.title}>
            {t(getPromptTitle(state, video, audio, screenShare))}
          </Typography>
          <IconButtonWithTooltip
            title={t("micCamCheck:close")}
            aria-label={t("modal.window.aria.label")}
            id="prompt-close-button"
            data-testid="prompt-close-button"
            onClick={handlePopupClose}
            size="small"
            className={styles.closeButton}
          >
            <CloseIcon />
          </IconButtonWithTooltip>
        </div>
        <Typography variant="body1" className={styles.promptContent}>
          {t(getPromptContent(state, video, audio, screenShare))}
        </Typography>
        {screenShare && state !== PermissionState.NOT_FOUND ? (
          <ScreenSharingDirections state={state} blockedBy={blockedBy} />
        ) : (
          <Typography variant="body1">
            {t(getPromptDirections(state, video, audio, browser, os))}
          </Typography>
        )}
        {!screenShare && (
          <>
            {state === PermissionState.NOT_FOUND ? (
              os === OS_NAMES.MAC && (
                <Typography
                  key="prompt.not-found.assurance"
                  variant="body1"
                  className={styles.followUp}
                >
                  {t(
                    `settings:prompt.${state}.assurance.${getBlockedDevice(
                      audio,
                      video,
                    )}`,
                  )}
                </Typography>
              )
            ) : (
              <Typography
                key="prompt.directions.alt"
                variant="body1"
                className={styles.followUp}
              >
                {t("prompt.directions.alt")}
              </Typography>
            )}
            <Typography
              key="prompt.assurance"
              variant="body1"
              className={styles.assurance}
            >
              {t(getPromptAssurance(video, audio))}
            </Typography>
          </>
        )}
        <div className={styles.helpLink}>
          <Typography variant="body1" component="span">
            {t("prompt.help.text")}
          </Typography>{" "}
          <Link
            openInNewTab
            href={
              screenShare
                ? t(`url:troubleshoot.screenShare.${blockedBy ?? "unknown"}`)
                : t(getMicCamTroubleshootLink(os))
            }
            data-testid="prompt-troubleshoot-url"
            aria-label={t("prompt.help.link.text")}
          >
            <Typography variant="body1" component="span" underline>
              {t("prompt.help.link.text")}
            </Typography>
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
};
