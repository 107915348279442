import { compareVersions, validate } from "compare-versions";

const MAC_SUPPORTED_VERSION = "10.13";
const IOS_SUPPORTED_VERSION = "12.4.1";
const ANDROID_SUPPORTED_VERSION = "7";
const WINDOWS_SUPPORTED_VERSION = "10";

const isVersionSupported = (currentVersion: string, targetVersion: string) =>
  Number(compareVersions(currentVersion, targetVersion)) >= 0;

const checkOsSupport = (osName: string, osVersion: string): boolean => {
  if (!validate(osVersion)) {
    // we do not support any version that's not semantic
    return false;
  }

  switch (osName) {
    case "iOS":
      return isVersionSupported(osVersion, IOS_SUPPORTED_VERSION);
    case "Android":
      return isVersionSupported(osVersion, ANDROID_SUPPORTED_VERSION);
    case "Windows":
      return isVersionSupported(osVersion, WINDOWS_SUPPORTED_VERSION);
    case "Mac OS":
      return isVersionSupported(osVersion, MAC_SUPPORTED_VERSION);
    default:
      return false;
  }
};

export default checkOsSupport;
