import { firebase } from "services/firebaseService/firebaseConfig";
import getRandomString from "../../getRandomString";
import loadImage from "../../loadImage";

export const TEST_IMAGE_PREFIX = "storage-access-test-image";
const IMAGE_TYPE = "png";
const IMAGE_BASE64_DATA = `data:${IMAGE_TYPE};base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==`;

export const createFakeImage = async (): Promise<{
  name: string;
  file: File;
}> => {
  const random = getRandomString();
  const name = `${TEST_IMAGE_PREFIX}${random}.${IMAGE_TYPE}`;
  const data = await fetch(IMAGE_BASE64_DATA).then((res) => res.blob());
  const file = new File([data], name, {
    type: `image/${IMAGE_TYPE}`,
  });

  return { name, file };
};

export const uploadImage = async (
  image: File,
  imageRef: firebase.storage.Reference,
): Promise<void> => {
  await imageRef.put(image, {
    cacheControl: "public, max-age=31536000",
    contentType: `image/${IMAGE_TYPE}`,
  });
};

export const downloadImage = async (
  imageRef: firebase.storage.Reference,
): Promise<void> => {
  const url = await imageRef.getDownloadURL();
  return loadImage(url);
};

export const deleteImage = async (
  imageRef: firebase.storage.Reference,
): Promise<void> => {
  await imageRef.delete();
};
