export enum EventStatus {
  active = "active",
  archived = "archived",
  inactive = "inactive",
  preparing = "preparing",
}

// @TODO: Get rid of this as soon as we get rid of redux-saga [rc-3877]

enum ActionTypes {
  ADD_ALL_EVENT_DETAILS = "app/event/ADD_ALL_EVENT_DETAILS",
  ADD_EVENT_DETAILS = "app/event/ADD_EVENT_DETAILS",
  ADD_THEATER_DETAILS = "app/event/ADD_THEATER_DETAILS",
  NEW_EVENT_ID = "app/event/NEW_EVENT_ID",
  REMOVE_EVENT = "app/event/REMOVE_EVENT",
  RESET_EVENTS = "app/event/RESET_EVENTS",
  SET_CURRENT_EVENT_ID = "app/event/SET_CURRENT_EVENT_ID",
  SET_ONGOING_JOINED_EVENT_IDS = "app/event/SET_ONGOING_JOINED_EVENT_IDS",
  SET_ONGOING_MY_EVENT_IDS = "app/event/SET_ONGOING_MY_EVENT_IDS",
  SET_PAST_JOINED_EVENT_IDS = "app/event/SET_PAST_JOINED_EVENT_IDS",
  SET_PAST_MY_EVENT_IDS = "app/event/SET_PAST_MY_EVENT_IDS",
  SET_UPCOMING_JOINED_EVENT_IDS = "app/event/SET_UPCOMING_JOINED_EVENT_IDS",
  SET_UPCOMING_MY_EVENT_IDS = "app/event/SET_UPCOMING_MY_EVENT_IDS",
  UPDATE_EVENT_SPEAKERS = "app/event/UPDATE_EVENT_SPEAKERS",
  VIEW_EVENT = "app/event/VIEW_EVENT",
}

export default ActionTypes;

export enum EventRole {
  admin = "admin",
  attendee = "attendee",
  helper = "helper", // event helper who has access only to this event
  speaker = "speaker",
}

export enum EventStates {
  ENDED = "ended",
  IN_PROGRESS = "in-progress",
  UPCOMING = "upcoming",
}

export const INITIAL_EVENT_DURATION_MINUTES = 270;
export const DEFAULT_EVENT_CAPACITY = 15;
export const DEFAULT_EVENT_DATA = {
  isAudienceViewDisabled: true,
  isScreenShareDisabled: false,
  isRemojiDisabled: false,
  isQnADisabled: false,
  isChatSupportDisabled: false,
  isChatDisabled: false,
  isWhiteboardDisabled: false,
  isLobbyActive: false,
  isGuestExperienceSurveyDisabled: true,
  isOverflowSeatingDisabled: true,
  isUserIdDisplayed: false,
};

export const COLORS = {
  PRIMARY: "#16871c",
  SECONDARY: "#7E8E7A",
  TEXT: "#474747",
  LINK: "#45ACE1",
  RED: "#e90100",
};

export const SVG_ORIGIN_X = 0;

export const SVG_ORIGIN_Y = 0;

export const SVG_WIDTH = 2691.87;

export const SVG_HEIGHT = 1766.5;

export const SEAT_RADIUS = 22.5;

export const SEAT_BORDER_WIDTH = 5;

export const SVG_ZOOM = {
  min: 0.6,
  max: 3,
};

export const MAP_NAVIGATION_ZOOMING = {
  IN: 1,
  OUT: -1,
};

export const MAP_NAVIGATION_ZOOMING_VARIANT = 0.3;

export const MAP_NAVIGATION_LOCATE_USER_ZOOM_LEVEL = 1.1;

export const MAP_NAVIGATION_TOOLTIP_SHOW_DELAY_MS = 10;

export const MAP_NAVIGATION_TOOLTIP_DURATION_MS = 4000;

export const MAP_NAVIGATION_USER_AVATAR_ANIMATION_MS = 1750;

export const MAX_OVERFLOW = 10;

export const CONTEXT_MENU_TYPES = {
  ROOM: "room",
  USER: "user",
};

export const USER_AVAILABILITY_COLORS = {
  AVAILABLE: "#1DB100",
  CONNECTION_ISSUE: "#F8BA00",
};

export const EVENT_DEFAULT_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/remo-conference.appspot.com/o/static%2Fevent-default.jpg?alt=media&token=8c17f226-57be-44b2-9a4c-a827d2209c87";

export const EVENT_DISCOVERY_QUERY_KEY = "event-discovery";
export const PAST_JOINED_EVENTS_QUERY_KEY = "past-joined-events";
