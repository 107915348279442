import { detectAnyAdblocker } from "just-detect-adblock";
import { Errors, trackError } from "modules/monitoring";

const isAdBlockEnabled = async (): Promise<boolean> => {
  try {
    const result = await detectAnyAdblocker();

    return result;
  } catch (error) {
    trackError(error, { label: Errors.ADBLOCK_DETECTION });

    return false;
  }
};

export default isAdBlockEnabled;
