import { ReactElement, useEffect, useState } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { LinearProgress } from "@remo-co/ui-core/src/components/LinearProgress";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import { selectStaticAnimations } from "modules/appSetting/redux/selectors";
import { EventPollStatus } from "graphql/generated";
import { format } from "date-fns";
import { useStyles } from "../Poll/styles";

interface Props {
  correct?: boolean;
  isHost: boolean;
  status: EventPollStatus;
  time: number;
  onFinish: () => void;
  currentTime: number;
  initialTime: number;
}

export const AnswerCountDown = ({
  correct,
  isHost,
  status,
  time,
  onFinish,
  currentTime,
  initialTime,
}: Props): ReactElement | null => {
  const staticAnimations = useSelector(selectStaticAnimations);
  const [countDownTime, setCountdownTime] = useState(
    status !== EventPollStatus.Closed ? initialTime - currentTime + time : 0,
  );
  const { t } = useI18n(["event"]);
  const classes = useStyles();

  useEffect(() => {
    if (status === EventPollStatus.Draft || staticAnimations) {
      return undefined;
    }

    if (countDownTime <= 0) {
      onFinish();
      return undefined;
    }

    const timeout = setTimeout(() => {
      const newTime = initialTime - Date.now() / 1000 + time;
      setCountdownTime(newTime);
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [countDownTime, onFinish, staticAnimations, status, initialTime, time]);

  const countDownFinished = countDownTime <= 0;

  if (isHost && status === EventPollStatus.Closed) {
    return null;
  }

  const timeLeftDate = format(countDownTime * 1000, "mm:ss");

  return (
    <div className={classes.countDown}>
      {!isHost && (
        <>
          {countDownFinished && correct === undefined && (
            <Typography>{t("quiz.answer.missing")}</Typography>
          )}
          {countDownFinished && correct === true && (
            <Typography className={classes.correctAnswerMessage}>
              {t("quiz.answer.correct")}
            </Typography>
          )}
          {countDownFinished && correct === false && (
            <Typography className={classes.incorrectAnswerMessage}>
              {t("quiz.answer.incorrect")}
            </Typography>
          )}
        </>
      )}
      {status === EventPollStatus.Ongoing && !countDownFinished && (
        <Typography>
          {t("quiz.showing.answer", {
            time: timeLeftDate,
          })}
        </Typography>
      )}
      {status === EventPollStatus.Draft && (
        <Typography>
          {t("quiz.timer", {
            timer: `${Math.floor(time / 60).toFixed(0)}:${time % 60}`,
          })}
        </Typography>
      )}
      <LinearProgress
        classes={{
          root: classes.linearProgress,
          colorPrimary: classes.colorPrimary,
          bar: classes.bar,
        }}
        variant="determinate"
        value={Math.round((countDownTime * 100) / time)}
      />
    </div>
  );
};
