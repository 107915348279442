import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles/createTheme";

export const useSwitchStyles = makeStyles<Theme>((theme) => ({
  darkMode: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: `${theme.palette.darkModeBlue.main}`,
      "&:hover": {
        backgroundColor: `${theme.palette.darkModeBlue.main[600]}`,
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.gray.switchTrackDark,
    },
  },
}));
