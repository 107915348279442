import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "../../../../zIndexes.module.scss";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    zIndex: zIndexes.modalZIndex + 1,
  },
  title: {
    color: theme.palette.blue.dark,
    fontWeight: 800,
    marginBottom: 10,
  },
  activeOption: {
    color: theme.palette.blue.dark,
  },
}));

export default useStyles;
