/* eslint-disable */
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  DID: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IP: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: string;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  SemVer: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type ContentData = {
  __typename?: "ContentData";
  createdAt: Scalars["String"];
  id: Scalars["Int"];
  mode: EventEmbedContentMode;
  url: Scalars["String"];
};

export type ContentWithMapping = {
  __typename?: "ContentWithMapping";
  company: Scalars["ObjectID"];
  ctaLink?: Maybe<Scalars["String"]>;
  ctaText?: Maybe<Scalars["String"]>;
  event: Scalars["ObjectID"];
  floor: Scalars["ObjectID"];
  floorImage?: Maybe<Scalars["String"]>;
  hideOpenInNewTabButton?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ObjectID"];
  media: Scalars["String"];
  mediaType: EventContentMediaType;
  name: Scalars["String"];
  slot: Scalars["String"];
  type: EventContentType;
};

export type EmbedContentListResult = EventEmbedContentList | GqlError;

export type EmbedContentResult = EventEmbedContent | GqlError;

export type EventContent = {
  __typename?: "EventContent";
  company: Scalars["ObjectID"];
  ctaLink?: Maybe<Scalars["String"]>;
  ctaText?: Maybe<Scalars["String"]>;
  floorImage?: Maybe<Scalars["String"]>;
  hideOpenInNewTabButton?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ObjectID"];
  media: Scalars["String"];
  mediaType: EventContentMediaType;
  name: Scalars["String"];
  type: EventContentType;
};

export type EventContentList = {
  __typename?: "EventContentList";
  content: Array<Maybe<ContentWithMapping>>;
  event: Scalars["ObjectID"];
};

export type EventContentListResult = EventContentList | GqlError;

export type EventContentMapping = {
  __typename?: "EventContentMapping";
  event: Scalars["ObjectID"];
  eventContent: Scalars["ObjectID"];
  floor: Scalars["ObjectID"];
  slot: Scalars["String"];
};

export enum EventContentMediaType {
  Iframe = "IFRAME",
  Image = "IMAGE",
  Text = "TEXT",
  Video = "VIDEO",
}

export type EventContentResult = EventContent | GqlError;

export enum EventContentType {
  EventFloor = "EVENT_FLOOR",
  FullScreen = "FULL_SCREEN",
  Popup = "POPUP",
}

export type EventEmbedContent = {
  __typename?: "EventEmbedContent";
  createdAt: Scalars["String"];
  eventId: Scalars["String"];
  id: Scalars["Int"];
  mode: EventEmbedContentMode;
  url: Scalars["String"];
};

export type EventEmbedContentDeleteResult = EventEmbedContent | GqlError;

export type EventEmbedContentList = {
  __typename?: "EventEmbedContentList";
  content: Array<Maybe<ContentData>>;
  eventId: Scalars["String"];
};

export enum EventEmbedContentMode {
  Conversation = "CONVERSATION",
  Presentation = "PRESENTATION",
}

export type EventFloorContentList = {
  __typename?: "EventFloorContentList";
  content: Array<Maybe<ContentWithMapping>>;
  event: Scalars["ObjectID"];
  floor: Scalars["ObjectID"];
};

export type EventFloorContentListResult = EventFloorContentList | GqlError;

export type EventPoll = {
  __typename?: "EventPoll";
  correctAnswers: Array<Maybe<Scalars["Int"]>>;
  createdAt: Scalars["String"];
  createdBy?: Maybe<Scalars["String"]>;
  eventId: Scalars["ID"];
  id: Scalars["Int"];
  options: Array<Maybe<EventPollOption>>;
  selectedAnswer?: Maybe<Scalars["Int"]>;
  status: EventPollStatus;
  timer: Scalars["Int"];
  title: Scalars["String"];
  type: EventPollType;
  updatedAt: Scalars["String"];
  userAnswers?: Maybe<Array<Maybe<EventPollUserAnswer>>>;
};

export type EventPollDelete = {
  __typename?: "EventPollDelete";
  correctAnswers: Array<Maybe<Scalars["Int"]>>;
  createdAt: Scalars["String"];
  createdBy?: Maybe<Scalars["String"]>;
  eventId: Scalars["ID"];
  id: Scalars["Int"];
  options: Array<Maybe<EventPollOption>>;
  selectedAnswer?: Maybe<Scalars["Int"]>;
  status: EventPollStatus;
  timer: Scalars["Int"];
  title: Scalars["String"];
  type: EventPollType;
  updatedAt: Scalars["String"];
  userAnswers?: Maybe<Array<Maybe<EventPollUserAnswer>>>;
};

export type EventPollDeleteResult = EventPollDelete | GqlError;

export type EventPollOption = {
  __typename?: "EventPollOption";
  id: Scalars["Int"];
  option: Scalars["String"];
  votes: Scalars["Int"];
};

export type EventPollOptionInput = {
  correctAnswer?: InputMaybe<Scalars["Boolean"]>;
  option: Scalars["String"];
};

export type EventPollOptionResult = EventPollOption | GqlError;

export type EventPollOptionUpdateInput = {
  correctAnswer?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["Int"]>;
  option: Scalars["String"];
};

export type EventPollResult = EventPoll | GqlError;

export enum EventPollStatus {
  Closed = "CLOSED",
  Draft = "DRAFT",
  Ongoing = "ONGOING",
}

export enum EventPollType {
  Poll = "POLL",
  Quiz = "QUIZ",
}

export type EventPollUserAnswer = {
  __typename?: "EventPollUserAnswer";
  optionId: Scalars["Int"];
  pollId: Scalars["Int"];
  userId: Scalars["String"];
};

export type EventPollUserAnswerResult = EventPollUserAnswer | GqlError;

export type EventPolls = {
  __typename?: "EventPolls";
  polls: Array<Maybe<EventPoll>>;
};

export type EventPollsResult = EventPolls | GqlError;

export type EventRating = {
  __typename?: "EventRating";
  eventId?: Maybe<Scalars["ObjectID"]>;
  rating?: Maybe<Scalars["Float"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type EventRatingAverage = {
  __typename?: "EventRatingAverage";
  numberOfRatings: Scalars["Int"];
  rating?: Maybe<Scalars["Float"]>;
};

export type EventRatingResult = EventRating | GqlError;

export type EventScoreResult = EventRatingAverage | GqlError;

export type GqlError = {
  __typename?: "GQLError";
  message: Scalars["String"];
};

export type MappingDeleteResult = EventContentMapping | GqlError;

export type MappingUpdateResult = ContentWithMapping | GqlError;

export type Mutation = {
  __typename?: "Mutation";
  answerPoll: EventPollResult;
  createEmbedContent: EmbedContentResult;
  createEventContent: EventContentResult;
  createEventPoll: EventPollResult;
  deleteEventContent: EventContentResult;
  deleteEventEmbedContent: EventEmbedContentDeleteResult;
  deleteEventPoll: EventPollDeleteResult;
  deleteEventRating?: Maybe<EventRatingResult>;
  placeContentOnSlot: MappingUpdateResult;
  rateEvent?: Maybe<EventRatingResult>;
  removeContentOnSlot: MappingDeleteResult;
  updateEventContent: EventContentResult;
  updateEventPoll: EventPollResult;
};

export type MutationAnswerPollArgs = {
  optionId: Scalars["Int"];
  pollId: Scalars["Int"];
};

export type MutationCreateEmbedContentArgs = {
  eventId: Scalars["ID"];
  mode: EventEmbedContentMode;
  url: Scalars["String"];
};

export type MutationCreateEventContentArgs = {
  companyId: Scalars["ObjectID"];
  ctaLink?: InputMaybe<Scalars["String"]>;
  ctaText?: InputMaybe<Scalars["String"]>;
  floorImage?: InputMaybe<Scalars["String"]>;
  hideOpenInNewTabButton?: InputMaybe<Scalars["Boolean"]>;
  media: Scalars["String"];
  mediaType: EventContentMediaType;
  name: Scalars["String"];
  type: EventContentType;
};

export type MutationCreateEventPollArgs = {
  eventId: Scalars["ObjectID"];
  options?: InputMaybe<Array<EventPollOptionInput>>;
  status: EventPollStatus;
  timer: Scalars["Int"];
  title: Scalars["String"];
  type: EventPollType;
};

export type MutationDeleteEventContentArgs = {
  companyId: Scalars["ObjectID"];
  id: Scalars["ObjectID"];
};

export type MutationDeleteEventEmbedContentArgs = {
  eventId: Scalars["String"];
  id: Scalars["Int"];
};

export type MutationDeleteEventPollArgs = {
  eventId: Scalars["ObjectID"];
  pollId: Scalars["Int"];
};

export type MutationDeleteEventRatingArgs = {
  eventId: Scalars["ObjectID"];
};

export type MutationPlaceContentOnSlotArgs = {
  companyId: Scalars["ObjectID"];
  eventContent: Scalars["ObjectID"];
  eventId: Scalars["ObjectID"];
  floorId: Scalars["ObjectID"];
  slot: Scalars["String"];
};

export type MutationRateEventArgs = {
  eventId: Scalars["ObjectID"];
  rating: Scalars["Float"];
};

export type MutationRemoveContentOnSlotArgs = {
  companyId: Scalars["ObjectID"];
  eventId: Scalars["ObjectID"];
  floorId: Scalars["ObjectID"];
  slot: Scalars["String"];
};

export type MutationUpdateEventContentArgs = {
  companyId: Scalars["ObjectID"];
  ctaLink?: InputMaybe<Scalars["String"]>;
  ctaText?: InputMaybe<Scalars["String"]>;
  floorImage?: InputMaybe<Scalars["String"]>;
  hideOpenInNewTabButton?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ObjectID"];
  media?: InputMaybe<Scalars["String"]>;
  mediaType?: InputMaybe<EventContentMediaType>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EventContentType>;
};

export type MutationUpdateEventPollArgs = {
  eventId: Scalars["ObjectID"];
  id: Scalars["Int"];
  options?: InputMaybe<Array<EventPollOptionUpdateInput>>;
  status?: InputMaybe<EventPollStatus>;
  timer?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EventPollType>;
};

export type Query = {
  __typename?: "Query";
  embedContent: EmbedContentListResult;
  eventContent: EventContentListResult;
  eventFloorContent: EventFloorContentListResult;
  eventPolls: EventPollsResult;
  ratings?: Maybe<EventScoreResult>;
};

export type QueryEmbedContentArgs = {
  eventId: Scalars["ID"];
  subscribedAt?: InputMaybe<Scalars["String"]>;
};

export type QueryEventContentArgs = {
  event: Scalars["ObjectID"];
};

export type QueryEventFloorContentArgs = {
  event: Scalars["ObjectID"];
  floor: Scalars["ObjectID"];
};

export type QueryEventPollsArgs = {
  eventId: Scalars["ObjectID"];
  includeUserAnswers?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryRatingsArgs = {
  eventId: Scalars["ObjectID"];
};

export type Subscription = {
  __typename?: "Subscription";
  onEventContentChanged?: Maybe<ContentWithMapping>;
  onEventContentDeleted?: Maybe<EventContentMapping>;
  onEventEmbedContentChange?: Maybe<EmbedContentResult>;
  onEventEmbedContentDelete?: Maybe<EventEmbedContentDeleteResult>;
  onEventFloorContentChanged?: Maybe<ContentWithMapping>;
  onEventFloorContentDeleted?: Maybe<EventContentMapping>;
  pollChanged?: Maybe<EventPollResult>;
  pollDeleted?: Maybe<EventPollDeleteResult>;
};

export type SubscriptionOnEventContentChangedArgs = {
  event: Scalars["ObjectID"];
};

export type SubscriptionOnEventContentDeletedArgs = {
  event: Scalars["ObjectID"];
};

export type SubscriptionOnEventEmbedContentChangeArgs = {
  eventId: Scalars["ID"];
};

export type SubscriptionOnEventEmbedContentDeleteArgs = {
  eventId: Scalars["ID"];
};

export type SubscriptionOnEventFloorContentChangedArgs = {
  event: Scalars["ObjectID"];
  floor: Scalars["ObjectID"];
};

export type SubscriptionOnEventFloorContentDeletedArgs = {
  event: Scalars["ObjectID"];
  floor: Scalars["ObjectID"];
};

export type SubscriptionPollChangedArgs = {
  eventId: Scalars["ObjectID"];
  includeUserAnswers?: InputMaybe<Scalars["Boolean"]>;
};

export type SubscriptionPollDeletedArgs = {
  eventId: Scalars["ObjectID"];
  includeUserAnswers?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateEventContentMutationVariables = Exact<{
  companyId: Scalars["ObjectID"];
  name: Scalars["String"];
  type: EventContentType;
  mediaType: EventContentMediaType;
  media: Scalars["String"];
  ctaLink?: InputMaybe<Scalars["String"]>;
  ctaText?: InputMaybe<Scalars["String"]>;
  floorImage?: InputMaybe<Scalars["String"]>;
}>;

export type CreateEventContentMutation = {
  __typename?: "Mutation";
  createEventContent:
    | {
        __typename: "EventContent";
        id: string;
        company: string;
        name: string;
        type: EventContentType;
        mediaType: EventContentMediaType;
        media: string;
        ctaLink?: string;
        ctaText?: string;
        floorImage?: string;
      }
    | { __typename: "GQLError"; message: string };
};

export type UpdateEventContentMutationVariables = Exact<{
  id: Scalars["ObjectID"];
  companyId: Scalars["ObjectID"];
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EventContentType>;
  mediaType?: InputMaybe<EventContentMediaType>;
  media?: InputMaybe<Scalars["String"]>;
  ctaLink?: InputMaybe<Scalars["String"]>;
  ctaText?: InputMaybe<Scalars["String"]>;
  floorImage?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateEventContentMutation = {
  __typename?: "Mutation";
  updateEventContent:
    | {
        __typename: "EventContent";
        id: string;
        company: string;
        name: string;
        type: EventContentType;
        mediaType: EventContentMediaType;
        media: string;
        ctaLink?: string;
        ctaText?: string;
        floorImage?: string;
      }
    | { __typename: "GQLError"; message: string };
};

export type DeleteEventContentMutationVariables = Exact<{
  id: Scalars["ObjectID"];
  companyId: Scalars["ObjectID"];
}>;

export type DeleteEventContentMutation = {
  __typename?: "Mutation";
  deleteEventContent:
    | {
        __typename: "EventContent";
        id: string;
        company: string;
        name: string;
        type: EventContentType;
        mediaType: EventContentMediaType;
        media: string;
        ctaLink?: string;
        ctaText?: string;
        floorImage?: string;
      }
    | { __typename: "GQLError"; message: string };
};

export type PlaceContentOnSlotMutationVariables = Exact<{
  companyId: Scalars["ObjectID"];
  eventId: Scalars["ObjectID"];
  floorId: Scalars["ObjectID"];
  slot: Scalars["String"];
  eventContent: Scalars["ObjectID"];
}>;

export type PlaceContentOnSlotMutation = {
  __typename?: "Mutation";
  placeContentOnSlot:
    | {
        __typename: "ContentWithMapping";
        id: string;
        company: string;
        event: string;
        floor: string;
        slot: string;
        name: string;
        type: EventContentType;
        mediaType: EventContentMediaType;
        media: string;
        ctaLink?: string;
        ctaText?: string;
        floorImage?: string;
      }
    | { __typename: "GQLError"; message: string };
};

export type RemoveContentOnSlotMutationVariables = Exact<{
  companyId: Scalars["ObjectID"];
  eventId: Scalars["ObjectID"];
  floorId: Scalars["ObjectID"];
  slot: Scalars["String"];
}>;

export type RemoveContentOnSlotMutation = {
  __typename?: "Mutation";
  removeContentOnSlot:
    | {
        __typename: "EventContentMapping";
        event: string;
        floor: string;
        slot: string;
        eventContent: string;
      }
    | { __typename: "GQLError"; message: string };
};

export type GetEventContentQueryVariables = Exact<{
  event: Scalars["ObjectID"];
}>;

export type GetEventContentQuery = {
  __typename?: "Query";
  eventContent:
    | {
        __typename: "EventContentList";
        event: string;
        content: Array<{
          __typename?: "ContentWithMapping";
          company: string;
          event: string;
          floor: string;
          slot: string;
          ctaLink?: string;
          ctaText?: string;
          floorImage?: string;
          id: string;
          media: string;
          mediaType: EventContentMediaType;
          name: string;
          type: EventContentType;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type GetEventFloorContentQueryVariables = Exact<{
  event: Scalars["ObjectID"];
  floor: Scalars["ObjectID"];
}>;

export type GetEventFloorContentQuery = {
  __typename?: "Query";
  eventFloorContent:
    | {
        __typename: "EventFloorContentList";
        event: string;
        floor: string;
        content: Array<{
          __typename?: "ContentWithMapping";
          company: string;
          event: string;
          floor: string;
          slot: string;
          ctaLink?: string;
          ctaText?: string;
          floorImage?: string;
          id: string;
          media: string;
          mediaType: EventContentMediaType;
          name: string;
          type: EventContentType;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type OnEventContentChangedSubscriptionVariables = Exact<{
  event: Scalars["ObjectID"];
}>;

export type OnEventContentChangedSubscription = {
  __typename?: "Subscription";
  onEventContentChanged?: {
    __typename: "ContentWithMapping";
    id: string;
    event: string;
    floor: string;
    slot: string;
    company: string;
    name: string;
    ctaLink?: string;
    ctaText?: string;
    floorImage?: string;
    media: string;
    mediaType: EventContentMediaType;
    type: EventContentType;
  };
};

export type OnEventContentDeletedSubscriptionVariables = Exact<{
  event: Scalars["ObjectID"];
}>;

export type OnEventContentDeletedSubscription = {
  __typename?: "Subscription";
  onEventContentDeleted?: {
    __typename: "EventContentMapping";
    eventContent: string;
    floor: string;
    event: string;
    slot: string;
  };
};

export type OnEventFloorContentChangedSubscriptionVariables = Exact<{
  event: Scalars["ObjectID"];
  floor: Scalars["ObjectID"];
}>;

export type OnEventFloorContentChangedSubscription = {
  __typename?: "Subscription";
  onEventFloorContentChanged?: {
    __typename: "ContentWithMapping";
    id: string;
    event: string;
    floor: string;
    slot: string;
    company: string;
    name: string;
    ctaLink?: string;
    ctaText?: string;
    floorImage?: string;
    media: string;
    mediaType: EventContentMediaType;
    type: EventContentType;
  };
};

export type OnEventFloorContentDeletedSubscriptionVariables = Exact<{
  event: Scalars["ObjectID"];
  floor: Scalars["ObjectID"];
}>;

export type OnEventFloorContentDeletedSubscription = {
  __typename?: "Subscription";
  onEventFloorContentDeleted?: {
    __typename: "EventContentMapping";
    eventContent: string;
    floor: string;
    event: string;
    slot: string;
  };
};

export type CreateEmbedContentMutationVariables = Exact<{
  eventId: Scalars["ID"];
  url: Scalars["String"];
  mode: EventEmbedContentMode;
}>;

export type CreateEmbedContentMutation = {
  __typename?: "Mutation";
  createEmbedContent:
    | {
        __typename: "EventEmbedContent";
        id: number;
        url: string;
        eventId: string;
        createdAt: string;
        mode: EventEmbedContentMode;
      }
    | { __typename: "GQLError"; message: string };
};

export type DeleteEventEmbedContentMutationVariables = Exact<{
  id: Scalars["Int"];
  eventId: Scalars["String"];
}>;

export type DeleteEventEmbedContentMutation = {
  __typename?: "Mutation";
  deleteEventEmbedContent:
    | {
        __typename: "EventEmbedContent";
        id: number;
        url: string;
        eventId: string;
        createdAt: string;
        mode: EventEmbedContentMode;
      }
    | { __typename: "GQLError"; message: string };
};

export type GetEmbedContentQueryVariables = Exact<{
  eventId: Scalars["ID"];
  subscribedAt?: InputMaybe<Scalars["String"]>;
}>;

export type GetEmbedContentQuery = {
  __typename?: "Query";
  embedContent:
    | {
        __typename: "EventEmbedContentList";
        eventId: string;
        content: Array<{
          __typename?: "ContentData";
          createdAt: string;
          id: number;
          url: string;
          mode: EventEmbedContentMode;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type OnEmbedContentCreatedSubscriptionVariables = Exact<{
  eventId: Scalars["ID"];
}>;

export type OnEmbedContentCreatedSubscription = {
  __typename?: "Subscription";
  onEventEmbedContentChange?:
    | {
        __typename: "EventEmbedContent";
        id: number;
        createdAt: string;
        eventId: string;
        url: string;
        mode: EventEmbedContentMode;
      }
    | { __typename: "GQLError"; message: string };
};

export type OnEventEmbedContentDeletedSubscriptionVariables = Exact<{
  eventId: Scalars["ID"];
}>;

export type OnEventEmbedContentDeletedSubscription = {
  __typename?: "Subscription";
  onEventEmbedContentDelete?:
    | {
        __typename: "EventEmbedContent";
        id: number;
        createdAt: string;
        eventId: string;
        url: string;
        mode: EventEmbedContentMode;
      }
    | { __typename: "GQLError"; message: string };
};

export type GetEventRatingQueryVariables = Exact<{
  eventId: Scalars["ObjectID"];
}>;

export type GetEventRatingQuery = {
  __typename?: "Query";
  ratings?:
    | { __typename?: "EventRatingAverage"; rating?: number }
    | { __typename: "GQLError"; message: string };
};

export type SubmitRatingMutationVariables = Exact<{
  eventId: Scalars["ObjectID"];
  rating: Scalars["Float"];
}>;

export type SubmitRatingMutation = {
  __typename?: "Mutation";
  rateEvent?:
    | {
        __typename?: "EventRating";
        eventId?: string;
        userId?: string;
        rating?: number;
      }
    | { __typename: "GQLError"; message: string };
};

export type AnswerPollMutationVariables = Exact<{
  pollId: Scalars["Int"];
  optionId: Scalars["Int"];
}>;

export type AnswerPollMutation = {
  __typename?: "Mutation";
  answerPoll:
    | {
        __typename: "EventPoll";
        id: number;
        correctAnswers: Array<number>;
        createdBy?: string;
        createdAt: string;
        eventId: string;
        selectedAnswer?: number;
        status: EventPollStatus;
        title: string;
        timer: number;
        type: EventPollType;
        updatedAt: string;
        options: Array<{
          __typename?: "EventPollOption";
          option: string;
          id: number;
          votes: number;
        }>;
        userAnswers?: Array<{
          __typename?: "EventPollUserAnswer";
          pollId: number;
          userId: string;
          optionId: number;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type DeleteEventPollMutationVariables = Exact<{
  pollId: Scalars["Int"];
  eventId: Scalars["ObjectID"];
}>;

export type DeleteEventPollMutation = {
  __typename?: "Mutation";
  deleteEventPoll:
    | {
        __typename: "EventPollDelete";
        id: number;
        correctAnswers: Array<number>;
        createdBy?: string;
        createdAt: string;
        eventId: string;
        status: EventPollStatus;
        title: string;
        timer: number;
        type: EventPollType;
        updatedAt: string;
        options: Array<{
          __typename?: "EventPollOption";
          id: number;
          option: string;
          votes: number;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type CreateEventPollMutationVariables = Exact<{
  eventId: Scalars["ObjectID"];
  status: EventPollStatus;
  timer: Scalars["Int"];
  title: Scalars["String"];
  type: EventPollType;
  options?: InputMaybe<Array<EventPollOptionInput> | EventPollOptionInput>;
}>;

export type CreateEventPollMutation = {
  __typename?: "Mutation";
  createEventPoll:
    | {
        __typename: "EventPoll";
        id: number;
        correctAnswers: Array<number>;
        createdBy?: string;
        createdAt: string;
        eventId: string;
        selectedAnswer?: number;
        timer: number;
        status: EventPollStatus;
        title: string;
        type: EventPollType;
        updatedAt: string;
        options: Array<{
          __typename?: "EventPollOption";
          option: string;
          id: number;
          votes: number;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type UpdateEventPollMutationVariables = Exact<{
  id: Scalars["Int"];
  eventId: Scalars["ObjectID"];
  status?: InputMaybe<EventPollStatus>;
  timer?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EventPollType>;
  options?: InputMaybe<
    Array<EventPollOptionUpdateInput> | EventPollOptionUpdateInput
  >;
}>;

export type UpdateEventPollMutation = {
  __typename?: "Mutation";
  updateEventPoll:
    | {
        __typename: "EventPoll";
        id: number;
        correctAnswers: Array<number>;
        createdBy?: string;
        createdAt: string;
        eventId: string;
        selectedAnswer?: number;
        timer: number;
        status: EventPollStatus;
        title: string;
        type: EventPollType;
        updatedAt: string;
        options: Array<{
          __typename?: "EventPollOption";
          option: string;
          id: number;
          votes: number;
        }>;
        userAnswers?: Array<{
          __typename?: "EventPollUserAnswer";
          pollId: number;
          userId: string;
          optionId: number;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type ChangePollStatusMutationVariables = Exact<{
  pollId: Scalars["Int"];
  eventId: Scalars["ObjectID"];
  pollStatus: EventPollStatus;
}>;

export type ChangePollStatusMutation = {
  __typename?: "Mutation";
  updateEventPoll:
    | {
        __typename: "EventPoll";
        id: number;
        updatedAt: string;
        type: EventPollType;
        status: EventPollStatus;
        selectedAnswer?: number;
        eventId: string;
        createdBy?: string;
        createdAt: string;
        correctAnswers: Array<number>;
        title: string;
        timer: number;
        options: Array<{
          __typename?: "EventPollOption";
          option: string;
          id: number;
          votes: number;
        }>;
        userAnswers?: Array<{
          __typename?: "EventPollUserAnswer";
          pollId: number;
          userId: string;
          optionId: number;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type GetEventPollsQueryVariables = Exact<{
  eventId: Scalars["ObjectID"];
  includeUserAnswers?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetEventPollsQuery = {
  __typename?: "Query";
  eventPolls:
    | {
        __typename: "EventPolls";
        polls: Array<{
          __typename?: "EventPoll";
          correctAnswers: Array<number>;
          createdBy?: string;
          createdAt: string;
          eventId: string;
          id: number;
          selectedAnswer?: number;
          status: EventPollStatus;
          timer: number;
          title: string;
          updatedAt: string;
          type: EventPollType;
          options: Array<{
            __typename?: "EventPollOption";
            id: number;
            option: string;
            votes: number;
          }>;
          userAnswers?: Array<{
            __typename?: "EventPollUserAnswer";
            pollId: number;
            userId: string;
            optionId: number;
          }>;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type OnPollChangedSubscriptionVariables = Exact<{
  eventId: Scalars["ObjectID"];
}>;

export type OnPollChangedSubscription = {
  __typename?: "Subscription";
  pollChanged?:
    | {
        __typename: "EventPoll";
        id: number;
        correctAnswers: Array<number>;
        eventId: string;
        createdBy?: string;
        createdAt: string;
        updatedAt: string;
        status: EventPollStatus;
        timer: number;
        title: string;
        type: EventPollType;
        options: Array<{
          __typename?: "EventPollOption";
          id: number;
          option: string;
          votes: number;
        }>;
        userAnswers?: Array<{
          __typename?: "EventPollUserAnswer";
          pollId: number;
          userId: string;
          optionId: number;
        }>;
      }
    | { __typename: "GQLError"; message: string };
};

export type OnPollDeletedSubscriptionVariables = Exact<{
  eventId: Scalars["ObjectID"];
}>;

export type OnPollDeletedSubscription = {
  __typename?: "Subscription";
  pollDeleted?:
    | { __typename?: "EventPollDelete"; id: number }
    | { __typename: "GQLError"; message: string };
};
