import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    color: theme.palette.white,
  },
  header: {
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.present.black,
    color: "#fff",
    boxSizing: "border-box",
  },
  headerText: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  svg: {
    marginRight: theme.spacing(1),
  },
  headerCloseButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  headerCloseButton: {
    color: theme.palette.white,
    padding: 0,
  },
  messagesContainer: {
    height: "100%",
    padding: theme.spacing(4),
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "10px",
    },

    "&::-webkit-scrollbar-track": {
      background: "var(--White-op-030)",
      borderRadius: "5px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#45475e",
      borderRadius: "5px",
    },

    scrollbarColor: "#45475e var(--White-op-030)",
    display: "flex",
    flexDirection: "column-reverse",
  },
  withoutMessages: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default useStyles;
