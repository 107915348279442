import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import * as FilePond from "filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import logger from "logging/logger";
import { FilePondOptions } from "filepond";
import { UPLOAD_LEVEL } from "services/firebaseService/storagePaths";
import { upload } from "../firebaseService/files";

FilePond.registerPlugin(FilePondPluginFileValidateSize);
FilePond.registerPlugin(FilePondPluginFileValidateType);
FilePond.registerPlugin(FilePondPluginFileRename);

/**
 * Initialize service wherever needed
 * expose browse method
 *
 */

export enum FILE_UPLOAD_STATUS {
  START,
  RUNNING,
  PAUSED,
  END,
}

export type IAfterFileUpload<T> = (
  err: Error | null,
  origin?: T,
  url?: string,
  file?: File,
  fullPath?: string,
) => void;

export type UploadServiceOptions<T> = Partial<
  FilePondOptions & {
    path: string;
    level: UPLOAD_LEVEL;
    afterFileUpload: IAfterFileUpload<T>;
  }
>;

class UploadService<T> {
  uploader: FilePond.FilePond | undefined;

  origin: T | undefined;

  public status: FILE_UPLOAD_STATUS | undefined;

  private markedAsDestroyable: boolean;

  constructor(roolElement: string, options?: UploadServiceOptions<T>) {
    this.init(roolElement, options);
    this.browse = this.browse.bind(this);
    this.markedAsDestroyable = false;
  }

  public setMarkedAsDestroyable = () => {
    this.markedAsDestroyable = true;
    if (this.status === undefined || this.status === FILE_UPLOAD_STATUS.END) {
      this.destroy();
    }
  };

  public destroy = () => {
    this.origin = undefined;
    this.uploader?.destroy();
  };

  public browse(origin: T) {
    this.origin = origin;
    this.uploader?.browse();
  }

  public getStatus = () => this.status;

  public addFile = (file: Blob, origin: T) => {
    this.origin = origin;
    this.uploader?.addFile(file);
  };

  private init = (roolElement: string, options?: UploadServiceOptions<T>) => {
    const element = document.getElementById(roolElement);

    if (!element) {
      throw new Error(
        `Cannot initialize UploadService. Element not found with ID ${roolElement}`,
      );
    }

    // create a FilePond instance at the input element location
    this.uploader = FilePond.create(element, {
      ...options,
      allowPaste: false,
    });

    this.uploader.on("addfilestart", () => {
      this.status = FILE_UPLOAD_STATUS.START;
    });

    if (!this.uploader) {
      logger.error("[UploadService] not able to initialize uploader.");

      return;
    }

    const process: FilePond.ProcessServerConfigFunction = (
      _,
      file,
      metadata,
      load,
      error,
      progress,
      abort,
      // eslint-disable-next-line max-params
    ) => {
      const path = options?.path;
      const level = options?.level;

      if (!path || !level) {
        throw new Error("undefined path or level");
      }

      const formData = new FormData();

      formData.set("file", file, file.name);
      const uploadTask: firebase.storage.UploadTask | null = upload(
        formData,
        metadata,
        path,
        level,
        (err, response) => {
          const { origin } = this;

          if (!origin) {
            logger.error(
              `[UploadService] origin is undefined. Error: ${err?.message}`,
            );
          }

          if (err && err.message) {
            options?.afterFileUpload?.(
              err,
              origin,
              undefined,
              undefined,
              undefined,
            );

            error(err.message);
            this.status = FILE_UPLOAD_STATUS.END;
            logger.error(`[UploadService] upload error: ${err?.message}`);

            if (this.markedAsDestroyable) {
              this.destroy();
            }
          }

          if (!response) {
            return;
          }

          const { status } = response;

          if (status === firebase.storage.TaskState.RUNNING) {
            this.status = FILE_UPLOAD_STATUS.RUNNING;
            progress(true, response.progress[0], response.progress[1]);
          }

          if (status === firebase.storage.TaskState.PAUSED) {
            this.status = FILE_UPLOAD_STATUS.PAUSED;
            progress(true, response.progress[0], response.progress[1]);
          }

          if (status === firebase.storage.TaskState.SUCCESS) {
            progress(true, response.progress[0], response.progress[1]);
            const { url } = response;

            if (!url) {
              throw new Error("url not defined in response");
            }

            load(url);

            const blobToFile = new File([file], file.name, {
              type: file.type,
            });

            options?.afterFileUpload?.(
              null,
              origin,
              url,
              blobToFile,
              uploadTask?.snapshot?.ref?.fullPath,
            );

            // Update cache control header after uploading the image
            const getRef = firebase.storage().refFromURL(url);

            getRef
              .updateMetadata({ cacheControl: "public, max-age=315360000" })
              // eslint-disable-next-line promise/prefer-await-to-then
              .catch((e) => {
                logger.error(
                  `Error updating meta for uploaded file, ${e.message}`,
                );
              });
            // Remove the first file, since we will handle message display in remo code
            setTimeout(() => {
              this.uploader?.removeFile();
              if (this.markedAsDestroyable) {
                this.destroy();
              }
            }, 2000);
            this.status = FILE_UPLOAD_STATUS.END;
          }
        },
      );

      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          if (uploadTask) {
            uploadTask.cancel();
          }
          this.status = FILE_UPLOAD_STATUS.END;
          // Let FilePond know the request has been cancelled
          abort();
        },
      };
    };

    const serverConfig = {
      process,
    };

    this.uploader.setOptions({
      server: serverConfig,
      fileRenameFunction: options?.fileRenameFunction,
    });
  };
}

export default UploadService;
