import color from "color";
import { StyleRulesCallback } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import { ICardProps } from "./Card";

export const styles: StyleRulesCallback<Theme, ICardProps> = ({ palette }) => ({
  root: ({ border }) => ({
    backgroundColor: palette.background.default,
    border:
      border === false
        ? "unset"
        : `1px solid ${color(palette.background.default)
            .darken(0.15)
            .string()}`,
  }),
  primary: {
    backgroundColor: palette.white,
    border: `1px solid ${palette.gray.light}`,
  },
  elevation1: {
    boxShadow: "-2px 2px 30px #0000000D",
  },
  action: {
    position: "absolute",
    right: 0,
    top: 0,
  },
});
