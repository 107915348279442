import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  draggableItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    cursor: "grab",
    "&:hover $actions": {
      display: "flex",
    },
  },
  question: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "50px",
    borderRadius: 5,
    border: "1px solid #EAEEF2",
    background: "#FFF",
    gap: theme.spacing(1.25),
    padding: `${0}px ${theme.spacing(2)}px`,
  },
  questionText: {},
  dragIcon: {
    marginRight: theme.spacing(0.5),

    ":hover": {
      cursor: "move",
    },
  },
  isDragging: {
    opacity: "0.5",
  },
  content: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.25),
    flex: 1,
  },
  actions: {
    display: "none",
    gap: theme.spacing(0.5),
  },
}));
