import { useEffect, RefObject } from "react";

export const useStreams = ({
  audioRef,
  videoRef,
  audioTrack,
  videoTrack,
  audioState,
  videoState,
}: {
  audioRef?: RefObject<HTMLAudioElement>;
  videoRef: RefObject<HTMLVideoElement>;
  audioTrack?: false | MediaStreamTrack | undefined;
  videoTrack: false | MediaStreamTrack | undefined;
  audioState?: string;
  videoState: string;
}) => {
  useEffect(() => {
    const videoEl = videoRef.current;
    const audioEl = audioRef?.current;
    if (videoEl && videoTrack) {
      if (
        videoEl.srcObject instanceof MediaStream &&
        videoEl.srcObject?.getTracks()[0] !== videoTrack
      ) {
        videoRef.current.srcObject = new MediaStream([videoTrack]);
      } else if (!videoEl.srcObject) {
        videoRef.current.srcObject = new MediaStream([videoTrack]);
      }
    }
    if (audioTrack && audioEl) {
      const stream = new MediaStream([audioTrack]);
      audioRef.current.srcObject = stream;
    }
    return () => {
      if (audioEl && !audioTrack) {
        audioEl.srcObject = null;
      }
      if (videoEl && !videoTrack) {
        videoEl.srcObject = null;
      }
    };
  }, [audioRef, audioState, audioTrack, videoRef, videoState, videoTrack]);
};
