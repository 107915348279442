import logger from "logging/logger";
import { useMutation } from "@tanstack/react-query";
import { leaveFloorRequest } from "./request";

export const useRemoveUserFromFloor = () => {
  const { mutateAsync: removeUserFromFloor } = useMutation(
    ["remove.user.from.floor"],
    leaveFloorRequest,
    {
      onError(error) {
        const message = error instanceof Error ? error.message : "unknown";
        logger.error(`[removeUserFromFloor] ${message}`, {
          error,
        });
      },
    },
  );

  return { removeUserFromFloor };
};
