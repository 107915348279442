import { Theme } from "@material-ui/core/styles/createTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: () => ({
    fontFamily: theme.typography.fontFamily,
    cursor: "pointer",
    color: theme.palette.blue.darkShade1,
  }),
}));
