import useSystemClock from "modules/system/hooks/useSystemClock";
import { CHAT_TYPE } from "../../redux/constants";
import { genChannelId } from "../../chatUtils";
import useChatFirestore from "../../firestore/useChatFirestore";
import { useChatActions } from "../useChatActions";

const useSendMessage = () => {
  const { getCurrentTime } = useSystemClock();
  const { updateChatChannelMeta } = useChatFirestore();
  const { openChannel } = useChatActions();

  const startDirectMessage = (fromUserId: string, toUserId: string) => {
    const time = getCurrentTime();
    const channelId = genChannelId(toUserId, fromUserId);
    const users = <Record<string, {}>>{};

    users[toUserId] = { lastAccess: time };
    users[fromUserId] = { lastAccess: time };
    const channel = {
      id: channelId,
      name: "",
      type: CHAT_TYPE.USER,
      users,
    };

    updateChatChannelMeta(channel);
    openChannel(channel.id);
  };

  return {
    startDirectMessage,
  };
};

export default useSendMessage;
