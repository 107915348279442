import { useActiveSpeakerId, useDaily } from "@daily-co/daily-react";
import { useParticipantsAtTable } from "modules/audioVideo/hooks/useMeetingParticipants";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { selectCurrentRoomId } from "store/rooms";
import { setActiveSpeaker } from "../redux/slice";
import { selectActiveSpeaker } from "../redux/selectors";

export const ActiveSpeakerSubscriber = () => {
  const tableId = useSelector(selectCurrentRoomId);
  const participantsAtTable = useParticipantsAtTable({ tableId });
  const participantsAtTableRef = useRef(participantsAtTable);
  const dispatch = useAppDispatch();
  const isInBroadCast = useSelector(selectIsInBroadcast);
  const activeSpeaker = useSelector(selectActiveSpeaker);
  const dailySpeaker = useActiveSpeakerId();
  const daily = useDaily();

  useEffect(() => {
    participantsAtTableRef.current = participantsAtTable;
  }, [participantsAtTable]);

  const handleAudioLevel = ({
    participantsAudioLevel,
  }: {
    participantsAudioLevel: Record<string, number>;
  }) => {
    if (isInBroadCast) {
      if (activeSpeaker !== dailySpeaker) {
        dispatch(setActiveSpeaker(dailySpeaker));
      }
      return;
    }

    const participantsAudioAtTable = Object.keys(participantsAudioLevel).filter(
      (userSessionId) => participantsAtTableRef.current.includes(userSessionId),
    );

    participantsAudioAtTable.sort(
      (a, b) => participantsAudioLevel[b] - participantsAudioLevel[a],
    );

    const activeParticipant = participantsAudioAtTable[0];

    if (activeParticipant && activeSpeaker !== activeParticipant) {
      dispatch(setActiveSpeaker(activeParticipant));
    }
  };

  useEffect(() => {
    if (!daily) return;

    daily.on("remote-participants-audio-level", handleAudioLevel);

    // eslint-disable-next-line consistent-return
    return () => {
      daily.off("remote-participants-audio-level", handleAudioLevel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daily]);

  return null;
};
