import { ChangeEvent } from "react";
import { Input } from "@remo-co/ui-core/src/components/Input";
import { useI18n } from "i18n";
import {
  useEventTableSettings,
  LockedAttendeeInputPopover,
} from "modules/assignTables";
import { useEventContentSubscription } from "modules/eventContent/hooks/useEventContentSubscription";

interface Props {
  eventId?: string;
  isExpectedAttendanceDisabled: boolean;
  handleCapacity: (e: ChangeEvent<HTMLInputElement>) => void;
  validateChanges: () => void;
  capacity: {
    value: number;
  };
  maxCapacity: number;
  isUnlimitedEvent?: boolean;
}

const ExpectedAttendees = ({
  eventId,
  isExpectedAttendanceDisabled,
  handleCapacity,
  validateChanges,
  capacity,
  maxCapacity,
  isUnlimitedEvent,
}: Props): JSX.Element => {
  const { t } = useI18n(["eventForm"]);
  const { tableSettings } = useEventTableSettings(eventId);
  const { data } = useEventContentSubscription({ eventId });

  const { content: contents = [] } =
    data && data?.eventContent.__typename !== "GQLError"
      ? data.eventContent
      : {};
  const hasFloorContent = contents.length > 0;
  const hasAssignedTables = tableSettings.length > 0;
  const isInputDisabled =
    hasAssignedTables ||
    hasFloorContent ||
    isExpectedAttendanceDisabled ||
    isUnlimitedEvent;

  return (
    <Input
      name="eventCapacity"
      size="sm"
      id="et-attendance-input"
      data-testid="expected-attendee-input"
      label={t("eventForm:attendance.expected")}
      disabled={isInputDisabled}
      description={
        maxCapacity !== -1 && !isUnlimitedEvent
          ? t("eventForm:max.guest.validation", {
              key: `${maxCapacity}`,
            })
          : ""
      }
      value={capacity.value === 0 ? undefined : capacity.value}
      onChange={handleCapacity}
      onBlur={validateChanges}
      inputEndAdornment={
        <LockedAttendeeInputPopover
          hasAssignedTables={hasAssignedTables}
          hasFloorContent={hasFloorContent}
        />
      }
      inputProps={{ max: maxCapacity, type: "number", min: 1 }}
    />
  );
};

export default ExpectedAttendees;
