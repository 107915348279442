import { Errors, trackError } from "modules/monitoring";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useQuery } from "@tanstack/react-query";
import { useI18n } from "i18n";
import { getEventTableSettings } from "../../apis";
import { EventTableSetting, SettingUser, TableData } from "../../types";
import { EVENT_ASSIGN_TABLES_QUERY_KEY } from "../../constants";

export interface EventTableSettingsQuery {
  assignedAttendees: SettingUser[];
  tableSettings: EventTableSetting[];
  tableData: TableData;
}

type Status = "idle" | "error" | "loading" | "success";

export const defaultResults = {
  assignedAttendees: [],
  tableSettings: [],
  tableData: {},
};

const useEventTableSettings = (
  eventId?: string | null,
  presetId?: string,
): EventTableSettingsQuery & { status: Status } => {
  const { t } = useI18n(["manageEvent"]);
  const { addErrorNotification } = useNotificationActions();
  // accommodating "" as a potential value here given how the ManageEventContext initializes this
  const isEventIdMissing = !eventId || eventId === "";

  const { data, status } = useQuery(
    [EVENT_ASSIGN_TABLES_QUERY_KEY, eventId, presetId],
    async () => {
      if (isEventIdMissing) {
        return undefined;
      }

      const result = await getEventTableSettings(eventId, presetId);

      if (result.reason || result.message) {
        throw new Error(result.reason || result.message);
      }

      return result;
    },
    {
      placeholderData: defaultResults,
      enabled: !isEventIdMissing,
      onError: (error) => {
        if (error instanceof Error) {
          addErrorNotification({
            message: t("error.get.table.settings", {
              error: error.message,
            }),
            position: "tc",
          });

          trackError(error, {
            label: Errors.EVENT_TABLE_SETTINGS,
          });
        }
      },
    },
  );

  return {
    ...defaultResults,
    ...data,
    status,
  };
};

export default useEventTableSettings;
