import { useSelector } from "react-redux";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import {
  addTile as addTileMutation,
  addPrimaryTile as addPrimaryTileMutation,
  getActiveTiles as getActiveTilesQuery,
  clearLayout as clearLayoutMutation,
  removeTile as removeTileMutation,
  parseActiveTiles,
  addPrimaryTileTransaction,
} from "./dal";
import { LAYOUT_CACHE_KEY } from "./constants";
import { LayoutCollection, Tile } from "./types";

export const usePresentationLayout = () => {
  const theaterId = useSelector(selectCurrentTheaterId);
  const queryClient = useQueryClient();

  const { mutateAsync: addActiveTile } = useMutation(addTileMutation);
  const { mutateAsync: addPrimaryTile } = useMutation(addPrimaryTileMutation);
  const { mutateAsync: clearActiveLayout } = useMutation(clearLayoutMutation);
  const { mutateAsync: removeActiveTile } = useMutation(removeTileMutation);

  const { data: focusedTiles = [], status } = useQuery(
    [LAYOUT_CACHE_KEY, theaterId],
    async () => {
      if (!theaterId) {
        return [];
      }
      return getActiveTilesQuery({ theaterId });
    },
    { enabled: theaterId !== null },
  );

  const clearLocalLayout = useCallback(() => {
    queryClient.setQueryData([LAYOUT_CACHE_KEY, theaterId], () => []);
  }, [queryClient, theaterId]);

  const addLocalActiveTile = useCallback(
    (streamId: string) => {
      queryClient.setQueryData(
        [LAYOUT_CACHE_KEY, theaterId],
        (oldData: Tile[] | undefined = []) => {
          const uniqueTiles: LayoutCollection = Object.fromEntries(
            oldData.map(({ streamId, ...rest }) => [
              streamId,
              { streamId, ...rest },
            ]),
          );
          const allTiles = parseActiveTiles(
            addPrimaryTileTransaction(streamId, uniqueTiles),
          );

          return allTiles;
        },
      );
    },
    [queryClient, theaterId],
  );

  return {
    addActiveTile,
    removeActiveTile,
    clearActiveLayout,
    clearLocalLayout,
    addPrimaryTile,
    addLocalActiveTile,
    focusedTiles,
    status,
  };
};
