import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "../../../../zIndexes.module.scss";

export const useStyles = makeStyles((theme) => ({
  icon: {
    width: 16,
    height: 16,
    marginLeft: 6,
    color: theme.palette.blue.darkShade1,
    cursor: "pointer",
  },
  popper: {
    zIndex: zIndexes.modalZIndex,
  },
  container: {
    maxWidth: "370px",
    borderRadius: "5px",
    padding: 10,
  },
}));

export default useStyles;
