import React, { useContext } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import {
  getEventsUsingTemplate,
  softDeleteTemplate,
} from "modules/customFloorPlan/customFloorPlan.api";
import { CUSTOM_FLOOR_CONTEXT } from "modules/customFloorPlan/context/useCustomFloorPlan";
import { COLORS } from "modules/event";
import { useI18n } from "i18n";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import useCompany from "modules/company/hooks/useCompany";
import { IEvent } from "modules/event/types";
import { IMapTemplate } from "types/theater";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";

const CONFIRM_DELETE_DIALOG_TYPE = "cfp-confirm-delete-dialog";

interface Props {
  id?: string;
  template: IMapTemplate;
  setLayoutLoading: () => void;
}

const LayoutDelete = ({
  id,
  template,
  setLayoutLoading,
}: Props): JSX.Element => {
  const { t } = useI18n([
    "manageEvent",
    "settings",
    "common",
    "customFloorPlan",
  ]);
  const dialog = useDialogNotificationActions();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { company } = useCompany();
  const { getTemplates, state } = useContext(CUSTOM_FLOOR_CONTEXT);
  const { getTemplates: getTemplatesFromEvent } =
    useContext(MANAGE_EVENT_CONTEXT);

  const getDeleteNotificationContent = (events: IEvent[]) => {
    const noOfEvents = events.length;

    if (noOfEvents > 0) {
      return (
        <div className="ct-notif">
          <Typography variant="h4" className="header">
            {t("manageEvent:warning.delete.floorplan.events", {
              noOfEvents,
            })}
          </Typography>
          <span className="event-list">
            <ul>
              {events.map(
                (event: IEvent, index: number) =>
                  index <= 3 && <li>{event.name}</li>,
              )}
            </ul>
            {noOfEvents > 4 && (
              <Typography variant="subtitle1">
                {t(`and in ${noOfEvents - 4} more`)}
              </Typography>
            )}
          </span>
          <Typography variant="h4" className="info">
            <Info
              fontSize="small"
              style={{ color: "#DFAB38", marginRight: "0.75rem" }}
            />
            {t("customFloorPlan:default.floor.plan.after.delete")}
          </Typography>
        </div>
      );
    }

    return (
      <div>
        <Typography variant="h4">
          {t("settings:warning.delete.floorplan")}
        </Typography>
      </div>
    );
  };

  const onDelete = async () => {
    if (!company) {
      return;
    }
    setLayoutLoading();
    dialog.updateDialogParams(CONFIRM_DELETE_DIALOG_TYPE, {
      dismissDisabled: true,
      confirmLoading: true,
    });

    const resp = await softDeleteTemplate(company.id, template.code);

    if (resp) {
      if (state) {
        await getTemplates();
      } else {
        await getTemplatesFromEvent();
      }
      addSuccessNotification({
        message: t("settings:custom.floor.plan.delete.success", {
          templateName: template.name,
        }),
      });
    } else {
      addErrorNotification({
        message: t("settings:custom.floor.plan.delete.failed", {
          templateName: template.name,
        }),
      });
    }
  };

  const onDeleteLayout = async (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (!company) {
      return;
    }
    const resp = await getEventsUsingTemplate(company.id, template.code);
    const { events } = resp;

    if (resp.isSuccess) {
      dialog.openDialog({
        hideCloseButton: true,
        message: "",
        content: getDeleteNotificationContent(events),
        type: CONFIRM_DELETE_DIALOG_TYPE,
        confirmColor: COLORS.RED,
        confirmText: t("common:button.delete"),
        dismissText: t("common:button.cancel"),
        onConfirm: async () => {
          await onDelete();
        },
      });
    }
  };

  return (
    <Tooltip title={`${t("common:tooltip.delete.floor.plan")}`} placement="top">
      <Button
        onClick={onDeleteLayout}
        id={`${id}-delete-button`}
        className="fp-delete-button"
        data-testid="layout-delete-button"
        isIconButton
        color="gray"
        variant="ghost"
      >
        <Delete color="error" />
      </Button>
    </Tooltip>
  );
};

export default LayoutDelete;
