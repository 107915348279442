import { database, serverValue } from "services/firebaseService/firebaseConfig";
import logger from "logging/logger";
import { FirebaseDBReference } from "../../types/firebase";

const getRoomWhiteboardDocRef = (roomId: string): FirebaseDBReference =>
  database.ref(`Room/${roomId}/Whiteboard`);

const openWhiteboardFirebase = (
  roomId: string,
  data: { [key: string]: unknown },
): void => {
  logger.info(
    `[openWhiteboard] params: ${JSON.stringify({ roomId, ...data })}`,
  );

  getRoomWhiteboardDocRef(roomId).set({
    ...data,
    startedAt: serverValue.TIMESTAMP,
  });
};

export default {
  getRoomWhiteboardDocRef,
  openWhiteboardFirebase,
};
