import React, { useCallback } from "react";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { CloudDownload } from "@remo-co/ui-core/src/icons/CloudDownload";
import { useI18n } from "i18n";
import { useStyles } from "./ChatModalImageHeader.styles";

const ChatModalImageHeader = (props: { url: string }) => {
  const { t } = useI18n();
  const { url } = props;

  const classes = useStyles();

  const downloadImage = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (url) {
        window.open(url, "_blank", "noopener,noreferrer");
      }
    },
    [url],
  );

  return (
    <IconButton
      aria-label={t("tooltip.download.title")}
      onClick={downloadImage}
      className={classes.downloadButton}
      data-testid="chat-modal-image-header"
    >
      <CloudDownload className={classes.downloadIcon} />
    </IconButton>
  );
};

export default ChatModalImageHeader;
