import { Theme } from "@material-ui/core/styles/createTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Colors } from "@remo-co/ui-core/theme";

export const useStyles = makeStyles<Theme, { fullScreen: boolean }>(() => ({
  root: {
    background: ({ fullScreen }) =>
      fullScreen ? "rgba(0, 0, 0, 0.25)" : Colors.ORANGE,
    width: "100%",
    height: "100%",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    top: 0,
    left: 0,
    zIndex: 9999999, // matches $pageLoadingZIndex from client/src/zIndexes.scss
  },
}));
