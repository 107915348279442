import { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { VERSION_CHECK_INTERVAL } from "../../constants";
import { fetchDeployedVersion } from "../../actions";

export const useWatchDeployedAppVersion = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeout: number | undefined;

    const watchDeployedVersion = () => {
      dispatch(fetchDeployedVersion());

      timeout = window.setTimeout(watchDeployedVersion, VERSION_CHECK_INTERVAL);
    };

    watchDeployedVersion();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [dispatch]);
};
