import { useI18n } from "i18n";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";

type Confirm = (onConfirm: () => void, onCancel?: () => void) => void;

const useConfirmationDialog = ({
  message,
  confirmLabel,
  cancelLabel,
}: {
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
}): Confirm => {
  const { t } = useI18n();
  const { openDialog } = useDialogNotificationActions();

  return (onConfirm, onCancel) =>
    openDialog({
      message,
      hideCloseButton: true,
      onConfirm,
      onDismiss: onCancel,
      confirmText: confirmLabel || t("confirm.dialog.leave"),
      dismissText: cancelLabel || t("confirm.dialog.stay"),
    });
};

export default useConfirmationDialog;
