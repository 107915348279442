import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { database } from "services/firebaseService/firebaseConfig";

const refMap = new Map<string, firebase.database.Reference>();

export const generateDocRef =
  <T extends unknown[]>(getRefHash: (...args: T) => string) =>
  (...args: T) => {
    const refHash = getRefHash(...args);

    let dbRef = refMap.get(refHash);

    if (!dbRef) {
      dbRef = database.ref(refHash);
      refMap.set(refHash, dbRef);
    }

    return dbRef;
  };
