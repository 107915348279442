import createImage from "./createImage";

const loadImage = (imageUrl: string): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const image = createImage();

    image.onload = () => {
      resolve();
    };

    image.onerror = (error) => {
      reject(error);
    };

    image.src = imageUrl;
  });

export default loadImage;
