import React, { useEffect } from "react";
import {
  TranscriptionContext,
  initSymblSdk,
  useTranscriptionSubscriber,
} from "modules/transcription";
import { useSelector } from "react-redux";
import {
  selectShouldProcessCaptions,
  selectShowCaptions,
  selectShowLiveCaptions,
} from "modules/transcription/redux/selectors";
import {
  clearProcessor,
  clearWebSocket,
  reducer,
  registerProcessorHandlers,
} from "modules/transcription/components/TranscriptionContext/context/";
import { initialState } from "modules/transcription/components/TranscriptionContext/TranscriptionContext";
import { selectIsMeBroadcaster } from "modules/broadcaster";
import {
  selectIsEventManager,
  selectIsEventSpeaker,
} from "modules/event/selectors";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { setIsLoading } from "modules/transcription/redux";
import { useAppDispatch } from "store/hooks";
import { ATTENDEE_SUBSCRIPTION_DELAY_TIMEOUT } from "modules/transcription/constants";
import { usePrevious } from "helpers/reactHooksUtils";
import { useTranscriptionRequests } from "../../hooks/useTranscriptionRequests";

export const TranscriptionProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const appDispatch = useAppDispatch();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { subscribeToWebSocket } = useTranscriptionSubscriber(dispatch);
  const {
    subscribe: subscribeToRequests,
    unsubscribe: unsubscribeFromRequests,
  } = useTranscriptionRequests();
  const showCaptions = useSelector(selectShowCaptions);
  const showLiveCaptions = useSelector(selectShowLiveCaptions);
  const shouldProcessCaptions = useSelector(selectShouldProcessCaptions);
  const isInBroadcast = useSelector(selectIsInBroadcast);

  const isMeBroadcaster = useSelector(selectIsMeBroadcaster);
  const isEventSpeaker = useSelector(selectIsEventSpeaker);
  const isEventManager = useSelector(selectIsEventManager);
  const isSpeaker = isMeBroadcaster || isEventSpeaker || isEventManager;

  const [symblSdkInitialized, setSymblSdkInitialized] = React.useState(false);

  const areCaptionsEnabled = showCaptions || showLiveCaptions;
  const prevAreCaptionsEnabled = usePrevious(areCaptionsEnabled);

  // initializes symbl sdk
  useEffect(() => {
    if (!symblSdkInitialized && isInBroadcast) {
      initSymblSdk();
      setSymblSdkInitialized(true);
    }
  }, [symblSdkInitialized, isInBroadcast]);

  useEffect(() => {
    if (!isInBroadcast) {
      dispatch(clearProcessor());
      dispatch(clearWebSocket());
    }
  }, [isInBroadcast]);

  useEffect(() => {
    if (!isInBroadcast) {
      dispatch(clearProcessor());
      dispatch(clearWebSocket());
      return;
    }

    if (state.processor) {
      const ws = subscribeToWebSocket(state.sampleRate);
      if (!ws) {
        return;
      }
      registerProcessorHandlers(state.processor, ws);
    } else if (shouldProcessCaptions) {
      subscribeToWebSocket(state.sampleRate);
    } else if (areCaptionsEnabled) {
      if (areCaptionsEnabled && !prevAreCaptionsEnabled) {
        // delayed start - wait for hosts to start publishing captions
        appDispatch(setIsLoading(true));
        const timeout = setTimeout(() => {
          subscribeToWebSocket(state.sampleRate);
          appDispatch(setIsLoading(false));
        }, ATTENDEE_SUBSCRIPTION_DELAY_TIMEOUT);

        // eslint-disable-next-line consistent-return
        return () => {
          clearTimeout(timeout);
          appDispatch(setIsLoading(false));
        };
      }
    } else {
      dispatch(clearProcessor());
      dispatch(clearWebSocket());
    }
  }, [
    appDispatch,
    state.sampleRate,
    shouldProcessCaptions,
    state.processor,
    subscribeToWebSocket,
    isSpeaker,
    isInBroadcast,
    areCaptionsEnabled,
    prevAreCaptionsEnabled,
  ]);

  useEffect(() => {
    if (isInBroadcast && isSpeaker) {
      subscribeToRequests();

      return unsubscribeFromRequests;
    }

    unsubscribeFromRequests();

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInBroadcast, isSpeaker]);

  const contextValue = React.useMemo(
    () => ({
      ...state,
      dispatch,
    }),
    [state, dispatch],
  );

  return (
    <TranscriptionContext.Provider value={contextValue}>
      {children}
    </TranscriptionContext.Provider>
  );
};
