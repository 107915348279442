import React, { ComponentProps } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useSelector } from "react-redux";
import { HelpChatIcon } from "modules/eventButtons/icons/HelpChatIcon";
import { useI18n } from "i18n";
import classNames from "classnames";
import { selectIsChatSupportEnabled } from "modules/event/selectors";
import {
  selectDisableFreshchatWidget,
  selectEnableAgencyWhiteLabelling,
} from "modules/settings/redux/selectors";
import { HELP_CHAT_ELEMENT_ID } from "../../constants";
import { HelpChatWrapper } from "..";
import { useNeedHelp } from "../../hooks";
import { useStyles } from "../../styles";

interface IHelpChatButtonProps extends ComponentProps<typeof Button> {
  darkMode?: boolean;
  tooltip?: boolean;
  disableHover?: boolean;
  menuItem?: boolean;
  children?: React.ReactNode;
}

interface IHelpChatButtonDisplayComponent extends IHelpChatButtonProps {
  callApi: (arg: string) => void;
}

export const HelpChatDisplayComponent = (
  props: IHelpChatButtonDisplayComponent,
) => {
  const styles = useStyles();
  const {
    darkMode = true,
    children = null,
    tooltip = true,
    disableHover = false,
    menuItem = false,
    disabled,
    callApi,
    className,
    ...rest
  } = props;
  const { t } = useI18n();

  // eslint-disable-next-line react/no-unstable-nested-components
  const HelpChatButtonComponent = () => (
    <Button
      className={classNames(className, {
        [styles.disableHover]: disableHover,
        [styles.menuButton]: menuItem,
        [styles.disabled]: disabled,
      })}
      contentClassName={styles.content}
      onClick={() => callApi("showNewMessage")}
      id={HELP_CHAT_ELEMENT_ID}
      aria-label="Contact us"
      data-cy="contact-btn"
      data-testid="help-chat-button"
      startAdornment={menuItem ? <HelpChatIcon darkMode={darkMode} /> : null}
      disabled={disabled}
      {...rest}
    >
      {menuItem ? null : (
        <HelpChatIcon className="support-img" darkMode={darkMode} />
      )}
      {children}
    </Button>
  );

  const needHelpText = t("need.help");

  return (
    <div className="broadcast-btn">
      {tooltip ? (
        <Tooltip title={needHelpText}>
          <HelpChatButtonComponent />
        </Tooltip>
      ) : (
        <HelpChatButtonComponent />
      )}
    </div>
  );
};

const HelpChatButton = (props: IHelpChatButtonProps) => {
  const { callApi } = useNeedHelp();
  const isSupportEnabled = useSelector(selectIsChatSupportEnabled);
  const disableFreshchatWidget = useSelector(selectDisableFreshchatWidget);
  const enableAgencyWhiteLabelling = useSelector(
    selectEnableAgencyWhiteLabelling,
  );

  if (
    !isSupportEnabled ||
    (enableAgencyWhiteLabelling && disableFreshchatWidget)
  ) {
    return null;
  }

  return (
    <HelpChatWrapper>
      <HelpChatDisplayComponent callApi={callApi} {...props} />
    </HelpChatWrapper>
  );
};

export default HelpChatButton;
