import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { FormHelperText } from "@remo-co/ui-core/src/components/FormHelperText";
import { Grid } from "@remo-co/ui-core/src/components/Grid";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import classNames from "classnames";
import { getMapTemplateByType } from "modules/event/template";
import useFloorsUtils from "modules/manageEvent/hooks/useFloorsUtils";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { ITheater } from "types/theater";
import { useStyles } from "./styles";
import { ExpectedAttendees } from "../ExpectedAttendees";

interface Props {
  eventId?: string;
  theaterData: ITheater;
  onChange: (theater: ITheater) => void;
  isExpectedAttendanceDisabled: boolean;
  handleFloorCount: (floorCount: number) => void;
  isUnlimitedEvent?: boolean;
}

export const EventFloorSettings = ({
  eventId,
  theaterData,
  onChange,
  isExpectedAttendanceDisabled,
  handleFloorCount,
  isUnlimitedEvent,
}: Props): JSX.Element => {
  const { getMaxTheaterCapacity, unlimitedEventsSettings } =
    useCompanyPlanSettings();
  const { t } = useI18n(["common", "eventForm"]);
  const maxCapacity = getMaxTheaterCapacity();
  const styles = useStyles();
  const [capacity, setCapacity] = useState({
    error: false,
    value: isUnlimitedEvent
      ? unlimitedEventsSettings?.maxCapacity || 25
      : theaterData.capacity || 50,
    message: "",
  });

  const evTemplate = getMapTemplateByType(theaterData.template);

  const updateCapacity = (value: string | number) => {
    const newCapacity = Number(value);

    if (Number.isNaN(value) || newCapacity > maxCapacity || newCapacity < 1) {
      setCapacity({
        error: true,
        message: `Please provide value between 1 to ${maxCapacity}`,
        value: newCapacity,
      });
    } else {
      setCapacity({ error: false, message: "", value: newCapacity });
    }
  };

  const handleCapacity = (e: ChangeEvent<HTMLInputElement>) => {
    updateCapacity(e.target.value);
  };

  const validateChanges = useCallback(() => {
    updateCapacity(capacity.value);

    onChange({
      ...theaterData,
      capacity: capacity.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacity.value]);

  useEffect(() => {
    validateChanges();
  }, [validateChanges]);

  const { noOfFloors } = useFloorsUtils(capacity.value, evTemplate);

  useEffect(() => {
    handleFloorCount(noOfFloors);
  }, [handleFloorCount, noOfFloors]);

  return (
    <Grid container className="mar-top-40" data-testid="ev-theater-info">
      <Grid item xs={6} className={styles.container}>
        <ExpectedAttendees
          eventId={eventId}
          capacity={capacity}
          handleCapacity={handleCapacity}
          validateChanges={validateChanges}
          isExpectedAttendanceDisabled={isExpectedAttendanceDisabled}
          maxCapacity={maxCapacity}
          isUnlimitedEvent={isUnlimitedEvent}
        />
      </Grid>
      {noOfFloors > 0 && (
        <Grid item xs={6} className={styles.container}>
          <Typography variant="h5">{t("eventForm:floors.use")}</Typography>
          <div
            className={classNames(styles.floors, {
              [styles.error]: noOfFloors > 10,
            })}
          >
            <Typography color="inherit" data-testid="no-of-floors">
              {noOfFloors}
            </Typography>
          </div>
          {noOfFloors > 10 && (
            <Typography
              component="p"
              className="MuiFormHelperText-root Mui-error"
              data-testid="nof-error"
            >
              {t("eventForm:maximum.floors.allowed")}
            </Typography>
          )}
        </Grid>
      )}
      {isExpectedAttendanceDisabled && (
        <FormHelperText>
          {t("eventForm:change.number.people.denied")}
        </FormHelperText>
      )}
      {isUnlimitedEvent && (
        <FormHelperText>{t("event:remo.expected.participants")}</FormHelperText>
      )}
    </Grid>
  );
};
