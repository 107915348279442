import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useI18n } from "i18n";
import { Errors, trackError } from "modules/monitoring";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useSelector } from "react-redux";
import { selectUserId } from "modules/auth/redux/selectors";
import {
  reorderQuestionsRequest,
  createQuestionRequest,
  updateQuestionRequest,
  deleteQuestionRequest,
} from "./request";
import { ICustomRegistrationQuestion, IQuestionOrder } from "../../types";
import {
  CUSTOM_QUESTIONS_QUERY_KEY,
  CustomRegistrationQuestionsErrors,
  QuestionType,
} from "../../constants";

interface Options {
  onCreateSuccess: (type: QuestionType) => void;
  onUpdateSuccess: () => void;
  onDeleteSuccess: () => void;
}

export const useCustomQuestionOperations = (
  eventId: string | undefined,
  options: Options,
) => {
  const userId = useSelector(selectUserId);
  const { onCreateSuccess, onUpdateSuccess, onDeleteSuccess } = options;
  const { addErrorNotification } = useNotificationActions();
  const { t } = useI18n(["eventForm"]);
  const queryClient = useQueryClient();

  const {
    mutateAsync: reorderQuestions,
    mutate: reorderQuestionsSync,
    isLoading: isReorderLoading,
    status: reorderStatus,
  } = useMutation(
    async (reorderedQuestionIds: IQuestionOrder[]) => {
      if (!eventId) {
        throw new Error(CustomRegistrationQuestionsErrors.NO_EVENT_ID);
      }

      return reorderQuestionsRequest(eventId, reorderedQuestionIds);
    },
    {
      onSuccess: (questions) => {
        queryClient.setQueryData(
          [CUSTOM_QUESTIONS_QUERY_KEY, eventId, userId],
          questions,
        );
      },
      onError: (error: Error, _) => {
        addErrorNotification({
          message: t("custom.registration.question.save.error", {
            error: error.message,
          }),
          position: "tc",
        });
        trackError(error, {
          label: Errors.CUSTOM_REGISTRATION_QUESTIONS_REORDER,
        });
      },
    },
  );

  const {
    mutateAsync: createQuestion,
    mutate: createQuestionSync,
    isLoading: isCreateLoading,
    status: createStatus,
  } = useMutation(
    async (question: Partial<ICustomRegistrationQuestion>) => {
      if (!eventId) {
        throw new Error(CustomRegistrationQuestionsErrors.NO_EVENT_ID);
      }

      return createQuestionRequest(eventId, question);
    },
    {
      onSuccess: (questions, addedQuestion) => {
        queryClient.setQueryData(
          [CUSTOM_QUESTIONS_QUERY_KEY, eventId, userId],
          questions,
        );

        onCreateSuccess(addedQuestion.type as QuestionType);
      },
      onError: (error: Error, _) => {
        addErrorNotification({
          message: t("custom.registration.question.save.error", {
            error: error.message,
          }),
          position: "tc",
        });
        trackError(error, {
          label: Errors.CUSTOM_REGISTRATION_QUESTIONS_CREATE,
        });
      },
    },
  );

  const {
    mutateAsync: updateQuestion,
    mutate: updateQuestionSync,
    isLoading: isUpdateLoading,
    status: updateStatus,
  } = useMutation(
    async (question: ICustomRegistrationQuestion & { _id: string }) => {
      if (!eventId) {
        throw new Error(CustomRegistrationQuestionsErrors.NO_EVENT_ID);
      }
      if (!question._id) {
        throw new Error(CustomRegistrationQuestionsErrors.NO_QUESTION_ID);
      }

      return updateQuestionRequest(eventId, question._id, question);
    },
    {
      onSuccess: (questions) => {
        queryClient.setQueryData(
          [CUSTOM_QUESTIONS_QUERY_KEY, eventId, userId],
          questions,
        );

        onUpdateSuccess();
      },
      onError: (error: Error, _) => {
        addErrorNotification({
          message: t("custom.registration.question.save.error", {
            error: error.message,
          }),
          position: "tc",
        });
        trackError(error, {
          label: Errors.CUSTOM_REGISTRATION_QUESTIONS_UPDATE,
        });
      },
    },
  );

  const {
    mutateAsync: deleteQuestion,
    mutate: deleteQuestionSync,
    isLoading: isDeleteLoading,
    status: deleteStatus,
  } = useMutation(
    async (id: string) => {
      if (!eventId) {
        throw new Error(CustomRegistrationQuestionsErrors.NO_EVENT_ID);
      }
      if (!id) {
        throw new Error(CustomRegistrationQuestionsErrors.NO_QUESTION_ID);
      }

      return deleteQuestionRequest(eventId, id);
    },
    {
      onSuccess: (questions) => {
        queryClient.setQueryData(
          [CUSTOM_QUESTIONS_QUERY_KEY, eventId, userId],
          questions,
        );

        onDeleteSuccess();
      },
      onError: (error: Error, _) => {
        addErrorNotification({
          message: t("custom.registration.question.delete.error", {
            error: error.message,
          }),
          position: "tc",
        });
        trackError(error, {
          label: Errors.CUSTOM_REGISTRATION_QUESTIONS_DELETE,
        });
      },
    },
  );

  const getStatus = () => {
    if (
      reorderStatus === "idle" &&
      createStatus === "idle" &&
      updateStatus === "idle" &&
      deleteStatus === "idle"
    ) {
      return "idle";
    }

    if (
      reorderStatus === "loading" ||
      createStatus === "loading" ||
      updateStatus === "loading" ||
      deleteStatus === "loading"
    ) {
      return "loading";
    }

    if (
      reorderStatus === "error" ||
      createStatus === "error" ||
      updateStatus === "error" ||
      deleteStatus === "error"
    ) {
      return "error";
    }

    if (
      reorderStatus === "success" ||
      createStatus === "success" ||
      updateStatus === "success" ||
      deleteStatus === "success"
    ) {
      return "success";
    }

    return "idle";
  };

  return {
    isLoading:
      isReorderLoading || isCreateLoading || isUpdateLoading || isDeleteLoading,
    status: getStatus(),
    reorderQuestions,
    createQuestion,
    updateQuestion,
    deleteQuestion,
    reorderQuestionsSync,
    createQuestionSync,
    updateQuestionSync,
    deleteQuestionSync,
  };
};
