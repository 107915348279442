import { Badge } from "@remo-co/ui-core/src/components/Badge";
import { ActionButton } from "modules/actionButton";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { TABS, selectRightPanelState, setPanelState } from "modules/rightPanel";
import { updateActiveChannel } from "modules/chat/redux/actions";
import { ReactComponent as ParticipantsIcon } from "./icons/participants.svg";

interface Props {
  isSmallButton?: boolean;
  darkMode?: boolean;
}

const ParticipantsButton = ({
  isSmallButton,
  darkMode,
}: Props): JSX.Element => {
  const { t } = useI18n(["event"]);
  const dispatch = useAppDispatch();
  const { isOpen, tabId } = useSelector(selectRightPanelState);

  const handleClick = () => {
    dispatch(
      setPanelState({
        isOpen: !isOpen || (isOpen && tabId !== TABS.PARTICIPANTS),
        tabId: TABS.PARTICIPANTS,
      }),
    );
    if (isOpen) {
      dispatch(updateActiveChannel(null));
    }
  };

  const isButtonActive = isOpen && tabId === TABS.PARTICIPANTS;

  const getDDActionName = () => {
    if (isOpen && tabId === TABS.CHAT) {
      return "Participants Button - Close right panel";
    }
    if (isOpen && tabId !== TABS.CHAT) {
      return "Participants Button - Switch to Participants tab";
    }
    return "Participants Button - Open right panel";
  };

  return (
    <ActionButton
      data-testid="participants-button"
      title={t("event:participants.view")}
      data-dd-action-name={getDDActionName()}
      onClick={handleClick}
      label={t("event:participants")}
      isActive={isButtonActive}
      isPopoverButton={isSmallButton}
      darkMode={darkMode}
      icon={
        <Badge badgeContent={0} color="error">
          <ParticipantsIcon />
        </Badge>
      }
    />
  );
};

export default ParticipantsButton;
