import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { createLockTablesRef } from "modules/lockTables/firebase";
import { LOCK_TABLE_QUERY_KEY } from "../constants";

interface Props {
  eventId?: string;
  floorId: string | null;
}

export const useSubscribeToLockTables = ({ eventId, floorId }: Props) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!eventId || !floorId) {
      return undefined;
    }
    const lockTablesRef = createLockTablesRef(eventId, floorId);
    lockTablesRef.on("value", () => {
      queryClient.invalidateQueries([LOCK_TABLE_QUERY_KEY, eventId, floorId]);
    });

    return () => {
      if (lockTablesRef) {
        lockTablesRef.off();
      }
    };
  }, [eventId, floorId, queryClient]);
};
