import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useI18n } from "i18n";
import { Errors, trackError } from "modules/monitoring";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useContext } from "react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { createPresetRequest, deletePresetRequest } from "./request";
import {
  EVENT_ASSIGN_TABLE_PRESETS_QUERY_KEY,
  AssignTableErrors,
} from "../../constants";

interface Options {
  onCreateSuccess: (preset: string) => void;
  onDeleteSuccess?: () => void;
}

export const useEventTableSettingPresetOperations = (
  eventId: string | undefined,
  options: Options,
) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const { onCreateSuccess, onDeleteSuccess } = options;
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { t } = useI18n(["manageEvent"]);
  const queryClient = useQueryClient();

  const {
    mutateAsync: createPreset,
    mutate: createPresetSync,
    isLoading: isCreateLoading,
    status: createStatus,
  } = useMutation(
    async () => {
      if (!eventId) {
        throw new Error(AssignTableErrors.NO_EVENT_ID);
      }

      return createPresetRequest(eventId);
    },
    {
      onSuccess: (newPresetId) => {
        queryClient.invalidateQueries({
          queryKey: [EVENT_ASSIGN_TABLE_PRESETS_QUERY_KEY, eventId],
        });

        addSuccessNotification({
          message: t("preset.created.successfully"),
          position: "tc",
        });

        track(Events.ASSIGN_TABLE_PRESET_CREATED);

        onCreateSuccess(newPresetId);
      },
      onError: (error: Error, _) => {
        addErrorNotification({
          message: t("preset.created.error"),
          position: "tc",
        });
        trackError(error, {
          label: Errors.EVENT_TABLE_SETTING_PRESETS_CREATE,
        });
      },
    },
  );

  const {
    mutateAsync: deletePreset,
    mutate: deletePresetSync,
    isLoading: isDeleteLoading,
    status: deleteStatus,
  } = useMutation(
    async (presetId: string) => {
      if (!eventId) {
        throw new Error(AssignTableErrors.NO_EVENT_ID);
      }
      if (!presetId) {
        throw new Error(AssignTableErrors.NO_PRESET_ID);
      }

      return deletePresetRequest(eventId, presetId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [EVENT_ASSIGN_TABLE_PRESETS_QUERY_KEY, eventId],
        });

        addSuccessNotification({
          message: t("preset.deleted.successfully"),
          position: "tc",
        });

        track(Events.ASSIGN_TABLE_PRESET_DELETED);

        if (onDeleteSuccess) {
          onDeleteSuccess();
        }
      },
      onError: (error: Error, _) => {
        addErrorNotification({
          message: t("preset.deleted.error"),
          position: "tc",
        });
        trackError(error, {
          label: Errors.EVENT_TABLE_SETTING_PRESETS_DELETE,
        });
      },
    },
  );

  const getStatus = () => {
    if (createStatus === "idle" && deleteStatus === "idle") {
      return "idle";
    }

    if (createStatus === "loading" || deleteStatus === "loading") {
      return "loading";
    }

    if (createStatus === "error" || deleteStatus === "error") {
      return "error";
    }

    if (createStatus === "success" || deleteStatus === "success") {
      return "success";
    }

    return "idle";
  };

  return {
    isLoading: isCreateLoading || isDeleteLoading,
    status: getStatus(),
    createPreset,
    deletePreset,
    createPresetSync,
    deletePresetSync,
  };
};
