import { useEnforceTableAssignment } from "modules/enforceTableAssignment";
import { useI18n } from "i18n";
import { ActionButton } from "modules/actionButton";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { showShuffleAttendeesDialog } from "modules/shuffleAttendees/redux/actions";
import { useAppDispatch } from "store/hooks";
import { ReactComponent as ShuffleAttendeesIcon } from "./icons/shuffle-attendees-icon.inline.svg";

const ShuffleButton = () => {
  const { t } = useI18n(["event"]);
  const { addWarningNotification } = useNotificationActions();
  const dispatch = useAppDispatch();

  const { isEnforceTableAssignmentScheduled } = useEnforceTableAssignment();

  const handleClick = () => {
    if (isEnforceTableAssignmentScheduled) {
      addWarningNotification({
        message: t("shuffle.not.allowed.during.enforce"),
        position: "tc",
      });

      return;
    }

    dispatch(showShuffleAttendeesDialog());
  };

  return (
    <ActionButton
      title={t("event:shuffle.attendee.seats")}
      label={t("event:shuffle.guests")}
      onClick={handleClick}
      isPopoverButton
      icon={<ShuffleAttendeesIcon />}
    />
  );
};

export default ShuffleButton;
