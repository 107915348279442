import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  description: {
    maxWidth: "36rem",
    color: "rgba(72, 74, 86, 0.7)",
  },
  link: {
    color: "rgba(72, 74, 86, 0.7)",
    textDecoration: "underline",
  },
  note: {
    marginTop: theme.spacing(2),
    maxWidth: "36rem",
    color: "rgba(72, 74, 86, 0.7)",
  },
  helpLinkButton: {
    marginTop: theme.spacing(2),
    textDecoration: "underline",
  },
  emptyState: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FAFAFA",
    marginTop: theme.spacing(2),
    maxWidth: "36rem",
    borderRadius: "10px",
  },
  emptyStateText: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    maxWidth: "36rem",
    color: "rgba(72, 74, 86, 0.7)",
  },
}));
