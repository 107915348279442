import { ActionButton } from "modules/actionButton";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { Badge } from "@remo-co/ui-core/src/components/Badge";
import { Poll as PollIcon } from "@remo-co/ui-core/src/icons/Poll";
import { selectIsEventManager } from "modules/event/selectors";
import { usePolls } from "modules/polls/hooks/usePolls";
import { TABS, selectRightPanelState, setPanelState } from "modules/rightPanel";
import { updateActiveChannel } from "modules/chat/redux/actions";

const PollsButton = (): JSX.Element | null => {
  const isEventManager = useSelector(selectIsEventManager);
  const { unansweredPolls } = usePolls();
  const dispatch = useAppDispatch();
  const { t } = useI18n(["polls", "event"]);
  const { isOpen, tabId } = useSelector(selectRightPanelState);

  const handleClick = () => {
    dispatch(
      setPanelState({
        isOpen: !isOpen || (isOpen && tabId !== TABS.POLLS),
        tabId: TABS.POLLS,
      }),
    );
    if (isOpen) {
      dispatch(updateActiveChannel(null));
    }
  };

  const isButtonActive = isOpen && tabId === TABS.POLLS;

  const getDDActionName = () => {
    if (isOpen && tabId === TABS.CHAT) {
      return "Polls Button - Close right panel";
    }
    if (isOpen && tabId !== TABS.CHAT) {
      return "Polls Button - Switch to Polls tab";
    }
    return "Polls Button - Open right panel";
  };

  return (
    <ActionButton
      title={`${
        isButtonActive ? t("polls:button.close") : t("polls:button.open")
      }`}
      onClick={handleClick}
      data-dd-action-name={getDDActionName()}
      label={t("polls:label")}
      isActive={isButtonActive}
      data-cy="polls-button"
      data-testid="polls-button"
      icon={
        <Badge
          badgeContent={
            isEventManager || unansweredPolls.length === 0 ? (
              0
            ) : (
              <span data-testid="unanswered-count">
                {unansweredPolls.length}
              </span>
            )
          }
          color="error"
        >
          <PollIcon />
        </Badge>
      }
    />
  );
};

export default PollsButton;
