import React from "react";
import { useI18n } from "i18n";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Divider } from "@remo-co/ui-core/src/components/Divider";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import moment from "moment";
import { EVENT_AGENDA_CONTEXT } from "./context/EventAgendaContext";
import "./EventAgendaHeader.scss";
import EventDaysScroller from "./EventDaysScroller";

const EventAgendaHeader = () => {
  const { eventData } = React.useContext(EVENT_AGENDA_CONTEXT);
  const { t } = useI18n(["eventAgenda"]);

  return (
    <>
      <Box className="eva-header">
        <div style={{ flex: "1 0 20%" }}>
          <Typography variant="caption">
            {t("eventAgenda:agenda.start.hour")}
          </Typography>
          <Typography variant="h6">
            {eventData ? moment(eventData.startTime).format("hh:mm A") : ""}
          </Typography>
        </div>
        <EventDaysScroller />
      </Box>
      <Divider />
    </>
  );
};

export default EventAgendaHeader;
