import MuiChip, { ChipProps } from "@material-ui/core/Chip";
import { useStyles } from "./styles";

export interface Props extends Omit<ChipProps, "color" | "variant"> {
  uppercase?: boolean;
  color?: "blue" | "dark" | "gray" | "green" | "primary" | "red" | "salmon";
}

const Chip = ({
  color = "primary",
  uppercase = false,
  ...restProps
}: Props) => {
  const styles = useStyles({ color, uppercase });
  return <MuiChip className={styles.root} {...restProps} />;
};
export default Chip;
