import {
  ChangeEvent,
  KeyboardEvent,
  FormEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { InputBase } from "@remo-co/ui-core/src/components/InputBase";
import * as DOMPurify from "dompurify";
import { useChatActions } from "modules/chat";
import { IChatMessage } from "modules/chat/types";
import { useSelector } from "react-redux";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { useI18n } from "i18n";
import { ChatFileUploader, EmojiButton } from "../../components";
import { makeSelectActiveChannel } from "../../redux/selectors";
import useStyles from "./styles";
import { GifButton } from "../GifButton.tsx";
import { SendChatButton } from "./SendChatButton";

const INPUT_PROPS = {
  id: "sc-chat-input-text",
  "data-testid": "sc-chat-input-text",
};

const ChatInputContainer = (): JSX.Element => {
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const styles = useStyles({ isBroadcasting: Boolean(isBroadcasting) });
  const channel = useSelector(makeSelectActiveChannel());
  const input = useRef<HTMLElement | null>(null);
  const [message, setMessage] = useState<string>("");
  const { sendMessage } = useChatActions();
  const { t } = useI18n();

  const submitText = useCallback(
    (event: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLDivElement>) => {
      event.preventDefault();
      input.current?.focus();

      if (message && message.length > 0) {
        sendMessage(channel, {
          type: "text",
          text: DOMPurify.sanitize(message, { ALLOWED_TAGS: [] }),
        } as unknown as IChatMessage);
        setMessage("");
      }
    },
    [message, channel, sendMessage],
  );

  const handleEmojiPicked = useCallback((emoji: string) => {
    setMessage((currentMessage) => currentMessage + emoji);
    input.current?.focus();
  }, []);

  const handleMessageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setMessage(e.target.value);
    },
    [],
  );

  const handleOnKeypress = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (!e.shiftKey && e.key === "Enter") {
        submitText(e);
      }
    },
    [submitText],
  );

  return (
    <form
      className={styles.container}
      onSubmit={submitText}
      data-testid="sc-user-input"
    >
      <div className={styles.button}>
        <ChatFileUploader />
      </div>
      <InputBase
        onChange={handleMessageChange}
        onKeyPress={handleOnKeypress}
        autoFocus
        value={message}
        inputRef={input}
        multiline
        rowsMax="6"
        role="button"
        placeholder={t("type.message")}
        className={styles.textInput}
        inputProps={INPUT_PROPS}
        data-testid="user-input-text"
      />
      <div className={styles.buttons}>
        <div className={styles.button}>
          <GifButton />
        </div>
        <div className={styles.button}>
          <EmojiButton onEmojiPicked={handleEmojiPicked} />
        </div>
        <SendChatButton type="submit" />
      </div>
    </form>
  );
};

export default ChatInputContainer;
