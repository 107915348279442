import { PermissionState } from "modules/deviceInfo";
import { BROWSER_NAMES, OS_NAMES } from "modules/systemCheck";

export const getBlockedDevice = (
  audio: boolean,
  video: boolean,
  screenShare?: boolean,
) => {
  if (audio && video) return "both";
  if (audio) return "mic";
  if (video) return "cam";
  if (screenShare) return "screenShare";
  return "";
};

export const getPromptTitle = (
  state: PermissionState,
  video: boolean,
  audio: boolean,
  screenShare?: boolean,
) =>
  `settings:prompt.heading.${state}.${getBlockedDevice(
    audio,
    video,
    screenShare,
  )}`;

export const getPromptContent = (
  state: PermissionState,
  video: boolean,
  audio: boolean,
  screenShare?: boolean,
) =>
  `settings:prompt.content.${state}.${getBlockedDevice(
    audio,
    video,
    screenShare,
  )}`;

const getBlockedDirections = (
  video: boolean,
  audio: boolean,
  browser: BROWSER_NAMES,
  os: OS_NAMES,
) => {
  let browserSuffix = "";

  if (os === OS_NAMES.ANDROID && browser === BROWSER_NAMES.CHROME) {
    browserSuffix = "android-chrome";
  } else if (os === OS_NAMES.IOS && browser === BROWSER_NAMES.MOBILE_SAFARI) {
    browserSuffix = "ios-safari";
  } else {
    switch (browser) {
      case BROWSER_NAMES.CHROME:
        browserSuffix = "chrome";
        break;
      case BROWSER_NAMES.EDGE:
        browserSuffix = "edge";
        break;
      case BROWSER_NAMES.SAFARI:
        browserSuffix = "safari";
        break;
      default:
        browserSuffix = "others";
    }
  }

  return `settings:prompt.directions.blocked.${getBlockedDevice(
    audio,
    video,
  )}.${browserSuffix}`;
};

const getNotFoundDirections = (video: boolean, audio: boolean) =>
  `settings:prompt.directions.not-found.${getBlockedDevice(audio, video)}`;

// update when we handle IN_USE state
export const getPromptDirections = (
  state:
    | PermissionState.BLOCKED
    | PermissionState.NOT_FOUND
    | PermissionState.UNKNOWN,
  video: boolean,
  audio: boolean,
  browser: BROWSER_NAMES,
  os: OS_NAMES,
) =>
  state === PermissionState.BLOCKED
    ? getBlockedDirections(video, audio, browser, os)
    : getNotFoundDirections(video, audio);

export const getPromptAssurance = (video: boolean, audio: boolean) =>
  `settings:prompt.assurance.${getBlockedDevice(audio, video)}`;
