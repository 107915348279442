import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

export type SpaceState = {
  spaceId: string | null;
  spaceChangeInProgress: boolean;
  userCountsInSpaces: Record<string, number>;
};

export const initialState: SpaceState = {
  spaceId: null,
  spaceChangeInProgress: false,
  userCountsInSpaces: {},
};

const spaceReducerSlice = createSlice({
  name: "space",
  initialState,
  reducers: {
    changeSpace: (state) => {
      state.spaceChangeInProgress = true;
    },
    changeSpaceCompleted: (state) => {
      state.spaceChangeInProgress = false;
    },
    setSpaceId: (state, action: PayloadAction<string | null>) => {
      state.spaceId = action.payload;
    },
    setSpacesUserCount: (
      state,
      action: PayloadAction<Record<string, number>>,
    ) => {
      // check if reference is the same as initial, or if not initial, is deeply equal
      // we want to skip deep comparison if it's still the initial by reference
      if (
        current(state.userCountsInSpaces) === initialState.userCountsInSpaces ||
        !isEqual(state.userCountsInSpaces, action.payload)
      ) {
        state.userCountsInSpaces = action.payload;
      }
    },
    resetSpace: () => initialState,
  },
});

export const {
  changeSpace,
  changeSpaceCompleted,
  setSpaceId,
  setSpacesUserCount,
  resetSpace,
} = spaceReducerSlice.actions;

export default spaceReducerSlice.reducer;
