import { call, select, takeLatest } from "redux-saga/effects";
import { getCurrentClientTime } from "helpers/firebaseTimeHelper";
import store from "store";
import { trackHelpChatEvent } from "modules/helpChat/store";
import actionTypes from "./constants";
import { selectCurrentEvent } from "./selectors";
import { IEvent } from "./types";

function* viewEvent() {
  const event: IEvent = yield select(selectCurrentEvent);
  const time: number = yield call(getCurrentClientTime);

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  updateHelpChatEvents({
    rc_first_event_attended_name: event.name,
    rc_first_event_attended_at: Math.ceil(time),
  });
  // Track last attended event - keep updating even if it is already added
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  updateHelpChatEvents(
    {
      rc_last_event_attended_name: event.name,
      rc_last_event_attended_at: Math.ceil(time),
    },
    15000,
    false,
  );
}

// Setting timeout of 15s so it will load user details from helpChat
// To avoid over writing login details (ex: rc_first_event_attended_name)
export const updateHelpChatEvents = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
  timeout = 15000,
  skipUpdate = true,
) => {
  setTimeout(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      if (data[key] !== undefined) {
        store.dispatch(
          trackHelpChatEvent({ key, value: data[key], skipUpdate }),
        );
      }
    }
  }, timeout);
};

function* saga() {
  // yield takeLatest(MESSAGE_RECEIVED, receivedMessage);
  yield takeLatest(actionTypes.VIEW_EVENT, viewEvent);
}

export default saga;
