import React from "react";
import { useAppDispatch } from "store/hooks";
import actions from "../redux/actions";

interface ICameraSettingsDialog {
  showCameraSettingsDialog: () => void;
  hideCameraSettingsDialog: () => void;
}

const useCameraSettingsActions = (): ICameraSettingsDialog => {
  const dispatch = useAppDispatch();

  const showCameraSettingsDialog = React.useCallback(() => {
    dispatch(actions.showCameraSettingsDialog());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideCameraSettingsDialog = React.useCallback(() => {
    dispatch(actions.hideCameraSettingsDialog());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    showCameraSettingsDialog,
    hideCameraSettingsDialog,
  };
};

export default useCameraSettingsActions;
