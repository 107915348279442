import throttle from "modules/throttle";
import { useCallback, useRef } from "react";
import { useAppDispatch } from "store/hooks";
import firebase from "firebase/compat/app";
import {
  getRaisedHandUsersDocRef,
  resetRaisedHands,
  toggleRaisedHand as toggleRaisedHandFirebase,
} from "../raiseHand.firebase";
import {
  RaisedHandUserRecord,
  updateRaisedHandUsers,
} from "../redux/broadcastRaiseHandSlice";

const useRaiseHandActions = () => {
  const docRef = useRef<firebase.database.Reference>();
  const dispatch = useAppDispatch();

  const throttledUpdate = throttle((raisedHandUsers: RaisedHandUserRecord) => {
    dispatch(updateRaisedHandUsers(raisedHandUsers));
  }, 500);

  const subscribe = (theaterId: string) => {
    docRef.current = getRaisedHandUsersDocRef(theaterId);
    docRef.current.off();
    docRef.current.on("value", (snap: firebase.database.DataSnapshot) => {
      const raisedHandUsers = snap.val() || [];

      throttledUpdate(raisedHandUsers);
    });
  };

  const unsubscribe = () => {
    if (docRef.current) {
      docRef.current.off();
    }
  };

  const toggleRaiseHand = async (theaterId: string, userId: string) => {
    await toggleRaisedHandFirebase(theaterId, userId);
  };

  const unraiseAllHands = useCallback((theaterId: string) => {
    resetRaisedHands(theaterId);
  }, []);

  return {
    unraiseAllHands,
    toggleRaiseHand,
    subscribe,
    unsubscribe,
  };
};

export default useRaiseHandActions;
