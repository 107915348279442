import { SystemInfoWithTroubleShootLinks } from "./types";
import getTroubleShootLinks from "./utils/getTroubleShootLinks";
import checkSystemInfo from "./utils/checkSystemInfo";

export { default as getInternetConnectionSpeed } from "./utils/getInternetConnectionSpeed";
export { default as checkInternetConnection } from "../micCamCheck/systemCheck/utils/checkInternetConnection";

export * from "./constants";

const systemInfo = checkSystemInfo();
const troubleShootLinks = getTroubleShootLinks(systemInfo.osName);

export const getSystemInfo = (): SystemInfoWithTroubleShootLinks => ({
  ...systemInfo,
  troubleShootLinks,
});

export { default as checkFirebaseAccess } from "./utils/checkFirebaseAccess";

export { default as checkFirestoreAccess } from "./utils/checkFirestoreAccess";

export { default as checkFirebaseStorageAccess } from "./utils/checkFirebaseStorageAccess";

export { default as isAdBlockEnabled } from "./utils/isAdBlockEnabled";

export { default as isVPNDetected } from "./utils/isVPNDetected";

export { default as getPublicIPAddress } from "./utils/getPublicIPAddress";

export { default as isThirdPartyReachable } from "./utils/isThirdPartyReachable";

export type {
  BrowserSupport,
  SystemInfo,
  SystemInfoWithTroubleShootLinks,
} from "./types";
export { isBrowserName } from "modules/systemCheck/typeGuards";
export { isOsName } from "modules/systemCheck/typeGuards";
