import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Link } from "@remo-co/ui-core/src/components/Link";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { OpenInNew } from "@remo-co/ui-core/src/icons/OpenInNew";
import { useI18n } from "i18n";
import { selectPresentationModeContent } from "modules/eventEmbedContent/redux";
import { useSelector } from "react-redux";
import useStyles from "../PresentContentDisplay/styles";

export const PresentContentWindow = () => {
  const content = useSelector(selectPresentationModeContent);
  const { t } = useI18n(["event"]);
  const styles = useStyles();

  return (
    <Box width="100%" height="100%" overflow="hidden">
      <Box flex alignItems="center" className={styles.header}>
        <Button
          variant="text"
          size="sm"
          contentClassName={styles.openInNewTabButton}
          href={content?.url}
          component={Link}
          openInNewTab
          data-testid="open-new-tab-button"
        >
          <Typography variant="body1">
            {t("event:present.content.new.tab")}
          </Typography>
          <OpenInNew className={styles.openInNewTabButtonIcon} />
        </Button>
      </Box>
      <iframe
        className={styles.iframe}
        src={content?.url}
        frameBorder={0}
        title="present content"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      />
    </Box>
  );
};
