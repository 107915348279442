import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Autocomplete } from "@remo-co/ui-core/src/components/Autocomplete";
import { useCompanyFloorPlanTags } from "modules/company/hooks/useCompanyFloorPlanTags";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@remo-co/ui-core/src/components/Checkbox";
import { TextField } from "@remo-co/ui-core/src/components/TextField";
import { Paper, PaperProps } from "@remo-co/ui-core/src/components/Paper";
import { CreateNewTag } from "modules/customFloorPlan/components/CreateNewTag";
import { useEffect, useMemo, useState } from "react";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { setFloorPlanSelectedTags } from "modules/customFloorPlan/redux/slice";
import { useCreateNewTag } from "modules/customFloorPlan/hooks/useCreateNewTag";
import { useStyles } from "./styles";

export type LabelPosition = "top" | "left";

interface Props {
  allowCreate?: boolean;
  label: string;
  labelPosition: LabelPosition;
  freeSolo?: boolean;
  isFullscreen?: boolean;
}

interface SelectedTags {
  label: string;
  value: string;
}

interface CustomPaperProps {
  isMatched: boolean;
  allowCreate?: boolean;
  search: string | undefined;
  setSearch: (search: string | undefined) => void;
}

const CustomPaperComponent = ({
  children,
  allowCreate,
  isMatched,
  search,
  setSearch,
  ...rest
}: PaperProps & CustomPaperProps) => {
  const { t } = useI18n(["customFloorPlan"]);
  const styles = useStyles({});

  return (
    <Paper {...rest}>
      {allowCreate && (
        <Typography className={styles.selectCreateText}>
          {t("customFloorPlan:select.create.tag")}
        </Typography>
      )}
      {children}
      {allowCreate && !isMatched && search && (
        <CreateNewTag name={search} setSearch={setSearch} />
      )}
    </Paper>
  );
};

export const FloorPlanSelectTags = ({
  allowCreate = false,
  label,
  labelPosition,
  freeSolo = false,
  isFullscreen,
}: Props) => {
  const { tags, selectedTags, setSelectedTags } = useCompanyFloorPlanTags();
  const [search, setSearch] = useState<string>();
  const { t } = useI18n(["customFloorPlan"]);
  const dispatch = useAppDispatch();
  const { createNewTag } = useCreateNewTag();

  const styles = useStyles({ labelPosition });

  const isMatched = useMemo(
    () => Boolean(tags.find((tag) => tag.label === search)),
    [tags, search],
  );

  useEffect(
    () => () => {
      dispatch(setFloorPlanSelectedTags([]));
    },
    [dispatch],
  );

  return (
    <Box display="flex" className={styles.selectBox}>
      <Typography className={styles.filterText}>{label}</Typography>
      <Autocomplete
        disabled={isFullscreen}
        multiple
        id="company-tags"
        options={tags}
        freeSolo={freeSolo}
        disableCloseOnSelect
        limitTags={2}
        className={styles.selectField}
        autoHighlight
        onChange={async (e, val, reason, option) => {
          if (reason === "create-option" && option?.option && !isMatched) {
            await createNewTag(option.option as unknown as string);
          } else {
            const values = val as SelectedTags[];
            const data = selectedTags.find(
              (v) => v.value === option?.option.value,
            );
            if (data) {
              setSelectedTags(values.filter((v) => v.value !== data.value));
            } else {
              setSelectedTags(values);
            }
          }
        }}
        noOptionsText={false}
        value={selectedTags}
        onClose={() => setSearch(undefined)}
        getOptionLabel={(option) => option.label}
        classes={{ option: styles.selectOption, listbox: styles.selectList }}
        renderOption={(option) => (
          <>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={
                selectedTags.findIndex((tag) => tag.value === option.value) > -1
              }
            />
            {option.label}
          </>
        )}
        // eslint-disable-next-line react/no-unstable-nested-components
        PaperComponent={(props) => (
          <CustomPaperComponent
            {...props}
            isMatched={isMatched}
            setSearch={setSearch}
            search={search}
            allowCreate={allowCreate}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              className: `${params.InputProps.className} ${styles.tagsInput}`,
              onChange: (e) => setSearch(e.target.value),
              ...(selectedTags.length === 0 && {
                placeholder: t("customFloorPlan:select.tags"),
              }),
            }}
          />
        )}
      />
    </Box>
  );
};
