import { Container } from "@remo-co/ui-core/src/components/Container";
import { selectIsMobile } from "modules/deviceInfo";
import { useSelector } from "react-redux";
import { PaginationButton } from "modules/audioVideo/components/DailyContainer/PaginationButton";
import { ChangeLayoutButton } from "modules/audioVideo/components/DailyContainer/ChangeLayoutButton";
import { PopperPlacement } from "modules/audioVideo/components/DailyContainer/ChangeLayoutButton/constants";
import { TileGroup } from "modules/audioVideo/components/DailyContainer/Tiles/TileGroup";
import { usePagination } from "modules/audioVideo/components/DailyContainer/Layouts/hooks/usePagination";
import { LayoutProps } from "modules/audioVideo/components/DailyContainer/Layouts/types";
import { useActiveSpeakerTile } from "modules/audioVideo/hooks/useActiveSpeakerTile";
import { useEffect, useMemo, useState } from "react";
import {
  MAX_PAGE_SIZE_DESKTOP,
  MAX_PAGE_SIZE_MOBILE,
  TILE_WIDTH,
} from "modules/audioVideo/components/DailyContainer/Layouts/hooks/constants";
import { useStyles } from "./styles";

export const RibbonLayout = ({
  tiles,
  onTileClick,
  switchLayout,
}: LayoutProps) => {
  const isMobile = useSelector(selectIsMobile);

  const [width, setWidth] = useState<number | undefined>();

  useEffect(() => {
    const handlePageResize = () => {
      const widthContainer = document.getElementById("layout-container");
      setWidth(widthContainer?.offsetWidth);
    };

    window.addEventListener("resize", handlePageResize);

    return () => {
      window.removeEventListener("resize", handlePageResize);
    };
  }, []);

  const maxPageSize = useMemo(() => {
    if (width) {
      return Math.floor(width / TILE_WIDTH) || MAX_PAGE_SIZE_MOBILE;
    }

    if (isMobile) {
      return MAX_PAGE_SIZE_MOBILE;
    }

    return MAX_PAGE_SIZE_DESKTOP;
  }, [isMobile, width]);

  const { participantTiles } = useActiveSpeakerTile(tiles, maxPageSize);

  const {
    currentPage,
    canPaginateBackward,
    canPaginateForward,
    paginateBack,
    paginateForward,
    pageIndex,
    totalPages,
    hasPagination,
  } = usePagination(participantTiles, maxPageSize);

  const styles = useStyles({
    isMobile,
    hasPagination,
  });
  return (
    <Container flex className={styles.root} data-testid="ribbon-layout">
      <Container>
        <div className={styles.ribbon}>
          <TileGroup tiles={currentPage} onTileClick={onTileClick} />
        </div>
        <div className={styles.banner}>
          <Container className={styles.button}>
            <Container className={styles.viewButtonRoot}>
              {hasPagination && (
                <PaginationButton
                  canPaginateForward={canPaginateForward}
                  canPaginateBackward={canPaginateBackward}
                  paginateForward={paginateForward}
                  paginateBackward={paginateBack}
                  totalPages={totalPages}
                  currentPage={pageIndex}
                  direction="horizontal"
                />
              )}
              <ChangeLayoutButton
                placement={
                  isMobile
                    ? PopperPlacement.bottom
                    : PopperPlacement.bottomStart
                }
                tiles={tiles}
                hasPagination={hasPagination}
                switchLayout={switchLayout}
              />
            </Container>
          </Container>
        </div>
      </Container>
    </Container>
  );
};
