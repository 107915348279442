import { Box } from "@remo-co/ui-core/src/components/Box";
import { useTheme } from "@remo-co/ui-core/src/hooks/useTheme";
import { ManageEventContent } from "../ManageEventContent";
import "./styles.scss";

const ManageEvent = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      boxSizing="border-box"
      maxWidth={theme.constants.maxContentWidth}
      mx="auto"
      px={3}
      width="100%"
      height="100vh"
      className="manage-event-container"
    >
      <Box>
        <ManageEventContent />
      </Box>
    </Box>
  );
};

export default ManageEvent;
