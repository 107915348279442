import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: theme.spacing(2.5),
  },
  select: {
    width: "120px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingRight: theme.spacing(3.5),
    paddingLeft: theme.spacing(1),
  },
  floorPrefix: {
    marginRight: theme.spacing(0.5),
  },
  slotPrefix: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  mapPreview: {
    marginTop: `${theme.spacing(1)}px !important`,
  },
  error: {
    color: theme.palette.red.main,
    display: "block",
  },
}));
