import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import { Theme } from "@remo-co/ui-core/src/types";
import { ActivityStatusColors } from "modules/eventActivityStatus/constants";
import { ActivityStatus } from "../../types";

interface StyleProps {
  status: ActivityStatus | null;
  small?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {},
  statusDot: {
    borderRadius: "9999px",
    height: ({ small }) => `${theme.spacing(small ? 1 : 1.5)}px`,
    width: ({ small }) => `${theme.spacing(small ? 1 : 1.5)}px`,
    marginRight: theme.spacing(0.5),
    backgroundColor: ({ status }: { status: ActivityStatus | null }) => {
      switch (status) {
        case ActivityStatus.ACTIVE:
        case ActivityStatus.AWAY:
        case ActivityStatus.FOCUSING:
          return ActivityStatusColors[status];
        default:
          return ActivityStatusColors[ActivityStatus.ACTIVE];
      }
    },
  },
}));
