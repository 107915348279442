import { database, serverValue } from "services/firebaseService/firebaseConfig";
import { THEATER_PATH } from "modules/theater/firebase";
import { ActivePresenterRecord } from "./types";

export const getPresentersDbRef = (theaterId: string) =>
  database.ref(`${THEATER_PATH}/${theaterId}/Broadcast/Presenters`);

export const getBroadcastDbRef = (theaterId: string) =>
  database.ref(`${THEATER_PATH}/${theaterId}/Broadcast`);

export const addPresenter = async (theaterId: string, userId: string) => {
  const dbRef = getPresentersDbRef(theaterId);

  await dbRef.child(userId).set({
    joined: serverValue.TIMESTAMP,
  });
};

export const getPresenters = async (theaterId: string) => {
  const dbRef = getBroadcastDbRef(theaterId);
  const broadcastSnapshot = await dbRef.get();
  const broadcastData = await broadcastSnapshot.val();

  const broadcastStartedAt = broadcastData?.BroadcastInfo?.startedAt;

  if (!broadcastStartedAt) {
    return [];
  }

  const presenters: ActivePresenterRecord = broadcastData?.Presenters ?? [];

  return Object.entries(presenters)
    .filter(([_userId, { joined }]) => joined >= broadcastStartedAt)
    .map(([userId]) => userId);
};

export const clearPresenters = (theaterId: string) => {
  const dbRef = getPresentersDbRef(theaterId);
  return dbRef.remove();
};
