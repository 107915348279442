import { ReactElement } from "react";
import { EventPollOption } from "graphql/generated";
import { Result } from "./Result";

interface Props {
  options: EventPollOption[];
  selectedAnswer?: number;
  totalVotes: number;
}

const QuizResults = ({
  selectedAnswer,
  options,
  totalVotes,
}: Props): ReactElement => (
  <>
    {options.map((result) => (
      <Result
        votes={result.votes}
        id={result?.id}
        key={result?.id}
        option={result.option}
        totalVotes={totalVotes}
        selectedAnswer={selectedAnswer}
      />
    ))}
  </>
);

export default QuizResults;
