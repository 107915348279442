import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  registerToEvent: {
    zIndex: 1100,
  },
  paperScrollPaper: {
    width: "450px",
  },
  dialogContent: {
    minHeight: "10rem",
    textAlign: "center",
    padding: "60px 50px 40px",
    position: "relative",
    overflowY: "visible",
    "&:first-child": {
      paddingTop: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 1rem",
    },
  },
  closeButton: {
    position: "absolute",
    zIndex: 1,
    right: "20px",
    top: "18px",
    margin: "0",
    width: "36px",
    height: "36px",
    background: "#eeeeee",
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
  },
}));
