import { useEffect } from "react";
import { pageAccessedByReload } from "helpers/pageAccessedByReload";
import { Actions } from "../../actions";
import { trackAction } from "../../helpers/trackActions";

const TRACKING_DELAY = 1000;
const useTrackRefreshes = (isDatadogInit: boolean) => {
  useEffect(() => {
    if (!isDatadogInit) {
      return;
    }
    const isLoaded = sessionStorage.getItem("loaded");
    const isReloaded = pageAccessedByReload();

    if (isReloaded && isLoaded === "true") {
      // manual refresh
      setTimeout(() => {
        trackAction(Actions.REFRESHED);
      }, TRACKING_DELAY);
    } else if (!isLoaded) {
      // first time load
      sessionStorage.setItem("loaded", "true");
    }

    // eslint-disable-next-line consistent-return
    return () => {
      sessionStorage.removeItem("loaded");
    };
  }, [isDatadogInit]);
};

export default useTrackRefreshes;
