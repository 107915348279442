import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FAFAFA",
    marginTop: theme.spacing(2),
    borderRadius: "10px",
  },
  text: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    maxWidth: "36rem",
    color: "rgba(72, 74, 86, 0.7)",
  },
  button: {
    height: "unset",
    marginTop: theme.spacing(2),
  },
  content: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(5)}px`,
  },
  icon: {
    fontSize: "14px",
  },
}));
