import store from "store";
import firebase from "firebase/compat/app";
import { database } from "../../services/firebaseService/firebaseConfig";
import { addQuestionsDetails } from "./redux/actions";
import { IQuestion } from "./types";

const prefix = "BroadcastQA";

export const askQuestion = (
  eventId: string,
  question: string,
  authorName: string,
) => {
  const id = new Date().getTime();
  const qaRef = database.ref(`${prefix}/${eventId}/questions/${id}`);

  qaRef.update({
    id,
    question,
    authorName,
    voteCount: 0,
    isAnswered: false,
    voters: {},
  });
};

export const updateVoteForQuestion = (
  eventId: string,
  questionId: number,
  userId: string,
  isDownvote = false,
  // eslint-disable-next-line max-params
) => {
  const qaRef = database.ref(`${prefix}/${eventId}/questions/${questionId}`);

  qaRef.transaction((data) => {
    const question = data as IQuestion;

    if (question && !isDownvote) {
      if (question.voteCount && question.voters[userId]) {
        return question;
      }
      // eslint-disable-next-line no-plusplus
      question.voteCount++;

      if (!data.voters) {
        question.voters = {};
      }
      question.voters[userId] = true;
    } else if (question && isDownvote) {
      if (!question.voters) {
        // eslint-disable-next-line consistent-return
        return;
      }
      question.voters[userId] = false;
      // eslint-disable-next-line no-plusplus
      question.voteCount--;
    }

    return data;
  });
};

export const questionAnswered = (eventId: string, questionId: number) => {
  const qaRef = database.ref(`${prefix}/${eventId}/questions/${questionId}`);

  qaRef.update({ isAnswered: true });
};

export const listQuestions = (eventId: string): firebase.database.Query => {
  const qaRef = database
    .ref(`${prefix}/${eventId}`)
    .child("questions")
    .orderByChild("isAnswered")
    .equalTo(false);

  qaRef.off();

  qaRef.on("value", (status) => {
    const values = status.val();
    const questions: IQuestion[] = [];

    if (values) {
      Object.keys(values).map((key) => questions.push(values[key]));
    }
    questions.sort((a, b) => b.voteCount - a.voteCount);
    store.dispatch(addQuestionsDetails(questions));
  });

  return qaRef;
};
