import { MIN_DOWNLOAD_SPEED_MBPS } from "../../../../systemCheck/constants";

const checkInternetConnection = (downloadSpeedMbps: number | null): boolean => {
  if (!downloadSpeedMbps) {
    return false;
  }

  return downloadSpeedMbps >= MIN_DOWNLOAD_SPEED_MBPS;
};

export default checkInternetConnection;
