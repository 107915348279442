import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "../../../../zIndexes.module.scss";

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: zIndexes.modalZIndex + 1,
    background: theme.palette.white,
    borderColor: theme.palette.gray.muted2,
    boxShadow: "-2px 2px 10px rgba(0, 0, 0, 0.5)",
    maxWidth: 375,
    borderRadius: 10,
    transform: "translate(50%, -50%) !important",
    top: "50% !important",
    right: "50% !important",
    left: "auto !important",
  },
  content: {
    marginTop: 30,
  },
  title: {
    margin: "0 10px",
  },
  subtitle: {
    margin: "17px 20px 20px",
  },
  animationContainer: {
    margin: "60px 37px 0",
  },
}));

export default useStyles;
