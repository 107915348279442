import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { Colors } from "../colors";

export const darkPalette: PaletteOptions = {
  action: {
    active: Colors.WHITE,
    disabled: Colors.DISABLED,
    hover: Colors.GREEN_HOVER,
    disabledBackground: Colors.GRAY_DISABLED_INPUT,
    selected: Colors.PRESENT_BLACK_MUTED,
  },
  salmon: {
    main: Colors.SALMON_PASTEL,
  },
  green: {
    main: Colors.GREEN_MAIN,
    light: Colors.GREEN_LIGHT,
    hover: Colors.GREEN_HOVER,
    background: Colors.GREEN_BACKGROUND,
    action: Colors.GREEN_ACTION,
    actionDarkMode: Colors.GREEN_ACTION_DARK_MODE,
    hoverDarkMode: Colors.GREEN_HOVER_DARK_MODE,
  },
  red: {
    main: Colors.RED_DANGER,
    light: Colors.RED_DANGER_LIGHT,
    hover: Colors.RED_DANGER_HOVER,
  },
  corraBrown: Colors.CORRA_BROWN,
  black: Colors.BLACK,
  error: {
    main: Colors.RED_DANGER,
  },
  present: {
    black: Colors.PRESENT_BLACK_MUTED,
    green: Colors.PRESENT_GREEN,
    greenBackground: Colors.PRESENT_GREEN_BG,
    greyBackground: Colors.PRESENT_GREY_BG,
    red: Colors.PRESENT_RED,
    redBackground: Colors.PRESENT_RED_BG,
    white: Colors.PRESENT_WHITE,
  },
  gray: {
    disabled: Colors.GRAY_DISABLED,
    icon: Colors.GRAY_ICON,
    disabledInput: Colors.GRAY_DISABLED_INPUT,
    medium: Colors.GRAY_MEDIUM,
    light: Colors.GRAY_LIGHT,
    lightShade: Colors.GRAY_LIGHT_SHADE,
    main: Colors.GRAY_LIGHT,
    muted1: Colors.GRAY_MUTED1,
    muted2: Colors.GRAY_MUTED2,
    switchTrackDark: Colors.GRAY_SWITCH_TRACK_DARK,
  },
  white: Colors.WHITE,
  dark: {
    main: Colors.BLUE_DARK,
    secondary: Colors.BLUE_DARKSHADE3,
    overlay: Colors.DARK_OVERLAY,
    overlay2: Colors.DARK_OVERLAY2,
  },
  blue: {
    main: Colors.BLUE_PRIMARY,
    primaryHover: Colors.BLUE_PRIMARY_HOVER,
    lightShade1: Colors.BLUE_LIGHTSHADE1,
    lightShade2: Colors.BLUE_LIGHTSHADE2,
    lightShade3: Colors.BLUE_LIGHTSHADE3,
    lightShade4: Colors.BLUE_LIGHTSHADE4,
    lightShade5: Colors.BLUE_LIGHTSHADE5,
    lightShade3Disabled: Colors.BLUE_LIGHTSHADE3_DISABLED,
    dark: Colors.BLUE_DARK,
    darkShade1: Colors.BLUE_DARKSHADE1,
    darkShade2: Colors.BLUE_DARKSHADE2,
    disabled: Colors.DISABLED,
  },
  darkModeBlue: {
    main: Colors.BLUE_DARK_MODE,
  },
  primary: {
    main: Colors.BLUE_PRIMARY,
  },
  secondary: {
    main: Colors.BLUE_DARK,
  },
  text: {
    primary: Colors.WHITE,
    secondary: Colors.BLUE_DARKSHADE1,
  },
  yellow: {
    lightShade1: Colors.YELLOW_LIGHTSHADE_1,
    dark: Colors.YELLOW_DARK,
  },
  status: {
    warning: Colors.WARNING,
    success: Colors.SUCCESS,
    error: Colors.ERROR,
  },
  tour: {
    background: Colors.BLUE_DARKSHADE2,
  },
  textInput: {
    backgroundColor: Colors.BLUE_GRAYISH_DARK,
  },
  polls: {
    selectedAnswer: Colors.BLUE_DARK_MODE,
    otherAnswer: Colors.BLUE_DARKSHADE3,
    correctAnswer: Colors.GREEN_MAIN,
    incorrectAnswer: Colors.RED_DANGER,
    timerBar: Colors.BLUE_DARK_MODE,
    background: Colors.PRESENTATION_MODE_BACKGROUND,
    border: Colors.BLUE_DARKSHADE3,
    ongoing: Colors.BLUE_DARK_MODE,
    draft: Colors.GRAY_DISABLED,
    closed: Colors.RED_DANGER,
  },
  background: {
    default: Colors.PRESENTATION_MODE_BACKGROUND,
    secondary: "#232944",
  },
  border: {
    default: Colors.BLACK,
  },
};
