import { useI18n } from "i18n";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useAppDispatch } from "store/hooks";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { selectCurrentEventId } from "modules/event/selectors";
import { Actions, trackActionEnd, trackActionStart } from "modules/monitoring";
import logger from "logging/logger";
import { selectCurrentSpaceId } from "modules/space/redux";
import { inviteToTableRequest, InviteToTablePayload } from "./request";

export const useInviteToTable = () => {
  const eventId = useSelector(selectCurrentEventId);
  const spaceId = useSelector(selectCurrentSpaceId);
  const dispatch = useAppDispatch();
  const { t } = useI18n(["common"]);

  const { mutate, isLoading, status } = useMutation(
    async (payload: InviteToTablePayload) => {
      trackActionStart(Actions.INVITE_TO_TABLE);

      return inviteToTableRequest(payload);
    },
    {
      async onSuccess(_, { tableId, userId }) {
        trackActionEnd(
          Actions.INVITE_TO_TABLE,
          Actions.INVITE_TO_TABLE_SUCCESS,
          {
            eventId,
            tableId,
            targetUserId: userId,
          },
        );
      },
      onError(error: Error, { tableId, userId }) {
        trackActionEnd(
          Actions.INVITE_TO_TABLE,
          Actions.INVITE_TO_TABLE_FAILURE,
          {
            error: error.message,
            eventId,
            tableId,
            targetUserId: userId,
          },
        );

        dispatch(
          addErrorNotification({
            message: t("error.unknown"),
          }),
        );
      },
    },
  );

  const inviteToTable = useCallback(
    (data: Omit<InviteToTablePayload, "eventId" | "spaceId">) => {
      if (!eventId) {
        logger.warn("[useInviteToTable] No Event");
        return;
      }
      if (!spaceId) {
        logger.warn("[useInviteToTable] No Space");
        return;
      }

      mutate({ ...data, eventId, spaceId });
    },
    [eventId, mutate, spaceId],
  );

  return { inviteToTable, isLoading, status };
};
