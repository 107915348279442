import { useState, useRef } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Card } from "@remo-co/ui-core/src/components/Card";
import { Info } from "@remo-co/ui-core/src/icons/Info";
import { useI18n } from "i18n";
import useStyles from "./styles";

const TableInfoPopover = (): JSX.Element => {
  const iconRef = useRef<SVGSVGElement | null>(null);
  const [open, setOpen] = useState(false);
  const { t } = useI18n(["manageEvent"]);
  const styles = useStyles();

  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <>
      <Info
        data-testid="table-info-icon"
        ref={iconRef}
        className={styles.icon}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <Popper
        open={open}
        anchorEl={iconRef.current}
        popperOptions={{
          modifiers: {
            offset: {
              offset: "5,5",
            },
          },
        }}
        placement="right-start"
        className={styles.popper}
      >
        <Card className={styles.container} size="sm">
          <Typography variant="body2">
            {t("invited.guests.reserved")}
          </Typography>
        </Card>
      </Popper>
    </>
  );
};

export default TableInfoPopover;
