export const defaultTitle = "hello.welcome";

export const defaultMessage = "default.message";

export const maxMessageLength = 170;

export const maxShortDescLength = 100;

export const EVENT_BANNER_MAX_FILE_SIZE = "2048KB";

export const EVENT_OUTCOMES = [
  {
    label: "eventForm:event.outcome.attendees.new",
    value: "attendees.new",
  },
  {
    label: "eventForm:event.outcome.celebrate.company.success",
    value: "celebrate.company.success",
  },
  {
    label: "eventForm:event.outcome.educate.attendees",
    value: "educate.attendees",
  },
  {
    label: "eventForm:event.outcome.generate.more.leads",
    value: "generate.more.leads",
  },
  {
    label: "eventForm:event.outcome.access.talent.and.opportunities",
    value: "access.talent.and.opportunities",
  },
  {
    label: "eventForm:event.outcome.increase.attendance.rate",
    value: "increase.attendance.rate",
  },
  {
    label: "eventForm:event.outcome.increase.customer.happiness",
    value: "increase.customer.happiness",
  },
  {
    label: "eventForm:event.outcome.raise.money",
    value: "raise.money",
  },
  {
    label: "eventForm:event.outcome.other",
    value: "other",
  },
];
