import { useSelector } from "react-redux";
import {
  BasicSettingsTab,
  FloorContentTab,
  EventDetailsTab,
  LobbyAndWelcome,
  PostEvent,
  Sponsorship,
  Invitations,
} from "modules/eventForm";
import { RefObject, useContext, useEffect, useRef } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import EventAgenda from "modules/eventAgenda/EventAgenda";
import EventCustomBrandingSettings from "modules/eventForm/branding/components/EventCustomBrandingSettings";
import { selectCurrentEvent } from "modules/event/selectors";
import { AssignTablesTab } from "modules/assignTables";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import logger from "logging/logger";
import { BASIC_SETTINGS, TICKETING } from "modules/manageEvent/constants";
import { LiveStream } from "modules/liveStream";
import { RegistrationTab } from "modules/eventForm/registration/components/RegistrationTab";
import { TicketingTab } from "modules/eventForm/ticketing/components/TicketingTab";
import ActionBar from "../ActionBar";
import Features from "../Features";
import LeftMenu from "../LeftMenu";
import { useStyles } from "./styles";

const ManageEventContent = (): JSX.Element => {
  const { state, updateEventData, isStartTimeDisabled, isGeneratingImage } =
    useContext(MANAGE_EVENT_CONTEXT);
  const pageId = state?.pageId ?? undefined;
  const eventData = state?.eventData ?? undefined;
  const { isUnlimitedEvent } = state?.eventData ?? {};
  const currentEvent = useSelector(selectCurrentEvent);
  const contentRef: RefObject<HTMLElement | null | undefined> = useRef();
  const styles = useStyles();

  useEffect(() => {
    if (contentRef.current) {
      try {
        contentRef.current.scrollTo(0, 0);
      } catch (error) {
        logger.error(
          "[ManageEventContent] could not scroll to top of content element",
          {
            error,
          },
        );
      }
    }
  }, [pageId]);

  return (
    <>
      <LeftMenu />
      {eventData && (
        <Box
          {...{ ref: contentRef }}
          className={`${currentEvent ? "inevent" : ""} ${pageId} me-content`}
        >
          <Box className={styles.content}>
            <div className="me-tab-content">
              {pageId === BASIC_SETTINGS.pageId && (
                <BasicSettingsTab
                  isGeneratingImage={isGeneratingImage}
                  eventData={eventData}
                  onEventDataChange={updateEventData}
                  isStartTimeDisabled={isStartTimeDisabled}
                />
              )}
              {pageId === TICKETING.pageId && !isUnlimitedEvent && (
                <TicketingTab eventData={eventData} />
              )}
              {pageId === "details" && (
                <EventDetailsTab
                  eventData={eventData}
                  onEventDataChange={updateEventData}
                />
              )}
              {pageId === "registration" && (
                <RegistrationTab eventData={eventData} />
              )}
              {pageId === "floor-content" && <FloorContentTab />}
              {pageId === "sponsors" && <Sponsorship />}
              {pageId === "agenda" && <EventAgenda />}
              {pageId === "invitations" && (
                <Invitations eventData={eventData} />
              )}
              {pageId === "assign-tables" && (
                <AssignTablesTab
                  eventId={eventData.id}
                  eventName={eventData.name}
                  isEventPublished={!!eventData.id}
                  theaters={eventData.theaters}
                />
              )}
              {pageId === "branding" && (
                <EventCustomBrandingSettings
                  eventData={eventData}
                  onEventDataChange={updateEventData}
                />
              )}
              {pageId === "lobby-and-welcome" && (
                <LobbyAndWelcome
                  eventData={eventData}
                  onEventDataChange={updateEventData}
                />
              )}
              {pageId === "post-event" && <PostEvent eventData={eventData} />}
              {pageId === "features" && (
                <Features
                  eventData={eventData}
                  onEventDataChange={updateEventData}
                />
              )}
              {pageId === "stream" && <LiveStream eventId={eventData.id} />}
            </div>
          </Box>
          <ActionBar />
        </Box>
      )}
    </>
  );
};

export default ManageEventContent;
