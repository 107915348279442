import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { selectCurrentEventId } from "modules/event/selectors";
import { useI18n } from "i18n";
import {
  changeSpace,
  changeSpaceCompleted,
  selectCurrentSpaceId,
} from "modules/space/redux";
import { useAppDispatch } from "store/hooks";
import logger from "logging/logger";
import { Actions, trackActionEnd, trackActionStart } from "modules/monitoring";
import { useUserData } from "modules/audioVideo/hooks";
import { joinFloorRequest, SwitchFloorPayload } from "./request";
import getErrorMessage from "./errorHandler";
import { SwitchFloorErrors } from "./constants";
import { useClearFloorDataAndRegisterListeners } from "./hooks/useClearFloorDataAndRegisterListeners";

export const useJoinFloor = () => {
  const eventId = useSelector(selectCurrentEventId);
  const currentFloorId = useSelector(selectCurrentSpaceId);
  const { t } = useI18n(["server", "template"]);
  const dispatch = useAppDispatch();
  const { clearFloorDataAndRegisterListeners } =
    useClearFloorDataAndRegisterListeners();
  const { setConversationId } = useUserData();

  const {
    mutate: switchFloor,
    isLoading,
    status,
  } = useMutation(
    ["remo.switchFloor"],
    async ({ targetFloorId }: Pick<SwitchFloorPayload, "targetFloorId">) => {
      if (!currentFloorId) {
        throw new Error(SwitchFloorErrors.MISSING_FLOOR_ID);
      }

      if (eventId == null) {
        throw new Error(SwitchFloorErrors.MISSING_EVENT_ID);
      }

      if (currentFloorId === targetFloorId) {
        throw new Error(SwitchFloorErrors.USER_ON_FLOOR);
      }

      logger.info("user switching floors", {
        targetFloorId,
        eventId,
        currentFloorId,
      });

      trackActionStart(Actions.JOIN_FLOOR);

      dispatch(changeSpace());

      const { tableId } = await joinFloorRequest({
        eventId,
        currentFloorId,
        targetFloorId,
      });

      await setConversationId({ conversationId: tableId });

      return { currentFloorId, targetFloorId };
    },
    {
      onSuccess(_, { targetFloorId }) {
        logger.info("user switched floors", {
          targetFloorId,
          eventId,
          currentFloorId,
        });
        clearFloorDataAndRegisterListeners(targetFloorId);

        trackActionEnd(Actions.JOIN_FLOOR, Actions.JOIN_FLOOR_SUCCESS, {
          targetFloorId,
          currentFloorId,
          eventId,
        });
      },

      onError(error: Error, { targetFloorId }) {
        const errorMessage = getErrorMessage(error.message);

        if (errorMessage) {
          dispatch(
            addErrorNotification({
              message: t(errorMessage),
            }),
          );
        }

        trackActionEnd(Actions.JOIN_FLOOR, Actions.JOIN_FLOOR_FAILURE, {
          targetFloorId,
          currentFloorId,
          eventId,
          reason: errorMessage,
        });
      },
      onSettled() {
        dispatch(changeSpaceCompleted());
      },
    },
  );

  return { switchFloor, isLoading, status };
};
