import { Suspense, ReactElement, useState } from "react";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { ConnectedRouter } from "connected-react-router";
import { QueryClientProvider } from "@tanstack/react-query";
import { datadogLogs } from "@datadog/browser-logs";
import getUserLanguage from "i18n/utils/getUserLanguage";
import { ErrorBoundary } from "react-error-boundary";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./global-style.scss";
import "@remo-co/ui-core/src/globalStyles.scss";
import logger from "logging/logger";
import { SplitProvider } from "services/splitService";
import { getVar } from "config";
import PageSettingProvider from "modules/pageSetting/context/pageSettingContext";
import { TrackingProvider } from "modules/tracking";
import store, { history } from "store";
import ErrorPage from "modules/app/pages/ErrorPage";
import { ApolloProvider } from "@apollo/client";
import "./i18n/config";
import client from "modules/graphql";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import { Provider as ThemeProvider } from "@remo-co/ui-core/src/components/Provider";
import { AudioVideoProvider } from "modules/audioVideo/provider";
import { I18nProvider, useI18n } from "./i18n";
import { getEnvironment } from "./helpers/getEnvironment";
import { lazyLoad } from "./helpers/lazyLoader";
import { queryClient } from "./services/apiService/queryClient";

const App = lazyLoad(() => import("./modules/app/App"));

const environment = getEnvironment();

datadogLogs.init({
  clientToken: getVar("REACT_APP_DATADOG_CLIENT_TOKEN"),
  env: environment,
  forwardConsoleLogs: ["error", "warn"],
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: "remo-conference-client",
  site: "datadoghq.com",
  trackSessionAcrossSubdomains: true,
  useCrossSiteSessionCookie: true,
  useSecureSessionCookie: true,
  version: getVar("REACT_APP_VERSION"),
});

if (environment !== "development") {
  datadogLogs.logger.setLevel("info"); // This should filter debug logs out in prod (https://docs.datadoghq.com/logs/log_collection/javascript/#npm-10).
}

const RemoConference = (): ReactElement => {
  const { t } = useI18n(["common"]);
  const [lang] = useState(
    "languages" in navigator
      ? getUserLanguage(navigator.languages)
      : getUserLanguage([navigator.language]),
  );

  if (!App) {
    logger.error("[App] failed to lazy load.");

    return (
      <ErrorPage
        error={{
          name: t("error.unexpected"),
          message: t("error.unknown"),
        }}
        info={{ componentStack: "App" }}
      />
    );
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <Provider store={store}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <SplitProvider>
              <ConnectedRouter history={history}>
                <PageSettingProvider>
                  <Suspense fallback={<PageLoader message="Loading..." />}>
                    <TrackingProvider>
                      <I18nProvider language={lang.toString()}>
                        <QueryClientProvider client={queryClient}>
                          <DndProvider backend={HTML5Backend}>
                            <AudioVideoProvider>
                              <HelmetProvider>
                                <App />
                              </HelmetProvider>
                            </AudioVideoProvider>
                          </DndProvider>
                        </QueryClientProvider>
                      </I18nProvider>
                    </TrackingProvider>
                  </Suspense>
                </PageSettingProvider>
              </ConnectedRouter>
            </SplitProvider>
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default RemoConference;
