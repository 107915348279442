import { ReactNode } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { useStyles } from "./styles";

export const VoteGraph = ({
  children,
  backgroundColor,
  votes,
  totalVotes,
  hidden,
}: {
  children: ReactNode;
  backgroundColor: string;
  votes: number;
  totalVotes: number;
  hidden: boolean;
}) => {
  const { t } = useI18n(["event"]);

  const percentage =
    totalVotes > 0 ? Math.floor(((votes ?? 0) * 100) / totalVotes) : 0;

  const answersAmount =
    votes === 1 ? `${votes} ${t("answer")}` : `${votes} ${t("answers")}`;

  const classes = useStyles({
    backgroundColor,
    percentage,
  });

  return (
    <Container flex alignItems="center">
      {!hidden && <div className={classes.votesGraph} />}
      {children}
      {!hidden && (
        <div className={classes.pollAnswerValuePercentage}>
          <Typography variant="subtitle1">{`${percentage}%`}</Typography>
          <Typography variant="subtitle1">{answersAmount}</Typography>
        </div>
      )}
    </Container>
  );
};
