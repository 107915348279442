import { Errors, trackError } from "modules/monitoring";
import { getVar } from "config";
import { VPNAPIResponse } from "./types";

export const VPN_API_BASE_URL = "https://vpnapi.io/api";

export const getVPNAPIUrl = (ipAddress: string): string =>
  `${VPN_API_BASE_URL}/${ipAddress}?key=${getVar(
    "REACT_APP_VPN_DETECTION_API_KEY",
  )}`;

const isVPNDetected = async (ipAddress: string): Promise<boolean> => {
  try {
    if (ipAddress) {
      const result = await fetch(getVPNAPIUrl(ipAddress));
      const response = await result.json();

      if (result.status === 429) {
        trackError(response, { label: Errors.VPN_QUOTA_LIMIT_REACHED });
      }

      return response && response.security
        ? (response as VPNAPIResponse).security.vpn
        : false;
    }

    return false;
  } catch (error) {
    trackError(error, { label: Errors.VPN_DETECTION });

    return false;
  }
};

export default isVPNDetected;
