import React, { useContext } from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogActions } from "@remo-co/ui-core/src/components/DialogActions";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { InputLabel } from "@remo-co/ui-core/src/components/InputLabel";
import { TextField } from "@remo-co/ui-core/src/components/TextField";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { DialogCloseButton } from "@remo-co/ui-core/src/components/DialogCloseButton";
import { PageLoader } from "@remo-co/ui-core/src/components/PageLoader";
import { Select } from "@remo-co/ui-core/src/components/Select";
import { Delete } from "@remo-co/ui-core/src/icons/Delete";
import logger from "logging/logger";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useI18n } from "i18n";
import {
  EventAgendaColor,
  MAX_AGENDA_DURATION,
  MIN_AGENDA_DURATION,
} from "./constants";
import { EVENT_AGENDA_CONTEXT } from "./context/EventAgendaContext";
import eventAgendaAPI from "./eventAgenda.api";
import "./NewAgendaItem.scss";
import DurationInput from "./DurationInput";
import useEventAgenda from "./context/useEventAgenda";
import { IEventAgenda, EventAgendaColorKeys } from "./types";
import ColorOptionIcon from "./ColorOptionIcon";

interface INewAgendaItemProps {
  onAfterSave: () => void;
  agenda?: IEventAgenda;
}

const NewAgendaItem = ({ onAfterSave, agenda }: INewAgendaItemProps) => {
  const { eventData, state } = React.useContext(EVENT_AGENDA_CONTEXT);
  const agendaItems = state?.agendaItems || null;
  const currentDate = state?.currentDate || null;
  const propsAgenda = {
    description: "",
    title: "",
    color: Object.keys(EventAgendaColor)[0] as EventAgendaColorKeys,
    rank: agendaItems?.size || 0,
    duration: 30,
    actions: [],
    offset: 0,
    ...agenda,
  };
  const [agendaItem, setAgendaItem] = React.useState<IEventAgenda>(propsAgenda);
  const { addSuccessNotification, addErrorNotification } =
    useNotificationActions();
  const [isOpen, setIsOpen] = React.useState(true);
  const { deleteAgenda, isInProgress, setIsInProgress } = useEventAgenda();
  const { track } = useContext(TRACKING_CONTEXT);
  const { t } = useI18n([
    "common",
    "eventAgenda",
    "micCamCheck",
    "errorPage",
    "server",
  ]);

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    setAgendaItem({ ...agendaItem, [e.target.name]: val });
  };
  const onDurationChange = (name: string, value: number) => {
    setAgendaItem({ ...agendaItem, [name]: value });
  };
  const onSelectChange = (value: string, name = "") => {
    setAgendaItem({ ...agendaItem, [name]: value });
  };

  const onClose = () => {
    setIsOpen(false);
    onAfterSave();
  };

  const onFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    logger.info("[NewAgendaItem] adding", agendaItem);
    setIsInProgress(t("eventAgenda:loading.agenda.add"));

    if (!eventData || !eventData.id) {
      logger.warn("[NewAgendaItem] adding failed, no eventData or id passed");

      return;
    }

    if (!currentDate) {
      logger.warn("[NewAgendaItem] adding failed, no currentDate passed");

      return;
    }

    if (agendaItem.duration < MIN_AGENDA_DURATION) {
      addErrorNotification({
        message: t("eventAgenda:min.duration.message"),
      });
      setIsInProgress(null);

      return;
    }

    if (agendaItem.duration > MAX_AGENDA_DURATION) {
      addErrorNotification({
        message: t("eventAgenda:max.duration.agenda"),
      });
      setIsInProgress(null);

      return;
    }

    const result = await eventAgendaAPI.upsertEventAgenda(
      eventData.id,
      currentDate,
      agendaItem,
    );

    if (result.isSuccess) {
      addSuccessNotification({ message: t("eventAgenda:item.save.success") });
      track(Events.AGENDA_ITEM_ADDED, {
        eventId: eventData.id,
      });
      onAfterSave();
    } else {
      addErrorNotification({
        message: t("errorPage:agenda.save.failure", {
          key: `${t(result.message ?? "")}`,
        }),
      });
    }
    setIsInProgress(null);
  };

  const onDeleteItem = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    deleteAgenda(agendaItem, onClose);
  };

  const renderColors = () =>
    Object.entries(EventAgendaColor)
      .filter(([_key, { hidden }]) => !hidden)
      .map(([key, { color, label }]) => ({
        value: key,
        label: t(label),
        leftAdornment: <ColorOptionIcon color={color} />,
      }));

  return (
    <Dialog className="agenda-item-modal" open={isOpen} maxWidth="xs" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {agendaItem.id
            ? t("eventAgenda:edit.agenda.item")
            : t("eventAgenda:add.agenda.item")}
        </Typography>
      </DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <form onSubmit={onFormSubmit}>
        <DialogContent>
          <TextField
            name="title"
            label={t("title")}
            value={agendaItem.title}
            onChange={onFieldChange}
            inputProps={{ maxLength: 100 }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            required
            data-testid="title-input"
            helperText={t("character.remaining", {
              key: `${100 - agendaItem.title.length}`,
            })}
          />
          <div className="two-col">
            <div className="na-duration">
              <InputLabel>{t("duration")}</InputLabel>
              <DurationInput
                onFieldChange={onDurationChange}
                value={agendaItem.duration}
                name="duration"
              />
            </div>
            <div className="na-color">
              <InputLabel id="agenda-color">{t("color")}</InputLabel>
              <Select
                labelId="agenda-color"
                name="color"
                value={agendaItem.color}
                onChange={onSelectChange}
                options={renderColors()}
                fullWidth
                variant="outlined"
              />
            </div>
          </div>
          <TextField
            style={{ marginTop: 10 }}
            label={t("description")}
            name="description"
            multiline
            rows={6}
            value={agendaItem.description}
            inputProps={{ maxLength: 200 }}
            fullWidth
            onChange={onFieldChange}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            helperText={t("character.remaining", {
              key: `${200 - agendaItem.description.length}`,
            })}
          />
        </DialogContent>
        <DialogActions>
          {agendaItem.id ? (
            <Button
              className="delete-btn"
              size="md"
              variant="primary"
              color="red"
              onClick={onDeleteItem}
            >
              <Container flex alignItems="center">
                <Delete fontSize="small" />
                {t("micCamCheck:delete.item")}
              </Container>
            </Button>
          ) : (
            <Button
              variant="secondary"
              color="dark"
              size="md"
              onClick={onClose}
            >
              {t("button.cancel")}
            </Button>
          )}
          <Button size="md" variant="primary" color="blue" type="submit">
            {t("button.save.item")}
          </Button>
        </DialogActions>
        {isInProgress && <PageLoader fullScreen message={isInProgress} />}
      </form>
    </Dialog>
  );
};

export default NewAgendaItem;
