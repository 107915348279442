/* eslint-disable jsx-a11y/media-has-caption */
import { useRef } from "react";
import { useParticipantProperty } from "@daily-co/daily-react";
import { useStreams } from "modules/audioVideo/components/Tile/Video/useStreams";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { DAILY_PLAYABLE_TRACK_STATES } from "modules/audioVideo/constants";
import { useSelector } from "react-redux";
import { selectBrowserName } from "modules/micCamCheck/systemCheck/redux/selectors";
import { BROWSER_NAMES } from "modules/systemCheck/constants";
import { useDailyLocalNetwork } from "modules/audioVideo/hooks/useDailyLocalNetwork";
import { BaseTile } from "../BaseTile";
import { useStyles } from "../Video/styles";
import { DisplayItem } from "../../DailyContainer/Layouts/types";

interface Props {
  isClickable?: boolean;
  id: string;
}
const Screen = ({ isClickable, id }: Props): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLVideoElement>(null);
  const [userName, audioTrack, videoTrack, audioState, videoState, isLocal] =
    useParticipantProperty(id.replace("-screen", ""), [
      "user_name",
      "tracks.screenAudio.persistentTrack",
      "tracks.screenVideo.persistentTrack",
      "tracks.screenAudio.state",
      "tracks.screenVideo.state",
      "local",
    ]);

  const { isInterrupted: isNetworkConnectionInterrupted } =
    useDailyLocalNetwork();
  const styles = useStyles();
  const { t } = useI18n(["common", "event"]);
  const browserName = useSelector(selectBrowserName);
  const videoType = "screen";

  useStreams({
    audioRef,
    audioTrack,
    videoRef,
    videoTrack,
    audioState,
    videoState,
  });

  const showScreenUnpausePrompt =
    isLocal &&
    [BROWSER_NAMES.SAFARI, BROWSER_NAMES.MOBILE_SAFARI].includes(browserName) &&
    videoState === "interrupted";

  return (
    <BaseTile
      videoType={videoType}
      hasAudio={DAILY_PLAYABLE_TRACK_STATES.includes(audioState)}
      tile={{ streamId: id, type: DisplayItem.screenShare }}
      isClickable={isClickable}
      isLocal={isLocal}
      isInterrupted={
        (isLocal && isNetworkConnectionInterrupted) ||
        audioState === "interrupted" ||
        videoState === "interrupted"
      }
    >
      {showScreenUnpausePrompt ? (
        <Typography variant="caption" className={styles.tileOverlay}>
          {t("event:safari.paused.screen")}
        </Typography>
      ) : (
        <>
          <Typography variant="body1" className={styles.userName}>
            {t("user.shared.screen", { name: userName })}
          </Typography>
          <video
            data-testid={`screen-${id}`}
            autoPlay
            playsInline
            height="100%"
            width="100%"
            ref={videoRef}
          />
          {!isLocal && <audio autoPlay ref={audioRef} />}
        </>
      )}
    </BaseTile>
  );
};

export default Screen;
