import logger from "logging/logger";
import { database } from "services/firebaseService/firebaseConfig";
import { getCurrentSpaceId } from "helpers/reduxHelper";
import { asyncResult } from "services/firebaseService/firebase";

const HOST_SEAT_NOTIFICATION_PATH = "HostSeat";
const getHostSeatNotificationRef = (roomId: string, spaceId: string) =>
  database.ref(`/Space/${spaceId}/${HOST_SEAT_NOTIFICATION_PATH}/${roomId}`);

export const addNotificationForHostSeat = async (
  roomId: string,
  userId: string,
) => {
  const spaceId = getCurrentSpaceId();

  if (!spaceId) {
    return;
  }

  logger.info(
    `[addNotificationForHostSeat] roomId: ${roomId} userId: ${userId} spaceId: ${spaceId}`,
  );

  const ref = getHostSeatNotificationRef(roomId, spaceId);
  const existingReq = await asyncResult(ref);

  // For one room, retain only one request
  if (!existingReq) {
    logger.info(
      `[addNotificationForHostSeat] no existing request roomId: ${roomId} userId: ${userId} spaceId: ${spaceId}`,
    );
    await ref.set({
      userId,
    });

    // Remove this immediately, because we dont need to show this to admins who enter into the table after this request
    setTimeout(() => {
      logger.info(
        `[addNotificationForHostSeat] removing req request roomId: ${roomId} userId: ${userId} spaceId: ${spaceId}`,
      );
      ref.remove();
    }, 1000);
  }
  logger.info(
    `[addNotificationForHostSeat] already request existing roomId: ${roomId} userId: ${userId} spaceId: ${spaceId}`,
    existingReq,
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subscribeHostSeatRequest = (callback: any, roomId: string) => {
  const spaceId = getCurrentSpaceId();

  if (!spaceId) {
    return;
  }

  logger.info(
    `[subscribeHostSeatRequest] subscribe roomId: ${roomId} spaceId: ${spaceId}`,
  );

  const ref = getHostSeatNotificationRef(roomId, spaceId);

  ref.off();
  ref.on("value", (snap) => {
    const val = snap.val();

    if (val) {
      logger.info(
        `[subscribeHostSeatRequest] req created roomId: ${roomId} spaceId: ${spaceId}`,
        val,
      );
      callback(val);
    }
  });
};

export const unsubscribeHostSeatRequest = (roomId: string) => {
  const spaceId = getCurrentSpaceId();

  if (!spaceId) {
    return;
  }

  logger.info(
    `[unsubscribeHostSeatRequest] unsubscribe roomId: ${roomId} spaceId: ${spaceId}`,
  );

  const ref = getHostSeatNotificationRef(roomId, spaceId);

  ref.off();
};
