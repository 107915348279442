import { useCallback, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import logger from "logging/logger";
import { useAppDispatch } from "store/hooks";
import { selectUserId } from "modules/auth/redux/selectors";
import { selectCurrentEventId } from "modules/event/selectors";
import { Errors, trackError } from "modules/monitoring";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { fieldValue } from "services/firebaseService";
import { firestore } from "services/firebaseService/firebaseConfig";
import { turnOffProcessing, turnOnProcessing } from "../../redux";
import {
  TRANSCRIPTION_REQUESTS_COLLECTION,
  TRANSCRIPTION_REQUESTS_USERS_REF,
} from "../../constants";

const getTranscriptionRequestEventDoc = (eventId: string) =>
  firestore.collection(TRANSCRIPTION_REQUESTS_COLLECTION).doc(eventId);

const getTranscriptionRequests = (eventId: string) =>
  getTranscriptionRequestEventDoc(eventId).collection(
    TRANSCRIPTION_REQUESTS_USERS_REF,
  );

export const useTranscriptionRequests = () => {
  const { t } = useI18n(["event"]);
  const dispatch = useAppDispatch();
  const { track } = useContext(TRACKING_CONTEXT);
  const currentEventId = useSelector(selectCurrentEventId);
  const userId = useSelector(selectUserId);
  const { addErrorNotification } = useNotificationActions();

  const requestListener = useRef<{ listener: null | (() => void) }>({
    listener: null,
  });

  const registerTranscriptionRequest = async () => {
    if (!currentEventId || !userId) {
      return;
    }

    try {
      const doc = getTranscriptionRequests(currentEventId).doc(userId);

      await doc.set({
        timestamp: fieldValue.serverTimestamp(),
      });

      track(Events.TRANSCRIPTION_REQUEST_REGISTERED, {
        eventId: currentEventId,
        userId,
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;

      addErrorNotification({
        message: t("event:transcription.request.error"),
      });
      logger.error(
        `[Transcription] An error occurred while registering request: ${errorMessage}`,
        {
          eventId: currentEventId,
        },
      );
      trackError(error, {
        label: Errors.TRANSCRIPTION_REQUEST_REGISTER,
      });
    }
  };

  const revokeTranscriptionRequest = async () => {
    if (!currentEventId || !userId) {
      return;
    }
    try {
      await getTranscriptionRequests(currentEventId).doc(userId).delete();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;

      logger.error(
        `[Transcription] An error occurred while revoking request: ${errorMessage}`,
        {
          eventId: currentEventId,
        },
      );
      trackError(error, {
        label: Errors.TRANSCRIPTION_REQUEST_REVOKE,
      });
    }
  };

  const clearAllTranscriptionRequests = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    try {
      const querySnapshot = await getTranscriptionRequests(
        currentEventId,
      ).get();

      await Promise.all(querySnapshot.docs.map((doc) => doc.ref.delete()));
      await getTranscriptionRequestEventDoc(currentEventId).delete();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : error;

      logger.error(
        `[Transcription] An error occurred while clearing all requests: ${errorMessage}`,
        {
          eventId: currentEventId,
        },
      );
      trackError(error, {
        label: Errors.TRANSCRIPTION_REQUEST_CLEAR_ALL,
      });
    }
  }, [currentEventId]);

  const subscribe = () => {
    if (!currentEventId) {
      return;
    }

    requestListener.current.listener = getTranscriptionRequests(
      currentEventId,
    ).onSnapshot(({ docs }) => {
      if (docs.length > 0) {
        dispatch(turnOnProcessing());
      }

      if (docs.length === 0) {
        dispatch(turnOffProcessing());
      }
    });
  };

  const unsubscribe = () => {
    if (requestListener.current.listener) {
      requestListener.current.listener();
    }
  };

  return {
    registerTranscriptionRequest,
    revokeTranscriptionRequest,
    clearAllTranscriptionRequests,
    subscribe,
    unsubscribe,
  };
};
