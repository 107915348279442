import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",

    padding: `${theme.spacing(1)}px 0`,
  },
  icon: {
    width: "24px !important",
    height: "16px !important",
    marginRight: 5,
    color: theme.palette.black,
  },
  upIcon: {
    transform: "rotate(-90deg)",
    paddingLeft: "5px !important",
  },
  downIcon: {
    transform: "rotate(90deg)",
    paddingLeft: "5px !important",
  },
  leftIcon: {
    transform: "rotate(-180deg)",
  },
  rightIcon: {
    transform: "rotate(0deg)",
  },
  arrowButton: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    color: theme.palette.black,
    padding: 0,
  },
  label: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    width: "max-content",
  },
  iconDisabled: {
    color: theme.palette.gray.disabled,
    cursor: "default",
  },
  divider: {
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.secondary.main,
  },
}));
