import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "10px 16px",
    marginLeft: "-30px", // offset broadcast top bar left padding
    marginRight: "20px",
    borderRight: `1px solid ${theme.palette.blue.darkShade2}`,
  },
}));
