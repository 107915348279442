import loadImage from "../loadImage";

export const IMAGE_ADDRESS = "/sample-78840bbd00.png";

const IMAGE_SIZE_IN_KB = 3221818;

const calculateDownloadSpeed = (durationInSeconds: number): number => {
  const bitsLoaded = IMAGE_SIZE_IN_KB * 8;
  const speedBps = Number((bitsLoaded / durationInSeconds).toFixed(2));
  const speedKbps = Number((speedBps / 1024).toFixed(2));
  const speedMbps = Number((speedKbps / 1024).toFixed(2));

  return speedMbps;
};

const loadImageAndDetermineConnection = async (imageUrl: string) => {
  try {
    const startTime = Date.now();

    await loadImage(imageUrl);

    const downloadSpeedMbps = calculateDownloadSpeed(
      (Date.now() - startTime) / 1000,
    );

    return downloadSpeedMbps;
  } catch (error) {
    return null;
  }
};

const getInternetConnectionSpeed = async (): Promise<number | null> => {
  const imageAddress = `${IMAGE_ADDRESS}?r=${Date.now()}`;

  return loadImageAndDetermineConnection(imageAddress);
};

export default getInternetConnectionSpeed;
