import { useSelector } from "react-redux";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { DialogTitle } from "@remo-co/ui-core/src/components/DialogTitle";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Redirect, useHistory } from "react-router";
import { selectUser } from "modules/auth/redux/selectors";
import { ManageEventContextProvider } from "modules/manageEvent";
import { useI18n } from "i18n";
import { ManageEventActions } from "../ManageEventActions";
import ManageEvent from "../ManageEvent";
import "./styles.scss";

interface Props {
  pageId?: string;
  eventId?: string;
  token: string;
}

const ManageEventModal = (props: Props): JSX.Element | null => {
  const { t } = useI18n(["manageEvent"]);
  const { eventId, pageId, token } = props;
  const history = useHistory();
  const user = useSelector(selectUser);

  if (!pageId) {
    return null;
  }

  if (!user) {
    return (
      <Redirect
        to={`/signin?redirectTo=${encodeURIComponent(
          window.location.pathname,
        )}`}
      />
    );
  }
  const closeModal = () => {
    history.push(`/e/${token}`);
  };

  return (
    <ManageEventContextProvider eventId={eventId}>
      <Dialog open fullScreen className="manage-event-modal">
        <DialogTitle disableTypography className="dialog-title">
          <Container maxWidth="xl" className="title-container">
            <Typography className="title" variant="h3">
              {t("manageEvent:edit.event")}
            </Typography>
            <ManageEventActions
              onSuccessfulSave={closeModal}
              closeButtonLink={`/e/${token}`}
            />
          </Container>
        </DialogTitle>
        <DialogContent>
          <ManageEvent />
        </DialogContent>
      </Dialog>
    </ManageEventContextProvider>
  );
};

export default ManageEventModal;
