/**
 * @deprecated Access colors [through the theme instead](https://mui.com/system/styles/basics/#using-the-theme-context)
 */

export enum Colors {
  BLACK = "#000",
  BLUE_DARK = "#0E1F3C",
  BLUE_DARKSHADE1 = "#354767",
  BLUE_DARKSHADE2 = "#2c2f41",
  BLUE_DARKSHADE3 = "#494A56",
  BLUE_DARK_MODE = "#6482ff",
  BLUE_GRAYISH_DARK = "#333644",
  BLUE_LIGHTSHADE1 = "#DEEAFF",
  BLUE_LIGHTSHADE2 = "#235EFB",
  BLUE_LIGHTSHADE3 = "#f9feff",
  BLUE_LIGHTSHADE3_DISABLED = "#BCCEFF",
  BLUE_LIGHTSHADE4 = "#f0fcff",
  BLUE_LIGHTSHADE5 = "#B5E9FF",
  BLUE_PRIMARY = "#235EFB",
  BLUE_PRIMARY_HOVER = "#0D2F9D",
  CORRA_BROWN = "#342127",
  DARK_OVERLAY = "#000000E6",
  DARK_OVERLAY2 = "#000000CC",
  DISABLED = "#77829E",
  ERROR = "#DC3545",
  GRAY_DISABLED = "#88909F",
  GRAY_ICON = "#727274",
  PRESENTATION_MODE_BACKGROUND = "#1C1F2F",
  GRAY_DISABLED_INPUT = "#E9ECEF",
  GRAY_LIGHT = "#EAEEF2",
  GRAY_LIGHT_SHADE = "#FAFAFA",
  GRAY_MEDIUM = "#C7D1DB",
  GRAY_MUTED1 = "#DBDBDB",
  GRAY_MUTED2 = "#E5E5E5",
  GRAY_SWITCH_TRACK_DARK = "#DBE4FF",
  GREEN_ACTION = "#1CAC8A",
  GREEN_ACTION_DARK_MODE = "#2BEA36",
  GREEN_BACKGROUND = "#255A3D",
  GREEN_HOVER = "#B9E7DE",
  GREEN_HOVER_DARK_MODE = "#1C2A2B",
  GREEN_LIGHT = "#DFF4E0",
  GREEN_MAIN = "#16871c",
  ORANGE = "#fff8f1",
  PRESENT_BLACK_MUTED = "#151515",
  PRESENT_GREEN = "#2CEA36",
  PRESENT_GREEN_BG = "#1C2A2B",
  PRESENT_GREY_BG = "#484a56",
  PRESENT_RED = "#FC5555",
  PRESENT_RED_BG = "#352227",
  PRESENT_WHITE = "#E8E9EA",
  RED_DANGER = "#F74949",
  RED_DANGER_HOVER = "#FFBBBB",
  RED_DANGER_LIGHT = "#FFDDDD",
  SALMON_PASTEL = "#FF8778",
  SUCCESS = "#16871c",
  WARNING = "#FFA500",
  WHITE = "#FFFFFF",
  YELLOW_DARK = "#E9BE25",
  YELLOW_LIGHTSHADE_1 = "#FEFFE1",
}
