import { createSelector } from "@reduxjs/toolkit";
import {
  selectUser,
  selectUserId,
  selectUserEmail,
} from "modules/auth/redux/selectors";
import { makeSelectDeviation } from "modules/serverTime/redux/selectors";
import { CompanyFeatureToggles } from "modules/company/constants";
import { RootState } from "store";
import { selectCompanySettingsFeatureToggles } from "modules/companySettings/redux/selectors";
import { isEventManager, isEventSpeaker } from "./utils/eventUtils";
import { EventStates } from "./constants";

const selectEvent = (state: RootState) => state.event;
const selectEvents = createSelector(selectEvent, (event) => event.events);
const selectCurrentEventId = createSelector(
  selectEvent,
  (event) => event.currentEventId,
);

const selectNewEventId = createSelector(
  selectEvent,
  (event) => event.newEventId,
);
const selectCurrentEvent = createSelector(
  selectEvents,
  selectCurrentEventId,
  (events, currentEventId) => {
    if (currentEventId) {
      return events?.[currentEventId] ?? null;
    }

    return null;
  },
);

const selectLobbyWelcomeMessage = createSelector(
  selectCurrentEvent,
  (currentEvent) => currentEvent?.lobbyWelcomeMessage ?? "",
);
const selectEventLogoUrl = createSelector(
  selectCurrentEvent,
  (currentEvent) => currentEvent?.logoURL ?? null,
);
const selectEventCompany = createSelector(
  selectCurrentEvent,
  (currentEvent) => currentEvent?.company ?? null,
);
const selectEventCompanyId = createSelector(selectEventCompany, (company) => {
  if (typeof company === "string") {
    return company;
  }

  return company?.id ?? null;
});

export const selectEventCompanyCreatedAt = createSelector(
  selectEventCompany,
  (company) => {
    if (typeof company === "string") {
      return null;
    }

    return company?.createdAt ?? null;
  },
);

const selectCurrentEventCode = createSelector(
  selectCurrentEvent,
  (currentEvent) => currentEvent?.code ?? null,
);

const selectDirectoriesOfCurrentEvent = createSelector(
  selectCurrentEvent,
  (currentEvent) => {
    const directories = [];
    if (currentEvent?.directoryId) {
      directories.push(currentEvent?.directoryId);
    }
    if (currentEvent?.directories) {
      directories.push(...(currentEvent?.directories ?? []));
    }
    return directories;
  },
);

const selectCurrentEventCompanyOwner = createSelector(
  selectEventCompany,
  (company) => {
    if (company && typeof company !== "string") {
      return company.owner;
    }

    return null;
  },
);

export const selectCurrentEventCompanyWhiteLabelledLogo = createSelector(
  selectEventCompany,
  // eslint-disable-next-line consistent-return
  (company) => {
    if (company && typeof company !== "string") {
      return company.enabledWhiteLabel;
    }
  },
);

export const selectCurrentEventCompanyLogoUrl = createSelector(
  selectEventCompany,
  // eslint-disable-next-line consistent-return
  (company) => {
    if (company && typeof company !== "string") {
      return company.logoURL;
    }
  },
);
const selectCurrentEventIsLobbyActive = createSelector(
  selectCurrentEvent,
  (currentEvent) => currentEvent?.isLobbyActive ?? false,
);
const selectCurrentEventName = createSelector(
  selectCurrentEvent,
  (currentEvent) => currentEvent?.name ?? "",
);
const selectCurrentTheater = createSelector(
  selectCurrentEvent,
  (currentEvent) => currentEvent?.theaters?.[0] ?? null,
);
const makeSelectIsUserSpeaker = (userId: string) =>
  createSelector(
    selectCurrentEvent,
    (currentEvent) => !!currentEvent?.speakers?.includes(userId),
  );
const selectIsAudienceViewEnabled = createSelector(
  selectCurrentEvent,
  (currentEvent) => !!(currentEvent && !currentEvent.isAudienceViewDisabled),
);
const selectIsRemojiEnabled = createSelector(
  selectCurrentEvent,
  (currentEvent) => !!(currentEvent && !currentEvent.isRemojiDisabled),
);
const selectIsGuestExperienceSurveyEnabled = createSelector(
  selectCurrentEvent,
  (currentEvent) =>
    !!(currentEvent && !currentEvent.isGuestExperienceSurveyDisabled),
);

const selectCurrentEventCompanyPlan = createSelector(
  selectEvent,
  (substate) => substate.currentEventCompanyPlan,
);

const selectCurrentEventCompany = createSelector(
  selectEvent,
  (substate) => substate.currentEventCompany,
);

const selectIsQnAEnabled = createSelector(
  selectCurrentEvent,
  selectCompanySettingsFeatureToggles,
  (currentEvent, disabledFeatures) => {
    if (disabledFeatures?.includes(CompanyFeatureToggles.QA)) {
      return false;
    }

    return !!(currentEvent && !currentEvent.isQnADisabled);
  },
);
const selectIsChatSupportEnabled = createSelector(
  selectCurrentEvent,
  (currentEvent) => !!(currentEvent && !currentEvent.isChatSupportDisabled),
);
const selectIsChatEnabled = createSelector(
  selectCurrentEvent,
  selectCompanySettingsFeatureToggles,
  (currentEvent, disabledFeatures) => {
    if (disabledFeatures?.includes(CompanyFeatureToggles.Chat)) {
      return false;
    }

    return !!(currentEvent && !currentEvent.isChatDisabled);
  },
);
const selectIsChatUploadEnabled = createSelector(
  selectCompanySettingsFeatureToggles,
  (disabledFeatures) => {
    if (disabledFeatures?.includes(CompanyFeatureToggles.ChatUpload)) {
      return false;
    }

    return true;
  },
);
const selectIsWhiteboardEnabled = createSelector(
  selectCurrentEvent,
  selectCompanySettingsFeatureToggles,
  (currentEvent, disabledFeatures) => {
    if (disabledFeatures?.includes(CompanyFeatureToggles.Whiteboard)) {
      return false;
    }

    return !!(currentEvent && !currentEvent.isWhiteboardDisabled);
  },
);
const selectIsLockTablesByGuestsEnabled = createSelector(
  selectCurrentEvent,
  selectCompanySettingsFeatureToggles,
  (currentEvent, disabledFeatures) => {
    if (disabledFeatures?.includes(CompanyFeatureToggles.LockTables)) {
      return false;
    }

    return !!(currentEvent && !currentEvent.isLockTablesDisabled);
  },
);
const selectIsPollsEnabled = createSelector(
  selectCurrentEvent,
  selectCompanySettingsFeatureToggles,
  (currentEvent, disabledFeatures) => {
    if (disabledFeatures?.includes(CompanyFeatureToggles.Polls)) {
      return false;
    }

    return !!(currentEvent && !currentEvent.isPollsDisabled);
  },
);
const selectIsScreenShareEnabled = createSelector(
  selectCurrentEvent,
  (currentEvent) =>
    Boolean(currentEvent && !currentEvent.isScreenShareDisabled),
);
const selectIsSpeaker = createSelector(
  selectCurrentEvent,
  selectUser,
  // @ts-expect-error TODO: if user is not defined, then it's automatic false
  (currentEvent, user) => currentEvent?.speakers?.includes(user?.id) ?? false,
);
const selectUpcomingMyEvents = createSelector(selectEvent, (event) =>
  event.upcomingMyEventIds.map((eventId: string) => event.events[eventId]),
);
const selectPastMyEvents = createSelector(selectEvent, (event) =>
  event.pastMyEventIds.map((eventId: string) => event.events[eventId]),
);
const selectOngoingMyEvents = createSelector(selectEvent, (event) =>
  event.ongoingMyEventIds.map((eventId: string) => event.events[eventId]),
);

const selectUnlimitedOngoingAndUpcomingEvents = createSelector(
  selectEvent,
  (event) => {
    const ongoingUnlimitedEvents = event.ongoingMyEventIds
      .map((eventId: string) => event.events[eventId])
      .filter((eventData) => eventData?.isUnlimitedEvent);
    const upcomingUnlimitedEvents = event.upcomingMyEventIds
      .map((eventId: string) => event.events[eventId])
      .filter((eventData) => eventData?.isUnlimitedEvent);
    return [...upcomingUnlimitedEvents, ...ongoingUnlimitedEvents];
  },
);

const selectUpcomingJoinedEvents = createSelector(selectEvent, (event) =>
  event.upcomingJoinedEventIds.map((eventId: string) => event.events[eventId]),
);
const selectOngoingJoinedEvents = createSelector(selectEvent, (substate) =>
  substate.ongoingJoinedEventIds.map(
    (eventId: string) => substate.events[eventId],
  ),
);
const selectNewEvent = createSelector(
  selectEvents,
  selectNewEventId,
  (events, newEventId) => {
    if (newEventId) {
      return events[newEventId];
    }

    return null;
  },
);
const selectEventState = createSelector(
  selectCurrentEvent,
  makeSelectDeviation(),
  (event, deviation) => {
    if (!event || deviation === null) {
      return null;
    }

    const nowTime = Date.now() + deviation;
    const { startTime, endTime, status } = event;

    if (status === "active" && nowTime < startTime) {
      return EventStates.UPCOMING;
    }

    if (status === "active" && startTime < nowTime && nowTime < endTime) {
      return EventStates.IN_PROGRESS;
    }

    return EventStates.ENDED;
  },
);
const selectIsEventManager = createSelector(
  selectEvents,
  selectCurrentEventId,
  selectUserId,
  (allEvents, currentEventId, userId): boolean =>
    Boolean(
      currentEventId &&
        userId &&
        isEventManager(allEvents[currentEventId], userId),
    ),
);
const selectIsEventSpeaker = createSelector(
  selectEvents,
  selectCurrentEventId,
  selectUserId,
  selectUserEmail,
  (allEvents, currentEventId, userId, userEmail): boolean =>
    Boolean(
      currentEventId &&
        userId &&
        isEventSpeaker(allEvents[currentEventId], userId, userEmail),
    ),
);
const selectIsEventOwner = createSelector(
  selectCurrentEventId,
  selectUserId,
  selectCurrentEventCompanyOwner,
  (currentEventId, userId, eventCompanyOwner): boolean =>
    Boolean(currentEventId && userId && eventCompanyOwner === userId),
);
const selectEventManagerIds = () =>
  createSelector(selectCurrentEvent, (currentEvent) => currentEvent?.managers);
const selectEventSpeakerIds = () =>
  createSelector(selectCurrentEvent, (currentEvent) => currentEvent?.speakers);

export {
  selectEventManagerIds,
  selectEventSpeakerIds,
  selectEventLogoUrl,
  selectEventCompany,
  selectCurrentEventIsLobbyActive,
  selectCurrentEventName,
  selectEventCompanyId,
  selectCurrentEventCompany,
  selectCurrentEventCompanyPlan,
  selectCurrentEventCode,
  selectCurrentEventCompanyOwner,
  selectCurrentEventId,
  selectNewEventId,
  selectCurrentEvent,
  selectCurrentTheater as selectTheater,
  selectEvents,
  selectIsSpeaker,
  selectLobbyWelcomeMessage,
  makeSelectIsUserSpeaker,
  selectUpcomingMyEvents,
  selectPastMyEvents,
  selectUpcomingJoinedEvents,
  selectOngoingMyEvents,
  selectOngoingJoinedEvents,
  selectNewEvent,
  selectIsAudienceViewEnabled,
  selectIsRemojiEnabled,
  selectEventState,
  selectIsQnAEnabled,
  selectIsChatEnabled,
  selectIsChatUploadEnabled,
  selectIsChatSupportEnabled,
  selectIsWhiteboardEnabled,
  selectIsLockTablesByGuestsEnabled,
  selectIsPollsEnabled,
  selectIsEventManager,
  selectIsEventSpeaker,
  selectIsEventOwner,
  selectIsGuestExperienceSurveyEnabled,
  selectIsScreenShareEnabled,
  selectDirectoriesOfCurrentEvent,
  selectUnlimitedOngoingAndUpcomingEvents,
};
