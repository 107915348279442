import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { FormHelperText } from "@remo-co/ui-core/src/components/FormHelperText";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { FormTextInput } from "@remo-co/ui-core/src/components/FormTextInput";
import { SwitchInput } from "@remo-co/ui-core/src/components/SwitchInput";
import { ChangeEvent, useEffect, useState, ReactNode } from "react";
import { validateVideoURL } from "helpers/videoHelper";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import UploadCaption, { BestUploadSizes } from "modules/uploader/UploadCaption";
import { ImageUploader } from "modules/uploader";
import SponsorUploadAndPreview from "modules/eventForm/sponsorship/SponsorUploadAndPreview";
import { IEvent, IWelcomeMessage, NativeMediaType } from "modules/event/types";
import * as FileStoragePaths from "services/firebaseService/storagePaths";
import { maxMessageLength } from "../../constants";
import { useStyles } from "./styles";

export interface WelcomeSettingsInputField {
  error: boolean;
  value: string;
  message: string;
}

interface Props {
  defaultWelcomeMessage: IWelcomeMessage;
  mediaURL: WelcomeSettingsInputField;
  mediaType?: NativeMediaType;
  message: WelcomeSettingsInputField;
  setMediaURL: (mediaUrl: WelcomeSettingsInputField) => void;
  setMediaType: (mediaType: NativeMediaType) => void;
  setMessage: (message: WelcomeSettingsInputField) => void;
  onEventDataChange: (data: Partial<IEvent>) => void;
  openPreview: () => void;
}

const WelcomeSettings = ({
  defaultWelcomeMessage,
  mediaURL,
  mediaType,
  message,
  setMediaURL,
  setMediaType,
  setMessage,
  onEventDataChange,
  openPreview,
}: Props): JSX.Element => {
  const styles = useStyles();
  const { addErrorNotification } = useNotificationActions();

  const { isActive } = defaultWelcomeMessage;

  const mediaValue = mediaURL.value;
  const isValidVideoUrl = mediaValue && validateVideoURL(mediaValue);
  const validVideoUrl =
    mediaType === "video" && isValidVideoUrl ? mediaValue : "";
  const [isTextDefault, setIsTextDefault] = useState(false);
  const [isMediaDefault, setIsMediaDefault] = useState(false);
  const { t } = useI18n(["common", "eventForm", "manageEvent", "eventForm"]);
  const validateChanges = () => {
    if (mediaValue.length > 0 && mediaType === "video" && !isValidVideoUrl) {
      addErrorNotification({
        message: t("eventForm:invalid.url.input"),
        position: "tc",
        autoDismiss: 5,
      });
    }
  };

  const toggleIsTextDefault = () => {
    setIsTextDefault(!isTextDefault);
  };

  const toggleIsMediaDefault = () => {
    setIsMediaDefault(!isMediaDefault);
  };

  const handleMessage = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage({ error: false, value: e.target.value, message: "" });
  };

  const handleMediaURLImage = (value: string) => {
    setMediaURL({ error: false, value, message: "" });
  };

  const handleMediaURL = (e: ChangeEvent<HTMLInputElement>) => {
    setMediaURL({ error: false, value: e.target.value, message: "" });
  };

  const checkboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (mediaType === e.target.value) {
      return;
    }
    setMediaType(e.target.value as NativeMediaType);
    setMediaURL({ error: false, value: "", message: "" });
  };

  const toggleIsActive = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onEventDataChange({
      welcomeMessage: {
        ...defaultWelcomeMessage,
        message: message.value,
        mediaURL: mediaType === "video" ? validVideoUrl : mediaValue,
        mediaType,
        isActive: checked,
      },
      isTextDefault,
      isMediaDefault,
    } as unknown as IEvent);
  };

  const onDelete = () => {
    setMediaURL({ error: false, value: "", message: "" });
  };

  useEffect(() => {
    const data = {
      welcomeMessage: {
        title: defaultWelcomeMessage.title,
        message: message.value,
        mediaURL: mediaType === "video" ? validVideoUrl : mediaValue,
        isActive,
        mediaType,
      },
      isTextDefault,
      isMediaDefault,
    };

    const debouncedEventDataUpdate = setTimeout(
      () => onEventDataChange(data),
      250,
    );

    return () => {
      clearTimeout(debouncedEventDataUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validVideoUrl,
    mediaValue,
    mediaType,
    message,
    isActive,
    isTextDefault,
    isMediaDefault,
  ]);
  return (
    <>
      <div>
        <Typography variant="h3">
          {t("eventForm:onboard.welcome.message")}
        </Typography>
        <Typography variant="body1">
          {t("eventForm:show.guest.message")}
        </Typography>
        <div className="mar-top-40">
          <SwitchInput
            id="onboard-toggle"
            data-testid="onboard-toggle"
            label={t("eventForm:label.welcome.message")}
            checked={isActive}
            onChange={toggleIsActive}
          />
        </div>
      </div>
      {isActive && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            className={styles.heading}
          >
            <Typography variant="h4" className={styles.title}>
              {t("eventForm:customize.welcome.message")}
            </Typography>
            <Button
              variant="secondary"
              color="blue"
              size="md"
              onClick={openPreview}
              data-testid="welcome-preview-button"
            >
              {t("event:preview")}
            </Button>
          </Box>
          <FormTextInput
            className="mar-top-20"
            disabled={!isActive}
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            getRemainingCharsMessage={(key) =>
              t("character.remaining", { key })
            }
            InputLabelProps={{ shrink: true }}
            name="message"
            placeholder={t("eventForm:label.welcome.text")}
            value={message.value}
            onChange={handleMessage}
            inputProps={{
              maxLength: maxMessageLength,
              style: { lineHeight: "1.2rem", minHeight: "160px" },
            }}
          />
          <SwitchInput
            disabled={!isActive}
            label={t("eventForm:switch.use.welcome.message")}
            checked={isTextDefault}
            onChange={toggleIsTextDefault}
          />
          <Typography variant="h4" className="mar-top-60 mar-bottom-20">
            {t("eventForm:add.image.video.message")}
          </Typography>
          <div style={{ marginBottom: "10rem" }}>
            <div className="mar-bottom-20">
              <RadioGroup
                aria-label={t("eventForm:label.upload.image")}
                name="mediaUrl"
                value={mediaType}
                onChange={checkboxClick}
                row
              >
                <FormControlLabel
                  disabled={!isActive}
                  value="image"
                  control={<Radio color="primary" />}
                  label={t("eventForm:form.label.image")}
                />
                <FormControlLabel
                  disabled={!isActive}
                  value="video"
                  control={<Radio color="primary" />}
                  label={t("eventForm:form.label.video")}
                />
              </RadioGroup>
            </div>
            {mediaType === "video" ? (
              <FormTextInput
                disabled={!isActive}
                variant="outlined"
                fullWidth
                onBlur={validateChanges}
                InputLabelProps={{ shrink: true }}
                name="medialURL"
                placeholder={t("eventForm:form.url.placeholder")}
                value={mediaURL.value}
                onChange={handleMediaURL}
                tooltip={
                  (
                    <ul style={{ padding: "1rem", wordBreak: "break-all" }}>
                      <li>{t("eventForm:ensure.url.message")}</li>
                      <li>{t("eventForm:ensure.url.example")}</li>
                    </ul>
                  ) as unknown as ReactNode
                }
                error={!!mediaURL.value && !validateVideoURL(mediaURL.value)}
                helperText={
                  !!mediaURL.value && !validateVideoURL(mediaURL.value)
                    ? t("eventForm:url.invalid")
                    : ""
                }
              />
            ) : (
              <div className="pad-top-20">
                <ImageUploader
                  disabled={!isActive}
                  name="medialURL"
                  level={FileStoragePaths.UPLOAD_LEVEL.EVENT}
                  path={FileStoragePaths.EVENT_MEDIA}
                  onImageUploaded={handleMediaURLImage}
                  data-testid="image-uploader"
                  variant="text"
                  size="lg"
                  className={styles.uploader}
                >
                  <SponsorUploadAndPreview
                    onDelete={onDelete}
                    dataKey="medialURL"
                    uploadedImage={mediaURL.value}
                    disabled={!isActive}
                    helpText={
                      <FormHelperText>
                        <UploadCaption
                          allowGif
                          bestSize={BestUploadSizes.portrait}
                        />
                      </FormHelperText>
                    }
                  />
                </ImageUploader>
              </div>
            )}

            <div className="pad-top-20">
              <SwitchInput
                disabled={!isActive}
                label={t("eventForm:use.image.future")}
                checked={isMediaDefault}
                onChange={toggleIsMediaDefault}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WelcomeSettings;
