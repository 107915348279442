import { useRef } from "react";
import firebase from "firebase/compat/app";
import { listQuestions } from "../firebase";

const useQAActions = () => {
  const qaRef = useRef<firebase.database.Query>();

  const subscribe = (eventId: string) => {
    qaRef.current = listQuestions(eventId);
  };
  const unsubscribe = () => {
    if (qaRef.current) {
      qaRef.current.off();
    }
  };

  return {
    subscribe,
    unsubscribe,
  };
};

export default useQAActions;
