import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getSystemInfo,
  SystemInfoWithTroubleShootLinks,
} from "modules/systemCheck";
import { ReachabilityTestResult } from "modules/micCamCheck/systemCheck/hooks/useFirewallTest/types";
import { ReachabilityTest } from "modules/micCamCheck/systemCheck/hooks/useFirewallTest/constants";

export type TestResult = {
  isLoading: boolean;
  isSuccessful: boolean | null;
  isDirty: boolean;
};

export type DirtyStatus = {
  value: boolean;
  skipDailyTest?: boolean;
};

interface InternetSpeedTestResult extends Omit<TestResult, "isSuccessful"> {
  downloadSpeedMbps: number | null;
}

export interface SystemCheckReducerState {
  isSystemCheckCompleted: boolean;
  firebaseResult: TestResult;
  dailyResult: TestResult;
  firestoreResult: TestResult;
  internetSpeedResult: InternetSpeedTestResult;
  systemInfo: SystemInfoWithTroubleShootLinks;
  firebaseStorageResult: TestResult;
  adBlockResult: TestResult;
  vpnResult: TestResult;
  reachabilityResult: ReachabilityTestResult;
  disableSystemCheck: boolean;
  disableVPNCheck: boolean;
}

// TODO: refactor all tests to use this state instead of the above one
export const initialTestResultState = {
  isLoading: false,
  isSuccessful: null,
  isDirty: false,
  downloadSpeedMbps: null,
};
export const loadingTestResultState = {
  isLoading: true,
  isSuccessful: null,
  isDirty: false,
};

export const successTestResultState = {
  isLoading: false,
  isSuccessful: true,
  isDirty: false,
};

export const failedTestResultState = {
  isLoading: false,
  isSuccessful: false,
  isDirty: false,
};
export const systemCheckInitialState: SystemCheckReducerState = {
  isSystemCheckCompleted: false,
  firebaseResult: initialTestResultState,
  firestoreResult: initialTestResultState,
  internetSpeedResult: initialTestResultState,
  systemInfo: getSystemInfo(),
  firebaseStorageResult: initialTestResultState,
  adBlockResult: initialTestResultState,
  vpnResult: initialTestResultState,
  dailyResult: initialTestResultState,
  reachabilityResult: {
    [ReachabilityTest.FEATURE_TOGGLES]: initialTestResultState,
    [ReachabilityTest.USAGE_ANALYTICS]: initialTestResultState,
    [ReachabilityTest.SUPPORT_TROUBLESHOOTING]: initialTestResultState,
    [ReachabilityTest.WHITEBOARD]: initialTestResultState,
  },
  disableSystemCheck: false,
  disableVPNCheck: false,
};

export const systemCheckSlice = createSlice({
  name: "systemCheck",
  initialState: systemCheckInitialState,
  reducers: {
    resetSystemCheckState: (state) => {
      Object.assign(state, systemCheckInitialState);
    },
    setIsSystemCheckCompleted: (state, action: PayloadAction<boolean>) => {
      state.isSystemCheckCompleted = action.payload;
    },
    setFirebaseResult: (state, action: PayloadAction<TestResult>) => {
      state.firebaseResult = action.payload;
    },
    setFirestoreResult: (state, action: PayloadAction<TestResult>) => {
      state.firestoreResult = action.payload;
    },
    setInternetSpeedResult: (
      state,
      action: PayloadAction<InternetSpeedTestResult>,
    ) => {
      state.internetSpeedResult = action.payload;
    },
    setAllDirty: (state, action: PayloadAction<DirtyStatus>) => {
      if (!action.payload.skipDailyTest) {
        state.dailyResult.isDirty = action.payload.value;
      }
      state.firebaseResult.isDirty = action.payload.value;
      state.firestoreResult.isDirty = action.payload.value;
      state.internetSpeedResult.isDirty = action.payload.value;
      state.firebaseStorageResult.isDirty = action.payload.value;
      state.adBlockResult.isDirty = action.payload.value;
      state.vpnResult.isDirty = action.payload.value;
      Object.values(ReachabilityTest).forEach((reachabilityTest) => {
        state.reachabilityResult[reachabilityTest].isDirty =
          action.payload.value;
      });
    },
    setAllRequiredDirty: (state, action: PayloadAction<boolean>) => {
      state.dailyResult.isDirty = action.payload;
      state.firebaseResult.isDirty = action.payload;
      state.firestoreResult.isDirty = action.payload;
    },
    setAllOptionalDirty: (state, action: PayloadAction<boolean>) => {
      state.internetSpeedResult.isDirty = action.payload;
      state.firebaseStorageResult.isDirty = action.payload;
      state.adBlockResult.isDirty = action.payload;
      state.vpnResult.isDirty = action.payload;
      Object.values(ReachabilityTest).forEach((reachabilityTest) => {
        state.reachabilityResult[reachabilityTest].isDirty = action.payload;
      });
    },
    setFirebaseStorageResult: (state, action: PayloadAction<TestResult>) => {
      state.firebaseStorageResult = action.payload;
    },
    setAdBlockResult: (state, action: PayloadAction<TestResult>) => {
      state.adBlockResult = action.payload;
    },
    setDailyResult: (state, action: PayloadAction<TestResult>) => {
      state.dailyResult = action.payload;
    },
    setVPNDetectionResult: (state, action: PayloadAction<TestResult>) => {
      state.vpnResult = action.payload;
    },
    setReachabilityResult: (
      state,
      action: PayloadAction<ReachabilityTestResult>,
    ) => {
      state.reachabilityResult = action.payload;
    },
    setReachabilityTestResult: (
      state,
      action: PayloadAction<{
        reachabilityTest: ReachabilityTest;
        result: TestResult;
      }>,
    ) => {
      state.reachabilityResult[action.payload.reachabilityTest] =
        action.payload.result;
    },
    setDisableSystemCheck: (state, action: PayloadAction<boolean>) => {
      state.disableSystemCheck = action.payload;
    },
    setDisableVPNCheck: (state, action: PayloadAction<boolean>) => {
      state.disableVPNCheck = action.payload;
    },
  },
});

export const {
  resetSystemCheckState,
  setIsSystemCheckCompleted,
  setFirebaseResult,
  setFirestoreResult,
  setInternetSpeedResult,
  setAllDirty,
  setAllRequiredDirty,
  setAllOptionalDirty,
  setFirebaseStorageResult,
  setAdBlockResult,
  setDailyResult,
  setVPNDetectionResult,
  setReachabilityResult,
  setReachabilityTestResult,
  setDisableSystemCheck,
  setDisableVPNCheck,
} = systemCheckSlice.actions;

export default systemCheckSlice.reducer;
