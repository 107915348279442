import { ReactElement } from "react";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Lottie } from "@remo-co/ui-core/src/components/Lottie";
import { EventPollOption } from "graphql/generated";
import { useStyles } from "../Poll/styles";
import ConfettiAnimation from "../../assets/confetti.lottie.json";
import Result from "./Result";

interface Props {
  answer: number[];
  options: EventPollOption[];
  showAnswer: boolean;
  userAnswer?: number;
  isHost: boolean;
}

const QuizResults = ({
  answer,
  options,
  showAnswer,
  userAnswer,
  isHost,
}: Props): ReactElement => {
  const classes = useStyles();

  const userAnsweredCorrectly =
    userAnswer !== undefined && answer.includes(userAnswer);

  const totalVotes =
    options?.reduce((total, option) => total + (option?.votes ?? 0), 0) ?? 0;

  const playAnimation = userAnsweredCorrectly && showAnswer && !isHost;

  return (
    <Container>
      <div className={classes.animationContainer}>
        {playAnimation && (
          <Lottie animationData={ConfettiAnimation} loop={false} autoplay />
        )}
      </div>
      {options.map((option) => (
        <Result
          isHost={isHost}
          id={option.id}
          option={option.option}
          votes={option.votes}
          key={option.option}
          isAnswer={answer.includes(option.id)}
          isUserSelection={option.id === userAnswer}
          totalVotes={totalVotes}
          reveal={showAnswer}
        />
      ))}
    </Container>
  );
};

export default QuizResults;
