import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import {
  JoinRoomStatus,
  selectCurrentRoomId,
  updateRoomStatus,
} from "store/rooms";
import { useAppDispatch } from "store/hooks";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { selectCurrentEventId } from "modules/event/selectors";
import { Actions, trackActionEnd, trackActionStart } from "modules/monitoring";

import { useI18n } from "i18n";
import { addNotificationForHostSeat } from "modules/joinRoom/host.seat.firebase";
import { selectUserId } from "modules/auth/redux/selectors";
import { selectCurrentSpaceId } from "modules/space/redux";
import { setVisible as setAssignedTableNudgeVisible } from "modules/assignTables/redux";
import { useUserData } from "modules/audioVideo/hooks";
import joinTableRequest, { SwitchTablePayload } from "./request";
import { getErrorMessage, shouldRequestHostSeat } from "./errorHandler";

export const useJoinTable = () => {
  const eventId = useSelector(selectCurrentEventId);
  const floorId = useSelector(selectCurrentSpaceId);
  const currentTableId = useSelector(selectCurrentRoomId);
  const userId = useSelector(selectUserId);
  const { t } = useI18n(["server", "template"]);
  const dispatch = useAppDispatch();
  const { setConversationId } = useUserData();

  const { mutate, isLoading, status } = useMutation(
    ["remo.switchTable"],
    async (payload: SwitchTablePayload) => {
      trackActionStart(Actions.JOIN_ROOM);

      dispatch(updateRoomStatus(JoinRoomStatus.IN_PROGRESS));

      await joinTableRequest(payload);

      setConversationId({ conversationId: payload.tableId });

      trackActionEnd(Actions.JOIN_ROOM, Actions.JOIN_ROOM_SUCCESS, {
        targetTableId: payload.tableId,
        currentTableId,
        eventId,
        floorId,
      });
    },
    {
      async onSuccess(_, { tableId }) {
        trackActionEnd(Actions.JOIN_ROOM, Actions.JOIN_ROOM_SUCCESS, {
          targetTableId: tableId,
          currentTableId,
          eventId,
          floorId,
        });

        dispatch(setAssignedTableNudgeVisible(false));
        dispatch(updateRoomStatus(JoinRoomStatus.DONE));
      },
      onError(error: Error, { tableId }) {
        trackActionEnd(Actions.JOIN_ROOM, Actions.JOIN_ROOM_FAILURE, {
          targetTableId: tableId,
          currentTableId,
          eventId,
          floorId,
          error: error.message,
        });

        const errorMessage = getErrorMessage(error.message);

        if (errorMessage) {
          dispatch(
            addErrorNotification({
              message: t(errorMessage),
            }),
          );
        }

        if (shouldRequestHostSeat(error.message) && userId) {
          addNotificationForHostSeat(tableId, userId);
        }
      },
      onSettled() {
        dispatch(updateRoomStatus(JoinRoomStatus.DONE));
      },
    },
  );

  const switchTable = useCallback(
    ({ tableId }: Pick<SwitchTablePayload, "tableId">) => {
      // Bail early if switch table request is already processing
      if (isLoading) {
        return;
      }

      if (!eventId || !floorId) {
        return;
      }
      if (tableId === currentTableId) {
        return;
      }

      mutate({ tableId, eventId, floorId });
    },
    [currentTableId, eventId, floorId, isLoading, mutate],
  );

  return { switchTable, isLoading, status };
};
