import MUISwitch from "@material-ui/core/Switch";
import classNames from "classnames";
import { useEffect, useState, MouseEvent } from "react";
import { useSwitchStyles } from "./styles";
import { SwitchProps } from "./types";

const Switch = (props: SwitchProps): JSX.Element => {
  const {
    checked = false,
    onClick,
    disabled,
    color = "primary",
    defaultChecked,
    size = "medium",
    required = false,
    darkMode = false,
    controlled = false,
    testId,
    ...rest
  } = props;
  const [checkedState, setCheckedState] = useState<boolean>(checked);
  const classes = useSwitchStyles();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    if (!controlled) {
      setCheckedState(!checkedState);
    }
  };

  const switchClasses = classNames({
    [classes.darkMode]: darkMode,
  });

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <MUISwitch
      checked={checkedState}
      onClick={handleClick}
      // @ts-expect-error switch doesn't support data-testid input prop
      inputProps={{ "aria-label": "switch", "data-testid": testId }}
      disabled={disabled}
      color={color}
      defaultChecked={defaultChecked}
      size={size}
      data-testid="ui-core-switch"
      required={required}
      role="switch"
      aria-checked={checkedState}
      className={switchClasses}
      {...rest}
    />
  );
};

export default Switch;
