import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Errors, trackError } from "modules/monitoring";
import {
  AttendeeTableUpdatePayload,
  addToTable,
  removeFromTable,
  moveToTable,
  unassignTable,
  unassignFloor,
  unassignAll,
} from "../../utils/modifyTableData";
import {
  AssignTableErrors,
  EVENT_ASSIGN_TABLES_QUERY_KEY,
} from "../../constants";
import {
  useEventTableSettings,
  EventTableSettingsQuery,
} from "../useEventTableSettings";
import { UpdateTableSettingsPayload, request } from "./request";

export const useUpdateEventTableSettings = (
  eventId?: string | null,
  presetId?: string,
) => {
  const {
    tableData: currentTableData,
    assignedAttendees: currentAssignedAttendees,
  } = useEventTableSettings(eventId, presetId);
  const { addErrorNotification } = useNotificationActions();
  const queryClient = useQueryClient();
  const { t } = useI18n(["manageEvent"]);

  const { mutate, isLoading, status } = useMutation(
    async (payload: Omit<UpdateTableSettingsPayload, "eventId">) => {
      if (!eventId) {
        throw new Error(AssignTableErrors.NO_EVENT_ID);
      }

      return request({ eventId, presetId, ...payload });
    },
    {
      onMutate: async ({ tableData, assignedAttendees }) => {
        await queryClient.cancelQueries([
          EVENT_ASSIGN_TABLES_QUERY_KEY,
          eventId,
        ]);

        queryClient.setQueryData<EventTableSettingsQuery>(
          [EVENT_ASSIGN_TABLES_QUERY_KEY, eventId, presetId],
          (existing) => ({
            tableSettings: existing?.tableSettings ?? [],
            tableData,
            assignedAttendees,
          }),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          EVENT_ASSIGN_TABLES_QUERY_KEY,
          eventId,
          presetId,
        ]);
      },
      onError: (error: Error, _) => {
        addErrorNotification({
          message: t("error.update.table.settings", {
            error: error.message,
          }),
          position: "tc",
        });
        trackError(error, {
          label: Errors.EVENT_TABLE_SETTINGS_SAVE,
        });
      },
    },
  );

  const addAttendeeToTable = useCallback(
    (payload: AttendeeTableUpdatePayload) =>
      mutate(addToTable(currentTableData, currentAssignedAttendees, payload)),
    [mutate, currentTableData, currentAssignedAttendees],
  );

  const removeAttendeeFromTable = useCallback(
    (payload: AttendeeTableUpdatePayload) =>
      mutate(
        removeFromTable(currentTableData, currentAssignedAttendees, payload),
      ),
    [mutate, currentTableData, currentAssignedAttendees],
  );

  const moveAttendeeToTable = useCallback(
    (payload: AttendeeTableUpdatePayload & { previousTableId: string }) =>
      mutate(moveToTable(currentTableData, currentAssignedAttendees, payload)),
    [mutate, currentTableData, currentAssignedAttendees],
  );

  const unassignAttendeesFromTable = useCallback(
    (floorId: string, tableId: string) =>
      mutate(
        unassignTable(
          currentTableData,
          currentAssignedAttendees,
          floorId,
          tableId,
        ),
      ),
    [mutate, currentTableData, currentAssignedAttendees],
  );

  const unassignAttendeesFromFloor = useCallback(
    (floorId: string) =>
      mutate(
        unassignFloor(currentTableData, currentAssignedAttendees, floorId),
      ),
    [mutate, currentTableData, currentAssignedAttendees],
  );

  const unassignAttendeesFromEvent = useCallback(
    () => mutate(unassignAll(currentTableData)),
    [mutate, currentTableData],
  );

  return {
    addAttendeeToTable,
    removeAttendeeFromTable,
    moveAttendeeToTable,
    unassignAttendeesFromTable,
    unassignAttendeesFromFloor,
    unassignAttendeesFromEvent,
    updateEventTableSettings: mutate,
    isLoading,
    status,
  };
};
