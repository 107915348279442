import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  signalIndicator: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  userName: {
    zIndex: 1,
    position: "absolute",
    width: "100%",
    textAlign: "center",
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    height: 26,
    lineHeight: "28px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tileOverlay: {
    padding: theme.spacing(1),
    color: "white",
  },
}));
