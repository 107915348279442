import { database } from "services/firebaseService/firebaseConfig";

export const getTimeDeviation = async () =>
  database
    .ref("/.info/serverTimeOffset")
    .once("value")
    // eslint-disable-next-line promise/prefer-await-to-then
    .then(
      (data) => data.val(),
      (err) => err,
    );

export const getCurrentClientTime = async (): Promise<number> => {
  const deviation = await getTimeDeviation();

  return Date.now() + deviation;
};
