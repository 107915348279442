import { useI18n } from "i18n";
import { selectCurrentEventId } from "modules/event/selectors";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { sendDeleteRequest } from "services/apiService";

type DeleteChatParams = {
  eventId: string | null;
  channelId: string;
  messageId?: string;
};
type Response =
  | {
      status: false;
      reason: string;
    }
  | {
      status: true;
    };

const deleteChatRequest = async ({
  eventId,
  channelId,
  messageId,
}: DeleteChatParams) => {
  if (eventId === null) {
    throw new Error("could not find event id");
  }

  const path = [eventId, channelId];

  if (messageId) {
    path.push(messageId);
  }
  const resp = await sendDeleteRequest<DeleteChatParams, Response>(
    `/chat/${path.join("/")}`,
  );

  if (!resp.status) {
    throw new Error(resp.reason);
  }

  return resp;
};

export const useDeleteMessagesInChannel = () => {
  const eventId = useSelector(selectCurrentEventId);
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();

  const { t } = useI18n(["common", "event", "server"]);

  const {
    mutate: deleteChannel,
    isLoading,
    status,
  } = useMutation(
    ({ channelId }: Pick<DeleteChatParams, "channelId">) =>
      deleteChatRequest({ eventId, channelId }),
    {
      onSuccess() {
        addSuccessNotification({ message: t("event:messages.all.deleted") });
      },
      onError() {
        addErrorNotification({ message: t("event:channel.delete.failed") });
      },
    },
  );

  return { deleteChannel, isLoading, status };
};

export const useDeleteMessage = () => {
  const eventId = useSelector(selectCurrentEventId);

  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();

  const { t } = useI18n(["common", "event", "server"]);

  const {
    mutate: deleteMessage,
    isLoading,
    status,
  } = useMutation(
    ({
      channelId,
      messageId,
    }: Pick<DeleteChatParams, "channelId" | "messageId">) =>
      deleteChatRequest({ eventId, channelId, messageId }),
    {
      onSuccess() {
        addSuccessNotification({ message: t("event:message.deleted") });
      },

      onError() {
        addErrorNotification({ message: t("event:message.delete.failed") });
      },
    },
  );

  return { deleteMessage, isLoading, status };
};
