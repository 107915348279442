import { ReactElement, useState } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "modules/polls/components/Poll/styles";
import Metadata from "modules/polls/components/Poll/Metadata";
import Options from "modules/polls/components/Poll/Options";
import {
  AnswerPollMutation,
  EventPoll,
  EventPollOption,
  EventPollStatus,
  EventPollType,
} from "graphql/generated";
import { useSelector } from "react-redux";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import QuizResults from "./QuizResults";
import { AnswerCountDown } from "../AnswerCountdown";
import Stats from "../Poll/Stats";
import { HostControls } from "../HostControls";
import { EditPollForm } from "../EditPollForm";

interface Props {
  isHost?: boolean;
  quiz: EventPoll;
  status: EventPollStatus;
  onSubmit: (
    option: EventPollOption,
  ) => Promise<AnswerPollMutation | null | undefined>;
}

const Quiz = ({
  isHost = false,
  quiz,
  status,
  onSubmit,
}: Props): ReactElement | null => {
  const user = useSelector(makeSelectUserById(quiz.createdBy));
  const [isEditing, setIsEditing] = useState(false);
  const correctOption = quiz.correctAnswers;
  const [userAnswer, setUserAnswer] = useState<EventPollOption | undefined>(
    quiz.options.find((option) => option.id === quiz.selectedAnswer),
  );
  const [finished, setFinished] = useState(status === EventPollStatus.Closed);
  const classes = useStyles();

  const submit = (selectedAnswer?: EventPollOption) => {
    if (selectedAnswer) {
      setUserAnswer(selectedAnswer);
      onSubmit(selectedAnswer);
    }
  };

  const showResults =
    (userAnswer &&
      quiz.options.some((option) => option.id === quiz.selectedAnswer)) ||
    finished ||
    isHost;
  const totalVotes = quiz.options.reduce(
    (total, option) => total + option.votes,
    0,
  );

  if (!correctOption) {
    // This should never happen.
    return null;
  }

  return (
    <Box className={classes.poll}>
      {isEditing && (
        <EditPollForm
          closeForm={() => setIsEditing(false)}
          initialForm={{
            id: quiz.id,
            status: quiz.status,
            type: quiz.type,
            title: quiz.title,
            eventId: quiz.eventId,
            timer: quiz.timer,
            options: quiz.options.map((option) => ({
              ...option,
              correctAnswer: option.id === quiz.correctAnswers[0],
            })),
          }}
          pollType={EventPollType.Quiz}
        />
      )}
      {!isEditing && (
        <>
          {user && (
            <Metadata
              isHost={isHost}
              status={status}
              timestamp={new Date(quiz.updatedAt).getTime()}
              type="quiz"
              user={user}
            />
          )}
          <Container>
            <Typography variant="h4" className={classes.question}>
              {quiz.title}
            </Typography>
            <AnswerCountDown
              correct={
                userAnswer
                  ? quiz.correctAnswers.includes(userAnswer.id)
                  : undefined
              }
              isHost={isHost}
              currentTime={Date.now() / 1000}
              initialTime={new Date(quiz.updatedAt).getTime() / 1000}
              status={status}
              time={quiz.timer}
              onFinish={() => setFinished(true)}
            />
            {showResults ? (
              <QuizResults
                isHost={isHost}
                answer={quiz.correctAnswers}
                options={quiz.options}
                showAnswer={finished || isHost}
                userAnswer={userAnswer?.id}
              />
            ) : (
              <Options options={quiz.options} onSubmit={submit} />
            )}
            {isHost && (
              <>
                <Stats options={quiz.options} status={status} />
                <HostControls
                  setIsEditing={setIsEditing}
                  poll={quiz}
                  status={status}
                  totalVotes={totalVotes}
                  isQuiz
                />
              </>
            )}
          </Container>
        </>
      )}
    </Box>
  );
};

export default Quiz;
