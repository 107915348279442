import Container, { ContainerProps } from "@material-ui/core/Container";
import React from "react";

interface Props extends ContainerProps {
  index: string | number;
  value: string | number;
}

const TabPanel = React.forwardRef(
  ({ value, index, ...props }: Props, ref: React.Ref<HTMLDivElement>) =>
    value === index ? (
      <Container
        role="tabpanel"
        ref={ref}
        style={{ overflowY: "auto" }}
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        data-testid="vertical-tabpanel"
        aria-labelledby={`vertical-tab-${index}`}
        {...props}
      />
    ) : null,
);

export default TabPanel;
