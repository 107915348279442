import { Theme } from "@material-ui/core/styles/createTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));
