import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  contactSupport: {
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "5px",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    margin: "1rem 0",
    background: theme.palette.blue.lightShade1,
  },
  icon: {
    color: theme.palette.blue.main,
  },
  contactSupportText: {
    marginLeft: "1rem",
  },
}));
