import { CSSProperties } from "@remo-co/ui-core/src/types";
import { createStyles } from "@remo-co/ui-core/src/utils/createStyles";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: "0.5rem 0",
    },
    listItem: {
      display: "flex",
      alignItems: "center",
    },
    success: {
      marginRight: "1rem",
      color: theme.palette.green.main,
    },
    error: {
      marginRight: "1rem",
      color: theme.palette.red.main,
    },
    warning: {
      marginRight: "1rem",
      color: theme.palette.yellow.dark,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    } as CSSProperties,
  }),
);
