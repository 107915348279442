import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  addButtonContent: {
    display: "flex",
    alignItems: "center",
  },
  config: {
    rowGap: theme.spacing(1),
    padding: `${theme.spacing(2)}px 0`,
    position: "relative",
  },
  deleteButton: {
    position: "absolute",
    top: theme.spacing(1.25),
    right: 0,
  },
  deleteIcon: {
    width: "0.8em !important",
    height: "0.8em !important",
  },
}));
