import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { all, call, spawn } from "redux-saga/effects";
import { Action, ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import { getVar } from "config";
import { reactionsMiddleware } from "modules/emoji/Reactions/redux/reactionsMiddleware";
import logger from "logging/logger";

import createRootReducer from "./reducers";
import sagas from "./sagas";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: false, // Turn this to true once we refactor all reducers
      serializableCheck: false, // Turn this to true once we refactor all reducers
    })
      .concat(sagaMiddleware)
      .concat(routerMiddleware(history))
      .concat(reactionsMiddleware),
  devTools: getVar("REACT_APP_CLIENT_HOST") !== "live.remo.co" ? {} : false,
});

function* rootSaga() {
  yield all(
    sagas.map((saga) =>
      spawn(function* sagaGenerator() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            if (error instanceof Object) {
              logger.error("Error: ", error);
            }
          }
        }
      }),
    ),
  );
}

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = ThunkDispatch<RootState, void, Action>;

export default store;
