import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles/createTheme";
import { ISelectProps } from "./types";

export const useSelectStyles = makeStyles<Theme, ISelectProps>((theme) => ({
  container: {
    borderWidth: "1px",
    borderStyle: "solid",
    fontSize: 14,
    backgroundColor: theme.palette.white,
    margin: "5px 0",

    borderColor: (props) =>
      props.open ? theme.palette.blue.main : theme.palette.blue.darkShade1,
    borderRadius: (props) => (props.open ? "4px 4px 0 0" : "4px"),

    "&:hover": {
      borderColor: theme.palette.blue.dark,
    },

    "& fieldset": {
      border: "none",
    },

    "& .MuiInputBase-root": {
      height: "100%",
    },

    "& .MuiSelect-select": {
      textAlign: "start",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      opacity: (props) =>
        props.placeholder && (!props.value || props.value.length === 0)
          ? "0.85"
          : "1",
    },

    "& .MuiSelect-select > *": {
      display: "inline-block",
    },

    "& .chevron-icon": {
      position: "absolute",
      right: "10px",
      pointerEvents: "none",
      cursor: "pointer",
    },
  },
  value: {
    borderColor: theme.palette.blue.dark,
  },
  disabled: {
    background: theme.palette.gray.disabledInput,
    borderColor: `${theme.palette.gray.light} !important`,
    color: `${theme.palette.blue.darkShade1}70 !important`,

    "&:hover": {
      cursor: "not-allowed",
      background: theme.palette.gray.disabledInput,
      borderColor: theme.palette.gray.light,
    },
  },
  error: {
    borderColor: (props) =>
      !props.open
        ? `${theme.palette.red.main} !important`
        : theme.palette.blue.main,
    "&:hover": {
      borderColor: (props) =>
        !props.open
          ? `${theme.palette.red.main} !important`
          : theme.palette.blue.main,
    },
  },
  sm: {
    height: "40px",

    "& .MuiSelect-select": {
      padding: "11px 25px 11px 15px",
    },
  },
  lg: {
    height: "50px",
    "& .MuiSelect-select": {
      padding: "16px 25px 16px 15px",
    },
  },
  popover: {
    maxWidth: (props) => props.width,
    maxHeight: (props) => props.optionHeight,
  },
  popoverPaper: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.blue.main,
    borderRadius: 0,
    marginLeft: "-1px",
    maxWidth: (props) =>
      props.disableOptionMaxWidth ? "unset !important" : "calc(100%-32px)",
  },
  errorDescription: {
    fontSize: "12px !important",
    color: `${theme.palette.red.main} !important`,
    padding: "0 !important",
    margin: "0 !important",
  },
  label: {
    fontSize: 14,
    color: `${theme.palette.blue.dark} !important`,
    padding: 0,
    margin: 0,
    lineHeight: 1,
  },
  selectOption: {
    display: "flex",
    justifyContent: "space-between",
  },
  selectText: {
    overflowX: "clip",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  optionDescription: {
    color: theme.palette.blue.darkShade1,
  },
  option: {
    width: "100%",
  },
  listSubheader: {
    pointerEvents: "none",
  },
}));
