import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import useSystemClock from "modules/system/hooks/useSystemClock";
import useChatFirestore from "modules/chat/firestore/useChatFirestore";
import {
  makeSelectActiveChannel,
  makeSelectChatListenersSetup,
} from "modules/chat/redux/selectors";
import { useI18n } from "i18n";
import { IRoom } from "types";
import { getUnreadCount } from "services/apiService/apis";
import useEventTracking from "modules/eventTracking/hooks/useEventTracking";
import { selectUser } from "modules/auth/redux/selectors";
import { setPanelState, TABS } from "modules/rightPanel";
import * as actions from "../../redux/actions";
import {
  CHAT_TYPE,
  CLIENT_MESSAGE,
  SPACE_CHAT_NAME,
} from "../../redux/constants";
import { IChannel, IChatMessage } from "../../types";
import { selectCurrentEvent } from "../../../event/selectors";
import { selectCurrentTheater } from "../../../theater/selectors";

const LEAVE_ROOM_TIMEOUT = 100;

const useChatActions = () => {
  const dispatch = useAppDispatch();
  const event = useSelector(selectCurrentEvent);
  const activeChannel = useSelector(makeSelectActiveChannel());
  const user = useSelector(selectUser);
  const { getCurrentTime, serverTime } = useSystemClock();
  const {
    sendToChannel,
    updateLastAccessTimeForChannel,
    unsubscribe,
    subscribeToChannel,
  } = useChatFirestore();
  const { t } = useI18n();
  const areListenersLoaded = useSelector(makeSelectChatListenersSetup());
  const { loadChannels } = useChatFirestore();
  const currentTheater = useSelector(selectCurrentTheater);
  const { trackPublicChatMessage } = useEventTracking();

  const doOnLoad = () => {
    if (!areListenersLoaded) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      startChatListeners();
    }
  };

  const startChatListeners = async () => {
    if (event && currentTheater) {
      // Get unread count from server first otherwise, it will show old messages as unread
      const unreadCountMapFromAPI = await getUnreadCount(event.id, serverTime); // {'a67cda67e0': 1, '5c3d3d11368f441994ea67d6': 2};
      const time = getCurrentTime();

      dispatch(actions.updateUnreadCount(unreadCountMapFromAPI));
      loadChannels(time);

      // Subscribe to theater chat
      const channel = {
        id: currentTheater.id,
        name: t(SPACE_CHAT_NAME),
        type: CHAT_TYPE.THEATER,
        listensFrom: time,
      };

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      subscribeToChatChannel(channel);
      dispatch(actions.setListenersStatus(true));
    }
  };

  const sendMessage = (channel: IChannel, message: IChatMessage) => {
    const messageData = {
      ...message,
      authorId: user?.id,
      time: getCurrentTime(),
      roomId: channel.id,
      action: CLIENT_MESSAGE,
    };
    if (currentTheater?.id === channel.id) {
      trackPublicChatMessage();
    }
    sendToChannel(channel.id, messageData);
  };

  const subscribeToChatChannel = (channel: IChannel) => {
    dispatch(actions.updateChatChannel(channel));
    // send message for join room.
    subscribeToChannel(channel.id);
  };

  const leaveRoom = (prevRoomId: IRoom["id"]) => {
    // Send left and entered message in tables

    setTimeout(() => {
      unsubscribe(prevRoomId);
    }, LEAVE_ROOM_TIMEOUT);
  };

  const createChannel = ({
    roomName,
    roomId,
  }: {
    roomName: string;
    roomId: string;
  }) => ({
    id: roomId,
    name: t("table.chat", {
      key: roomName,
    }),
    type: CHAT_TYPE.ROOM,
    listensFrom: getCurrentTime(),
  });

  const handleRoomChange = (currentRoom: IRoom, prevRoom: IRoom) => {
    const channel = createChannel({
      roomId: currentRoom.id,
      roomName: currentRoom.name,
    });

    const isRoomChanged = currentRoom.id !== prevRoom.id;

    if (!isRoomChanged) {
      // If I am in current room, then update the chat list with new name
      dispatch(actions.updateChatChannel(channel));
      return;
    }

    if (isRoomChanged) {
      subscribeToChatChannel(channel);

      leaveRoom(prevRoom.id);

      // if chat is open and table chat is active channel, update active channel
      if (activeChannel && activeChannel.type === CHAT_TYPE.ROOM) {
        dispatch(actions.updateActiveChannel(currentRoom.id));
      }
    }
  };

  const initRoomChat = (currentRoom: IRoom) => {
    const channel = createChannel({
      roomId: currentRoom.id,
      roomName: currentRoom.name,
    });
    subscribeToChatChannel(channel);
  };

  const onClose = () => {
    dispatch(actions.updateActiveChannel(null));

    if (user && activeChannel) {
      updateLastAccessTimeForChannel(user.id, activeChannel);
    }
  };

  const openChannel = (channelId: string) => {
    dispatch(setPanelState({ isOpen: true, tabId: TABS.CHAT }));
    dispatch(actions.updateActiveChannel(channelId));
  };

  return {
    openChannel,
    doOnLoad,
    handleRoomChange,
    initRoomChat,
    leaveRoom,
    onClose,
    sendMessage,
    subscribeToChatChannel,
  };
};

export default useChatActions;
