import { Map } from "immutable";
import { IUserState, REMOVE_USER_STATE, SYNC_USER_STATE } from "./types";

// Initial user state
export const initialState: Map<string, IUserState> = Map();

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/default-param-last
const userStateReducer = (state = initialState, action: any) => {
  if (!action || !action.payload) {
    return state;
  }
  switch (action.type) {
    case SYNC_USER_STATE:
      return state.merge(action.payload);
    case REMOVE_USER_STATE:
      return state.delete(action.payload);
    default:
      return state;
  }
};

export default userStateReducer;
