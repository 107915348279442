import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useSelector } from "react-redux";
import { selectCompany } from "modules/company/redux/selectors";

const useTrackCompanyPlan = (isDatadogInit: boolean): void => {
  const { companyPlan } = useSelector(selectCompany);

  useEffect(() => {
    if (!isDatadogInit) {
      return;
    }

    if (companyPlan) {
      datadogRum.setGlobalContextProperty("companyPlan", companyPlan);
    }
  }, [companyPlan, isDatadogInit]);
};

export default useTrackCompanyPlan;
