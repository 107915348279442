import { useSelector } from "react-redux";
import logger from "logging/logger";
import { selectUser } from "modules/auth/redux/selectors";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { isMasterAppInstance } from "modules/ensureSingleAppInstance/EnsureSingleAppInstance";
import { getEventRole, isEventSpeaker } from "modules/event/utils/eventUtils";
import {
  selectCurrentEvent,
  selectIsEventManager,
} from "modules/event/selectors";
import useMicTracking from "../useMicTracking";
import {
  clearCurrentSessionRequest,
  clearOtherSessionsRequest,
  ClearSessionPayload,
  createSessionRequest,
} from "./requests";

export const useUserSession = () => {
  const { track } = useContext(TRACKING_CONTEXT);
  const { getAndResetInstantMicOnDuration } = useMicTracking();
  const currentEvent = useSelector(selectCurrentEvent);
  const isEventManager = useSelector(selectIsEventManager);
  const user = useSelector(selectUser);

  const { mutateAsync: createSession } = useMutation(
    ["session", "update"],
    createSessionRequest,
    {
      onSuccess: (existingSession, { eventId, floorId }) => {
        const eventName = existingSession
          ? Events.USER_IN_EVENT
          : Events.EVENT_ENTERED;

        const micOnDuration = getAndResetInstantMicOnDuration();
        const eventRole =
          currentEvent &&
          getEventRole(
            isEventManager,
            isEventSpeaker(currentEvent, user?.id, user?.email),
          );

        track(eventName, {
          event: eventId,
          floor: floorId,
          userId: user?.email,
          micOnDuration,
          eventRole,
        });
      },
      onError(error) {
        const message = error instanceof Error ? error.message : "unknown";
        logger.error(`[sessionManagement][createSession] ${message}`, {
          error,
        });
      },
    },
  );

  const { mutateAsync: clearCurrentSession } = useMutation(
    ["session", "clearCurrentSession"],
    async (payload: ClearSessionPayload) => {
      if (!isMasterAppInstance) {
        return undefined;
      }

      return clearCurrentSessionRequest(payload);
    },
    {
      onError(error) {
        const message = error instanceof Error ? error.message : "unknown";
        logger.error(`[sessionManagement][clearCurrentSession] ${message}`, {
          error,
        });
      },
    },
  );

  const { mutateAsync: clearOtherSessions } = useMutation(
    ["session", "clearOtherSessions"],
    clearOtherSessionsRequest,
    {
      onError(error) {
        const message = error instanceof Error ? error.message : "unknown";
        logger.error(`[sessionManagement][clearOtherSessions] ${message}`, {
          error,
        });
      },
    },
  );

  return {
    createSession,
    clearCurrentSession,
    clearOtherSessions,
  };
};
