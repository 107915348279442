import {
  ChangeEvent,
  ComponentProps,
  KeyboardEventHandler,
  useState,
} from "react";
import { useTheme } from "@material-ui/core/styles";
import classnames from "classnames";
import { InputLabel } from "../InputLabel";
import useStyles from "./styles";
import { Box } from "../Box";
import { Input } from "../Input";
import { Chip } from "../Chip";
import { InputHelpText } from "../InputHelpText";

interface Props extends Omit<ComponentProps<typeof Input>, "onBlur" | "error"> {
  data: string[];
  validate?: (value: string) => boolean;
  onAdd: (chip: string) => void;
  onBlur: (chip: string) => void;
  onDelete: (chip: string) => void;
  placeholder: string;
  error?: string | boolean | JSX.Element;
  showRemainingChars?: undefined;
}

const ChipInput = ({
  data,
  onAdd,
  onBlur,
  onDelete,
  placeholder,
  error,
  description,
  validate,
  ...rest
}: Props): JSX.Element => {
  const theme = useTheme();
  const [value, setValue] = useState("");
  const styles = useStyles(rest);
  const handleDelete = (item: string) => () => {
    onDelete(item);
  };

  const invokeCallbackIfValueIsNotDuplicate = (
    callback: (chip: string) => void,
  ) => {
    if (!value) return;

    const isValueUnique = !data.includes(value);
    if (isValueUnique) {
      callback(value);
    }
    setValue("");
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();

      invokeCallbackIfValueIsNotDuplicate(onAdd);
    }
  };

  const handleBlur = () => {
    invokeCallbackIfValueIsNotDuplicate(onBlur);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <>
      {rest.label && (
        <InputLabel
          htmlFor={rest.name ?? "chip-input"}
          className={styles.label}
        >
          {rest.label}
        </InputLabel>
      )}
      <Box
        border={1}
        borderColor={error ? theme.palette.red.main : theme.palette.gray.light}
        borderRadius={5}
        className={styles.chipsContainer}
      >
        {data.map((datum) => (
          <Chip
            data-testid="chip"
            className={classnames(styles.chips, {
              [styles.error]: validate ? !validate(datum) : false,
            })}
            key={datum}
            tabIndex={-1}
            label={datum}
            onDelete={handleDelete(datum)}
          />
        ))}
        <Input
          name="chip-input"
          value={value}
          size="sm"
          inputProps={{
            onKeyDown: handleKeyDown,
            "data-testid": "chip-input",
          }}
          fullWidth
          className={styles.input}
          placeholder={placeholder}
          onBlur={handleBlur}
          onChange={handleChange}
          {...rest}
          label={undefined}
        />
      </Box>
      <InputHelpText isError={Boolean(error)}>
        {error || description}
      </InputHelpText>
    </>
  );
};

export default ChipInput;
