import React from "react";
import { addWarningNotification } from "modules/notification/redux/notificationSlice";
import store from "store";
import logger from "logging/logger";

/**
 * For React lazy loading
 * Retry to load the resources if the resource fails to load
 */
export function retryLazy<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (...args: any[]) => Promise<T>,
  retriesLeft = 3,
  interval = 1000,
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    fn()
      // eslint-disable-next-line promise/prefer-await-to-then
      .then(resolve)
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            store.dispatch(
              addWarningNotification({
                message:
                  "Not able to load the requested resource. Please refresh the page.",
              }),
            );
            reject(error);

            return;
          }

          // Passing on "reject" is the important part
          // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
          retryLazy(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, consistent-return, @typescript-eslint/no-explicit-any
export const lazyLoad = (fn: (...args: any[]) => Promise<any>) => {
  try {
    return React.lazy(() => retryLazy(fn));
  } catch (error) {
    logger.error(`[lazyLoad] failed to load: ${error}.`);
  }
};
