import {
  useAppMessage,
  useLocalSessionId,
  useParticipantProperty,
} from "@daily-co/daily-react";
import {
  CustomAppEvent,
  CustomAppEvents,
} from "modules/audioVideo/DailyEvents";
import { useCameraAndScreenTiles, useUserData } from "modules/audioVideo/hooks";
import { useParticipantsAtTable } from "modules/audioVideo/hooks/useMeetingParticipants";
import {
  selectFullScreenEventContent,
  selectIsFullScreenEventContentOpen,
} from "modules/eventContent";
import { selectIsRoomWhiteboardOpen } from "modules/roomWhiteboard/redux/selectors";
import { useSelector } from "react-redux";
import { selectCurrentRoomId } from "store/rooms";
import { DAILY_PLAYABLE_TRACK_STATES } from "modules/audioVideo/constants";
import { useMemo } from "react";
import { useParticipantSubscription } from "modules/audioVideo/hooks/useParticipantSubscription";
import { useConversationLayoutEffects } from "modules/audioVideo/hooks/useConversationLayoutEffects";
import { LayoutContainer } from "../../Layouts/Container";
import { DisplayItem, DisplayItemConfig, TileDraft } from "../../Layouts/types";

export const Conversation = () => {
  const tableId = useSelector(selectCurrentRoomId);

  const { setConversationId } = useUserData();

  const localSessionId = useLocalSessionId();

  const isWhiteBoardOpen = useSelector(selectIsRoomWhiteboardOpen);
  const content = useSelector(selectFullScreenEventContent);
  const isFullScreenEventContentOpen = useSelector(
    selectIsFullScreenEventContentOpen,
  );

  const [screenState, audioState, videoState] = useParticipantProperty(
    localSessionId,
    ["tracks.screenVideo.state", "tracks.audio.state", "tracks.video.state"],
  );

  useAppMessage<CustomAppEvent>({
    onAppMessage: (event) => {
      if (event.data.event !== CustomAppEvents.HOST_SEAT_REQUEST) {
        return;
      }

      const { tableId: conversationId } = event.data;

      setConversationId({ conversationId });
    },
  });

  const participantsAtTable = useParticipantsAtTable({ tableId });

  const { cameras, screens } = useCameraAndScreenTiles({
    participants: participantsAtTable,
    isInBroadcast: false,
  });

  const localParticipantDraft = {
    type: DisplayItem.camera,
    streamId: localSessionId,
    local: true,
  };

  const cameraTilesDraft: TileDraft[] = cameras.map((participantId) => ({
    type: DisplayItem.camera,
    streamId: participantId,
    local: localSessionId === participantId,
  }));

  const screenTilesDraft: TileDraft[] = screens.map((screenId) => ({
    type: DisplayItem.screenShare,
    streamId: screenId,
    local: screenId.includes(localSessionId),
  }));

  const showLocalParticipantTile = useMemo(
    () =>
      !!localSessionId &&
      !(
        DAILY_PLAYABLE_TRACK_STATES.includes(screenState) &&
        !DAILY_PLAYABLE_TRACK_STATES.includes(audioState) &&
        !DAILY_PLAYABLE_TRACK_STATES.includes(videoState)
      ),
    [localSessionId, screenState, audioState, videoState],
  );

  const tilesDraft = [
    ...(showLocalParticipantTile ? [localParticipantDraft] : []),
    ...cameraTilesDraft,
    ...screenTilesDraft,
  ];

  if (isWhiteBoardOpen) {
    tilesDraft.push({
      type: DisplayItem.whiteboard,
      streamId: DisplayItemConfig.whiteboardDisplayId,
    });
  }

  if (content && isFullScreenEventContentOpen) {
    tilesDraft.push({
      type: DisplayItem.eventContent,
      streamId: DisplayItemConfig.eventContentDisplayId,
    });
  }

  useParticipantSubscription({ participants: participantsAtTable });
  useConversationLayoutEffects({
    screens,
    cameras,
  });

  return <LayoutContainer key="conversation" tiles={tilesDraft} />;
};
