import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";
import zIndexes from "../../../../zIndexes.module.scss";

const baseZIndex = zIndexes.broadcastMenuZIndex;

const TranscriptionStyles = makeStyles<Theme>((theme) => ({
  root: {
    margin: "0 auto",
    padding: "5px",
    color: theme.palette.white,
    zIndex: baseZIndex - 1,
    marginBottom: theme.spacing(1),
    display: "flex",
    borderRadius: 5,
    backgroundColor: theme.palette.dark.overlay2,
    alignItems: "flex-start",
  },

  text: {
    display: "flex",
    flexDirection: "column-reverse",
    overflowY: "hidden",
  },
  textWrapper: {
    padding: "10px",
    maxWidth: "60%",
  },
}));

export default TranscriptionStyles;
