import { CircularProgress } from "@remo-co/ui-core/src/components/CircularProgress";
import { Fade } from "@remo-co/ui-core/src/components/Fade";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Container } from "@remo-co/ui-core/src/components/Container";
import {
  deleteRecording,
  getRecordingAccessLink,
} from "services/apiService/apis";
import { useI18n } from "i18n";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { RecordingObject } from "@remo-co/types";
import useDialogNotificationActions from "modules/dialogNotification/hooks/useDialogNotificationActions";
import { getRecordingName } from "modules/recording";
import { IEvent } from "modules/event/types";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { RecordingsContent } from "../RecordingsContent";

interface Props {
  event: IEvent;
}

const RecordingsContainer = ({ event }: Props): JSX.Element | null => {
  const { t } = useI18n(["common", "eventForm", "server"]);
  const { addSuccessNotification, addErrorNotification } =
    useNotificationActions();

  const queryClient = useQueryClient();
  const queryKey = `/daily/${event.id}/recordings`;

  const {
    data: recordings,
    status,
    refetch,
    isFetching,
  } = useQuery([queryKey], {
    select: (response: { data: RecordingObject[] }) => response.data,
    refetchInterval: 7500,
    retry: 2,
    onError: (err: Error) => {
      addErrorNotification({
        message: err.message ?? t("event:failed.fetch.recordings"),
      });
    },
  });

  const { openDialog } = useDialogNotificationActions();

  const getDownloadUrl = async (recordingId: string) => {
    try {
      const res = await getRecordingAccessLink({
        eventId: event.id,
        recordingId,
      });

      if (!res.isSuccess || !res.download_link) {
        addErrorNotification({
          message: t("server:file.url.not.found", {
            key: `${res.message}`,
          }),
        });

        return;
      }

      // eslint-disable-next-line consistent-return
      return res.download_link;
    } catch (e) {
      addErrorNotification({
        message: t("eventForm:broadcast.fail.message"),
      });
    }
  };

  const handleDownload = async ({ id }: RecordingObject) => {
    const signedUrl = await getDownloadUrl(id);

    if (signedUrl) {
      window.open(signedUrl, "_blank", "noopener");
    }
  };

  const deleteEntity = async ({ id }: RecordingObject) => {
    try {
      const res = await deleteRecording({ eventId: event.id, recordingId: id });

      if (res.isSuccess) {
        addSuccessNotification({
          message: t("eventForm:file.delete.success.message"),
        });

        queryClient.invalidateQueries({ queryKey: [queryKey] });
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "can't delete the file";

      addErrorNotification({
        message: t("server:error.delete.file", {
          key: errorMessage,
        }),
      });
    }
  };

  const handleDelete = (recording: RecordingObject) =>
    openDialog({
      message: t("event:warning.delete.recording", {
        key: getRecordingName(event.code || "my-event", recording),
      }),
      onConfirm: () => deleteEntity(recording),
      confirmText: t("confirm.text.delete"),
      dismissText: t("dismiss.text"),
      hideCloseButton: true,
    });

  return (
    <div>
      <Container flex justifyContent="space-between" alignItems="center">
        <Typography variant="h4">
          {t("eventForm:presentation.recording")}
        </Typography>
        <br />
        <br />
        <Typography component="span" color="secondary">
          {t("eventForm:presentation.recording.note")}
        </Typography>
        <Fade
          in={status !== "loading" && isFetching}
          style={{
            transitionDelay: isFetching ? "500ms" : "0ms",
          }}
          unmountOnExit
        >
          <CircularProgress size={16} />
        </Fade>
      </Container>
      <Container top="md">
        <RecordingsContent
          eventCode={event.code || "my-event"}
          status={status}
          recordings={recordings}
          handleDownload={handleDownload}
          handleDelete={handleDelete}
          refetch={refetch}
        />
      </Container>
    </div>
  );
};

export default RecordingsContainer;
