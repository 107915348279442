import { Close } from "../../icons/Close";
import { IconButton, IconButtonProps } from "../IconButton";
import { useStyles } from "./styles";

const DialogCloseButton = (props: IconButtonProps) => {
  const styles = useStyles();

  return (
    <IconButton
      data-testid="dialog-close-button"
      className={styles.root}
      {...props}
    >
      <Close />
    </IconButton>
  );
};

export default DialogCloseButton;
