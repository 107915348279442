import {
  MouseEvent,
  Ref,
  forwardRef,
  useCallback,
  useState,
  memo,
} from "react";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { ClickAwayListener } from "@remo-co/ui-core/src/components/ClickAwayListener";
import classnames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import throttle from "modules/throttle";
import { ArrowDropUpRounded } from "@remo-co/ui-core/src/icons/ArrowDropUpRounded";
import { useStyles } from "./styles";
import { ActionButtonEventSources, ActionButtonProps } from "./types";
import ActionButtonTitle from "../ActionButtonTitle";

const ActionHotkey = ({
  hotKey,
  handleClickEvent,
}: {
  hotKey: string;
  handleClickEvent: (e: KeyboardEvent) => void;
}) => {
  useHotkeys(hotKey, handleClickEvent, { filterPreventDefault: true }, [
    handleClickEvent,
    hotKey,
  ]);

  return null;
};

const ActionButton = forwardRef(
  (props: ActionButtonProps, ref?: Ref<HTMLButtonElement>) => {
    const {
      darkMode = false,
      state = "default",
      isActive = false,
      isPopoverButton = false,
      label,
      icon,
      popoverChildren,
      rightComponent,
      title = "",
      disabled,
      onClick,
      hotKey,
      throttleTime,
      buttonClassName,
      height,
      ...rest
    } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isPopoverOpen = Boolean(anchorEl);
    const classes = useStyles({ darkMode, isActive, disabled, height });

    const handleClick = useCallback(
      (e?: ActionButtonEventSources) => {
        if (!disabled) {
          onClick(e);
        }
      },
      [onClick, disabled],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleThrottleClick = useCallback(
      throttle(() => {
        handleClick();
      }, throttleTime),
      [handleClick],
    );

    const handleClickEvent = useCallback(
      (e: ActionButtonEventSources) => {
        if (throttleTime) {
          handleThrottleClick();
        } else {
          handleClick(e);
        }
      },
      [handleThrottleClick, handleClick, throttleTime],
    );

    const togglePopover = useCallback(
      (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const closePopover = useCallback(() => setAnchorEl(null), []);

    const buttonClass = classnames(classes.button, {
      [classes.defaultState]: state === "default",
      [classes.activeState]: state === "active",
      [classes.warningState]: state === "warning",
    });

    const popoverButtonClass = classnames(classes.popoverButton, {
      [classes.darkMode]: darkMode,
    });

    const labelClass = classnames(classes.label, {
      [classes.darkModeLabel]: darkMode,
    });

    const tooltipTitle = title && (
      <ActionButtonTitle title={title} hotKey={hotKey} />
    );

    if (isPopoverButton) {
      return (
        <Tooltip title={tooltipTitle} placement="top">
          <Box width="100%">
            <Button
              className={classes.smallButton}
              data-testid="action-button-on-popover"
              aria-label={title}
              aria-labelledby="action-button-title"
              title={title}
              {...rest}
              ref={ref}
              disabled={disabled}
              variant="ghost"
              color={isActive ? "blue" : "dark"}
              startAdornment={icon}
              fullWidth
              startAdornmentClassName={classes.smallButtonIcon}
              contentClassName={classes.smallButtonText}
              onClick={handleClickEvent}
            >
              {label}
              {hotKey ? (
                <ActionHotkey
                  hotKey={hotKey}
                  handleClickEvent={handleClickEvent}
                />
              ) : null}
            </Button>
          </Box>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={tooltipTitle} placement="top">
        <div className={classes.container}>
          <div className={classnames(classes.buttonContainer, buttonClassName)}>
            <Button
              className={buttonClass}
              data-testid="action-button"
              aria-label={title}
              aria-labelledby="action-button-title"
              {...rest}
              ref={ref}
              size="sm"
              variant="ghost"
              color="dark"
              disabled={disabled}
              onClick={handleClickEvent}
              contentClassName={classes.content}
            >
              {icon}
            </Button>
            {label ? (
              <Typography
                className={labelClass}
                onClick={handleClickEvent}
                variant="caption"
              >
                {label}
              </Typography>
            ) : null}
          </div>
          {rightComponent}
          {hotKey ? (
            <ActionHotkey hotKey={hotKey} handleClickEvent={handleClickEvent} />
          ) : null}
          {popoverChildren ? (
            <ClickAwayListener
              onClickAway={closePopover}
              mouseEvent="onMouseDown"
            >
              <div>
                <Button
                  className={popoverButtonClass}
                  onClick={togglePopover}
                  size="xs"
                  variant="ghost"
                  color="dark"
                >
                  <ArrowDropUpRounded />
                </Button>
                <Popper
                  open={isPopoverOpen}
                  anchorEl={anchorEl}
                  popperOptions={{
                    modifiers: {
                      offset: {
                        offset: "0,48",
                      },
                    },
                  }}
                  placement="top"
                >
                  {popoverChildren}
                </Popper>
              </div>
            </ClickAwayListener>
          ) : null}
        </div>
      </Tooltip>
    );
  },
);

export default memo(ActionButton);
